import Scene from "@/webgl/Scene";
import Program from "nanogl/program";
import gallery_background_vert from "./gallery-background.vert";
import gallery_background_frag from "./gallery-background.frag";
import GLConfig from "nanogl-state/config";
import Rect from "nanogl-primitives-2d/rect";
import { mat4 } from "gl-matrix";
import Camera from "nanogl-camera";
import Texture2D from "nanogl/texture-2d";
import gsap from "gsap";
import { TextureResource } from "@/webgl/assets/TextureResource";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import Paths from "@/core/Paths";

const IMVP = mat4.create();

export default class GalleryBackground {

  rect: Rect;
  prg: Program;
  alpha: number = 0;
  opaqueCfg: GLConfig;
  blendedCfg: GLConfig;
  private _loadPromise: Promise<void>;
  private texture: Texture2D;

  get cfg(): GLConfig{
    return this.alpha !== 1 ? this.blendedCfg : this.opaqueCfg;
  }


  constructor(
    public readonly scene: Scene
  ) {

    this.rect = new Rect(scene.gl);

    this.prg = new Program(
      scene.gl,
      gallery_background_vert(),
      gallery_background_frag(),
      scene.programs.getGlobalDefinitions()
    )


    const gl = this.scene.gl;
    this.blendedCfg = new GLConfig();
    this.blendedCfg
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      .enableCullface(true)
      .cullFace(gl.FRONT);

    this.opaqueCfg = new GLConfig();
    this.opaqueCfg
      .enableDepthTest(false)
      .enableBlend(false)
      .enableCullface(true)
      .cullFace(gl.FRONT);

  }

  load():Promise<void>{
    if( !this._loadPromise ){
      this._loadPromise = this._doload()
    }
    return this._loadPromise
  }


  async _doload():Promise<void>{
    const res = new TextureResource( new TextureSrcSet(Paths.resolve("assets/webgl/gradient_purple_light.png")), this.scene )
    const tex = await res.load()
    this.texture = tex;
  }

  // setTexture(texture: Texture2D) {
  //   this.texture = texture;
  // }

  show(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: 1, duration: 0.9, onComplete: resolve })
    });
  }

  hide(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: 0, duration: 1.5, onComplete: resolve })
    });
  }

  render(camera: Camera) {

    mat4.invert(IMVP, camera._viewProj);

    const prg = this.prg;
    const rect = this.rect;
    const scene = this.scene;

    prg.use();

    prg.uIMVP(IMVP);
    prg.tSkyColor(this.texture);
    prg.uAlpha(this.alpha);
    prg.uTime((this.scene.time * 0.08) % 1.0);

    rect.bind();
    rect.attribPointer(prg);

    scene.glstate.push(this.cfg);
    scene.glstate.apply();
    rect.render();
    scene.glstate.pop();

  }

}