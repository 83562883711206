
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useActor } from "@xstate/vue";

import AppService from "@/store/states/AppService";
import type { PovDatas } from "@/store/states/AppStateMachine";
import Viewport from "@/store/modules/Viewport";
import { THEME as UIBtnPrimaryTheme } from "@/components/UIBtn/UIBtnPrimary.vue";

import MiniMap from "@/components/MiniMap/MiniMap.vue";
import CustomCursor from "@/components/CustomCursor/CustomCursor.vue";
import ArtworkAPI from "@/components/ArtworkAPI/ArtworkAPI.vue";
import Gtm from "@/utils/Gtm";
import Browser from "@/utils/Browser";
import { anime, animeFromTo } from "@/utils/anime";

export default defineComponent({
  components: {
    MiniMap,
    ArtworkAPI,
    CustomCursor,
  },

  setup() {
    Gtm.sendVPV("photo-gallery");

    // Element/Components refs
    const titleRef = ref(null);
    const subtitleRef = ref<HTMLDivElement>(null);
    const minimapRef = ref(null);
    const dragHintRef = ref<HTMLDivElement>(null);

    // Others
    const povDatas = ref(null);
    const isDesktop = ref(Viewport.isDesktop);
    const showDragHint = ref(Viewport.isDesktop);
    const showCustomCursor = ref(false);
    const { state, send } = useActor(AppService);
    let tm = -1;
    let tm0 = -1;

    const onEnter = () => {
      const title = titleRef.value.$el;
      const subtitle = subtitleRef.value;
      const minimap = minimapRef.value.$el;
      const dragHint = dragHintRef.value;

      const ps: Promise<any>[] = [];

      if (title) {
        ps.push(
          animeFromTo(title, {
            y: [100, 0],
            opacity: [0, 1],
          })
        );
      }

      if (subtitle) {
        ps.push(
          animeFromTo(subtitle, {
            y: [100, 0],
            opacity: [0, 1],
            delay: 0.125,
          })
        );
      }

      if (minimap) {
        ps.push(
          animeFromTo(minimap, {
            y: [100, 0],
            opacity: [0, 1],
            delay: 0.25,
            onComplete: () => {
              if (dragHint) {
                dragHint.classList.remove("DragHint__Hidden");
              }
            },
          })
        );
      }

      return Promise.all(ps);
    };

    const onLeave = async () => {
      const title = titleRef.value.$el;
      const subtitle = subtitleRef.value;
      const minimap = minimapRef.value.$el;

      const items: HTMLElement[] = [];

      if (title) items.push(title);
      if (subtitle) items.push(subtitle);
      if (minimap) items.push(minimap);

      if (items.length > 0) {
        return Promise.all([
          anime(items, {
            opacity: 0,
          }),
        ]);
      }
    };

    watch(state, (newState) => {
      if (newState.event.type === "LOADING_OUTRO_COMPLETE") {
        onEnter();
      } else if (newState.event.type === "GOTO_PAINTING") {
        onLeave();
      }

      if (newState.event.type === "GALLERY_HOVER") {
        povDatas.value = newState.context.galleryPovDatas;
        clearTimeout(tm0);
        showCustomCursor.value = Viewport.isDesktop;
      } else if (newState.event.type === "GALLERY_OUT") {
        povDatas.value = null;
        clearTimeout(tm0);
        tm0 = setTimeout(() => {
          showCustomCursor.value = false;
        }, 250);
      }

      if (dragHintRef.value) {
        if (newState.event.type === "GALLERY_DRAGSTART") {
          dragHintRef.value.classList.add("DragHint__Hidden");
        } else if (newState.event.type === "GALLERY_DRAGEND") {
          clearTimeout(tm);
          tm = setTimeout(() => {
            if (dragHintRef.value) {
              dragHintRef.value.classList.remove("DragHint__Hidden");
            }
          }, 5000);
        }
      }
    });

    return {
      ...useActor(AppService),
      showDragHint,
      showCustomCursor,
      isDesktop,
      povDatas,
      dragHintRef,
      minimapRef,
      titleRef,
      subtitleRef,
    };
  },

  data() {
    return {
      photos: <PovDatas[]>[
        {
          position: [4.551731586456299, 1.5767663717269897, -5.623378276824951],
          rotation: [
            0.0184288639575243, 0.8856275677680969, 0.03527455031871796,
            -0.4626878499984741,
          ],
          format: "landscape",
          brightness: 0.5,
          contrast: 1,
          saturation: 1,
          temperature: 0.8,
          zoom: 1.0,
        },
        {
          position: [3.784916400909424, 1.0155620574951172, -8.120975494384766],
          rotation: [
            0.10782328248023987, 0.2442222535610199, 0.02733554318547249,
            -0.9633185267448425,
          ],
          format: "landscape",
          brightness: 1,
          contrast: 0.14,
          saturation: 0.41,
          temperature: 0.3,
          zoom: 0.5,
        },
        {
          position: [
            3.0846498012542725, 1.061795949935913, -3.9843838214874268,
          ],
          rotation: [
            -0.051345959305763245, -0.5087366700172424, 0.030414551496505737,
            -0.8588513135910034,
          ],
          format: "landscape",
          brightness: 1,
          contrast: 0.5,
          saturation: 0.41,
          temperature: 0.5,
          zoom: 0.2,
        },
      ],
      currentPhoto: null,
    };
  },

  methods: {
    openPhoto(photo: PovDatas) {
      this.send({ type: "OPEN_PHOTO", pov: photo });
    },
  },
});
