
// Probe 
var coeffs = 
[
    /* Band 0 */  2.211855603435530337,  2.154120080599764009,  2.721365248236911505 ,
    /* Band 1 */ -0.241443929718203332, -0.315348384218540390, -0.386094466545613690 ,  -0.315656544372200087, -0.345004601812114398, -0.371348198068470081, -0.381490134449043117, -0.382883602699976511, -0.445172070344556303,
    /* Band 2 */  -0.052988249697284301, -0.045879412766681296, -0.052807246913868414 ,   0.576117287412694523,  0.632470229246972937,  0.795053026752424730 ,   0.198499161825565068,  0.213880745630542601,  0.278524540769143902 ,   0.128875644244899140,  0.112087493661581078,  0.094323808483675692 ,  -0.092457229954308925, -0.080212373884958166, -0.093594576957277895 ,
    /* Band 3 */   0.078255884575695031,  0.107462354187343664,  0.130506980812617024 ,  -0.032549037190837779, -0.028768783173331255, -0.046124587447104119 ,  -0.494967037406280785, -0.568565458559982528, -0.670467982718051525 ,  -0.026245160527692640, -0.025345592024095431, -0.031527290192407686 ,   0.184622873781805474,  0.203771059917702252,  0.215320118895292234 ,   0.564511992683378927,  0.576769115917082353,  0.694010441012431589 ,  -0.171970220970813936, -0.215286930098871010, -0.274713362679470385 ,
    /* Band 4 */   0.031387661044751526,  0.032887647514917455,  0.040641819445349295 ,  -0.257394023383852533, -0.292582820539653454, -0.361220383246709298 ,  -0.045808998582320452, -0.043742227035465070, -0.069403798453811963 ,   0.156529611930546209,  0.165061006564868384,  0.201862237473404288 ,   0.008871434907664507,  0.022223081196415320, -0.002320457348577388 ,  -0.074721117402492834, -0.085672494017804954, -0.131470667308863198 ,  -0.390190812740551118, -0.443972932273951126, -0.552767534319581921 ,  -0.031744604133752179, -0.022738655162742084, -0.008521007799641694 ,   0.428484331673698593,  0.468154210081977828,  0.577275196826729564 
];

module.exports = coeffs;