import { CreatePane } from "@/dev/Tweak";
import { TextureFiltering, TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource, TextureWrap } from "@/webgl/assets/TextureResource";
import XRSnapshotFrame from "@/webgl/activities/PaintingXRActivity/xr-snapshot/XRSnapshotFrame";
import { DEG2RAD } from "@/webgl/math";
import { mat4, vec2, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Fbo from "nanogl/fbo";
import Texture2D from "nanogl/texture-2d";
import WebXRView from "@/webgl/xr/WebXRView";
import XRModule from "../../../entities/xr-module/XRModule";
import Scene from "@/webgl/Scene";
import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import Paths from "@/core/Paths";
import Deferred from "@/core/Deferred";
import { PhotoFormat, PhotoFormatDimensions } from "@/store/states/AppStateMachine";
import AppService from "@/store/states/AppService";
import { LocalConfig } from "nanogl-state";
import gsap from "gsap";


const snapdist = 1.0;

const FMT_LIST: Array<PhotoFormat> = ["landscape", "square", "portrait"]
const SCALES = {
  "landscape": {
    scale: vec2.fromValues(1.33333, 1.0)
  },
  "square": {
    scale: vec2.fromValues(1.0, 1.0)
  },
  "portrait": {
    scale: vec2.fromValues(1.0, 1.33333)
  }
} as Record<PhotoFormat, {scale: vec2}>

export default class XRSnapshot {

  camera: Camera<PerspectiveLens>;

  fbo: Fbo;
  _isSnapPending: boolean = false;

  xrframe: XRSnapshotFrame;
  xrview: WebXRView;

  private _enabled: boolean = false;
  private _hasSnapshot: boolean = false;
  private _currentFormat: PhotoFormat = "landscape";
  private _sizeIdx: number = 0;
  private _snapDeffer: Deferred;
  private _canSnapshot: boolean = false;

  get scale(): vec2 {
    return SCALES[this._currentFormat].scale;
  }

  get width(): number {
    return PhotoFormatDimensions[this._currentFormat].width;
  }

  get height(): number {
    return PhotoFormatDimensions[this._currentFormat].height;
  }

  get Texture(): Texture2D {
    return this.fbo.getColorTexture();
  }

  get HasSnapshot(): boolean {
    return this._hasSnapshot;
  }

  get Enabled(): boolean {
    return this._enabled;
  }

  get CanSnapshot(): boolean {
    return this._canSnapshot;
  }

  get CurrentFormat(): PhotoFormat {
    return this._currentFormat;
  }

  get scene(): Scene {
    return this.xrmodule.scene;
  }

  resources: ResourceGroup;
  private _loadPromise: Promise<void>;
  private blitCfg: LocalConfig;

  constructor(
    public readonly xrmodule: XRModule,
    public readonly activity: PaintingXRActivity
  ) {

    this.resources = new ResourceGroup();

    this.fbo = new Fbo(this.scene.gl);
    this.fbo.attachColor();
    this.fbo.attachDepth();
    this.fbo.resize(this.width, this.height);

    this.camera = new Camera(new PerspectiveLens());
    this.camera.lens.setVerticalFov(55.0 * DEG2RAD);
    this.camera.lens.near = 0.1;
    this.camera.lens.far = 1000.0;

    const set = new TextureSrcSet(Paths.resolve("assets/webgl/photo_frame_x2.png"));
    set.options = {
      bbc: false,
      flipY: true,
      genMips: false,
      wrap: TextureWrap.CLAMP,
      filtering: TextureFiltering.LINEAR
    }

    const res = new TextureResource(set, this.scene);
    this.resources.add(
      res,
      "photo-frame"
    )

    this.xrframe = new XRSnapshotFrame(this);
    this.xrframe.setScale(this.scale, this._currentFormat);

    this.blitCfg = this.scene.glstate.config();
    this.blitCfg
      .enableDepthTest(false)
      .enableBlend(false)
      .enableCullface(false);

  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  unload() {
    this.xrframe.unload();
  }

  private async _doLoad() {
    await this.resources.load();
    this.init();
  }

  init() {
    this.resources.get("photo-frame").setFilter(true, false, true);
    this.xrframe.setFrameTexture(this.resources.get("photo-frame") as Texture2D);
    this.xrframe.init();
    this.xrmodule.xrcamera.head.add(this.camera);
  }

  enable() {
    this._enabled = true;
  }

  disable() {
    this._enabled = false;
    this._canSnapshot = false;
    this.xrmodule.tooltips.pop("Right/Trigger", "");
    this.xrmodule.tooltips.pop("Right/Thumbstick", "");
    this.xrmodule.tooltips.pop("Right/Squeeze", "");
    this.clear();
  }

  changeSize() {
    this.clear();
    this._sizeIdx += 1;
    this._sizeIdx = this._sizeIdx % FMT_LIST.length;
    this._currentFormat = FMT_LIST[this._sizeIdx];
    AppService.send("UPDATE_POV_DATA", {
      pov: {
        format: this._currentFormat
      }
    })
    this.xrframe.setScale(SCALES[this._currentFormat].scale, this._currentFormat);
    this.fbo.resize(this.width, this.height);

  }

  clear() {
    this._hasSnapshot = false;
  }

  snap() {

    if (!this._canSnapshot)
      return;

    this.xrmodule.Inputs.getInput("right").gamepad.pulse(1.0, 100);

    const cam = this.xrmodule.xrcamera.cameras[1];

    const INV = mat4.create();
    mat4.invert(INV, cam._wmatrix);

    const tgt = vec3.create();
    vec3.transformMat4(tgt, this.xrframe.getTarget(), INV);

    this.camera.lookAt(tgt)
    this.camera.lens.aspect = this.width / this.height;
    this.camera.lens.setVerticalFov(this.xrframe.getFOV(cam._wposition as vec3));
    this.camera.updateWorldMatrix();
    this.camera.updateViewProjectionMatrix(this.width, this.height);

    this.activity.renderSnapshot(this.camera, this.fbo);
    this._hasSnapshot = true;

    this.xrmodule.tooltips.pop("Right/Trigger", "");
    this.xrmodule.tooltips.pop("Right/Thumbstick", "");
    this.xrmodule.tooltips.pop("Right/Squeeze", "");
    this._canSnapshot = false;

  }

  snapshotToBlob (): Promise<Blob>{

    gsap.killTweensOf(this.xrframe, "processMode");

    this.xrframe.processMode = 1.0;
    
    const w = PhotoFormatDimensions[AppService.state.context.povDatas.format].width;
    const h = PhotoFormatDimensions[AppService.state.context.povDatas.format].height;

    const sw = this.scene.glview.canvas.width;
    const sh = this.scene.glview.canvas.height;

    this.scene.glview.canvas.width = w;
    this.scene.glview.canvas.height = h;

    const gl = this.scene.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, w, h);
    gl.clearColor(1, 1, 1, 1)
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);

    const prg = this.scene.programs.get("blit");
    prg.use();
    prg.tTex(this.fbo.getColorTexture());

    this.scene.quad.bind();
    this.scene.quad.attribPointer(prg);

    this.scene.glstate.push(this.blitCfg);
    this.scene.glstate.apply();
    this.scene.quad.render();
    this.scene.glstate.pop();

    const promise = new Promise<Blob>((resolve)=>{
      this.scene.glview.canvas.toBlob((blob)=>{
        gsap.to(this.xrframe, {processMode: 0.0, duration: 0.4});
        resolve(blob);
      });
    });

    this.scene.glview.canvas.width = sw;
    this.scene.glview.canvas.height = sh;

    return promise;
    
  }

  preRender() {

    if (!this._enabled)
      return;

    this.xrframe.preRender();

    const cansnapshot = vec3.dist(this.camera._wposition as vec3, this.xrframe.wposition) < snapdist;

    if(this.HasSnapshot)
      return;

    if (cansnapshot && !this._canSnapshot) {
      this.xrmodule.tooltips.push("Right/Trigger", "");
      this.xrmodule.tooltips.push("Right/Thumbstick", "");
      this.xrmodule.tooltips.push("Right/Squeeze", "");
    }

    if (!cansnapshot && this._canSnapshot) {
      this.xrmodule.tooltips.pop("Right/Trigger", "");
      this.xrmodule.tooltips.pop("Right/Thumbstick", "");
      this.xrmodule.tooltips.pop("Right/Squeeze", "");
    }

    this._canSnapshot = cansnapshot;
  }

  render(camera: Camera) {

    if (this.HasSnapshot || (this._enabled && this._canSnapshot)) {
      this.xrframe.render(camera);
    }

  }

}