import AppService from "@/store/states/AppService";
import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import StateHelper from "@/webgl/activities/StateHelper";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";

const NOOP = () => { };

export default class XRPaintingInputHelper {

  private readonly xrmodule: XRModule;

  stateHelper: StateHelper;
  private _inputCallback: Function = NOOP;

  constructor(
    public readonly activity: PaintingXRActivity
  ) {

    this.xrmodule = activity.xrmodule;

    this.stateHelper = new StateHelper([
      { match: 'painting.explore', enter: this.enterExplore, exit: this.exitExplore },
      { match: 'painting.photo.tutorial', enter: this.enterPhotoTutorial, exit: this.exitPhotoTutorial },
      { match: 'painting.photo.default', enter: this.enterPhoto, exit: this.exitPhoto },
      { match: 'share.preview', enter: this.enterPreview, exit: this.exitPreview },
      { match: 'share.select', enter: this.enterShare, exit: this.exitShare },
    ])

  }

  start() {
    this.xrmodule.xrview.inputs.onBtnDown.on(this.btnDown);
    this.stateHelper.start();
  }

  stop() {
    this._inputCallback = NOOP;
    this.xrmodule.xrview.inputs.onBtnDown.off(this.btnDown);
    this.stateHelper.stop();
  }

  btnDown = (evt: ButtonEvent) => {
    this._inputCallback(evt);
  }



  /*
  EXPLORE
  */
  enterExplore = () => {
    // if (AppService.state.context.paintingPhotoTutorialCompleted) {
    this.xrmodule.tooltips.push("A", "xr.photo_gallery");
    this.xrmodule.tooltips.push("B", "xr.museum");
    // }
    this.xrmodule.tooltips.push("Left/Trigger", "xr.photo_mode", false);
    this._inputCallback = this.btnDownExplore;
  }

  exitExplore = () => {
    // if (AppService.state.context.paintingPhotoTutorialCompleted) {
    this.xrmodule.tooltips.pop("A", "xr.photo_gallery");
    this.xrmodule.tooltips.pop("B", "xr.museum");
    // }
    this.xrmodule.tooltips.pop("Left/Trigger", "xr.photo_mode");
    this._inputCallback = NOOP;
  }

  btnDownExplore = (evt: ButtonEvent) => {
    switch (evt.name) {
      case "A":
        // if (AppService.state.context.paintingPhotoTutorialCompleted) {
        AppService.send("UPDATE_POV_DATA", { pov: this.activity.getPovDatas() });
        this.activity.fader.fadeIn(1.0).then(() => {
          AppService.send("GOTO_GALLERY");
        });
        // }
        break;
      case "B":
        // if (AppService.state.context.paintingPhotoTutorialCompleted) {
        AppService.send("GOTO_MUSEUM");
        // }
        break;
      case "Left/Thumbstick":
      case "Left/Trigger":
      case "X":
      case "Y":
        AppService.send("ENTER_PHOTO");
        break;
      default:
        break;
    }
  }



  /*
  PHOTO TUTORIAL
  */
  enterPhotoTutorial = () => {
    this.xrmodule.tooltips.push("X", "");
    this.xrmodule.tooltips.push("Y", "");
    this.xrmodule.tooltips.push("Left/Trigger", "");
    this.xrmodule.tooltips.push("Left/Thumbstick", "");
    this._inputCallback = this.btnDownPhotoTutorial;
  }

  exitPhotoTutorial = () => {
    this.xrmodule.tooltips.pop("X", "");
    this.xrmodule.tooltips.pop("Y", "");
    this.xrmodule.tooltips.pop("Left/Trigger", "");
    this.xrmodule.tooltips.pop("Left/Thumbstick", "");
    this._inputCallback = NOOP;
  }

  btnDownPhotoTutorial = (evt: ButtonEvent) => {
    switch (evt.name) {
      case "Right/Thumbstick":
      case "Right/Squeeze":
        if (this.activity.snapshot.CanSnapshot && !this.activity.snapshot.HasSnapshot)
          this.activity.snapshot.changeSize();
        break;
      default:
        break;
    }

  }




  /*
  PHOTO
  */
  enterPhoto = () => {
    this.xrmodule.tooltips.push("X", "", true);
    this.xrmodule.tooltips.push("Y", "", true);
    this.xrmodule.tooltips.push("Left/Trigger", "", true);
    this.xrmodule.tooltips.push("Left/Thumbstick", "", true);
    this.xrmodule.tooltips.push("Left/Squeeze", "", true);
    this._inputCallback = this.btnDownPhoto;
  }

  exitPhoto = () => {
    this.xrmodule.tooltips.pop("X", "");
    this.xrmodule.tooltips.pop("Y", "");
    this.xrmodule.tooltips.pop("Left/Trigger", "");
    this.xrmodule.tooltips.pop("Left/Thumbstick", "");
    this.xrmodule.tooltips.pop("Left/Squeeze", "");
    this._inputCallback = NOOP;
  }

  btnDownPhoto = (evt: ButtonEvent) => {
    switch (evt.name) {

      case "Left/Thumbstick":
      case "Left/Trigger":
        if (!this.activity.settingswheel.InReset)
          AppService.send("CANCEL");
        break;

      // case "X":
      // case "Y":
      // case "Left/Trigger":
      //   AppService.send("CANCEL");
      //   break;

      case "Right/Trigger":
        if (this.activity.snapshot.CanSnapshot)
          AppService.send("SUBMIT");
        break;

      case "Right/Thumbstick":
      case "Right/Squeeze":
        if (this.activity.snapshot.CanSnapshot && !this.activity.snapshot.HasSnapshot)
          this.activity.snapshot.changeSize();
        break;

      default:
        break;
    }
  }


  /*
  PREVIEW
  */
  enterPreview = () => {
    this.xrmodule.tooltips.push("A", "xr.validate");
    this.xrmodule.tooltips.push("B", "xr.cancel");
    this._inputCallback = this.btnDownPreview;
  }

  exitPreview = () => {
    this.xrmodule.tooltips.pop("A", "xr.validate");
    this.xrmodule.tooltips.pop("B", "xr.cancel");
    this._inputCallback = NOOP;
  }

  btnDownPreview = (evt: ButtonEvent) => {

    switch (evt.name) {
      case "A":
        AppService.send("SUBMIT");
        break;
      case "B":
        AppService.send("CANCEL");
        break;

      default:
        break;
    }
  }



  /*
  SHARE
  */
  enterShare = () => {
    this.xrmodule.tooltips.push("A", "xr.photo_gallery");
    this.xrmodule.tooltips.push("B", "xr.painting");
    this._inputCallback = this.btnDownShare;
  }

  exitShare = () => {
    this.xrmodule.tooltips.pop("A", "xr.photo_gallery");
    this.xrmodule.tooltips.pop("B", "xr.painting");
    this._inputCallback = NOOP;
  }

  btnDownShare = (evt: ButtonEvent) => {

    switch (evt.name) {
      case "A":
        // AppService.send("SHARE");
        // this.activity.fader.fadeOut();
        AppService.send("SHARE");
        break;
      case "B":
        AppService.send("CANCEL");
        break;

      default:
        break;
    }
  }



}