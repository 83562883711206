import AppService from "@/store/states/AppService";
import { DefaultPovData } from "@/store/states/AppStateMachine";
import GalleryXRActivity from "@/webgl/activities/GalleryXRActivity";
import StateHelper from "@/webgl/activities/StateHelper";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";

const NOOP = () => { };

export default class XRGalleryInputHelper {

  private readonly xrmodule: XRModule;

  stateHelper: StateHelper;
  private _inputCallback: Function;

  constructor(
    public readonly activity: GalleryXRActivity
  ) {

    this.xrmodule = activity.xrmodule;

    this.stateHelper = new StateHelper([
      { match: 'gallery', enter: this.enterGallery, exit: this.exitGallery },
    ])

  }

  start() {
    this.xrmodule.xrview.inputs.onBtnDown.on(this.btnDown);
    this.stateHelper.start();
  }

  stop() {
    this._inputCallback = NOOP;
    this.xrmodule.xrview.inputs.onBtnDown.off(this.btnDown);
    this.stateHelper.stop();
  }

  btnDown = (evt: ButtonEvent) => {
    this._inputCallback(evt);
  }

  /*
  LIST
  */
  enterGallery = ()=> {
    this.xrmodule.tooltips.push("A", "xr.painting");
    // this.xrmodule.tooltips.push("B", "xr.museum");
    this._inputCallback = this.btnDownGallery;
  }

  exitGallery = ()=> {
    this.xrmodule.tooltips.pop("A", "xr.painting");
    // this.xrmodule.tooltips.pop("B", "xr.museum");
  }

  btnDownGallery = (evt: ButtonEvent) => {
    switch (evt.name) {
      case "A":
        // TODO
        this.activity.title.hide();
        this.activity.floor.hide();
        this.activity.background.hide().then(()=>{
          AppService.send("UPDATE_POV_DATA", {pov: DefaultPovData});
          AppService.send("GOTO_PAINTING");
        })
        break;
      case "B":
        // TODO
        // AppService.send("GOTO_MUSEUM");
        break;
      default:
        break;
    }
  }

}