import MaterialPass from "@/webgl/lib/nanogl-pbr/src/MaterialPass";
import nanoglCamera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import Node from "nanogl-node";
import Program from "nanogl/program";
import vShader from "./gradient-line.vert";
import fShader from "./gradient-line.frag";
import Flag from 'nanogl-pbr/Flag'
import Precision from 'nanogl-pbr/ShaderPrecision'
import Version from 'nanogl-pbr/ShaderVersion'
import Input, { ShaderType, Uniform } from "nanogl-pbr/Input";
import Texture2D from "nanogl/texture-2d";


const MAT_ID = "gradient-line";

/**
 * @extends {BaseMaterial}
 */
export default class GradientLinePass extends MaterialPass {

  version: Version;
  precision: Precision;
  shaderid: Flag<string>;

  progress: Input;
  texcoordOffset: Input;
  color: Texture2D;

  constructor() {

    super({
      uid: MAT_ID,
      vert: vShader(),
      frag: fShader(),
    });

    const inputs = this.inputs;

    inputs.add(this.version = new Version('300 es'));
    inputs.add(this.precision = new Precision('highp'));
    inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));
    inputs.add(this.progress = new Input("progress", 1));
    inputs.add(this.texcoordOffset = new Input("texcoordOffset", 2, ShaderType.ALL));

  }

  prepare(prg: Program, node: Node, camera: nanoglCamera<ICameraLens>): void {

    prg.tGradient(this.color);
    prg.uWorldMatrix(node._wmatrix);
    prg.uVP(camera._viewProj);

  }

}