import BaseEffect from "nanogl-post/effects/base-effect";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";

export default class Temperature extends BaseEffect {

  temperature: number = 6000;
  lut: Texture2D;

  release(): void {

  }

  init(): void {

    this.lut = new Texture2D(this.post.gl, this.post.gl.RGB);
    this.lut.bind();
    this.lut.clamp();
    const img = new Image();
    img.onload = ()=> this.lut.fromImage(img);
    // ORIGINAL
    // img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAAECAIAAADJfaBSAAAACXBIWXMAAAsTAAALEwEAmpwYAAABSklEQVQ4jbWTQU7FMAxEn+2EVogvgbgB978dazIsmrROWxAbrMgaTyeOnTqmjwevnzzgBZ5hhRUWqPAEBQpUqO/UN8pKXSkrZaEsxFP3UYmFqETtodceWhkg8IpXvOCFKFjBCx7YhgMvWHSm844HtuHAHIsOPLAQ1hpfYvMSbcMg0RoSDZqQUEtYNFBDIHXfhAB15hR2pYCDQUcGkr8CLkxxnKvZxQNmYJhhjGWYdU3nZ6Zv9JEqbx/Z9nRmN+R+bqpjypBLtim23MvcWubMrt9/DP/DHBg/l/6bTngzjbE5xJnRRaBpNibBZTHmkxGSeU0l5mxoSj+XtstJKZV6YowzqW+SRvkGEtaJyZpfTDdChzRw+9Rn3G0e/87ZNPunR5Cn9bz2/KdHk17AAeaaZuVx7KkDO87f2Zue0l3YXPXB3uLM/PGl2I3wGxJv3cZjFoLmAAAAAElFTkSuQmCC";
    img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAAECAYAAABGHzcFAAAACXBIWXMAAAsTAAALEwEAmpwYAAABU0lEQVRIicWUXXLoIAiFP0i6ym6xW6znPoiKxHRun8oMIz/nECWo6etT+AV+g3/EerFi4dtVchGzjHXAwe6u7oHxVMMXzzx8B66u7gtD5IZ6iZH4w+dKHAsd9cOfdQxhtAbfYq4StOETfouVnpNArfiRVwMFb6wtbLTip9jkCWCPo70uZX2zeYnfDo71vqxmJRvr5IqZYntM0WyjqD31CQJPOR3wKvhHLUhHLjhWfWX8QmIlmv0dvsVqyg7YH+h/KvcaO9bozRHMY2Nr7M1Xn/NYkXhvtrYPLt+y3dKPipgnvCV+S76z10Z93xkD3bZuS7Yf/dCOuVWeW09VZz+0BV5uZ25bBT/NQvql6J3m55ua7DnmWnEGXgfuSdM3xiEeL0LGHF6AcWvzjZft+5B3rR9zFq/U245bmJZpaWsjmXFT6itSjvV4e07gkxw/9p9y2NOQfxqv2veEg7DVAAAAAElFTkSuQmCC";
    
  }

  resize(w: number, h: number): void {

  }

  preRender(): void {

  }

  setupProgram(prg: Program): void {
    prg.uTemperature(this.temperature);
    prg.tTemperatureLUT(this.lut);
  }


  genCode(precode: string[], code: string[]): void {
    precode.push(/*glsl*/`
    uniform float uTemperature;
    uniform sampler2D tTemperatureLUT;
    #define LUMINANCE_PRESERVATION 0.9
    #define EPSILON 1e-10

    float saturate(float v) { return clamp(v, 0.0,       1.0);       }
    vec2  saturate(vec2  v) { return clamp(v, vec2(0.0), vec2(1.0)); }
    vec3  saturate(vec3  v) { return clamp(v, vec3(0.0), vec3(1.0)); }
    vec4  saturate(vec4  v) { return clamp(v, vec4(0.0), vec4(1.0)); }

    float Luminance(vec3 color)
    {
      float fmin = min(min(color.r, color.g), color.b);
      float fmax = max(max(color.r, color.g), color.b);
      return (fmax + fmin) / 2.0;
    }
    vec3 HUEtoRGB(float H)
    {
        float R = abs(H * 6.0 - 3.0) - 1.0;
        float G = 2.0 - abs(H * 6.0 - 2.0);
        float B = 2.0 - abs(H * 6.0 - 4.0);
        return saturate(vec3(R,G,B));
    }
    
    vec3 HSLtoRGB(in vec3 HSL)
    {
        vec3 RGB = HUEtoRGB(HSL.x);
        float C = (1.0 - abs(2.0 * HSL.z - 1.0)) * HSL.y;
        return (RGB - 0.5) * C + vec3(HSL.z);
    }
    vec3 RGBtoHCV(vec3 RGB)
    {
        // Based on work by Sam Hocevar and Emil Persson
        vec4 P = (RGB.g < RGB.b) ? vec4(RGB.bg, -1.0, 2.0/3.0) : vec4(RGB.gb, 0.0, -1.0/3.0);
        vec4 Q = (RGB.r < P.x) ? vec4(P.xyw, RGB.r) : vec4(RGB.r, P.yzx);
        float C = Q.x - min(Q.w, Q.y);
        float H = abs((Q.w - Q.y) / (6.0 * C + EPSILON) + Q.z);
        return vec3(H, C, Q.x);
    }
    
    vec3 RGBtoHSL(vec3 RGB)
    {
        vec3 HCV = RGBtoHCV(RGB);
        float L = HCV.z - HCV.y * 0.5;
        float S = HCV.y / (1.0 - abs(L * 2.0 - 1.0) + EPSILON);
        return vec3(HCV.x, S, L);
    }
    `);
  
    code.push(/*glsl*/`
    vec3 colorTempRGB = texture2D(tTemperatureLUT, vec2(uTemperature, 0.5)).rgb;

    float originalLuminance = Luminance(c);
        
    vec3 blended = c * colorTempRGB;
    vec3 resultHSL = RGBtoHSL(blended);
    vec3 luminancePreservedRGB = HSLtoRGB(vec3(resultHSL.x, resultHSL.y, originalLuminance));        

    c = mix(blended, luminancePreservedRGB, LUMINANCE_PRESERVATION);

    `);
  }

}