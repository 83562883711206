import { AppState } from "@/store/states/AppStateMachine";
import { vec3 } from "gl-matrix";
import Node from "../../lib/nanogl-gltf/lib/elements/Node";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import Ray from "../../math/Ray";
import { MuseumCameraController } from "./MuseumCameraController";
import HoverController from "./HoverController";
import StateHelper from "../StateHelper";
import AppService from "@/store/states/AppService";
import { InputTouch } from "@/webgl/lib/inputs";
import { AbortController } from "@azure/abort-controller";
import { MuseumPostProcessing } from "./PostProcessing";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import LensFlares from "@/webgl/entities/lensflares";
import { DiveinEffect } from "./DiveinEffect";




export default class MuseumActivity implements IActivity {

  readonly priority : number = 0;

  root: Node;

  cameraController: MuseumCameraController
  hoverController : HoverController       
  stateHelper     : StateHelper           
  postProcessing  : MuseumPostProcessing
  diveinEffect    : DiveinEffect
  lensflares      : LensFlares

  private _startAbortController: AbortController;


  get camera(){
    return this.cameraController.camera
  }

  constructor( public scene: Scene ) {
    
    this.root = new Node()
    
    this.cameraController = new MuseumCameraController( this )
    this.hoverController  = new HoverController( scene.museumScene )
    this.postProcessing   = new MuseumPostProcessing( scene )
    this.lensflares       = new LensFlares( scene  )
    this.diveinEffect     = new DiveinEffect( scene.gl  )


    this.stateHelper = new StateHelper([
      { match:'museum_loading.outro', enter : this.enterLockedMode, exit: this.exitLockedMode },
      { match:'museum.intro', enter : this.enterLockedMode, exit: this.exitLockedMode },
      { match:'museum.explore', enter : this.enterExplore, exit: this.exitExplore, update: this.exploreUpdate },
      { match:'museum.toPainting', enter : this.enterPaintingEntry, exit: this.exitPaintingEntry },
    ])



  }

  onStateUpdate(state: AppState): void {

    
  }

  load(): Promise<void> {
    return Promise.all([
      this.scene.loadMuseumScene(),
      this.lensflares.load()
    ]).then()
  }

  unload(): void {
    this.scene.museumScene.unload()
  }
  
  start(): void {
    this.diveinEffect.reset()

    this.scene.root.add( this.root )
    this.stateHelper.start()
    
    this._startAbortController = new AbortController()
    this.scene.museumScene.quality.onChange.on( this.onQualityChange)
    this.onQualityChange()
    
    this.scene.museumScene.startQualityAutoLevel( this._startAbortController )
  }
  
  stop(): void {
    this.scene.museumScene.quality.onChange.off( this.onQualityChange)
    
    this._startAbortController.abort()
    this._startAbortController = null

    this.scene.root.remove( this.root )
    this.stateHelper.stop()
  }
  


  onQualityChange = ()=>{
    const lvl = this.scene.museumScene.quality.level
    this.postProcessing.enabled = lvl.post
  }



  
  enterLockedMode= ()=>{
    
  }
  
  exitLockedMode= ()=>{
    
  }
  
  enterExplore= ()=>{
    this.cameraController.start()
    this.scene.inputs.onTouchRemoved.on( this.onMouseClick)
  }
  
  onMouseClick=( touch:InputTouch )=>{
    if( touch.getDeltaLength() > .1 || touch.duration>500 ) return;

    const mouseRay = new Ray()
    mouseRay.unproject( touch.ncoords, this.camera )
    this.hoverController.preRender(mouseRay)
    AppService.send( {type:'SELECT_ARTWORK', artworkId:this.hoverController.currentArtwork })
  }
  
  exploreUpdate= (state: AppState)=>{
    this.cameraController.setCurrentArtwork( state.context.selectedArtworkId )
  }
  
  exitExplore= ()=>{
    this.cameraController.stop()
    this.scene.inputs.onTouchRemoved.off( this.onMouseClick)
  }


  enterPaintingEntry = ()=>{
    if( this.cameraController.canGoToMainPainting() ){
      this.diveinEffect.diveIn(3.0)
      this.cameraController.goToMainPainting(3.0).then(()=> AppService.send( {type:'PAINTING_TRANSITION_COMPLETE'}) )
    } else {
      AppService.send( {type:'PAINTING_TRANSITION_COMPLETE'})
    }
  }


  exitPaintingEntry = ()=>{
    this.cameraController.diveinPovProgress = 0
    this.diveinEffect.reset()
  }

  

  preFrame(): void {
  
  }

  preRender(): void {

    this.scene.museumScene.preRender( [this.camera] )
    
    const mouseRay = new Ray()
    mouseRay.unproject( this.scene.inputs.mouseCoords, this.camera )

    this.cameraController.preRender()
    this.hoverController.preRender(mouseRay)
  
    AudioManager.updateListener(this.camera._wmatrix);
    // TODO PROPER WAY
    // 

  }


  render(): void {
    // if( AppService.getSnapshot().matches("museum_loading.outro")) return
    
    const vpw = this.scene.viewport.width
    const vph = this.scene.viewport.height

    this.camera.updateViewProjectionMatrix( vpw, vph )

    this.postProcessing.bindFrameBuffer(vpw, vph);
    this.scene.museumScene.render( this.camera )
    this.lensflares.render(this.camera)

    if( this.diveinEffect.active && this.postProcessing.enabled ){
      this.postProcessing.blit(this.diveinEffect.fbo.width, this.diveinEffect.fbo.height, this.diveinEffect.fbo.fbo );
      this.diveinEffect.render(vpw, vph)
    } else {
      this.postProcessing.blit(vpw, vph);
    }
    
  }

  renderUI(): void {
  
  }


  rttPass(): void {
    // if( AppService.getSnapshot().matches("museum_loading.outro")) return
    this.scene.museumScene.reflectPass( [this.camera])
  }


  testNavMesh() {
    const ray = new Ray()
    const POS = vec3.create()
    const NRM = vec3.create()
    ray.unproject( this.scene.inputs.mouseCoords, this.camera )
    if( this.scene.museumScene.navmesh.raycast( ray, POS, NRM ) ){
      // DebugDraw.drawGuizmo(POS)
      // console.log(POS);
      
    }
  }

}