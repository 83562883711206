import Scene from "@/webgl/Scene";
import Paths from "@/core/Paths";
import { CubeSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureCubeResource } from "@/webgl/assets/TextureResource";
import GraniteProbeEffect from "@/webgl/entities/granite-probe/GraniteProbeEffect";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Input, { Attribute } from "nanogl-pbr/Input";
import Camera from "nanogl-camera";
import Texture2D from "nanogl/texture-2d";

export default class GraniteProbe {

  textureRes: TextureCubeResource;
  effect: GraniteProbeEffect;

  constructor(
    public readonly scene: Scene,
    path: string
  ) {

    let srcset = new CubeSrcSet({
      'std': [
        Paths.resolve(`${path}/px.png`),
        Paths.resolve(`${path}/py.png`),
        Paths.resolve(`${path}/pz.png`),
        Paths.resolve(`${path}/nx.png`),
        Paths.resolve(`${path}/ny.png`),
        Paths.resolve(`${path}/nz.png`),
      ]
    })

    this.textureRes = new TextureCubeResource(srcset, this.scene)

    this.effect = new GraniteProbeEffect();

  }

  load(): Promise<void> {
    return this.textureRes.load().then();
  }

  unload() {
    this.effect = null;
    this.textureRes.unload();
  }

  setupMaterial(material: UnlitMaterial) {

    this.effect.setCubemap(this.textureRes.texture);
    this.effect.install(material.materialPass as UnlitPass);

  }

  setReflectTex(texture: Texture2D) {
    this.effect.setReflectTex(texture);
  }

  prepare(camera: Camera) {
    this.effect.prepare(camera);
  }

}