import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c85ce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LocaleChanger" }
const _hoisted_2 = { class: "LocaleChanger__Label small-caps" }
const _hoisted_3 = { class: "LocaleChanger__Label--small" }
const _hoisted_4 = { class: "LocaleChanger__Label--full" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "LocaleChanger__Dropdown" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.locale), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('locale_name')), 1)
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "small-caps",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (item) => {
        return (_openBlock(), _createElementBlock("option", {
          key: `locale-${item.locale}`,
          value: item.locale
        }, _toDisplayString(_ctx.rt(item.name)), 9, _hoisted_5))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.locale]
    ]),
    _createElementVNode("ul", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `locale-${item.locale}`,
          class: _normalizeClass(["LocaleChanger__Item", { current: item.locale === _ctx.locale }])
        }, [
          _createElementVNode("button", {
            type: "button",
            class: "LocaleChanger__Label small-caps",
            onClick: ($event: any) => (_ctx.locale=item.locale)
          }, _toDisplayString(_ctx.rt(item.name)), 9, _hoisted_7)
        ], 2))
      }), 128))
    ])
  ]))
}