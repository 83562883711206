
import { computed, defineComponent, watch } from 'vue'
import gsap from 'gsap'
import { useActor } from '@xstate/vue'
import PortalLoader from "@/components/PortalLoader/PortalLoader.vue"

import { useStore } from '@/store'
import AppService from '@/store/states/AppService'
import Viewport from '@/store/modules/Viewport'
import SplitText from '@/utils/SplitText'
import Browser from '@/utils/Browser'

export default defineComponent({

  components: {
    PortalLoader
  },

  setup() {

    const progress = computed(() => {
      return Math.round(useStore().state.app.load_progress * 100) + "%";
    });

    return {
      ...useActor(AppService),
      progress,
    }
  },

  data() {
    return {
      opacity: 0,
      finaleScale: null,
      fstate: 'none',
    }
  },

  methods: {

    onClick() {
      // if( this.state.matches('loading') ) {
      //   this.send('LOADING_COMPLETE');
      // }
    },

    onEnter() {
      if( this.fstate === 'enter' ) return;
      this.fstate = 'enter'
      const $ = gsap.utils.selector(document)

      new SplitText($('.Loading__Claim'), { type: 'lines', linesClass: 'Line' })

      const archeFront = $('.LoadingFrontLayer__arche')

      const tl = gsap.timeline({
        delay: 0.2,
        onComplete: () => {
          this.fstate = 'none'
          this.send('LOADING_INTRO_COMPLETE');
        }
      })

      tl
        .from('.Loading__Progress', {
          autoAlpha: 0,
          duration: 1.5
        }, 'start')
        .from('.Loading__Claim .Line', {
          y: 85,
          ease: 'customOut2',
          duration: 1.6,
          stagger: 0.1
        }, 'start+=0.233')
        .from('.Loading__Claim .Line', {
          autoAlpha: 0,
          ease: 'customOut',
          duration: 1.233,
          stagger: 0.1
        }, 'start+=0.367')
    },

    onReveal() {
      if( this.fstate === 'reveal' ) return;
      this.fstate = 'reveal'
      const $ = gsap.utils.selector(document)
      const glViewer = $('.glviewer')
      const archeFront = $('.LoadingFrontLayer__arche')
      const archeContainer = document.querySelector('.Loading .Loading__Inner') as HTMLDivElement

      gsap.set([glViewer, $('.LoadingFrontLayer__mask')], {
        clearProps: 'all'
      })

      if (archeContainer) {
        this.finaleScale = Viewport.isLandscape ? Viewport.windowWidth / archeContainer.offsetWidth : Viewport.windowHeight / archeContainer.offsetHeight
      }

      const tl = gsap.timeline({
        delay: 1,
        onComplete: () => {
          this.fstate = 'none'
          this.send('LOADING_OUTRO_COMPLETE')

          gsap.set([glViewer, $('.LoadingFrontLayer__mask')], {
            clearProps: 'all'
          })
        }
      })

      if (Browser.mobileVersion) {
        gsap.set($('.LoadingFrontLayer__mask'), { autoAlpha: 1, })

        tl
        .set(glViewer, {
          zIndex: 2,
        })
        .to($('.LoadingFrontLayer__mask'), {
          scaleX: this.finaleScale * 1.3,
          scaleY: this.finaleScale * 1.3,
          duration: 2,
          ease: 'archeOut',
        }, 'start')

      } else {
        let scaleX = 1
        let scaleY = 1
        if (Viewport.isDesktop) {
          scaleX = 298 / Viewport.windowWidth
          scaleY = 413 / Viewport.windowHeight
        } else {
          scaleX = 226 / Viewport.windowWidth
          scaleY = 313 / Viewport.windowHeight
        }

        tl.set(glViewer, {
          zIndex: 2,
          clipPath: 'url(#portalMask)'
        })
        .set($('#portalMask path'), {
          transformOrigin: '50% 50%',
          scaleX,
          scaleY
        })
        .to($('#portalMask path'), {
          scaleX: scaleX * this.finaleScale * 1.1,
          scaleY: scaleY * this.finaleScale * 1.1,
          // scale: 2,
          // scale: this.finaleScale * 1.1,
          duration: 2,
          // x: Viewport.windowWidth * 0.5,
          // y: Viewport.windowHeight * 0.5,
          // yPercent: -50,
          // xPercent: -50,
          // transformOrigin: 'center center',
          ease: 'archeOut',
        }, 'start')
      }

      tl.from(glViewer, {
        autoAlpha: 0,
        duration: 1,
        ease: 'customOut'
      }, 'start')
      
      .to('.Loading .InsetBorder', {
        autoAlpha: 0,
        duration: 0.867,
        ease: 'customOut'
      }, 'start')
      .to(archeFront, {
        autoAlpha: 1,
        duration: 0.867,
        ease: 'customOut'
      }, 'start')
      .to(archeFront, {
        autoAlpha: 0,
        duration: 0.867,
        ease: 'customOut'
      }, 'start+=0.867')
      .to(archeFront, {
        scale: this.finaleScale * (Browser.mobileVersion ? 1.4 : 1.1),
        duration: 2,
        ease: 'archeOut'
      }, 'start')
  }
},

  watch: {
  state: {

    handler(newState, oldState) {


      // trigger open anim
      if (
        this.state.matches('museum_loading.fromhome') || this.state.matches('museum_loading.intro') ||
        this.state.matches('painting_loading.fromhome') || this.state.matches('painting_loading.intro') ||
        this.state.matches('gallery_loading.intro') || this.state.matches('gallery_loading.fromshare')
      ) {
        this.$nextTick(this.onEnter)
      }


      // trigger exit anim
      if (
        this.state.matches('museum_loading.outro') ||
        this.state.matches('painting_loading.outro') ||
        this.state.matches('gallery_loading.outro')
      ) {
        this.onReveal()
      }


    },

    immediate: true

  },
  // state(){
  // }
},
})
