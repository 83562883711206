import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Camera from "nanogl-camera";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Scene from "@/webgl/Scene";
import { WebXRInput } from "@/webgl/xr/WebXRInputs";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import type { XRHandedness } from "webxr";
import Ray from "@/webgl/math/Ray";

const M3 = mat3.create();
const QUATA = quat.create(); 
const VRNZ = vec3.fromValues(0.0, 0.0, -1.0);

export default class GamepadEntity {

  ray: Ray;

  constructor(
    public readonly hand: XRHandedness,
    public readonly skin: Node,
    public readonly mesh: Node
  ) {

    this.skin.add(this.mesh);
    this.ray = new Ray();

  }

  update(input: WebXRInput) {

    if (!input.pose)
      return;

    this.skin.setMatrix(input.pose.transform.matrix as mat4);

    mat3.fromMat4(M3, this.skin._wmatrix);
    quat.fromMat3(QUATA, M3);
    this.ray.pos.set(this.skin._wposition);
    vec3.transformQuat(this.ray.dir, VRNZ, QUATA);

  }

  render(
    scene: Scene,
    camera: Camera
  ) {

    this.mesh.renderable?.render(
      scene,
      camera,
      Masks.OPAQUE,
      Passes.DEFAULT
    )

  }

}