import { IActivity } from "@/webgl/activities/Activity";
import MuseumActivity from "@/webgl/activities/MuseumActivity";
import MuseumXRActivity from "@/webgl/activities/MuseumXRActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class MuseumActivityServiceImpl extends ActivityService {
  createActivity(xr: boolean): IActivity {
    const scene = GLApp.getInstance().scene
    return xr ? new MuseumXRActivity(scene) : new MuseumActivity(scene)
  }
}

const MuseumActivityService = new MuseumActivityServiceImpl('museum')

export default MuseumActivityService

/////////////
/////////////////
//////////////////////
////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////
 
//////////