import { mat4, vec3 } from "gl-matrix";
import type Camera from "nanogl-camera";
import type PerspectiveLens from "nanogl-camera/perspective-lens";
import type Node from "nanogl-node";

/**
 * Generates a perspective projection matrix with the given bounds.
 * Passing null/undefined/no value for far will generate infinite projection matrix.
 */
export function perspective(
  out: mat4,
  fovy: number,
  aspect: number,
  near: number,
  far: number
) {
  let f = 1.0 / Math.tan(fovy / 2),
    nf: number;
  out[0] = f / aspect;
  out[1] = 0;
  out[2] = 0;
  out[3] = 0;
  out[4] = 0;
  out[5] = f;
  out[6] = 0;
  out[7] = 0;
  out[8] = 0;
  out[9] = 0;
  out[11] = -1;
  out[12] = 0;
  out[13] = 0;
  out[15] = 0;
  if (far != null && far !== Infinity) {
    nf = 1 / (near - far);
    out[10] = (far + near) * nf;
    out[14] = 2 * far * near * nf;
  } else {
    out[10] = -1;
    out[14] = -2 * near;
  }
  return out;
}

/**
 * Convert vertical field of view to horizontal field of view, given an aspect
 * ratio. See https://arstechnica.com/civis/viewtopic.php?f=6&t=37447
 *
 * @param vfov - The vertical field of view.
 * @param aspect - The aspect ratio, which is generally width/height of the viewport.
 * @returns - The horizontal field of view.
 */
export function vfovToHfov(vfov: number, aspect: number): number {
  return Math.atan(aspect * Math.tan(vfov / 2)) * 2;
}

/**
 * Get the distance from the camera to fit an object in view by either its
 * horizontal or its vertical dimension.
 *
 * @param size - This should be the width or height of the object to fit.
 * @param fov - If `size` is the object's width, `fov` should be the horizontal
 * field of view of the view camera. If `size` is the object's height, then
 * `fov` should be the view camera's vertical field of view.
 * @returns - The distance from the camera so that the object will fit from
 * edge to edge of the viewport.
 */
function _distanceToFitObjectInView(size: number, fov: number): number {
  return size / (2 * Math.tan(fov / 2));
}

export function getDistanceToFitObjectToView(
  cameraAspect: number,
  cameraVFov: number,
  objWidth: number,
  objHeight: number
): number {
  const objAspect = objWidth / objHeight;
  const cameraHFov = vfovToHfov(cameraVFov, cameraAspect);

  let distance = 0;

  if (objAspect > cameraAspect) {
    distance = _distanceToFitObjectInView(objHeight, cameraVFov);
  } else if (objAspect <= cameraAspect) {
    distance = _distanceToFitObjectInView(objWidth, cameraHFov);
  }

  return distance;
}

export function project(v: vec3, node: Node, camera: Camera) {
  vec3.transformMat4(v, v, node._wmatrix);
  vec3.transformMat4(v, v, camera._viewProj);

  v[0] = v[0] * 0.5 + 0.5;
  // v[0] = v[0] / v[2];
  v[1] = v[1] * 0.5 + 0.5;
  // v[1] = v[1] / v[2];
  v[2] = 0;

  return v;
}

export function cameraOverrideProjection(camera: Camera<PerspectiveLens>) {
  const _updateProjection = function (this: PerspectiveLens) {
    const mode = this._fovMode,
      aspect = this._aspect;
    if (mode === 1 || (mode === 3 && aspect > 1.0)) {
      this._vfov = this._fov;
      this._hfov = Math.atan(Math.tan(this._fov / 2.0) * aspect) * 2.0;
    } else {
      this._hfov = this._fov;
      this._vfov = Math.atan(Math.tan(this._fov / 2.0) / aspect) * 2.0;
    }
    perspective(this._proj, this._vfov, aspect, this._near, this._far);
    this._valid = true;
  };

  camera.lens._updateProjection = _updateProjection.bind(camera.lens);
}
