import Scene from "../Scene";
import { IActivity } from "./Activity";


export default class ActivitiesManager {


  constructor(private scene: Scene) { }


  private _activities: IActivity[] = [];


  preFrame(): void {
    for (const a of this._activities) a.preFrame()
  }

  preRender(): void {
    for (const a of this._activities) a.preRender()
  }

  rttPass(): void {
    for (const a of this._activities) a.rttPass()
  }

  render(): void {
    for (const a of this._activities) a.render()
  }



  registerActivity(activity: IActivity) {
    console.log("registerActivity", activity);
    if (this._activities.indexOf(activity) != -1) {
      return;
    }
    this._activities.push(activity);
    this._activities.sort((a, b)=> b.priority - a.priority);
  }

  unregisterActivity(activity: IActivity) {
    console.log("unregisterActivity", activity);
    const idx = this._activities.indexOf(activity);
    if (idx === -1) {
      return;
    }

    this._activities.splice(idx, 1);

  }


}