import Delay from "@/core/Delay";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import Scene from "../Scene";
import { IActivity } from "./Activity";
import StateHelper from "./StateHelper";


/**
 * Noop activity, loading in VUE
 */
export default class LoadingActivity implements IActivity {

  readonly priority : number = 0;

  stateHelper: StateHelper;

  constructor( public scene: Scene ) {

    // TODO, remove all that, loading handled by vue
    // this.stateHelper = new StateHelper([
    //   { match: 'painting_loading.intro'  , enter: this.enterIntro   },
    //   { match: 'painting_loading.default', enter: this.enterDefault },
    //   { match: 'painting_loading.outro'  , enter: this.enterOutro   },
    // ])
    
  }
 
  load(): Promise<void> {
    console.log( "LoadingActivity.load()" );
    
    return Promise.resolve()
  }

  onStateUpdate(state: AppState): void {

  }

  



  start() {
    // this.stateHelper.start()
  }
  
  stop(): void {
    // this.stateHelper.stop()
  }


  enterIntro = ()=>{
    Delay(500).then(()=>{
      AppService.send('LOADING_INTRO_COMPLETE')
    })
  }


  enterDefault = ()=>{

  }


  enterOutro = ()=>{
    Delay(500).then(()=>{
      AppService.send('LOADING_OUTRO_COMPLETE')
    })
  }





  unload(): void {}
  preFrame(): void {}
  render(): void {}
  renderUI(): void {}
  preRender(): void {}
  rttPass(): void {}

}