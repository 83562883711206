import { ArtworkData } from "./types";

const data: ArtworkData[] = [
  {
    src: "",
    id: "oezifhzeifhzeifh",
    width: 1080,
    height: 1920,
    author: "Banksy",
    pov: {
      position: [4.551731586456299, 2.5767663717269897, -5.623378276824951],
      rotation: [
        0.0184288639575243, 0.8856275677680969, 0.03527455031871796,
        -0.4626878499984741,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 1,
      saturation: 1,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "zefzefezfzef",
    width: 1920,
    height: 1080,
    pov: {
      position: [3.784916400909424, 2.0155620574951172, -8.120975494384766],
      rotation: [
        0.10782328248023987, 0.2442222535610199, 0.02733554318547249,
        -0.9633185267448425,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 0.14,
      saturation: 0.41,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "zefzefzefzefzef",
    width: 1080,
    height: 1920,
    pov: {
      position: [3.0846498012542725, 2.061795949935913, -3.9843838214874268],
      rotation: [
        -0.051345959305763245, -0.5087366700172424, 0.030414551496505737,
        -0.8588513135910034,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 0.14,
      saturation: 0.41,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "gergergergergerg",
    width: 1920,
    height: 1080,
    pov: {
      position: [4.551731586456299, 2.5767663717269897, -5.623378276824951],
      rotation: [
        0.0184288639575243, 0.8856275677680969, 0.03527455031871796,
        -0.4626878499984741,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 1,
      saturation: 1,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "ergergergergdfhdbf",
    width: 1920,
    height: 1080,
    pov: {
      position: [3.784916400909424, 2.0155620574951172, -8.120975494384766],
      rotation: [
        0.10782328248023987, 0.2442222535610199, 0.02733554318547249,
        -0.9633185267448425,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 0.14,
      saturation: 0.41,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "bvsdbedfberbbreb",
    width: 1080,
    height: 1920,
    pov: {
      position: [3.0846498012542725, 2.061795949935913, -3.9843838214874268],
      rotation: [
        -0.051345959305763245, -0.5087366700172424, 0.030414551496505737,
        -0.8588513135910034,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 0.14,
      saturation: 0.41,
      temperature: 1,
      zoom: 0,
    },
  },
  {
    src: "",
    id: "berdcxbchyjiykll",
    width: 1080,
    height: 1080,
    pov: {
      position: [4.551731586456299, 2.5767663717269897, -5.623378276824951],
      rotation: [
        0.0184288639575243, 0.8856275677680969, 0.03527455031871796,
        -0.4626878499984741,
      ],
      format: "landscape",
      brightness: 1,
      contrast: 1,
      saturation: 1,
      temperature: 1,
      zoom: 0,
    },
  },
];

export const GalleryActivitySamples = [...data, ...data, ...data];
