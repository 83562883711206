
// Probe 
var coeffs = 
[
    /* Band 0 */  1.843964879297560477,  1.708970513582217077,  2.080290848582190488 ,
    /* Band 1 */ -0.309521672369441059, -0.345937390913472975, -0.430102165444579465 ,   0.398579049021475729,  0.439832985079042782,  0.526359697543408167, -0.357057919327843476, -0.285473851450100014, -0.317013525610904234,
    /* Band 2 */  -0.057576142545792255, -0.065497854839072694, -0.079184959416941814 ,  -0.187889612812913664, -0.244399764958162347, -0.327208114006482642 ,   0.274450566411892860,  0.291712505193369664,  0.341000571550292952 ,   0.089125282368496067,  0.093677854825450227,  0.130327250581242421 ,  -0.356847655102303818, -0.380324084735033541, -0.457076424630763478 ,
    /* Band 3 */   0.143436354226243457,  0.180658776963060180,  0.229802095545842855 ,  -0.127039918975662386, -0.114592099038226994, -0.139496339183345724 ,  -0.210204189128151142, -0.251699545154406235, -0.320227956697958116 ,   0.064546575161964992,  0.083515702299536559,  0.111582187042328543 ,   0.011674511815874216,  0.030274429034463150,  0.049492232951377987 ,  -0.187522751793168491, -0.217977553992270978, -0.278870702614426980 ,   0.139709070219472292,  0.115220786640848269,  0.132475186488897584 ,
    /* Band 4 */   0.048965332478451098,  0.046209337290587162,  0.051439918927316745 ,   0.109327677367045226,  0.121886886871101413,  0.161537335620730277 ,  -0.131317101368160577, -0.124742716232850367, -0.149345864852066829 ,  -0.201914731661210961, -0.207305854718474070, -0.254658304233464972 ,   0.018822526208312443,  0.020638567134511884,  0.038750791564334849 ,   0.117550825892922553,  0.129506168307732011,  0.162572285319019405 ,  -0.142880661093537253, -0.156147946753899036, -0.186077420009395950 ,  -0.031948603245616228, -0.023115843291327879, -0.026405505590063688 ,   0.167937808635006069,  0.185871233579416262,  0.225363164437286184 
];

module.exports = coeffs;