import { ITextRenderInfo } from "@/webgl/entities/UIKit/text/TextRenderer";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Program from "nanogl/program";

import gradient_text_vertex from "./gradient-text.vert";
import gradient_text_fragment from "./gradient-text.frag";
import { LocalConfig } from "nanogl-state";
import Camera from "nanogl-camera";
import { CreatePane } from "@/dev/Tweak";
import Texture2D from "nanogl/texture-2d";
import { vec2, vec3 } from "gl-matrix";
import gsap from "gsap";

export default class GradientText {

  node: Node;

  private prg: Program;

  private _txtKey: string;
  private _cfg: LocalConfig;
  private _time: number = 0;
  private _gradOffset: vec3;
  private _revealProgress: number = 0;

  constructor(
    public ui: UIKit,
  ) {

    this.node = new Node();
    this.prg = ui.scene.programs.get("gradient-text");

    this._gradOffset = vec3.create();

    this._cfg = ui.scene.glstate.config();
    this._cfg
      .enableCullface(false)
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(ui.scene.gl.SRC_ALPHA, ui.scene.gl.ONE_MINUS_SRC_ALPHA);

  }

  setText(key: string) {
    this._txtKey = key;
  }

  show(){
    gsap.to(this, {_revealProgress: 1.0, duration: 2.5, ease: "power3.out"});
  }

  hide(){
    gsap.to(this, {_revealProgress: 0.0, duration: 1.5, ease: "power3.in"});
  }

  preRender(dt: number){
    this._time += dt * 0.2;
    this._gradOffset[0] = Math.sin(this._time);
    this._gradOffset[1] = Math.cos(this._time + 0.23);
    this._gradOffset[2] = this._revealProgress;
  }

  render(camera: Camera){

    this.prg.use();
    this.prg.uMVP(camera.getMVP(this.node._wmatrix));
    this.prg.tGradient(this.ui.gradientPrimitives.gradientTexture);
    this.prg.uOffset(this._gradOffset);

    this.ui.textRenderer.bindTex(this.prg, this._txtKey);
    this.ui.textRenderer.bindBuffer(this.prg);

    this.ui.scene.glstate.push(this._cfg);
    this.ui.scene.glstate.apply();

    this.ui.textRenderer.drawCall(this._txtKey)

    this.ui.scene.glstate.pop();

  }

}