
import {
  createArtwork,
  CreateArtworkParams,
  listArtwork,
  ListArtworkParams,
} from "@/api/artwork";
import { ImageFileToBlob } from "@/utils/Files";
import { defineComponent } from "vue";
import { log } from "xstate/lib/actionTypes";

export default defineComponent({
  props: {
    debug: {
      type: String,
      validator(value: string) {
        return ["create", "list"].includes(value);
      },
    },
  },

  emits: ["create:complete", "create:error", "list:complete", "list:error"],

  methods: {
    // This method exists only for debugging purpose when the attribute debug="list" or debug="create"
    async onDebug(e) {
      if (this.debug === "list") {
        this.list().then(console.log);
      } else if (this.debug === "create") {
        const form = e.currentTarget as HTMLFormElement;
        if (!form) return;
        const input = form.querySelector("input");
        if (!input) return;
        const file = input.files[0];
        if (!file) return;

        const blob = await ImageFileToBlob(file);
        this.create({
          Artwork: {
            Format: "landscape",
            PointOfView: {
              Position: [
                4.551731586456299, 1.5767663717269897, -5.623378276824951,
              ],
              Rotation: [
                0.0184288639575243, 0.8856275677680969, 0.03527455031871796,
                -0.4626878499984741,
              ],
            },
            PostProcessing: {
              Brightness: 1,
              Contrast: 1,
              Saturation: 1,
              Temperature: 1,
              Zoom: 1,
            },
          },
          Image: blob,
        }).then(console.log);
      }
    },

    // Create a new artwork. Must be called like this.$refs[REF_NAME].create(...);
    async create(params: CreateArtworkParams) {
      const result = await createArtwork(params);

      if (result.isOk()) {
        const response = result.unwrap();
        this.$emit("create:complete", response);
        return response;
      }

      this.$emit("create:error", result.error);
      return result.error;
    },

    // List artworks. Must be called like this.$refs[REF_NAME].list(...);
    async list(params?: ListArtworkParams) {
      const result = await listArtwork(params);

      if (result.isOk()) {
        const response = result.unwrap();
        this.$emit("list:complete", response);
        return response;
      }

      this.$emit("list:error", result.error);
      return result.error;
    },
  },
});
