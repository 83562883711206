import { Artwork } from "@/components/ArtworkCaption/useArtwork";
import ArtworkState from "@/webgl/activities/GalleryActivity/Artwork/State";
import Minimap2D, { Minimap2DOptions } from "@/webgl/entities/minimap";
import Scene from "@/webgl/Scene";
import { mat4, vec2 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import State, { LocalConfig } from "nanogl-state";
import ArrayBuffer from "nanogl/arraybuffer";
import IndexBuffer from "nanogl/indexbuffer";
import Program from "nanogl/program";
import Texture from "nanogl/texture";

const vertices = new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]);
const indices = new Uint8Array([0, 1, 2, 0, 2, 3]);

const VertexShader = /* glsl */ `
attribute vec2 aPosition;
varying vec2 vUv;
uniform mat4 uViewProjectionMatrix;
uniform mat4 uWorldMatrix;

void main(void) {
  vUv = aPosition * 0.5 + 0.5;
  vUv.y = 1.0 - vUv.y;
  vec3 position = vec3(aPosition, 0.0);
  vec4 worldPosition = uWorldMatrix * vec4(position.xyz, 1.0);
  gl_Position = uViewProjectionMatrix * worldPosition;

}
`;

const FragmentShader = /* glsl */ `precision lowp float;
varying vec2 vUv;
uniform sampler2D uTexture;
uniform float uAlpha;

void main(void) {
  vec4 color = texture2D(uTexture, vUv);
  color.a *= uAlpha;
  gl_FragColor = color;
}
`;

const M4 = mat4.create();
const V2 = vec2.create();

export default class MinimapMesh {
  private minimap: Minimap2D;
  private buffer: ArrayBuffer;
  private ibuffer: IndexBuffer;
  private program: Program;
  private config: LocalConfig;
  private texture: Texture;
  public renderable = true;
  public node: Node;
  private alpha: number = 0;

  private _currentState: ArtworkState = null;

  constructor(public scene: Scene) {
    const gl = this.scene.gl;
    this.buffer = new ArrayBuffer(gl, vertices);
    this.buffer.attrib("aPosition", 2, gl.FLOAT);

    this.ibuffer = new IndexBuffer(gl, gl.UNSIGNED_BYTE, indices);

    this.program = new Program(gl, VertexShader, FragmentShader);

    this.texture = new Texture(gl, gl.RGBA);

    this.minimap = new Minimap2D();

    this.config = this.scene.glstate.config();
    this.config
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    this.node = new Node();

  }

  async load() {
    await this.minimap.load();
  }

  set(options: Omit<Minimap2DOptions, "target">) {

    this.minimap.render({
      ...options,
      target: this.texture,
    });

  }

  show(){
    gsap.killTweensOf(this);
    gsap.to(this, {alpha: 1.0, duration: 0.4});
  }
  
  hide(){
    gsap.killTweensOf(this);
    gsap.to(this, {alpha: 0.0, duration: 0.4});
  }

  updateState(state: ArtworkState) {

    if (this._currentState === state)
      return;

    if(this._currentState == null && state){
      this.show();
    }

    this._currentState = state;

    if (this._currentState == null){
      this.hide();
      return;
    }

    this.minimap.render({
      data: this._currentState.data.pov,
      view: "seamless",
      target: this.texture
    }, true)

  }

  render(camera: Camera) {
    if (!this.renderable) return;

    this.scene.glstate.push(this.config);
    this.scene.glstate.apply();

    this.program.use();

    this.program.uViewProjectionMatrix(camera._viewProj);
    this.program.uWorldMatrix(this.node._wmatrix);

    this.program.uAlpha(this.alpha);
    this.program.uTexture(this.texture);

    this.buffer.bind();
    this.buffer.attribPointer(this.program);

    this.ibuffer.bind();
    this.ibuffer.drawTriangles();

    this.scene.glstate.pop();

  }
}
