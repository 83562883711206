import type { RecaptchaVerifyResponse } from "./recaptcha";
import { Result } from "@/utils/Result";
import { createToken } from "./recaptcha";

export interface CreateArtworkParams {
  Image: Blob;
  Artwork: {
    Author?: string;
    Format: "square" | "portrait" | "landscape";
    PointOfView: {
      Position: [number, number, number] | number[];
      Rotation: [number, number, number, number] | number[];
    };
    PostProcessing: {
      Brightness: number;
      Contrast: number;
      Saturation: number;
      Temperature: number;
      Zoom: number;
    };
  };
}

export interface ListArtworkParams {
  Author?: boolean;
  NextToken?: string;
  Limit: number;
}

export interface CreateArtwork201Response {
  ArtworkID: string;
  UploadURL: string;
}

export interface ListArtwork200Response {
  NextToken?: string;
  Count: number;
  Items: {
    CreatedAt: number;
    UserType: "Known" | "Unknown";
    ArtworkID: string;
    Status: "pending" | "published";
    Author?: string;
    Format: "square" | "portrait" | "landscape";
    ImageKey: string;
    ImageURL: string;
    ContentType: string;
    PointOfView: {
      Position: [number, number, number];
      Rotation: [number, number, number, number];
    };
    PostProcessing: {
      Brightness: number;
      Saturation: number;
      Contrast: number;
      Temperature: number;
      Zoom: number;
    };
  }[];
}

interface ErrorResponse {
  error: string;
  response: any;
}

export async function createArtwork(
  params: CreateArtworkParams
): Promise<Result<Pick<CreateArtwork201Response, "ArtworkID">, ErrorResponse>> {
  const { Artwork, Image } = params;
  const tokenResult = await createToken();
  const token = tokenResult.unwrap();
  const createRQ = await fetch(
    `${process.env.VUE_APP_SERVERLESS_BASE_URL}/artwork/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: token,
        Artwork: {
          ContentType: Image.type,
          ...Artwork,
        },
      }),
    }
  );

  const createRS = (await createRQ.json()) as
    | CreateArtwork201Response
    | RecaptchaVerifyResponse;

  if (createRQ.status === 201) {
    const createSuccessRS = createRS as CreateArtwork201Response;
    await fetch(createSuccessRS.UploadURL, {
      method: "PUT",
      body: Image,
    });
    return Result.Ok({ ArtworkID: createSuccessRS.ArtworkID });
  } else if (createRQ.status === 403) {
    return Result.Err({ error: "reCaptcha failed", response: createRS });
  } else {
    return Result.Err({ error: "Artwork creation failed", response: createRS });
  }
}

export async function listArtwork(
  params?: ListArtworkParams
): Promise<Result<ListArtwork200Response, ErrorResponse>> {
  const tokenResult = await createToken();
  const token = tokenResult.unwrap();
  const listRQ = await fetch(
    `${process.env.VUE_APP_SERVERLESS_BASE_URL}/artwork/list`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Token: token,
        Parameters: params || {},
      }),
    }
  );

  const response = (await listRQ.json()) as ListArtwork200Response;

  if (listRQ.status === 200) {
    return Result.Ok(response);
  } else {
    return Result.Err({ error: "Artwork listing failed", response });
  }
}
