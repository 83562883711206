import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import WebXRView from "@/webgl/xr/WebXRView";
import XRLens from "@/webgl/xr/WebXRLens";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import { XRViewerPose } from "webxr";

const FWD = vec3.fromValues(0.0, 0.0, -1.0);
const M3 = mat3.create();

export default class WebXRCamera {

  node: Node;
  pose: XRViewerPose;
  head: Camera<PerspectiveLens>;
  forward: vec3;
  cameras: Camera<XRLens>[] = [];
  wposition: vec3 = vec3.create();
  wrotation: quat = quat.create();

  constructor(
    public readonly xrview: WebXRView
  ) {

    this.node = new Node();
    let layer = this.xrview.session.renderState.baseLayer;

    this.forward = vec3.create();
    this.head = new Camera(new PerspectiveLens());
    this.node.add(this.head);

    for (const view of xrview.pose.views) {
      const cam = new Camera(new XRLens(view.eye, layer.getViewport(view)));
      this.node.add(cam);
      this.cameras.push(cam);
    }

  }

  update() {

    this.pose = this.xrview.pose;
    this.head.setMatrix(this.pose.transform.matrix as mat4);
    this.head.updateWorldMatrix();


    let layer = this.xrview.session.renderState.baseLayer;

    this.wposition = this.head._wposition as vec3;

    mat3.fromMat4(M3, this.head._wmatrix);
    vec3.transformMat3(this.forward, FWD, M3);
    quat.fromMat3(this.wrotation, M3);

    for (let i = 0; i < this.pose.views.length; i++) {
      this.cameras[i].lens.update(
        this.pose.views[i].eye,
        layer.getViewport(this.pose.views[i])
      );
      this.cameras[i].setMatrix(this.pose.views[i].transform.matrix as mat4);
      this.cameras[i].updateWorldMatrix();
      this.cameras[i].lens.proj = this.pose.views[i].projectionMatrix as mat4;
      this.cameras[i].updateViewProjectionMatrix(1, 1);
    }

  }

}