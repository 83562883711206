
import glmat from 'gl-matrix'
import Node from "nanogl-node";

const vec3 = glmat.vec3;
const mat4 = glmat.mat4;
const mat3 = glmat.mat3;

const edge1 = vec3.create();
const edge2 = vec3.create();
const normal = vec3.create();
const diff = vec3.create();
const VEC3 = vec3.create();
const M4 = mat4.create();
const M3 = mat3.create();

const ORIG = vec3.create();
const DIR = vec3.create();


class Picking {

  id: string;
  node: Node;
  indices: ArrayLike<number>;
  vertices: ArrayLike<number>;
  doubleSided: boolean;
  pos: Float32Array;
  normal: Float32Array;

  constructor(node, indices: ArrayLike<number>, vertices: ArrayLike<number>, doubleSided=false) {

    this.id = '';

    this.node = node;
    this.indices = indices;
    this.vertices = vertices;
    this.doubleSided = doubleSided
    this.pos = new Float32Array(3)
    this.normal = new Float32Array(3);

  }

  raycast(ray, pos, nrm = this.normal): number {

    mat4.invert(M4, this.node._wmatrix);
    mat3.fromMat4(M3, M4);
    vec3.transformMat4(ORIG, ray.pos, M4);
    vec3.transformMat3(DIR, ray.dir, M3);

    let origin = ORIG; //ray.pos;
    let rayDir = DIR;  //ray.dir;
    let faces = this.indices;
    let verts = this.vertices;

    let v0, v1, v2;
    let sign;


    for (var i = 0; i < faces.length; i += 3) {

      v0 = faces[i + 0] * 3;
      v1 = faces[i + 1] * 3;
      v2 = faces[i + 2] * 3;

      edge1[0] = verts[v1 + 0] - verts[v0 + 0];
      edge1[1] = verts[v1 + 1] - verts[v0 + 1];
      edge1[2] = verts[v1 + 2] - verts[v0 + 2];

      edge2[0] = verts[v2 + 0] - verts[v0 + 0];
      edge2[1] = verts[v2 + 1] - verts[v0 + 1];
      edge2[2] = verts[v2 + 2] - verts[v0 + 2];

      vec3.cross(normal, edge1, edge2);

      var DdN = vec3.dot(rayDir, normal);

      if (DdN > 0.0) {
        if (!this.doubleSided) continue;
        sign = 1.0;
      } else if (DdN < 0.0) {
        sign = -1.0;
        DdN = -DdN;
      } else {
        continue;
      }

      diff[0] = origin[0] - verts[v0 + 0];
      diff[1] = origin[1] - verts[v0 + 1];
      diff[2] = origin[2] - verts[v0 + 2];

      vec3.cross(VEC3, diff, edge2);
      var DdQxE2 = sign * vec3.dot(rayDir, VEC3);
      if (DdQxE2 < 0.0) {
        continue;
      }

      vec3.cross(VEC3, edge1, diff);
      var DdE1xQ = sign * vec3.dot(rayDir, VEC3);
      if (DdE1xQ < 0.0) {
        continue;
      }

      if (DdQxE2 + DdE1xQ > DdN) {
        continue;
      }


      var QdN = - sign * vec3.dot(diff, normal);
      if (QdN < 0) {
        continue;
      }

      // i pos
      vec3.scaleAndAdd(VEC3, origin, rayDir, QdN / DdN);
      vec3.transformMat4(VEC3, VEC3, this.node._wmatrix);

      pos[0] = VEC3[0]
      pos[1] = VEC3[1]
      pos[2] = VEC3[2]
      pos[3] = QdN / DdN

      this.pos[0] = VEC3[0]
      this.pos[1] = VEC3[1]
      this.pos[2] = VEC3[2]

      // i normal
      // vec3.transformMat3(normal, normal, M3);
      vec3.normalize(normal, normal);

      nrm[0] = normal[0];
      nrm[1] = normal[1];
      nrm[2] = normal[2];

      this.normal[0] = normal[0];
      this.normal[1] = normal[1];
      this.normal[2] = normal[2];

      return sign;

    }

    return 0;

  }

  // debug
  getPos() {
    return this.pos
  }

}

export default Picking;