import { mat3, vec3 } from "gl-matrix";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import TexCoord, { DynamicTexCoord, StaticTexCoord } from "nanogl-pbr/TexCoord";


export default class GradientBorder extends Chunk {

  size: vec3;
  color: vec3;
  static: StaticTexCoord;
  texcoord: DynamicTexCoord;

  constructor() {

    super(true, true);
    this.size = vec3.create();
    this.color = vec3.create();
    this.texcoord = TexCoord.createTransformedDynamic("aTexCoord0");
    this.texcoord.setMatrix(mat3.create());

  }

  update(time) {
    this.texcoord.translate(Math.cos(time * 0.8) * 0.22, Math.sin(time * 0.8) * 0.22)
  }

  setup(prg) {
    prg.uSize(this.size);
    prg.uColor(this.color);
  }

  protected _genCode(slots: ChunksSlots): void {
    slots.add('pv',
      /*glsl*/`
        uniform vec3 uSize;
        IN mediump vec2 aTexCoord1;
        OUT mediump vec2 vTexCoord1;
      `);

    slots.add('vertex_warp',
      /*glsl*/`
      vec2 left = (vertex.position.xy * uSize.y + vec2(uSize.x * 0.5, 0.0)) * aColor0.r;
      vec2 center = vertex.position.xy * vec2(uSize.xy) * aColor0.g;
      vec2 right = (vertex.position.xy * uSize.y - vec2(uSize.x * 0.5, 0.0)) * aColor0.b;
      vertex.position.xy = left + center + right;
      vertex.position.y += uSize.z;
      vertex.position.z -= 0.001;
      vTexCoord1 = aTexCoord1;
      `);

    slots.add('pf',
      /*glsl*/`
      uniform vec3 uColor;
      IN mediump vec2 vTexCoord1;
      `);

    slots.add('postf',
      /*glsl*/`
      vec3 background = uColor;
      vec3 purple = baseColor();
      float l = smoothstep(0.3, 0.5, vTexCoord1.x);
      FragColor.rgb = mix(purple, background, l);
      `);
  }

}