import { GLContext } from "nanogl/types";
import GLArrayBuffer from "nanogl/arraybuffer";
import GLIndexBuffer from "nanogl/indexbuffer";
import GLState, { LocalConfig } from "nanogl-state";
import Programs from "@/webgl/gl/Programs";
import Program from "nanogl/program";
import GLFontResource from "@/webgl/entities/UIKit/GLFontResource";
import { mat4 } from "gl-matrix";
import Camera from "nanogl-camera";

export type FontFamily = "graphik-600" | "graphik-400" | "new-paris-500" | "new-paris-400"

interface IRenderingContext {
  gl: GLContext;
  glstate: GLState;
  programs: Programs;
}

const defaultStyle: ITextStyle = {
  fontSize: 1.0,
  lineHeight: 1.0,
  boxWidth: 10000,
  justifyCenter: false,
  center: false,
  font: "graphik-600"
}

export interface ITextStyle {
  fontSize?: number;
  lineHeight?: number;
  boxWidth?: number;
  center?: boolean;
  justifyCenter?: boolean;
  font?: FontFamily;
}

export interface ITextRenderInfo {
  offset: number;
  count: number;
  width: number;
  height: number;
  font: FontFamily;
}

interface IPendingList {
  key: string;
  content: string;
  style: ITextStyle;
}

interface ITextRenderConfig {
  key: string;
  matrix: mat4;
  color: ArrayLike<number>;
}

export default class TextRenderer {

  private _vbuffer: GLArrayBuffer;
  private _ibuffer: GLIndexBuffer;
  private _cfg: LocalConfig;

  prg: Program;

  texts: Map<string, ITextRenderInfo>;

  private _activeFont: GLFontResource;
  // private _fonts: Record<FontFamily, GLFontResource>;
  private _fonts: Map<FontFamily, GLFontResource>;

  private _texWidth: number;
  private _texHeight: number;

  private _currentIndice: number;
  private _meshData: number[];
  private _indicesData: number[];

  private _pendingList: IPendingList[];
  private _shouldBuild: boolean;

  private _renderQueueList: ITextRenderConfig[] = [];

  constructor(
    public readonly context: IRenderingContext
  ) {

    const gl = context.gl;

    this._vbuffer = new GLArrayBuffer(gl);
    this._vbuffer
      .attrib("aPosition", 4, gl.FLOAT)
      .attrib("aTexCoord", 3, gl.FLOAT)
      .attrib("aRand", 3, gl.FLOAT);

    this._ibuffer = new GLIndexBuffer(gl, gl.UNSIGNED_SHORT);

    this.prg = context.programs.get('text-world');

    this._cfg = context.glstate.config();
    this._cfg
      .enableCullface(false)
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    this.texts = new Map<string, ITextRenderInfo>();

    this._currentIndice = 0;
    this._meshData = [];
    this._indicesData = [];
    this._pendingList = [];
    this._shouldBuild = false;
    this._renderQueueList = [];

  }

  clear() {
    this.texts = new Map<string, ITextRenderInfo>();
    this._currentIndice = 0;
    this._meshData = [];
    this._indicesData = [];
    this._pendingList = [];
    this._renderQueueList = [];
  }

  setFonts(fonts: Map<FontFamily, GLFontResource>) {
    this._fonts = fonts;

    for (let i = 0; i < this._pendingList.length; i++) {
      this.registerText(
        this._pendingList[i].key,
        this._pendingList[i].content,
        this._pendingList[i].style
      )
    }

    if (this._shouldBuild) {
      this.build();
      this._shouldBuild = false;
    }

    this._pendingList = [];
    this._shouldBuild = false;

  }

  registerText(key: string, content: string, style: ITextStyle = {}) {

    style.fontSize = style.fontSize ? style.fontSize : defaultStyle.fontSize;
    style.boxWidth = style.boxWidth ? style.boxWidth : defaultStyle.boxWidth;
    style.lineHeight = style.lineHeight ? style.lineHeight : defaultStyle.lineHeight;
    style.center = style.center ? style.center : defaultStyle.center;
    style.justifyCenter = style.justifyCenter ? style.justifyCenter : defaultStyle.justifyCenter;
    style.font = style.font ? style.font : defaultStyle.font;

    if (!this._fonts) {
      this._pendingList.push({
        key,
        content,
        style
      });
      return;
    }

    this._activeFont = this._fonts.get(style.font);

    const _text = content;

    const _tlen = _text.length;

    const info = this._activeFont.faceInfo;
    this._texWidth = this._activeFont._texture.width;
    this._texHeight = this._activeFont._texture.height;

    const txtWords = content.split(" ");
    const maxwidth = style.boxWidth;


    const words: number[][] = [];
    for (let i = 0; i < txtWords.length; i++) {

      const w = [];

      if (i != 0)
        words.push([" ".charCodeAt(0)]);

      for (let j = 0; j < txtWords[i].length; j++) {
        w.push(txtWords[i].charCodeAt(j));
      }
      words.push(w)

    }


    const size = (style.fontSize / info.pointSize);
    const offset = this._indicesData.length;
    const startvert = this._meshData.length;
    let lineStartVert = this._meshData.length;

    let indice = this._currentIndice;
    let caretX = style.justifyCenter ? style.boxWidth * 0.5 : 0;
    let caretY = -info.lineHeight * style.lineHeight * style.fontSize;
    let i = 0;
    let maxX = 0;
    let minY = 0;



    for (let word of words) {

      let space = word[0] == 32;
      let offset = style.justifyCenter ? style.boxWidth * 0.5 : 0;

      if (((caretX - offset) * size) + this.computeWordLength(word, style.fontSize) > maxwidth && !space) {

        if (style.justifyCenter) {
          this.applyCenter(lineStartVert, this._meshData.length, caretX / info.pointSize, 0)
        }

        lineStartVert = this._meshData.length;
        caretX = style.justifyCenter ? style.boxWidth * 0.5 : 0;
        caretY -= info.lineHeight * style.lineHeight * style.fontSize;

      }

      for (let code of word) {
        caretX += this.pushQuadLetter(
          this._meshData,
          code,
          _tlen,
          style.fontSize,
          caretX,
          caretY,
          i
        );

        this._indicesData.push(
          indice,
          indice + 3,
          indice + 1,
          indice,
          indice + 2,
          indice + 3
        );

        indice += 4;
        i += 1;

      }

      maxX = maxX < caretX ? caretX : maxX;
      minY = minY > caretY ? caretY : minY;

    }

    const width = maxX / info.pointSize;
    const height = -minY / info.pointSize;

    if (style.justifyCenter) {
      this.applyCenter(lineStartVert, this._meshData.length, caretX / info.pointSize, 0)
    }

    if (style.center) {
      this.applyCenter(startvert, this._meshData.length, width, height);
    }

    this.texts.set(key, {
      offset: offset * 2,
      count: (this._indicesData.length) - offset,
      width: width,
      height: height,
      font: style.font
    });

    this._currentIndice = indice;

  }

  computeWordLength(word: number[], fontSize: number) {

    let end = 0;
    const info = this._activeFont.faceInfo;
    for (let i = 0; i < word.length; i++) {
      const char = this._activeFont.getFromUnicode(word[i]);
      end += char.glyph.metrics.horizontalAdvance * (fontSize / info.pointSize);
    }
    return end;

  }

  applyCenter(start: number, end: number, width: number, height: number) {

    for (let i = start; i < end; i += 10) {
      this._meshData[i + 0] -= width * 0.5;
      this._meshData[i + 1] += height;
    }

  }

  pushQuadLetter(
    tgt: number[],
    unicode: number,
    tlen: number,
    size: number,
    caretX: number,
    caretY: number,
    idx: number
  ): number {

    const info = this._activeFont.faceInfo;

    const char = this._activeFont.getFromUnicode(unicode);

    const metrics = char.glyph.metrics;
    const rect = char.glyph.glyphRect;

    const w = metrics.width * size;
    const h = metrics.height * size;

    let x1 = (caretX + metrics.horizontalBearingX * size);
    let x2 = x1 + w;

    let y1 = (caretY + metrics.horizontalBearingY * size);
    let y2 = y1 - h;

    let uvx1 = rect.x;
    let uvx2 = uvx1 + rect.width;

    let uvy1 = rect.y;
    let uvy2 = uvy1 + rect.height;

    uvx1 /= this._texWidth;
    uvx2 /= this._texWidth;
    uvy1 /= this._texHeight;
    uvy2 /= this._texHeight;

    x1 /= info.pointSize;
    x2 /= info.pointSize;
    y1 /= info.pointSize;
    y2 /= info.pointSize;

    const cx = (x1 + x2) / 2;
    const cy = (y1 + y2) / 2;
    const cp = idx / tlen;
    const ra = [
      Math.random(),
      Math.random(),
      Math.random()
    ];

    tgt.push(
      x1, y1, cx, cy, uvx1, uvy2, cp, ra[0], ra[1], ra[2],
      x1, y2, cx, cy, uvx1, uvy1, cp, ra[0], ra[1], ra[2],
      x2, y1, cx, cy, uvx2, uvy2, cp, ra[0], ra[1], ra[2],
      x2, y2, cx, cy, uvx2, uvy1, cp, ra[0], ra[1], ra[2],
    );

    return char.glyph.metrics.horizontalAdvance * size;

  }

  build() {

    if (this._currentIndice === 0) {
      this._shouldBuild = true;
      return;
    }

    this._vbuffer.data(new Float32Array(this._meshData));
    this._ibuffer.data(new Uint16Array(this._indicesData));

    this._meshData = [];
    this._indicesData = [];

  }

  prepare() {

    this.prg.use();
    this.bindBuffer(this.prg);

    this.context.glstate.push(this._cfg);
    this.context.glstate.apply();

  }

  bindBuffer(prg: Program) {
    this._vbuffer.bind();
    this._vbuffer.attribPointer(prg);
    this._ibuffer.bind();
  }

  bindTex(prg: Program, key: string) {
    prg.tFontTex(this._fonts.get(this.texts.get(key).font)._texture);
  }

  drawCall(key: string) {
    this._ibuffer.drawTriangles(this.texts.get(key).count, this.texts.get(key).offset);
  }

  finish() {
    this.context.glstate.pop();
    this.context.glstate.apply();
  }

  render(key: string, matrix: mat4, color: ArrayLike<number>) {
    this._renderQueueList.push({
      key,
      matrix,
      color
    });
  }

  renderDirect(key: string, mvp: mat4, color: ArrayLike<number>) {

    this.bindTex(this.prg, key);
    this.prg.uMVP(mvp);
    this.prg.uColor(color);
    this.drawCall(key);

  }

  renderQueuedList(camera: Camera) {

    for (const config of this._renderQueueList) {
      this.renderDirect(config.key, camera.getMVP(config.matrix), config.color);
    }
  }

  flush() {
    this._renderQueueList.length = 0;
    this.context.glstate.pop();
    this.context.glstate.apply();
  }

}
