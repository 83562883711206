import Rect3D from "@/webgl/lib/Quad3D";
import Scene from "@/webgl/Scene";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import Node from "nanogl-node";
import Camera from "nanogl-camera";
import gallery_floor_vert from "./gallery-floor.vert";
import gallery_floor_frag from "./gallery-floor.frag";
import GLConfig from "nanogl-state/config";
import { TextureResource } from "@/webgl/assets/TextureResource";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import Paths from "@/core/Paths";
import gsap from "gsap";

export default class XRGalleryFloor {

  rect: Rect3D
  prg: Program;
  texture: Texture2D;
  node: Node;
  cfg: GLConfig;
  alpha: number = 0;

  constructor(
    public readonly scene: Scene
  ) {

    this.rect = new Rect3D(scene.gl);
    this.node = new Node();

    this.node.rotateX(Math.PI / 2);
    this.node.setScale(2.5);


    this.prg = new Program(
      scene.gl,
      gallery_floor_vert(),
      gallery_floor_frag(),
      scene.programs.getGlobalDefinitions()
    )

    const gl = scene.gl;
    this.cfg = scene.glstate.config();
    this.cfg
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);

    this.texture = new Texture2D(gl, gl.RGB);

  }

  async load(): Promise<any> {
    return new TextureResource(
      new TextureSrcSet(Paths.resolve("assets/webgl/floor_dots.jpg")),
      this.scene
    ).load().then((t: Texture2D) => {
      this.texture = t;
    });
  }

  show(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: 1, duration: 0.9, onComplete: resolve })
    });
  }

  hide(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: 0, duration: 0.9, onComplete: resolve })
    });
  }

  render(camera: Camera) {

    if (this.alpha == 0)
      return;

    this.prg.use();
    this.prg.uMVP(camera.getMVP(this.node._wmatrix));
    this.prg.uDotTex(this.texture);
    this.prg.uAlpha(this.alpha);

    this.rect.bind();
    this.rect.attribPointer(this.prg);

    this.scene.glstate.push(this.cfg);
    this.scene.glstate.apply();

    this.rect.render();

    this.scene.glstate.pop();

  }

}