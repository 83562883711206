import GradientLinePass from "@/webgl/glsl/gradient-line/GradientLinePass";
import { Passes } from "@/webgl/glsl/Passes";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import Input, { Uniform } from "nanogl-pbr/Input";
import { GLContext } from "nanogl/types";

const MAT_ID = "gradient-line"

export default class GradientLineMaterial extends BaseMaterial {

  constructor(gl: GLContext, name: string = MAT_ID) {

    super(gl, MAT_ID);

    this.addPass(new GradientLinePass(), Passes.DEFAULT)

  }


}