import Deferred from "@/core/Deferred";
import Delay from "@/core/Delay";
import Paths from "@/core/Paths";
import { CreatePane } from "@/dev/Tweak";
import AppService from "@/store/states/AppService";
import { AppState, DefaultPovData, PhotoFormatDimensions, PovDatas, SharedMedia } from "@/store/states/AppStateMachine";
import PaintingCameraController from "@/webgl/activities/PaintingActivity/PaintingCameraController";
import gsap from "gsap";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { Pane } from "tweakpane";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import StateHelper from "../StateHelper";
import PaintingCameraTargetRenderer from "@/webgl/activities/PaintingActivity/PaintingCameraTargetRenderer";
import PostProcess from "@/webgl/entities/post-process/PostProcess";
import PaintingSnapshot from "@/webgl/activities/PaintingActivity/snapshot/PaintingSnapshot";
import { POST_SETTINGS } from "@/webgl/entities/post-process/PostSettingsController";
import fbodebug from '@/webgl/dev/FboDebug';
import AudioManager from "@/core/audio/AudioManager";
import PaintingAudio from "@/webgl/scenes/PaintingScene/PaintingAudio";


export default class PaintingActivity implements IActivity {

  readonly priority: number = 0;

  // blurredSkybox: BlurredSkybox;

  private loaded = false;
  private _showSkybox = false;
  private camera: Camera<PerspectiveLens>;
  private stateHelper: StateHelper;
  private camCtrl: PaintingCameraController;
  private camTarget: PaintingCameraTargetRenderer;
  private snapshot: PaintingSnapshot;
  private audio: PaintingAudio;

///////////////
///////////////////
///////////////////////////
////////////



  constructor(public scene: Scene) {
    // this.blurredSkybox = new BlurredSkybox(scene)
    // this.blurredSkybox.opacity = 0

    // this.postprocess = new PostProcess(scene);
    this.audio = new PaintingAudio(scene.paintingScene);

    this.camera = Camera.makePerspectiveCamera()
    this.camera.lens.setVerticalFov(Math.PI / 3.5);
    this.camera.lens.near = 0.25;
    this.camera.lens.far = 500;

    this.stateHelper = new StateHelper([
      { match: 'painting.explore.default', enter: this.enterExplore, exit: this.exitExplore },
      { match: 'painting.explore.tutorial', enter: this.enterTutorial, exit: this.exitTutorial },
      { match: 'painting.photo', enter: this.enterPhoto, exit: this.exitPhoto },
      { match: 'painting.snapshot', enter: this.enterSnapshot },
      { match: 'painting.share.preview', enter: this.enterPreview, exit: this.exitPreview },
      { match: 'painting.share.select', enter: this.enterShare, exit: this.exitShare },
    ])

  }



  async load(): Promise<void> {

/////////////////
/////////////////////////////////////////

//////////////////////////////////////////////////////////////////
/////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////
//////////
//////////////
    if (this.loaded)
      return;

    this.postprocess = new PostProcess(this.scene);
    this.postprocess.init();

    this.camTarget = new PaintingCameraTargetRenderer(this.scene);

    await Promise.all([
      this.scene.loadPaintingScene(),
      this.camTarget.load(),
      this.audio.loadPositions()
      // this.blurredSkybox.load(),
    ])

    this.snapshot = new PaintingSnapshot(this);

    this.camCtrl = new PaintingCameraController(this, this.camera);
    this.camCtrl.navmesh = this.scene.paintingScene.navmesh;
    this.camTarget.attachController(this.camCtrl);
    this.loaded = true;

  }

  unload(): void {

    this.postprocess = null;
    this.camCtrl = null;
    this.snapshot = null;
    this.camTarget = null;
    this.loaded = false;

/////////////////
///////////////////////
//////////////
  }



  start() {
    this.scene.root.add(this.camera)
    this.stateHelper.start()
    this.camCtrl.updatePovData(AppService.state.context.povDatas);

    this.audio.start();

  }

  stop(): void {
    this.camCtrl.resetFOV();
    this.camCtrl.disable();
    this.scene.root.remove(this.camera);
    this.stateHelper.stop();
    this.audio.stop();
  }


  onStateUpdate(state: AppState): void {

    if (state.matches("painting.explore")) {
      console.log("[PaintingActivity] explore");
    }

    if (state.matches("painting.explore.tutorial")) {
      console.log("[PaintingActivity] tutorial ");
    }

    if (state.matches("painting.settings")) {
      console.log("[PaintingActivity] settings");
    }

  }


  enterExplore = (): void => {
    console.log("[PaintinActivity] enterExplore");
    this.camCtrl.enable();
    this.camCtrl.disableZoom();
    this.postprocess.settings.setActive(false);
    this.audio.start();
  }

  exitExplore = (): void => {
    this.camCtrl.disable();
    this.audio.stop();
  }


  enterTutorial = (): void => {
    console.log("[PaintinActivity] enterTutorial");
    this.camCtrl.disable();
  }

  exitTutorial = (): void => {
    this.camCtrl.enable();
  }


  enterPhoto = (): void => {
    console.log("[PaintinActivity] enterPhoto");
    this.camCtrl.enable();
    this.camCtrl.enableZoom();
    this.camCtrl.lockMove();
    this.postprocess.settings.setActive(true);
    this.audio.start();
  }

  exitPhoto = (): void => {
    this.camCtrl.disable();
    this.camCtrl.unlockMove();
    this.audio.stop();
  }

  enterSnapshot = (): void => {

    console.log("[PaintinActivity] enterSnapshot");
    AppService.send("UPDATE_POV_DATA", {
      pov: <PovDatas>{
        position: Array.from(this.camera.position),
        rotation: Array.from(this.camera.rotation),
        contrast: this.postprocess.settings.list.get(POST_SETTINGS.CONTRAST).mix,
        brightness: this.postprocess.settings.list.get(POST_SETTINGS.BRIGHTNESS).mix,
        saturation: this.postprocess.settings.list.get(POST_SETTINGS.SATURATION).mix,
        temperature: this.postprocess.settings.list.get(POST_SETTINGS.TEMPERATURE).mix,
        zoom: this.camCtrl.ZoomValue
      }
    });

    this.createSnapshot()

  }

  enterPreview = (): void => {
    console.log("[PaintinActivity] enterPreview");
    this.postprocess.settings.setActive(true);
  }

  exitPreview = (): void => {

  }

  enterShare = (): void => {
    console.log("[PaintinActivity] enterShare");
    this.postprocess.settings.setActive(true);
  }

  exitShare = (): void => {

  }



  preFrame(): void {

    // SNAPSHOT RENDERING
    if (this.snapshotDefered) {

      const gl = this.scene.gl;
      const w = PhotoFormatDimensions[AppService.state.context.povDatas.format].width;
      const h = PhotoFormatDimensions[AppService.state.context.povDatas.format].height;

      this.snapshot.updateCamera(this.camera, w, h);

      const camera = this.snapshot.camera;

      this.scene.paintingScene.reflect.scale = 1;
      this.scene.paintingScene.reflect.prepare(w, h);
      this.scene.paintingScene.reflect.viewport(gl, 10, 10, w - 20, h - 20);
      this.scene.paintingScene.reflectPass(camera);

      this.scene.paintingScene.reflect.blitRenderBuffer();
      this.scene.paintingScene.reflect.scale = 0.6;

      this.scene.paintingScene.probe.effect.viewport[0] = w;
      this.scene.paintingScene.probe.effect.viewport[1] = h;

      const sw = this.scene.glview.canvas.width;
      const sh = this.scene.glview.canvas.height;

      this.scene.glview.canvas.width = w;
      this.scene.glview.canvas.height = h;
      if (this.postprocess.setup.enabled) {
        this.postprocess.preRender(w, h);
        this.postprocess.bindColor();
      } else {
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.viewport(0, 0, w, h);
        gl.clearColor(1, 1, 1, 1)
        gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
      }

      gl.clearColor(1.0, 1.0, 1.0, 1.0);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      gl.viewport(0, 0, w, h);


      // RENDER
      // ========
      this.scene.paintingScene.render(camera)

      this.postprocess.render(10, 10, w - 20, h - 20, null);
      // this.scene.glview.canvas.toBlob(this.snapshotDefered.resolve);
      this.snapshot.generateBlob(this.scene.glview.canvas, this.snapshotDefered.resolve);
      this.snapshotDefered = null;


      this.scene.glview.canvas.width = sw;
      this.scene.glview.canvas.height = sh;

    }

  }



  preRender(): void {

    this.camCtrl.preRender();

    this.camTarget.preRender();

    this.postprocess.settings.applyPovDatas(AppService.state.context.povDatas);
    this.camCtrl.setZoom(AppService.state.context.povDatas.zoom);

    this.scene.paintingScene.preRender();

    this.camera.updateViewProjectionMatrix(this.scene.glview.width, this.scene.glview.height);

    AudioManager.updateListener(this.camera._wmatrix);
    this.audio.update(this.scene.dt);

  }

  render(): void {

    const w = this.scene.glview.width;
    const h = this.scene.glview.height;

    const gl = this.scene.gl;
    if (this.postprocess.setup.enabled) {
      this.postprocess.preRender(w, h);
      this.postprocess.bindColor();
    } else {
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
      gl.viewport(0, 0, w, h);
      gl.clearColor(0, 0, 0, 1)
      gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    }


    // RENDER
    // ========
    this.scene.paintingScene.render(this.camera)
    this.camTarget.render(this.camera);
    this.scene.glstate.apply();

    this.postprocess.render(0, 0, w, h, null);

  }


  rttPass(): void {

    const gl = this.scene.gl;

    const w = this.scene.glview.width;
    const h = this.scene.glview.height;
    const vp = this.scene.viewport;

    this.scene.paintingScene.reflect.prepare(w, h);
    this.scene.paintingScene.reflect.viewport(gl, vp.x, vp.y, vp.width, vp.height);
    this.scene.paintingScene.reflectPass(this.camera);

    this.scene.paintingScene.probe.effect.viewport[0] = w;
    this.scene.paintingScene.probe.effect.viewport[1] = h;
    this.scene.paintingScene.reflect.blitRenderBuffer();

  }




  private snapshotDefered: Deferred<Blob>

  async createSnapshot(): Promise<void> {

    this.snapshotDefered = new Deferred<Blob>()
    const blob = await this.snapshotDefered.promise

    AppService.send({
      type: 'SNAPSHOT_READY', media: {
        blob,
        url: "",
        loaded: false,
        artworkId: ""
      }
    })

  }


  // private showSkybox(value: boolean) {
  //   if (this._showSkybox === value) return
  //   this._showSkybox = value;
  //   gsap.to(this.blurredSkybox, { opacity: value ? 1 : 0, duration: 0.5 })
  // }

}
