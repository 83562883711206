import Animation from "@/webgl/lib/nanogl-gltf/lib/elements/Animation";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { mat4 } from "gl-matrix";
import MuseumScene from ".";


export default class CameraPath {

  animation: Animation
  camera: Node

  constructor(private museum:MuseumScene) {
    
  }

  release(){
    this.animation = null
    this.camera    = null
  }

  init(){
    this.animation = this.museum.gltf.getElementByName(GltfTypes.ANIMATION, 'Main')
    this.camera    = this.museum.gltf.getElementByName(GltfTypes.NODE, 'Camera')
  }

  getTransformAt(out:mat4, progress:number){
    this.animation.evaluate( progress * this.animation.duration )
    this.camera.updateWorldMatrix()
    out.set( this.camera._wmatrix )
  }

}