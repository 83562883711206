import Paths from "@/core/Paths";
import { PhotoFormat } from "@/store/states/AppStateMachine";
import TextureStreamingGroup, { StreamingAllocator } from "@/webgl/activities/GalleryActivity/Artwork/TextureStreamingGroup";
import Texture2D from "nanogl/texture-2d";
import type { ArtworkMediaSource } from "../types";
import ArtworkTextureResource from "./TextureResource";

type OnGetBehavior = "throwError" | "createResource";
interface ArtworkTexturePoolOptions {
  types: { name: string; count?: number }[];
}

interface ArtworkStreamingsGroup {
  square: TextureStreamingGroup
  landscape: TextureStreamingGroup
  portrait: TextureStreamingGroup
}

export default class ArtworkTexturePool {

  _streamingPool: ArtworkStreamingsGroup;

  constructor(
    public gl: WebGLRenderingContext,
  ) {

    this._streamingPool = {
      "landscape": new TextureStreamingGroup(gl, 12, Paths.resolve("assets/webgl/empty_landscape.png")),
      "portrait": new TextureStreamingGroup(gl, 12, Paths.resolve("assets/webgl/empty_portrait.png")),
      "square": new TextureStreamingGroup(gl, 12, Paths.resolve("assets/webgl/empty_square.png")),
    }

  }


  alloc(): Promise<any> {

    return Promise.all([
      this._streamingPool["landscape"].alloc(),
      this._streamingPool["portrait"].alloc(),
      this._streamingPool["square"].alloc()
    ])

  }

  registerAllocator(format: PhotoFormat, allocator: StreamingAllocator) {
    this._streamingPool[format].registerAllocator(allocator);
  }

  get(format: PhotoFormat, allocator: StreamingAllocator): Texture2D {
    return this._streamingPool[format].getTex(allocator);
  }

  prepare(dt: number) {
    this._streamingPool["landscape"].prepare(dt);
    this._streamingPool["portrait"].prepare(dt);
    this._streamingPool["square"].prepare(dt);
  }

}