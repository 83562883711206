
// Probe 
var coeffs = 
[
    /* Band 0 */  1.771872369120841118,  1.724447844308759636,  2.271903045739899873 ,
    /* Band 1 */  0.055544663843954095,  0.005995947142382102,  0.020409974078539034 ,  -0.310970248577576602, -0.290369948651495124, -0.354826777112566827, -0.138071617270023178, -0.131582234693437550, -0.207296395934010752,
    /* Band 2 */  -0.167691966373620327, -0.161956273743814355, -0.232035649531528831 ,   0.041845574186079496,  0.078383132594194146,  0.096884626745566388 ,   0.487014082327627551,  0.472197159523641607,  0.487582043289808920 ,  -0.085471929028760010, -0.066896675829738836, -0.034156194232752575 ,  -0.223683899896605309, -0.227579455735864855, -0.243760839414165265 ,
    /* Band 3 */   0.094335817212798698,  0.106168764889554471,  0.134794373002525614 ,  -0.018155616868199588, -0.013467863731937953,  0.012313601024203796 ,  -0.286726450377450204, -0.345162134242486818, -0.479977923705942333 ,   0.200436126241586104,  0.187152938304880417,  0.236747622520688261 ,  -0.032895713483165542, -0.027854286144854273, -0.031657354580530837 ,   0.250612865698553178,  0.246748000016318497,  0.284802862919184663 ,   0.043777534303598917,  0.042170610644207102,  0.059421600207388757 ,
    /* Band 4 */   0.086801498853219611,  0.086508438317137254,  0.121649372523718172 ,  -0.057415131345101039, -0.076822881373212137, -0.110238864675356429 ,   0.026094729594969206,  0.032875042240502646,  0.048058512134536387 ,  -0.010571432009908135, -0.012967175454574367,  0.003690280115373108 ,   0.117427800486281403,  0.146743074725758565,  0.197362141842593763 ,  -0.000760067706091722, -0.003107800223042463,  0.001799685129214607 ,  -0.220279579983386598, -0.233222850305371265, -0.258496936727918181 ,   0.092469437465509646,  0.097520196618107166,  0.107472027543966384 ,   0.089304082211304162,  0.098744443364973969,  0.116766604872235072 
];

module.exports = coeffs;