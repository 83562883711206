import Signal from "@/core/Signal";
import { PovDatas } from "@/store/states/AppStateMachine";
import PostProcess from "@/webgl/entities/post-process/PostProcess";
import { mix } from "@/webgl/math";

export const enum POST_SETTINGS {
  SATURATION = "saturation",
  CONTRAST = "contrast",
  TEMPERATURE = "temperature",
  BRIGHTNESS = "brightness",
  NONE = "none"
}

export class PostSettings {

  onChange: Signal<POST_SETTINGS>;
  defaultMix: number;
  active: boolean = false;
  _mtrans: number = 0;

  constructor(
    public id: POST_SETTINGS,
    public min: number,
    public max: number,
    public mix: number,
    public handler: string,
    public property: string,
  ) {
    this.onChange = new Signal<POST_SETTINGS>();
    this.defaultMix = mix;
  }

  setActive(flag: boolean) {
    this.active = flag;
    this.onChange.emit(this.id);
  }

  setMix(v: number) {
    this.mix = v;
    this.onChange.emit(this.id);
  }

  getValue() {
    let m = this.active ? this.mix : this.defaultMix;
    return mix(this.min, this.max, m);
  }

  reset() {
    this.mix = this.defaultMix;
  }

}

export default class PostSettingsController {

  list: Map<POST_SETTINGS, PostSettings>;
  active: boolean = false;

  constructor(public post: PostProcess) {

    this.list = new Map<POST_SETTINGS, PostSettings>();

    this.addSettings(POST_SETTINGS.SATURATION, 0, 2.0, 0.5, "saturation", "amount");
    this.addSettings(POST_SETTINGS.CONTRAST, 0.9, 1.1, 0.5, "contrast", "contrast");
    this.addSettings(POST_SETTINGS.BRIGHTNESS, 0.65, 1.35, 0.5, "contrast", "brightness");
    this.addSettings(POST_SETTINGS.TEMPERATURE, 1, 0, 0.5, "temperature", "temperature");

  }

  addSettings(id: POST_SETTINGS, min: number, max: number, mix: number, handler: string, property: string) {
    const settings = new PostSettings(id, min, max, mix, handler, property);
    settings.onChange.on(this.applySettings)
    this.list.set(id, settings);
    this.applySettings(id);
  }

  applySettings = (id: POST_SETTINGS) => {
    this.post[this.list.get(id).handler][this.list.get(id).property] = this.list.get(id).getValue();
  }

  applyPovDatas = (povDatas: PovDatas) => {
    this.list.get(POST_SETTINGS.CONTRAST).setMix(povDatas.contrast);
    this.list.get(POST_SETTINGS.BRIGHTNESS).setMix(povDatas.brightness);
    this.list.get(POST_SETTINGS.SATURATION).setMix(povDatas.saturation);
    this.list.get(POST_SETTINGS.TEMPERATURE).setMix(povDatas.temperature);
  }

  setActive(flag: boolean) {
    this.list.forEach((settings) => {
      settings.setActive(flag);
    });
  }

  reset() {
    this.list.forEach((settings) => {
      settings.reset()
    });
  }

}