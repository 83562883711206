import { reactive } from "@vue/runtime-core";


export const MuseumStore = reactive({
  pathProgress: 0,

  xrpositionset : false,
  xrposition:[0,0,0],
  xrrotation:[0,0,0,1],
  // landscapeMode:true
})


// window.addEventListener( 'resize', () => {
//   MuseumStore.landscapeMode = window.innerWidth > window.innerHeight
// })