import gsap from "gsap";

export function anime(targets: gsap.TweenTarget, vars: gsap.TweenVars) {
  return new Promise<void>((onComplete) => {
    gsap.to(targets, {
      ...vars,
      onComplete(...args: any[]) {
        if (vars.onComplete) vars.onComplete(...args);
        onComplete();
      },
    });
  });
}

type AnimeValidProperty<T extends object> = {
  [K in keyof T]: T[K] extends string|number ? K : never;
}[keyof T];

type AnimeValidObject<T extends object> = Pick<T, AnimeValidProperty<T>>;

type AnimeVars<T extends object, O extends AnimeValidObject<T> = AnimeValidObject<T>> = {
  [K in keyof O]?: O[K] extends Function ? never : ([O[K], O[K]] | O[K]);
};

// export function animeFromTo<T extends object>(targets: T, vars: AnimeVars<T> | gsap.TweenVars) {
export function animeFromTo<T extends object>(targets: T, vars: any) {
  return new Promise<void>((onComplete) => {
    const from: gsap.TweenVars = {};
    const to: gsap.TweenVars = {};


    Object.entries(vars).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        from[key] = value[0];
        to[key]   = value[1];
      } else {
        to[key] = value;
      }
    });

    gsap.fromTo(
      targets,
      from,
      {
        ...to,
        onComplete(...args: any[]) {
          // @ts-ignore
          if (vars.onComplete) vars.onComplete(...args);
          onComplete();
        },
      }
    );
  });
}
