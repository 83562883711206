
import GLArrayBuffer from "nanogl/arraybuffer";
import XRModule from "../XRModule";

import Program from 'nanogl/program'

import VS from './shader.vert'
import FS from './shader.frag'
import { XRHandedness } from "webxr";
import Camera from "nanogl-camera";
import GL from "@/webgl/gl/GL";
import { LocalConfig } from "nanogl-state";
import { mat4 } from "gl-matrix";


const M4 = mat4.create()
const OFFSET_MAT = mat4.create()
mat4.fromTranslation(OFFSET_MAT, [0, 0, -.03])


export default class RaycastLineRenderer {

  geom : GLArrayBuffer
  prg: Program;
  cfg: LocalConfig;

  constructor( private xrmodule: XRModule ) {

    const gl = xrmodule.xrview.gl;

    this.prg = new Program( gl, VS(), FS());
    
    this.geom = new GLArrayBuffer( gl, new Float32Array([
      0,1,
      0,-1,
      1,1,
      1,-1,
    ]));

    this.geom.attrib( 'aPosition', 2, gl.FLOAT );

    this.cfg = xrmodule.scene.glstate.config()
      .depthMask(false)
      .enableCullface(false)
      .enableBlend()
      .enableDepthTest()
      .blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )

  }

  prepare(camera:Camera){
    this.prg.use()
    this.prg.uViewProjMatrix(camera._viewProj)
    this.prg.uCameraWorldPos( camera._wposition )

    this.geom.attribPointer( this.prg );
    this.cfg.apply()
  }


  draw( handedness: XRHandedness, opacity: number=1, length=2, thickness=.01 ){
    const gamepad = this.xrmodule.gamepads.list.get(handedness)
    const baseNode = gamepad.skin

    mat4.multiply(M4, baseNode._wmatrix, OFFSET_MAT)
    this.prg.uWorldMatrix(M4)
    this.prg.uParams( [ thickness, length, opacity ] )
    this.geom.draw( GL.TRIANGLE_STRIP );
  }

}