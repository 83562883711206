export class Result<Value, Error> {
  private _type: "ok" | "error";

  constructor(private _error: Error | boolean, private _value?: Value) {
    if (_error) {
      this._type = "error";
    } else {
      this._type = "ok";
    }
  }

  isOk() {
    return this._type === "ok";
  }

  isError() {
    return this._type === "error";
  }

  expect(message: string) {
    if (this._type === "error") {
      throw message;
    }
    return this._value;
  }

  unwrap() {
    if (this._type === "error") {
      throw this._error;
    }
    return this._value;
  }

  unwrapOr(cb: () => Value) {
    if (this._type === "error") {
      return cb();
    }
    return this._value;
  }

  get error() {
    return this._error;
  }

  static Ok<Value, Error>(value: Value) {
    return new Result<Value, Error>(false, value);
  }

  static Err<Value, Error>(err: Error) {
    return new Result<Value, Error>(err);
  }
}
