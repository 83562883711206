import GalleryXRActivity from "@/webgl/activities/GalleryXRActivity";
import GradientText from "@/webgl/entities/UIKit/gradient-text/GradientText";
import RaycastButton from "@/webgl/entities/UIKit/raycast-button/RaycastButton";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import gsap from "gsap";
import Camera from "nanogl-camera";
import Node from "nanogl-node";

export default class XRGalleryUI {

  node: Node;

  title: GradientText;
  subtitle: Node;
  alpha: number = 0;
  // paintingBtn: RaycastButton;

  get xrmodule(): XRModule {
    return this.activity.xrmodule;
  }

  get uikit(): UIKit {
    return this.activity.scene.xrmodule.ui;
  }

  constructor(
    public activity: GalleryXRActivity
  ) {

    this.node = new Node();
    this.node.z = -3.5;
    this.node.y = 2.5;
    
    this.title = new GradientText(this.uikit);
    this.title.setText("gallery.title");
    
    this.subtitle = new Node();
    this.subtitle.y -= this.uikit.textRenderer.texts.get("gallery.title").height * 0.5;
    
    this.node.add(this.title.node);
    this.node.add(this.subtitle);

    // this.paintingBtn = new RaycastButton(this.xrmodule.ui, "painting_btn")
    // this.paintingBtn.node.x = -this.xrmodule.ui.textRenderer.texts.get("painting_btn").width * 0.5 - this.paintingBtn.Padding[0];
    // this.paintingBtn.node.y = 0.1;
    // this.paintingBtn.node.z = this.activity.strip.root.z;

    // this.activity.title.hide();
    // this.activity.floor.hide();
    // this.activity.background.hide().then(()=>{
    //   AppService.send("UPDATE_POV_DATA", {pov: DefaultPovData});
    //   AppService.send("GOTO_PAINTING");
    // })

  }

  show() {
    this.activity.scene.root.add(this.node);
    // this.activity.scene.root.add(this.paintingBtn.node);
    // this.xrmodule.raycaster.add(this.paintingBtn);
    this.title.show();
    gsap.killTweensOf(this, "alpha");
    gsap.to(this, { alpha: 1, duration: 0.5 });
    // this.paintingBtn.show(0.5);
  }

  hide() {
    this.title.hide();
    // this.paintingBtn.hide(0.0);
    // this.xrmodule.raycaster.remove(this.paintingBtn);
    gsap.killTweensOf(this, "alpha");
    gsap.to(this, {
      alpha: 0, duration: 0.5, onComplete: () => {
        this.activity.scene.root.remove(this.node);
        // this.activity.scene.root.add(this.paintingBtn.node);
      }
    });
  }

  preRender() {
    this.title.preRender(this.activity.scene.dt);
  }

  render(camera: Camera) {

    // this.paintingBtn.render(camera);
    this.title.render(camera);
    this.uikit.textRenderer.render("gallery.subtitle", this.subtitle._wmatrix, [0.13, 0.13, 0.13, this.alpha]);

  }

}