import BtnHighlightPass from "@/webgl/entities/xr-module/gamepad/btn-highlight/BtnHighlightPass";
import { Passes } from "@/webgl/glsl/Passes";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import { GLContext } from "nanogl/types";

const MAT_ID = "btn-highlight"

export default class BtnHighlightMaterial extends BaseMaterial {

  constructor(gl: GLContext, name: string = MAT_ID) {

    super(gl, MAT_ID);
    
    this.addPass(new BtnHighlightPass(), Passes.DEFAULT)

    this.glconfig
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      .enableCullface(true)
      .cullFace(gl.BACK);

  }


}