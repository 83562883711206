import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38bf225a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MiniMap" }
const _hoisted_2 = { class: "MiniMap__Wrapper" }
const _hoisted_3 = {
  class: "MiniMap__Map",
  ref: "map"
}
const _hoisted_4 = {
  class: "Label small-caps",
  ref: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.coords && _ctx.showCoords)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.coords), 1)
          ], 64))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
    ], 512)
  ]))
}