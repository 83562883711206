import Node from "nanogl-node";

export default class ArtworkFog {
  root = new Node();
  edge = 0.2;
  private _radius = 0;

  get radius() {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
    this.updateScale();
  }

  get worldRadius() {
    return this.root._wmatrix[0];
  }

  private updateScale() {
    this.root.setScale(this._radius);
  }
}
