
import { gsap } from 'gsap'
import { defineComponent } from 'vue'
import legalPage, { PAGES as LegalPages } from '@/store/modules/LegalPage'

export default defineComponent({
  setup() {
    return {
      legalPage,
      LegalPages
    }
  },

  mounted() {
    if (window.localStorage.getItem('cookie-banner') === 'hidden') {
      this.$store.dispatch('toggleCookieBanner', false)
    }
  },
  methods: {
    onEnter(el, onComplete) {
      gsap.from(el, {
        delay: 5,
        autoAlpha: 0,
        duration: 0.4,
        ease: 'customOut',
        onComplete
      })
    },
    onLeave(el, onComplete) {
      gsap.to(el, {
        autoAlpha: 0,
        duration: 0.3,
        ease: 'customOut',
        onComplete
      })
    },
    close() {
      window.localStorage.setItem('cookie-banner', 'hidden')
      this.$store.dispatch('toggleCookieBanner', false)
    }
  }
})
