import GradientLinePass from "@/webgl/glsl/gradient-line/GradientLinePass";
import XRSettingsWheel from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheel";
import { Passes } from "@/webgl/glsl/Passes";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { quat, vec2 } from "gl-matrix";
import gsap from "gsap";
import { Uniform } from "nanogl-pbr/Input";
import { clamp, clamp01 } from "@/webgl/math";

const V2 = vec2.create();


export default class XRSettingsWheelSlider {

  value: number = 0;
  text: GltfNode;
  selectCircle: GltfNode;
  pin: GltfNode;
  line: GltfNode;
  sliderLine: GltfNode;

  active: boolean = false;

  private _depth: number = 0;
  private _renderables: MeshRenderer[] = [];
  private _rotation: quat;

  private _progressUniform: Uniform;
  private _gradientOffset: Uniform;
  private _time: number = 0;
  private _goffset: vec2 = vec2.create();
  private _pdeadzone: number = 0;
  private _inDeadzone: boolean = false;
  public get InDeadZone(): boolean {
    return this._inDeadzone;
  }

  get Renderables(): MeshRenderer[] {
    this._renderables.length = 0;
    this._renderables.push(this.line.renderable);
    if (this.active) {
      this._renderables.push(this.sliderLine.renderable);
      this._renderables.push(this.pin.renderable);
    }
    return this._renderables;
  }

  constructor(
    public wheel: XRSettingsWheel,
    public node: GltfNode
  ) {

    this.text = node.findChild("text");
    this.selectCircle = node.findChild("select-circle");
    this.line = node.findChild("line");
    this.sliderLine = node.findChild("slider-line");
    this.pin = this.selectCircle.findChild("pin");
    this._rotation = quat.create();

    const gpass = (this.sliderLine.renderable as MeshRenderer).materials[0].getPass(Passes.DEFAULT).pass as GradientLinePass;
    this._progressUniform = gpass.progress.attachUniform("uProgress", 1);
    this._gradientOffset = gpass.texcoordOffset.attachUniform("uGradientOffset", 2);
    this._gradientOffset.set(...this._goffset);
    gpass.color = wheel.wheelGltf.getElementByName<GltfTypes.TEXTURE>(GltfTypes.TEXTURE, "gradient_purple").glTexture;

  }

  processInput(dt) {

    const axes = this.wheel.scene.xrview.inputs.getAxes("left");
    V2.set(axes);

    let current = this.value;
    let value = this.value;

    if (this._inDeadzone) {

      this._pdeadzone = clamp(this._pdeadzone + axes[0] * dt * 8.0, -1.0, 1.0);
      if (this._pdeadzone == 1 || this._pdeadzone == -1) {
        this._inDeadzone = false;
      }

    } else {

      value = clamp01(this.value + axes[0] * dt);
      if (Math.sign(current - 0.5) !== Math.sign(value - 0.5)) {
        this._pdeadzone = 0;
        this._inDeadzone = true;
        this.wheel.xrmodule.Inputs.getInput("left").gamepad.pulse(0.6, 50);
        this.setValue(0.5);
      }

    }

    this.setValue(value);

  }

  preRender(dt: number) {
    this._time += dt;
    this._goffset[0] = Math.cos(this._time) * 0.25;
    this._goffset[1] = Math.sin(this._time) * 0.25;
    this._gradientOffset.set(...this._goffset);
  }

  setActive(flag: boolean) {

    this.active = flag;
    let tgt = this.active ? 0.8 : 0.0;

    gsap.killTweensOf(this, "_depth");
    gsap.to(this, {
      _depth: tgt, duration: 0.3, ease: "power2.out", onUpdate: () => {
        this.node.z = this._depth;
      }
    });

  }

  setValue(v) {
    if (v != this.value) {
      this.value = v;
      this._progressUniform.set(v);
      quat.identity(this._rotation);
      quat.rotateZ(this._rotation, this._rotation, -(v - 0.5) * Math.PI);
      this.selectCircle.rotation.set(this._rotation);
      this.selectCircle.invalidate();
    }
  }

}