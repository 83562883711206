import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { TextureFiltering, TextureSrcSet, TextureWrap } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Programs from "@/webgl/gl/Programs";
import Scene from "@/webgl/Scene";


export default class GlobalResources {

  static setupTextures(group: ResourceGroup, scene: Scene) {
    let srcSet = new TextureSrcSet("assets/webgl/noise_8x8.png");
    group.add(new TextureResource(srcSet, scene), "noise3D_4x4");


    // srcSet = new TextureSrcSet("assets/webgl/ray_line.png");
    // srcSet.options = {
    //   bbc: false,
    //   flipY: true,
    //   genMips: false,
    //   wrap: TextureWrap.CLAMP,
    //   filtering: TextureFiltering.LINEAR
    // }
    // group.add(new TextureResource(srcSet, scene), "ray_line");


  }

  static setupPrograms(programs: Programs) {

  }

}