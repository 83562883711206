import MuseumAssets from "@/assets/webgl/museum"
import { vec3 } from "gl-matrix"


const POSITIONS = [[7.19008446, 4.02887678, 7.92765665], [-4.09254217, 4.02887678, -0.804036617], [0.0744710118, 1.75781261e-05, 19.4796829], [0.128111765, 1.75781261e-05, 9.73692894], [-0.0253439955, 1.75781261e-05, -1.98770666], [-0.0106124273,
  1.61132812e-05, -5.9361124], [-0.172248051, 1.61132812e-05, -11.1159782], [0.272649169, 1.75781261e-05, -17.7186222], [5.94633007, 4.02887678, -0.677178979], [-0.034140151, 1.75781261e-05, 3.47439742]] as const


class Probe {
  pos = vec3.create()
  sh:Float32Array

  constructor(index : number) {
    this.pos.set(POSITIONS[index])
    const coeffs = require(`@/assets/webgl/museum/probe.${(index+1)}`)
    this.sh = new Float32Array(coeffs)
  }

}

export class Probes {
  
  probes: Probe[] = []

  constructor() {
    for (let i = 0; i < POSITIONS.length; i++) {
      this.probes.push( new Probe(i))    
    }
  }

  getNearestProbe(pos: vec3) : Probe {
    let min = Infinity
    let minProbe: Probe = null
    for (let probe of this.probes) {
      const dist = vec3.squaredDistance(probe.pos, pos)
      if (dist < min) {
        min = dist
        minProbe = probe
      }
    }
    return minProbe
  }
}