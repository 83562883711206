/////////////
/////////////////////////////////
//////////


import { POST_SETTINGS } from "@/webgl/entities/post-process/PostSettingsController";
import AppService from "@/store/states/AppService";
import { AppState, PovDatas, SharedMedia } from "@/store/states/AppStateMachine";
import UrlParams from "@/utils/UrlParams";
import Navigation from "@/webgl/entities/xr-module/navigation/Navigation";
import Camera from "nanogl-camera";
import Fbo from "nanogl/fbo";
import PostProcess from "@/webgl/entities/post-process/PostProcess";
import XRSettingsWheel from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheel";
import XRSnapshot from "@/webgl/activities/PaintingXRActivity/xr-snapshot/XRSnapshot";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Scene from "@/webgl/Scene";
import { IActivity } from "@/webgl/activities/Activity";
import StateHelper from "@/webgl/activities/StateHelper";
import XRPhotoTutorial from "@/webgl/activities/PaintingXRActivity/XRPhotoTutorial";
import XRSharePhoto from "@/webgl/activities/PaintingXRActivity/xr-share-photo/XRSharePhoto";
import XRPaintingInputHelper from "@/webgl/activities/PaintingXRActivity/XRPaintingInputHelper";
import { CreatePane } from "@/dev/Tweak";
import { mat4, quat, vec3 } from "gl-matrix";

import Deferred from "@/core/Deferred";
import Fader from "@/webgl/entities/fader/Fader";
import XRPhotoModePush from "@/webgl/activities/PaintingXRActivity/XRPhotoModePush";
import PaintingAudio from "@/webgl/scenes/PaintingScene/PaintingAudio";
import AudioManager from "@/core/audio/AudioManager";

const post_backbuffer = UrlParams.getBool("debug-xr");

export default class PaintingXRActivity implements IActivity {

///////////////
///////////////////
////////////

  readonly priority: number = 0;
  private loaded = false;

  inputHelper: XRPaintingInputHelper;

  photoModePush: XRPhotoModePush;
  snapshot: XRSnapshot;
  settingswheel: XRSettingsWheel;
  navigation: Navigation;
  postprocess: PostProcess;
  photoTutorial: XRPhotoTutorial;
  share: XRSharePhoto;
  fader: Fader;

  private stateHelper: StateHelper;
  audio: PaintingAudio;


  get xrmodule(): XRModule {
    return this.scene.xrmodule;
  }

  constructor(public scene: Scene) {
    console.log("Creating PaintingXRActivity");

    this.postprocess = new PostProcess(scene);
    this.audio = new PaintingAudio(scene.paintingScene);

    this.stateHelper = new StateHelper([
      { match: 'painting.explore', enter: this.enterExplore, exit: this.exitExplore },
      { match: 'painting.photo.tutorial', enter: this.enterTutorial, exit: this.exitTutorial },
      { match: 'painting.photo.default', enter: this.enterPhoto, exit: this.exitPhoto },
      { match: 'painting.snapshot', enter: this.enterSnapshot },
      { match: 'share.preview', enter: this.enterPreview, exit: this.exitPreview },
      { match: 'share.select', enter: this.enterShare, exit: this.exitShare },
    ]);


  }


  async load(): Promise<void> {
    // console.log("Loading PaintingXRActivity");

/////////////////
/////////////////////////////////////////

//////////////////////////////////////////////////////////////////
/////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////
//////////
//////////////
    if (this.loaded)
      return;

    this.postprocess.init()

    await Promise.all([
      this.scene.loadXRModule(),
      this.scene.loadPaintingScene(),
      this.audio.loadPositions()
    ])

    this.inputHelper = new XRPaintingInputHelper(this);
    this.snapshot = new XRSnapshot(this.xrmodule, this);
    this.settingswheel = new XRSettingsWheel(this.xrmodule, this.snapshot, this.postprocess.settings.list);
    this.navigation = new Navigation(this.xrmodule);
    this.photoTutorial = new XRPhotoTutorial(this);
    this.photoModePush = new XRPhotoModePush(this);
    this.share = new XRSharePhoto(this);
    this.fader = new Fader(this.scene);

    await Promise.all([
      this.snapshot.load(),
      this.settingswheel.load(),
      this.navigation.load(),
      this.share.load()
    ])

    this.navigation.navmesh = this.scene.paintingScene.navmesh;

    this.loaded = true;
    // NEVER ENDING LOADING - DEBUG LOADING SCREEN
    // await new Promise((resolve)=>{});

    console.log("Loaded PaintingXRActivity");

  }

  unload(): void {
    this.loaded = false;
    this.inputHelper = null;
    this.snapshot = null;
    this.settingswheel = null;
    this.navigation = null;
    this.photoTutorial = null;
    this.photoModePush = null;
    this.share = null;
    this.fader = null;

  }

  start() {

    this.scene.xrmodule.gamepads.setCustomLightSetup(this.scene.paintingScene.lighting.lightSetup)

    if (!AppService.state.context.paintingPhotoTutorialCompleted)
      this.photoModePush.stateHelper.start();

    this.stateHelper.start();
    this.inputHelper.start();
    this.fader.set(1);
    this.fader.fadeOut();
    this.updatePovDatas(AppService.state.context.povDatas);
    this.scene.xrview.session.updateRenderState({
      depthNear: 0.15,
      depthFar: 500
    });

    this.audio.start();

  }

  stop(): void {
    console.log('[PaintingXRActivity] stop()');
    this.settingswheel.setActive(false);
    this.scene.xrmodule.gamepads.restoreDefaultLightSetup()
    this.stateHelper.stop();
    this.inputHelper.stop();
    this.audio.stop();
  }


  /*
  LIFECYCLE
  */
  onStateUpdate(state: AppState): void { }

  getPovDatas(): PovDatas {
    const p = this.xrmodule.xrcamera.wposition;
    const r = this.xrmodule.xrcamera.wrotation;
    return {
      position: [p[0], p[1], p[2]],
      rotation: [r[0], r[1], r[2], r[3]],
      format: this.snapshot.CurrentFormat,
      contrast: this.postprocess.settings.list.get(POST_SETTINGS.CONTRAST).mix,
      brightness: this.postprocess.settings.list.get(POST_SETTINGS.BRIGHTNESS).mix,
      saturation: this.postprocess.settings.list.get(POST_SETTINGS.SATURATION).mix,
      temperature: this.postprocess.settings.list.get(POST_SETTINGS.TEMPERATURE).mix,
      zoom: 0.5
    }
  }

  updatePovDatas(povDatas: PovDatas) {

    this.navigation.xrctrl.setPovDatas(povDatas);

    this.postprocess.settings.list.get(POST_SETTINGS.CONTRAST).setMix(povDatas.contrast);
    this.postprocess.settings.list.get(POST_SETTINGS.BRIGHTNESS).setMix(povDatas.brightness);
    this.postprocess.settings.list.get(POST_SETTINGS.SATURATION).setMix(povDatas.saturation);
    this.postprocess.settings.list.get(POST_SETTINGS.TEMPERATURE).setMix(povDatas.temperature);

  }


  //== TUTORIAL
  //===========
  enterTutorial = (): void => {
    console.log('[PaintingXRActivity] enterTutorial()');
    this.navigation.disable();
    this.postprocess.settings.setActive(true);
    this.settingswheel.enable();
    this.settingswheel.setActive(true);
    this.navigation.disable();
    this.photoTutorial.start().then(() => {
      AppService.send("COMPLETE_TUTORIAL");
    })
  }

  exitTutorial = (): void => {
    console.log('[PaintingXRActivity] exitTutorial()');
    this.navigation.xrctrl.enableController("left");
  }



  //== EXPLORE
  //===========
  enterExplore = (): void => {

    console.log('[PaintingXRActivity] enterExplore()');

    this.navigation.enable();
    this.snapshot.disable();

    this.settingswheel.enable();
    this.postprocess.settings.setActive(false);

  }

  exitExplore = (): void => { console.log('[PaintingXRActivity] exitExplore()') }



  //== PHOTO MODE
  //===========
  enterPhoto = (): void => {
    console.log('[PaintingXRActivity] enterPhoto()');
    this.snapshot.clear();
    this.snapshot.enable();

    this.settingswheel.enable();
    this.settingswheel.setActive(true);

    this.postprocess.settings.setActive(true);
    this.navigation.enable();
    this.navigation.xrctrl.disableController("left");

  }

  exitPhoto = (): void => {
    if (AppService.state.matches('painting.photo.tutorial'))
      return;
    console.log('[PaintingXRActivity] exitPhoto()');
    this.settingswheel.setActive(false);
    this.navigation.xrctrl.enableController("left");
    // this.snapshot.disable();

  }


  //== SNAPSHOT
  //===========
  enterSnapshot = (): void => {

    console.log('[PaintingXRActivity] enterSnapshot()');
    this.snapshot.enable();
    this.settingswheel.disable();
    this.navigation.disable();
    AppService.send("UPDATE_POV_DATA", {
      pov: <PovDatas>{
        position: Array.from(this.xrmodule.xrcamera.wposition),
        rotation: Array.from(this.xrmodule.xrcamera.wrotation),
        contrast: this.postprocess.settings.list.get(POST_SETTINGS.CONTRAST).mix,
        brightness: this.postprocess.settings.list.get(POST_SETTINGS.BRIGHTNESS).mix,
        saturation: this.postprocess.settings.list.get(POST_SETTINGS.SATURATION).mix,
        temperature: this.postprocess.settings.list.get(POST_SETTINGS.TEMPERATURE).mix,
        zoom: 0.5
      }
    });

    this.createSnapshot();

  }


  //== PREVIEW
  //===========
  enterPreview = (): void => {
    console.log('[PaintingXRActivity] enterPreview()')
    this.snapshot.enable();
    this.postprocess.settings.setActive(true);
    this.settingswheel.disable();
    this.navigation.disable();
  }

  exitPreview = (): void => {
    console.log('[PaintingXRActivity] exitPreview()')
  }


  //== SHARE
  //=========
  enterShare = (): void => {
    console.log('[PaintingXRActivity] enterShare()')
    this.share.enable();
    this.postprocess.settings.setActive(true);
    this.snapshot.disable();
    this.settingswheel.disable();
    this.navigation.disable();
  }

  exitShare = (): void => {
    console.log('[PaintingXRActivity] exitShare()');
    this.share.disable();
  }


  preFrame(): void {

    if (this.snapshotDefered) {

      this.snapshot.snap();

      const snapresolve = this.snapshotDefered.resolve;
      this.snapshot.snapshotToBlob().then((blob: Blob) => snapresolve(blob));
      this.snapshotDefered = null;

    }

  }


  preRender(): void {

    this.scene.paintingScene.preRender();
    this.navigation.preRender();
    this.xrmodule.preRender();
    this.snapshot.preRender();
    this.settingswheel.preRender();
    this.photoTutorial.preRender();
    this.share.preRender();

    AudioManager.updateListener(this.xrmodule.xrcamera.head._wmatrix);
    this.audio.update(this.scene.dt);

  }


  render(): void {

    const gl = this.scene.gl;
    const gllayer = this.scene.xrview.gllayer

    const w = gllayer.framebufferWidth;
    const h = gllayer.framebufferHeight;


    // RENDER
    // ========

    if (!this.share.IsActive || this.share.InTransition) {
      if (this.postprocess.setup.enabled) {
        this.postprocess.preRender(w, h);
        this.postprocess.bindColor();
      } else {
        gl.bindFramebuffer(gl.FRAMEBUFFER, gllayer.framebuffer);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }

      this.scene.glstate.apply();

      for (const camera of this.xrmodule.xrcamera.cameras) {
        const vp = camera.lens.viewport
        gl.viewport(vp.x, vp.y, vp.width, vp.height);

        this.renderScene(camera);
        this.fader.render(camera);
      }

      this.scene.glstate.apply();
    }

    if (post_backbuffer) {
      this.postprocess.render(0, 0, w, h, null);
    } else {
      this.postprocess.render(0, 0, w, h, gllayer.framebuffer);
      gl.bindFramebuffer(gl.FRAMEBUFFER, gllayer.framebuffer);
    }

    for (const camera of this.xrmodule.xrcamera.cameras) {
      const vp = camera.lens.viewport
      gl.viewport(vp.x, vp.y, vp.width, vp.height);

      this.renderUI(camera);

    }

  }

  renderSnapshot(camera: Camera, fbo: Fbo) {
    const gl = this.scene.gl;

    const w = fbo.width;
    const h = fbo.height;

    this.scene.paintingScene.reflect.prepare(w, h);
    this.scene.paintingScene.reflect.viewport(gl, 0, 0, w, h);
    this.scene.paintingScene.reflectPass(camera);

    this.scene.paintingScene.probe.effect.viewport[0] = w;
    this.scene.paintingScene.probe.effect.viewport[1] = h;

    if (this.postprocess.setup.enabled) {
      this.postprocess.preRender(w, h);
      this.postprocess.bindColor();
    } else {
      fbo.bind();
      fbo.clear();
    }

    gl.clearColor(1.0, 1.0, 1.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    gl.viewport(0, 0, w, h);
    this.renderScene(camera);

    this.scene.glstate.apply();
    this.postprocess.render(10, 10, w - 20, h - 20, fbo.fbo);


  }

  renderScene(camera: Camera) {
    this.scene.paintingScene.render(camera)
  }

  renderUI(camera: Camera): void {
    this.photoModePush.render(camera);
    this.share.render(camera);
    this.navigation.render(camera);
    this.photoTutorial.render(camera);
    this.xrmodule.render(camera)
    this.settingswheel.render(camera);
    this.snapshot.render(camera);
  }


  rttPass(): void {

    const gl = this.scene.gl;
    const gllayer = this.scene.xrview.gllayer

    const w = gllayer.framebufferWidth;
    const h = gllayer.framebufferHeight;

    this.scene.paintingScene.reflect.prepare(w, h);
    for (const camera of this.scene.xrmodule.xrcamera.cameras) {
      const vp = camera.lens.viewport;
      this.scene.paintingScene.reflect.viewport(gl, vp.x, vp.y, vp.width, vp.height);
      this.scene.paintingScene.reflectPass(camera);
    }

    this.scene.paintingScene.probe.effect.viewport[0] = w;
    this.scene.paintingScene.probe.effect.viewport[1] = h;
    this.scene.paintingScene.reflect.blitRenderBuffer();

  }



  private snapshotDefered: Deferred<Blob>

  async createSnapshot(): Promise<void> {

    this.snapshotDefered = new Deferred<Blob>()

    const blob = await this.snapshotDefered.promise

    AppService.send({
      type: 'SNAPSHOT_READY', media: {
        blob,
        url: "",
        loaded: false,
        artworkId: ""
      }
    })

  }

}
