import Signal from "@/core/Signal";
import Inputs, { InputTouch } from "@/webgl/lib/inputs";
import Picking from "@/webgl/lib/Picking";
import { clamp, clamp01, mix } from "@/webgl/math";
import Ray from "@/webgl/math/Ray";
import { mat3, mat4, quat, vec2, vec3 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { mat4Yaxis, mat4Zaxis } from "@/webgl/math/mat4-axis";
import mat4LookAt from "@/webgl/math/mat4-lookat";
import PaintingActivity from "@/webgl/activities/PaintingActivity";
import Node from "nanogl-node";
import { DefaultPovData, PovDatas } from "@/store/states/AppStateMachine";
import AppService from "@/store/states/AppService";
import { fromEuler, toEuler } from "@/webgl/lib/QuatUtils";
import AudioManager from "@/core/audio/AudioManager";
import Plane from "@/webgl/math/Plane";
import rayPlaneIntersection from "@/webgl/math/ray-plane-intersection";

export const enum CTRL_MODE {
  MOUSE,
  TOUCH
}


const DELTA = vec2.create()
const Q_ID = quat.create()
const Q_1 = quat.create()
const Q_2 = quat.create()
const M0 = mat4.create()
const M1 = mat4.create()

/**
 *  Split matrix in leveled one and remainder
 */
const _elV0 = vec3.create()
const _elDIR = vec3.create()
const _elUP = vec3.create()
const _elINV = mat4.create()
function extractLeveledMatrix(m: mat4, leveled: mat4, remainder: mat4) {
  mat4Zaxis(_elDIR, m)
  mat4Yaxis(_elUP, m)
  _elDIR[1] = 0
  _elDIR[0] *= -1
  _elDIR[2] *= -1
  mat4LookAt(leveled, _elV0, _elDIR, _elUP)
  leveled[12] = m[12]
  leveled[13] = m[13]
  leveled[14] = m[14]
  mat4.invert(_elINV, leveled)
  mat4.multiply(remainder, _elINV, m)
}

class LookAtController {

  currentTouch: InputTouch | null = null;

  rotations = vec2.create()
  lastFrameRotation = vec2.create()
  rotationsVelocity = vec2.create()
  startRotations = vec2.create()

  private _rotationMatrix = mat4.create()

  public onStartLook: Signal = new Signal();
  public onEndLook: Signal = new Signal();

  constructor(private camctrl: PaintingCameraController) { }

  start() {
    this.camctrl.activity.scene.inputs.onTouchAdded.on(this.onTouchDown)
  }


  stop() {
    this.currentTouch = null
    this.camctrl.activity.scene.inputs.onTouchAdded.off(this.onTouchDown)
  }


  _storeVelocity(dt: number) {

    vec2.sub(DELTA, this.rotations, this.lastFrameRotation)
    if (vec2.length(DELTA) > 0)
      vec2.scale(this.rotationsVelocity, DELTA, 1 / dt)
  }


  update(dt: number) {

    this.lastFrameRotation.set(this.rotations)

    if (this.currentTouch) {
      this.currentTouch.getDelta(DELTA)

      const dx = DELTA[0] * this.camctrl.camera.lens._hfov * .5
      const dy = DELTA[1] * this.camctrl.camera.lens._vfov * .5

      this.rotations[0] = this.startRotations[0] + dx
      this.rotations[1] = this.startRotations[1] + dy
      this._storeVelocity(dt)
    } else {
      vec2.scale(this.rotationsVelocity, this.rotationsVelocity, .9)
      vec2.scaleAndAdd(this.rotations, this.rotations, this.rotationsVelocity, dt)
    }

  }

  attenuateRotations(scale: number) {
    this.rotations[0] *= scale
    this.rotations[1] *= scale
  }

  onTouchDown = (touch: InputTouch) => {
    // if (touch.nativeTouch.identifier.toString() !== 'mouse0') return
    this.onStartLook.emit();
    this.startRotations.set(this.rotations)
    this.currentTouch = touch
    this.currentTouch.onEnd.on(this.onTouchUp)
  }

  onTouchUp = () => {
    this.currentTouch = null
    this.onEndLook.emit();
  }

  getRotationMatrix(): mat4 {

    quat.rotateY(Q_1, Q_ID, this.rotations[0])
    quat.rotateX(Q_2, Q_ID, -this.rotations[1])
    quat.multiply(Q_1, Q_1, Q_2)
    mat4.fromQuat(this._rotationMatrix, Q_1)
    return this._rotationMatrix
  }

}


class PinchController {


  public onPinchStart: Signal = new Signal();
  public onPinchUpdate: Signal = new Signal();
  public onPinchEnd: Signal = new Signal();

  private _touches: InputTouch[] = [];
  private _tcount: number = 0;
  private _sdelta: number = 0;
  private _cdelta: number = 0;
  private _delta: number = 0;

  private get IsPinching(): boolean {
    return this._tcount == 2;
  }

  constructor(private camctrl: PaintingCameraController) { }

  start() {
    this.camctrl.activity.scene.inputs.onTouchAdded.on(this.onTouchDown);
    this.camctrl.activity.scene.inputs.onTouchMove.on(this.onTouchMove);
    this.camctrl.activity.scene.inputs.onTouchRemoved.on(this.onTouchUp);
  }

  stop() {
    this.camctrl.activity.scene.inputs.onTouchAdded.off(this.onTouchDown);
    this.camctrl.activity.scene.inputs.onTouchMove.off(this.onTouchMove);
    this.camctrl.activity.scene.inputs.onTouchRemoved.off(this.onTouchUp);
  }

  startPinch() {
    this.onPinchStart.emit();
    this._sdelta = vec2.distance(this._touches[0].ncoords, this._touches[1].ncoords);
  }

  onTouchDown = (touch: InputTouch) => {

    const wasPinching = this.IsPinching;
    if (this._touches.indexOf(touch) === -1 && this._tcount < 2) {
      this._touches.push(touch);
    }
    this._tcount += 1;

    if (!wasPinching && this.IsPinching)
      this.startPinch();

  }

  onTouchMove = () => {
    if (!this.IsPinching)
      return;

    this._cdelta = vec2.distance(this._touches[0].ncoords, this._touches[1].ncoords);
    let d = this._cdelta - this._sdelta;
    if (d != this._delta) {
      this._delta = d;
      this.onPinchUpdate.emit();
    }

    this._delta = d;

  }

  onTouchUp = (touch: InputTouch) => {

    const tidx = this._touches.indexOf(touch);
    const wasPinching = this.IsPinching;

    if (tidx !== -1) {
      this._touches.splice(tidx, 1);
      this._tcount -= 1;
    }

    if (wasPinching && !this.IsPinching)
      this.onPinchEnd.emit();

  }

  getDelta() {
    return this._delta;
  }

}



export default class PaintingCameraController {

  private _active: boolean = false;
  private _tdown: boolean;
  private _touchMoved: boolean;
  public get Active(): boolean {
    return this._active;
  }

  private _canMove: boolean = true;
  public get CanMove(): boolean {
    return this._canMove;
  }

  private _moving: boolean = false;
  public get Moving(): boolean {
    return this._moving;
  }

  private _storedZoom: number = 0.5;
  private _zoomValue: number = 0.5;
  public get ZoomValue(): number {
    return this._zoomValue;
  }

  private _canZoom: boolean = false;
  public get CanZoom(): boolean {
    return this._canZoom;
  }
  private _defaultFOV: number = Math.PI / 3;
  private _mixZoomProgress: number = 0;

  public dragRay: Ray = new Ray();
  public pickingRay: Ray = new Ray();

  public navmesh?: Picking = null;
  public onMove: Signal<any> = new Signal();

  public hasTarget: boolean = false;
  public isNavigable: boolean = true;
  public target: vec3 = vec3.create();
  public normal: vec3 = vec3.create();

  public lookat: LookAtController;
  private pinch: PinchController;

  private camTransform: Node
  private _castCoord: vec2;
  private _ctrlMode: CTRL_MODE;
  public get CtrlMode(): CTRL_MODE {
    return this._ctrlMode;
  }

  private floorPlane: Plane;
  public hasGuardTarget: boolean = false;


  constructor(
    public readonly activity: PaintingActivity,
    public readonly camera: Camera<PerspectiveLens>
  ) {

    this._defaultFOV = this.camera.lens.fov;

    this.lookat = new LookAtController(this);
    this.pinch = new PinchController(this);

    this.lookat.onStartLook.on(this.updateIdleMatrix);

    this.pinch.onPinchStart.on(this.onPinchStart);
    this.pinch.onPinchUpdate.on(this.onPinchUpdate);
    this.pinch.onPinchEnd.on(this.onPinchEnd);

    this.camTransform = new Node();
    this.activity.scene.root.add(this.camTransform);
    this.floorPlane = new Plane();

  }


  updatePovData(povDatas: PovDatas) {

    const V3A = vec3.create();
    V3A.set(povDatas.position);
    V3A[1] = DefaultPovData.position[1];

    const QUAT = quat.create();
    QUAT.set(povDatas.rotation);
    const M4 = mat4.create();
    mat4.fromQuat(M4, QUAT);

    const m = mat4.create();
    mat4.fromRotationTranslation(m, QUAT, V3A);

    mat4Zaxis(_elDIR, m)
    _elDIR[1] *= -1
    _elDIR[0] *= -1
    _elDIR[2] *= -1
    mat4LookAt(M4, _elV0, _elDIR, vec3.fromValues(0.0, 1.0, 0.0))
    M4[12] = m[12]
    M4[13] = m[13]
    M4[14] = m[14]

    const M3 = mat3.create();
    mat3.fromMat4(M3, M4);
    quat.fromMat3(QUAT, M3);

    this.camTransform.position.set(V3A);
    this.camTransform.rotation.set(QUAT);
    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix();

  }


  // STATES
  //======
  enable() {
    if (this._active)
      return;

    this.activity.scene.inputs.onTouchAdded.on(this.onTouchAdded);
    this.activity.scene.inputs.onTouchMove.on(this.onTouchMove);
    this.activity.scene.inputs.onTouchRemoved.on(this.onTouchRemoved);
    this._active = true;
    this.updateIdleMatrix();
    this.lookat.start();

  }

  disable() {
    if (!this._active)
      return;

    this.activity.scene.inputs.onTouchAdded.off(this.onTouchAdded);
    this.activity.scene.inputs.onTouchMove.off(this.onTouchMove);
    this.activity.scene.inputs.onTouchRemoved.off(this.onTouchRemoved);
    this.lookat.stop();
    this.pinch.stop();
    this._active = false;
  }

  enableZoom() {
    this.pinch.start();
    this._canZoom = true;
    gsap.killTweensOf(this);
    gsap.to(this, { _mixZoomProgress: 1, duration: 0.4, onUpdate: () => this.applyZoom() });
  }

  disableZoom() {
    this.pinch.stop();
    gsap.killTweensOf(this);
    gsap.to(this, { _mixZoomProgress: 0, duration: 0.4, onUpdate: () => this.applyZoom(), onComplete: () => this.setZoom(0.5) });
    this._canZoom = false;
  }

  lockMove() {
    this._canMove = false;
  }

  unlockMove() {
    this._canMove = true;
  }

  onTouchAdded = (touch: InputTouch) => {
    if (touch.nativeTouch.identifier.toString() === "mouse0") {
      this._ctrlMode = CTRL_MODE.MOUSE;
    } else {
      this._ctrlMode = CTRL_MODE.TOUCH;
    }
    this._tdown = true;
    this._touchMoved = false
  }

  onTouchMove = () => {
    this._touchMoved = true;
  }

  onTouchRemoved = (touch: InputTouch) => {
    if (touch.nativeTouch.identifier.toString() === "mouse0") {
      this._ctrlMode = CTRL_MODE.MOUSE;
    } else {
      this._ctrlMode = CTRL_MODE.TOUCH;
    }
    this._tdown = false;
    if (!this._touchMoved && this.hasTarget && !this.hasGuardTarget)
      this.moveToTarget();
    this._touchMoved = false;
  }

  onPinchStart = () => {
    this.lookat.stop()
    this._storedZoom = this.ZoomValue;
  }

  onPinchUpdate = () => {
    const zoom = clamp01(this._storedZoom + this.pinch.getDelta());
    AppService.send({ type: 'UPDATE_POV_DATA', pov: { zoom } })
  }

  onPinchEnd = () => {
    this.lookat.start()
    // this._storedZoom = this.ZoomValue;
  }

  // ZOOM
  //=====
  setZoom(x: number) {
    if (x !== this.ZoomValue && this.CanZoom) {
      this._zoomValue = x;
      this.applyZoom();
    }
  }

  applyZoom() {
    const x = this.ZoomValue;
    let value = this.ZoomValue;
    if (x >= 0.5) value = 1 + (x - 0.5) * 2.0;
    if (x < 0.5) value = 0.5 + (x);
    let fov = mix(this._defaultFOV, this._defaultFOV / value, this._mixZoomProgress);
    this.camera.lens.setVerticalFov(fov);
    this.camera.invalidate();
  }

  resetFOV() {
    this.camera.lens.setVerticalFov(this._defaultFOV);
  }

  updateIdleMatrix = () => {
    this.lookat.attenuateRotations(0);
    this.camTransform.setMatrix(this.camera._matrix);
    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix();
  }




  // MOVE
  //=====
  raycastNavMesh(coords: vec2): boolean {
    this.pickingRay.unproject(coords, this.camera);
    return this.navmesh.raycast(this.pickingRay, this.target, this.normal) !== 0;
  }

  raycastPlane(coords: vec2): boolean {
    this.pickingRay.unproject(coords, this.camera);
    return rayPlaneIntersection(this.target, this.pickingRay, this.floorPlane);
  }

  moveToTarget() {
    if (this._moving || !this.CanMove)
      return;

    AudioManager.playUI("ui_click_move");
    this._moving = true;
    gsap.killTweensOf(this.camTransform);
    gsap.to(this.camTransform, {
      x: this.target[0],
      y: this.target[1] + DefaultPovData.position[1],
      z: this.target[2],
      duration: 2.0,
      ease: "power2.inOut",
      onComplete: () => this._moving = false
    });

    this.onMove.emit();

  }

  resolveRaycastCoords(): boolean {

    if (!this.Active)
      return false;

    const touch = this.activity.scene.inputs.touches.length > 0;
    const mouse = this.activity.scene.inputs.hasMouse;

    if (mouse) {
      this._castCoord = this.activity.scene.inputs.mouseCoords;
    }
    if (touch) {
      this._castCoord = this.activity.scene.inputs.touches[0].ncoords;
    }

    return mouse || touch;

  }

  preRender() {

    this.hasTarget = false;
    this.hasGuardTarget = false;

    if (this.resolveRaycastCoords()) {
      this.hasTarget = this.raycastNavMesh(this._castCoord)
      if (!this.hasTarget) {
        this.hasGuardTarget = this.raycastPlane(this._castCoord);
        this.hasTarget = this.hasGuardTarget;
      }
    }

    const m = this.camera._matrix;

    m.set(this.camTransform._wmatrix);

    extractLeveledMatrix(m, M0, M1);

    this.lookat.update(this.activity.scene.dt);

    mat4.multiply(m, M0, this.lookat.getRotationMatrix())
    mat4.multiply(m, m, M1)

    this.camera.setMatrix(m)
    // this.camera.rotateZ(0.5);

  }

}