
import { defineComponent, ref, computed } from 'vue'
import { useActor, useSelector } from '@xstate/vue'
import { useI18n } from 'vue-i18n'
import { gsap } from 'gsap'

import AppService from '@/store/states/AppService'
import Viewport from '@/store/modules/Viewport'
import type { ShareService } from '@/store/states/AppStateMachine'

import {
  DIRECTION as UIBtnSecondaryDirection,
  THEME as UIBtnSecondaryTheme,
} from '@/components/UIBtn/UIBtnSecondary.vue'
import MiniMap from '@/components/MiniMap/MiniMap.vue'
import { generateShareURL } from '@/api/share'
import Gtm from '@/utils/Gtm'
import Browser from '@/utils/Browser'

const SHARERS = {
  facebook: 'https://www.facebook.com/share.php?u=%URL%',
} as Record<ShareService, string>

const ShareVue = defineComponent({
  components: {
    MiniMap
  },

  setup() {


    const { t } = useI18n()
    const mediaUrl = useSelector(AppService, state => {
      if (state.context.sharedMedia.loaded) {
        return state.context.sharedMedia.url;
      }
      return "";
    })
    const shareSource = useSelector(AppService, state => state.context.sharedMedia.blob)
    const mapPosition = useSelector(AppService, state => state.context.povDatas)

    const supportWebShareAPI = ref(!!window.navigator.share)
/////////////////
///////////////////////////////////
//////////////

    // TODO: fetch artwork share URL
    const shareUrl = computed(() => {
      return mediaUrl
    })

    return {
      ...useActor(AppService),
      shareSource,
      mapPosition,
      supportWebShareAPI,
      shareUrl
    }
  },

  data() {
    return {
      Viewport,
      UIBtnSecondaryDirection,
      UIBtnSecondaryTheme,
      datauri: null,
      imgUploaded: false
    }
  },

  computed: {
    hideDownload() {
      return Browser.isFacebook || Browser.isInsta || Browser.isSnap
    }
  },

  watch: {

    shareSource: {
      handler() {
        if (!this.shareSource) return
        if (this.datauri) {
          URL.revokeObjectURL(this.datauri)
        }
        this.datauri = URL.createObjectURL(this.shareSource)
      },
      immediate: true,
    },

    state: {
      handler(newState, oldState) {
        // trigger open anim
        if (this.state.matches("share.preview")) {
          this.$nextTick(() => {

            gsap.set(['.AppHeader__Logo', '.ShareSelect__Inner .Header', '.AppHeader__Close .Icon', '.Sharers__Label', '.Sharers__Cta', '.ShareSelect__Inner .UIBtnPrimary', '.MiniMap'], { autoAlpha: 0, y: 20, })
            gsap.set(['.AppHeader__Close .Label'], { autoAlpha: 0, x: 20, })
            // console.log(oldState ,this.state.value)
            const tl = gsap.timeline()
            tl
              .to('.ShareImage', {
                autoAlpha: 1,
                duration: 0.7,
                ease: 'customOut2'
              }, 'start')
              .from('.ShareImage', {
                y: 20,
                scale: 1.03,
                duration: 0.7,
                ease: 'customOut2'
              }, 'start')
              .from('.SharePreview__CtaSubmit', {
                autoAlpha: 0,

                duration: 0.7,
                ease: 'customOut2'
              }, 'start+=0.2')
              .from('.SharePreview__CtaSubmit', {
                y: 40,
                duration: 0.7,
                ease: 'customOut2'
              }, 'start+=0.2')
          })
        }

        if (this.state.matches('share.upload')) {
          this.$nextTick(() => {
            const speed = 30 // in pixels per seconds
            const ticker = this.$refs.ticker as SVGGElement
            const { width } = ticker.getBBox()
            const duration = width / 2 / speed
            gsap.to(ticker, {
              xPercent: -50,
              duration,
              ease: 'none',
              repeat: -1
            })


            const tl = gsap.timeline()
            tl
              .to('.ShareImage', {
                scale: Viewport.isDesktop ? 1 : 0.85,
                rotation: -1,
                willChange: 'transform',
                duration: 1.533,
                ease: 'archeOut'
              }, 'start')
              .to(['.AppHeader__Logo', '.ShareSelect__Inner .Header', '.AppHeader__Close .Icon', '.Sharers__Label', '.Sharers__Cta', '.ShareSelect__Inner .UIBtnPrimary', '.MiniMap'], {
                autoAlpha: 1,
                duration: 1,
                y: 0,
                ease: 'customOut2',
                stagger: 0.06
              }, 'start+=1')
              .to(['.AppHeader__Close .Label'], {
                autoAlpha: 1,
                duration: 0.73,
                x: 0,
                ease: 'customOut2',
              }, 'start+=1.6')
          })
        }

        if (this.state._event.name === 'done.invoke.app.share.upload:invocation[0]') {
          this.imgUploaded = true
        }

        // // trigger exit anim
        if (this.state.matches("share.select")) {


        }
        //   this.$nextTick(() => {

        //     // ticker animation

        //   })
        // } else {
        //   gsap.killTweensOf(this.$refs.ticker as SVGGElement)
        // }
      },

      immediate: true,
    },
  },

  methods: {
    onDownload() {
      Gtm.sendEventPush(
        'share',
        'download',
        'Click on Download my photo',
      )
    },
    onCancel() {
      this.send('CANCEL')

      Gtm.sendEventPush(
        'photo-validation',
        'cancel',
        'Click on Take photo again',
      )
    },
    onSubmit() {
      this.send('SUBMIT')

      Gtm.sendVPV('share')
      Gtm.sendEventPush(
        'photo-validation',
        'confirm',
        'Click on validate',
      )
    },
    doShare(service: ShareService) {
      if (!SHARERS.hasOwnProperty(service)) {
        return
      }

      Gtm.sendEventPush(
        'share',
        'facebook',
        'Click on Share on Facebook',
      )

      // AppService.send('SHARE')
      // generateShareURL({ ArtworkID: })

      let sharerUrl = SHARERS[service]
      sharerUrl = sharerUrl.replace('%URL%', this.shareUrl.value)
      console.log(this.shareUrl.value);

      const width = 650
      const height = 350
      const top = window.screen.height / 2 - height / 2
      const left = window.screen.width / 2 - width / 2

      window.open(sharerUrl, 'sharer', `top=${top},left=${left},toolbar=0,status=0,width=${width},height=${height}`)
    },
    openSharer() {
      const navigator = window.navigator as any
      if (!(navigator.share && navigator.canShare)) {
        return
      }

      const url = this.shareUrl.value
      const filename = this.datauri.substr(this.datauri.lastIndexOf('/'))
      const file = new File(
        [this.shareSource],
        `${filename}.png`,
        {
          type: this.shareSource.type,
          lastModified: new Date().getTime()
        }
      )
      const filesArray = [file]

      // try to directly share the image as well, can't make it work though
      if (navigator.canShare({ url, files: filesArray })) {
        navigator.share({ url, files: filesArray })
      } else if (navigator.canShare({ url })) {
        navigator.share({ url })
      }
    }
  }
})

export default ShareVue
