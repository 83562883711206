import type Camera from "nanogl-camera";
import type Node from "nanogl-node";
import Program from "nanogl/program";
import ArrayBuffer from "nanogl/arraybuffer";
import IndexBuffer from "nanogl/indexbuffer";
import Texture from "nanogl/texture-2d";
import State, { LocalConfig } from "nanogl-state";
import { vec2, vec3, vec4 } from "gl-matrix";
import ArtworkFog from "./Fog";

const vertices = new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]);
const indices = new Uint8Array([0, 1, 2, 0, 2, 3]);

const VertexShader = /* glsl */ `
attribute vec2 aPosition;
varying vec2 vUv;
varying vec3 vWorldPosition;
uniform mat4 uViewProjectionMatrix;
uniform mat4 uWorldMatrix;

void main(void) {
  vUv = aPosition * 0.5 + 0.5;
  vUv.y = 1.0 - vUv.y;
  vec3 position = vec3(aPosition, 0.0);
  vec4 worldPosition = uWorldMatrix * vec4(position.xyz, 1.0);
  vWorldPosition = worldPosition.xyz;
  gl_Position = uViewProjectionMatrix * worldPosition;
}
`;

const FragmentShader = /* glsl */ `precision lowp float;
varying vec2 vUv;
varying vec3 vWorldPosition;
uniform vec4 uColor;
uniform sampler2D uTexture;
uniform vec2 uFog;
uniform float uRatio;
uniform vec2 uFrame;

const vec3 FRAME_COLOR = vec3(1.0);

float computeFrame(in vec2 uv, in vec2 frameValues)
{
  float screenRatio = frameValues.x;
  vec2 width = vec2(frameValues.y);

  vec2 topLeft = uv;
  topLeft = vec2(topLeft.x * screenRatio, topLeft.y);
  topLeft = step(topLeft, width);

  vec2 bottomRight = 1.0 - uv;
  bottomRight = vec2(bottomRight.x * screenRatio, bottomRight.y);
  bottomRight = step(bottomRight, width);

  float frame = max(topLeft.x, topLeft.y) + max(bottomRight.x, bottomRight.y);
  return clamp(frame, 0.0, 1.0);
}

void main(void)
{
  float fog = abs(vWorldPosition.x);
  fog = 1.0 - clamp(fog / uFog.x, 0.0, 1.0);
  fog = smoothstep(0.0, uFog.y, fog);
  if (fog == 0.0) discard;
  // vec3 color = texture2D(uTexture, vUv).rgb * uColor.rgb;
  vec3 color = mix(uColor.rgb, texture2D(uTexture, vUv).rgb, uColor.a);
  // float frame = computeFrame(vUv, uFrame);
  // color = mix(color.rgb, FRAME_COLOR, frame);
  // color *= fog;
  gl_FragColor = vec4(color.rgb, clamp(fog, 0.0, 1.0));
}
`;

const V2 = vec2.create();
const DEFAULT_COLOR = vec4.fromValues(1, 1, 1, 1);

export default class ArtworkMesh {
  private buffer: ArrayBuffer;
  private ibuffer: IndexBuffer;
  private program: Program;
  private config: LocalConfig;
  public frameWidth = 0.02;

  constructor(public gl: WebGLRenderingContext, public state: State) {
    this.buffer = new ArrayBuffer(gl, vertices);
    this.buffer.attrib("aPosition", 2, gl.FLOAT);

    this.ibuffer = new IndexBuffer(gl, gl.UNSIGNED_BYTE, indices);

    this.program = new Program(gl, VertexShader, FragmentShader);

    this.config = state.config();
    this.config
      .enableDepthTest(true)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  }

  render({
    camera,
    node,
    color,
    texture,
    fog,
  }: {
    camera: Camera;
    node: Node;
    color?: vec4;
    texture: Texture;
    fog: ArtworkFog
  }) {
    color ||= DEFAULT_COLOR;

    this.state.push(this.config);
    this.state.apply();

    this.program.use();

    this.program.uViewProjectionMatrix(camera._viewProj);
    this.program.uWorldMatrix(node._wmatrix);

    this.program.uTexture(texture);
    this.program.uColor(color);
    // this.program.uFrame(texture.width / texture.height, this.frameWidth);

    vec2.set(V2, fog.worldRadius * 2, fog.edge);
    this.program.uFog(V2);

    this.buffer.bind();
    this.buffer.attribPointer(this.program);

    this.ibuffer.bind();
    this.ibuffer.drawTriangles();

    this.state.pop();
    this.state.apply();
  }
}
