
import { defineComponent } from 'vue'
import { useI18n, LocaleMessageValue, VueMessageType } from 'vue-i18n'

interface ILocaleItem {
  locale: string
  name: LocaleMessageValue<VueMessageType>
}

export default defineComponent({
  setup() {
    const { rt, locale, availableLocales, getLocaleMessage } = useI18n({ useScope: 'global' })

    const locales: ILocaleItem[] = availableLocales.map(locale => {
      const messages = getLocaleMessage(locale)
      return { locale, name: messages.locale_name }
    })

    return {
      rt,
      locale,
      locales,
    }
  },
})
