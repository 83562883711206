import { vec3 } from "gl-matrix";
import Texture from "nanogl/texture";

const COLORS = [
  vec3.fromValues(1, 0, 0),
  vec3.fromValues(1, 1, 0),
  vec3.fromValues(0, 1, 1),
  vec3.fromValues(0, 0, 1),
  vec3.fromValues(0, 1, 0),
  vec3.fromValues(1, 0, 1),
];

export const pickColor = (index: number) => {
  index = index % COLORS.length;
  return COLORS[index];
};

const _NUMBER = {
  ctx: null as CanvasRenderingContext2D,
  init() {
    if (!_NUMBER.ctx) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      _NUMBER.ctx = ctx;

      // canvas.style.position = "fixed";
      // canvas.style.zIndex = "10000";
      // document.body.prepend(canvas);
    }
    return _NUMBER;
  },
};

export const pickImage = (options: {
  width: number;
  height: number;
  index: number;
  color?: vec3;
}) => {
  const { ctx } = _NUMBER.init();

  const dpi = 1;

  ctx.canvas.width = options.width * dpi;
  ctx.canvas.height = options.height * dpi;
  ctx.canvas.style.width = `${options.width}px`;
  ctx.canvas.style.height = `${options.height}px`;

  ctx.fillStyle = options.color
    ? `rgb(${Math.floor(options.color[0] * 255)}, ${Math.floor(
        options.color[1] * 255
      )}, ${Math.floor(options.color[2] * 255)})`
    : `#FFFFFF`;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.fillStyle = `#000000`;
  ctx.font = "bold 100px verdana, sans-serif";
  ctx.fillText(
    options.index.toString(),
    ctx.canvas.width * 0.5,
    ctx.canvas.height * 0.5
  );

  return ctx.canvas.toDataURL();
};

export const generateFrameTexture = (
  gl: WebGLRenderingContext,
  options: { width: number; height: number; border?: number; dpi?: number }
) => {
  const { ctx } = _NUMBER.init();

  const dpi = options.dpi || 1;
  const border = options.border || 10;

  ctx.canvas.width = options.width * dpi;
  ctx.canvas.height = options.height * dpi;
  ctx.canvas.style.width = `${options.width}px`;
  ctx.canvas.style.height = `${options.height}px`;

  ctx.fillStyle = `#FFFFFF`;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.fillStyle = `#000000`;
  ctx.fillRect(
    border,
    border,
    ctx.canvas.width - border * 2,
    ctx.canvas.height - border * 2
  );

  const t = new Texture(gl, gl.RGB);
  t.fromImage(ctx.canvas);
  return t;
};

/**
 * Generate a random string chain
 */
export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

/**
 * Generate a guid
 */
export function guid() {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function seconds(value: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, value * 1000);
  });
}

export function createWebWorkerFromString(source: string) {
  const blob = new Blob([source], { type: "application/javascript" });
  return new Worker(URL.createObjectURL(blob));
}

const ImageLoader = createWebWorkerFromString(/* js */ `
const BlobCache = new Map();
const BitmapCache = new Map();
self.addEventListener("message", async (e) => {
  const Item = e.data;
  try {
    if (!BlobCache.has(Item.ImageURL)) {
      const response = await fetch(Item.ImageURL);
      const Blob = await response.blob();
      BlobCache.set(Item.ImageURL, Blob);
      if (Item.Debug) console.log('Load Blob');
    }

    if (!BitmapCache.has(Item.ImageURL) && typeof self.createImageBitmap === "function") {
      const Blob = BlobCache.get(Item.ImageURL);
      const Bitmap = await self.createImageBitmap(Blob, {
        colorSpaceConversion: 'none',
      });
      BitmapCache.set(Item.ImageURL, Bitmap);
      if (Item.Debug) console.log('Load Bitmap');
    }

    const Bitmap = BitmapCache.get(Item.ImageURL);
    const Blob = BlobCache.get(Item.ImageURL);
    self.postMessage({ ...Item, Bitmap, Blob, Success: true });
  } catch (e) {
    console.error(e);
    self.postMessage({ ...Item, Success: false });
  }
});
`);
export async function loadImageBitmap(ImageURL: string, Debug = false) {
  return new Promise<ImageBitmap | HTMLImageElement>((resolve, reject) => {
    const onLoad = (e: MessageEvent) => {
      const Item = e.data as {
        ImageURL: string;
        Blob: Blob;
        Bitmap: ImageBitmap;
        Success: boolean;
      };
      if (Item.ImageURL === ImageURL) {
        ImageLoader.removeEventListener("message", onLoad);
        if (Item.Success) {
          if (Item.Bitmap) {
            resolve(Item.Bitmap);
          } else if (Item.Blob) {
            const u = URL.createObjectURL(Item.Blob);
            resolve(loadImage(u, ''));
          } else {
            resolve(loadImage(ImageURL));
          }
        } else {
          reject(new Error(`Failed to load image ${ImageURL}`));
        }
      }
    };
    ImageLoader.addEventListener("message", onLoad);
    ImageLoader.postMessage({ ImageURL, Debug });
  });
}

export async function loadImage(ImageURL: string, cors = "auto") {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = cors;
    image.decoding = "async";
    image.addEventListener(
      "load",
      (e) => {
        image.width = image.naturalWidth;
        image.height = image.naturalHeight;
        resolve(image);
      },
      { once: true }
    );
    image.addEventListener(
      "error",
      (e) => {
        reject(e);
      },
      { once: true }
    );
    image.src = ImageURL;
  });
}
