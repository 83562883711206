import fbodebug from '@/webgl/dev/FboDebug';
import DebugDraw from '@/webgl/dev/DebugDraw';
import FPSLimiter from '@/webgl/dev/FPSLimiter';

import GLState from 'nanogl-state';
import Node from 'nanogl-node';
import Camera from 'nanogl-camera';
import Rect from 'nanogl-primitives-2d/rect';
import { GLContext } from 'nanogl/types';


import Programs from '@/webgl/gl/Programs';
import Inputs from '@/webgl/lib/inputs';
import GLView from '@/webgl/GLView'

import Deferred from '@/core/Deferred';
import ResourceGroup from './assets/ResourceGroup';
import CamerasManager from './camera/CamerasManager';
import { BaseTextureResource } from '@/webgl/assets/TextureResource';
import GlobalResources from '@/webgl/GlobalResources';
import LightsManager from "@/webgl/entities/lights/LightsManager";


import Fbo from 'nanogl/fbo';

import MuseumScene from './scenes/MuseumScene';
import PaintingScene from './scenes/PaintingScene';
import ActivitiesManager from './activities/ActivitiesManager';
import WebXRView from './xr/WebXRView';
import XRModule from './entities/xr-module/XRModule';
import { CreatePane } from '@/dev/Tweak';
import { XRViewport } from 'webxr';
import AppService from '@/store/states/AppService';
import UrlParams from '@/utils/UrlParams';

export default class Scene {

  dt: number
  time: number
  aspect: number
  ilayer: HTMLElement
  loaddefer: Deferred
  glview: GLView
  gl: GLContext
  sroot: Node
  root: Node
  glstate: GLState
  quad: Rect
  inputs: Inputs
  programs: Programs
  cameras: CamerasManager

  // "polyfill" used in desktop mode
  viewport: XRViewport

  enableDebugDraw: boolean = false;
  forceFps: any

  resources: ResourceGroup;

  lights: LightsManager;
  envRotation: number = 0;



  museumScene: MuseumScene
  paintingScene: PaintingScene

  activities: ActivitiesManager


  xrview: WebXRView;
  xrmodule: XRModule


  get Resources(): ResourceGroup {
    return this.resources;
  }

  get camera() {
    return this.cameras.camera
  }


  constructor() {

    this.dt = 0
    this.time = 0
    this.aspect = 1.0
    this.ilayer = null

    this.viewport = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }

  }


  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview: GLView) {

    this.loaddefer = new Deferred()
    this.glview = glview
    this.gl = this.glview.gl

    this.xrview = new WebXRView(this.gl);
/////////////////
////////////////////////////////////////////////////////
//////////////

    BaseTextureResource.getTextureLoader(this.gl);

    this.resources = new ResourceGroup();

    // Setup global resources
    GlobalResources.setupTextures(this.resources, this);
    GlobalResources.setupPrograms(this.programs);

    this.sroot = new Node();
    this.root = new Node();
    this.sroot.add(this.root);

    this.glstate = new GLState(this.gl);
    this.programs = new Programs(this);
    this.quad = new Rect(this.gl);
    this.inputs = new Inputs(this.ilayer);
    this.cameras = new CamerasManager(this);
    this.lights = new LightsManager(this);


    this.museumScene = new MuseumScene(this);
    this.paintingScene = new PaintingScene(this);

    this.activities = new ActivitiesManager(this);

    // this.navigation = new Navigation(this);



    this.inputs.start();



    this.glview.onRender.on(this.onCanvasFrame)
    this.xrview.onRender.on(this.onXRFrame)

/////////////////
/////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////////
//////////////

  }


  async requestXR() {
    try {
      this.glview.stop();
      console.log("[Scene] requesting XR");

      await this.xrview.requestSession();
      console.log("[Scene] start xrview");
      this.xrview.start()
      await this.xrview.poseReady
      console.log("[Scene] pose ready");
      this.xrview.onSessionEnded.on(this.onXREnd);
    } catch (e) {
      this.glview.start();
      this.xrview.onSessionEnded.emit();
    }
  }

  onXREnd = () => {
    AppService.getSnapshot().context._xr.send('SESSION_END')
    this.glview.start();
  }

  loadXRModule(): Promise<void> {
    if (!this.xrmodule) {
      this.xrmodule = new XRModule(this);
    }
    return this.xrmodule.load()
  }

  handleResize() {

  }

  onCanvasFrame = (dt: number) => {
    this.viewport = {
      x: 0, y: 0,
      width: this.glview.width,
      height: this.glview.height
    }
    this.render(dt, false)
  }

  onXRFrame = (dt: number) => {
    this.viewport = {
      x: 0, y: 0,
      width: this.xrview.gllayer.framebufferWidth,
      height: this.xrview.gllayer.framebufferHeight
    }
    this.render(dt, true)
  }

  render(dt: any, xr:boolean ) {
    // ScreenSize.setSize(this.viewport.width, this.viewport.height)

    this.dt = dt;
    this.time += dt;
    this.activities.preFrame();
    this.drawScene(this.camera);

  }

  // // PRE RENDER
  // // =============
  preRender() {

    // this.sceneManager.Active.preRender();
    this.activities.preRender();

    this.cameras.preRender();

    // upadate graph
    // =================
    this.root.rotation.set([0, 0, 0, 1]);
    this.root.rotateY(this.envRotation * Math.PI);
    this.root.updateWorldMatrix();

  }



  // // RENDER
  // // =============
  drawScene(camera: Camera, fbo: Fbo = null, ui: boolean = true) {

    const w = fbo ? fbo.width : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.aspect = w / h;
    camera.lens.aspect = this.aspect;

    //

    this.preRender();
    camera.updateViewProjectionMatrix(w, h);

    // RTT
    // ==========
    this.lights.setup.prepare(this.gl);
    this.activities.rttPass();
    this.glstate.apply();
    // this.renderLightmaps();


    // RENDER
    // ========

    this.activities.render();
    this.glstate.apply();


/////////////////

////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////
////////////////////////////
/////
//////////////

  }

  async load() {

    await this.resources.load();
    await this.compileShaders()

    this.onLoaded();

  }


  loadPaintingScene() {
    this.museumScene.unload()
    return this.paintingScene.load()
  }

  loadMuseumScene() {
    this.paintingScene.unload()
    return this.museumScene.load()
  }


  compileShaders = () => {
    this.programs.compile()
    return Promise.resolve()
  }


  onLoaded = () => {

  }



}