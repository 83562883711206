import MaterialPass from "@/webgl/lib/nanogl-pbr/src/MaterialPass";
import nanoglCamera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import Node from "nanogl-node";
import Program from "nanogl/program";
import vShader from "./btn-highlight.vert";
import fShader from "./btn-highlight.frag";
import Flag from 'nanogl-pbr/Flag'
import Precision from 'nanogl-pbr/ShaderPrecision'
import Version from 'nanogl-pbr/ShaderVersion'
import Input, { ShaderType, Uniform } from "nanogl-pbr/Input";
import Texture2D from "nanogl/texture-2d";
import { DynamicTexCoord } from "nanogl-pbr/TexCoord";
import { vec2 } from "gl-matrix";


const MAT_ID = "btn-highlight";

/**
 * @extends {BaseMaterial}
 */
export default class BtnHighlightPass extends MaterialPass {

  version: Version;
  precision: Precision;
  shaderid: Flag<string>;

  baseColor: Input;
  color: Texture2D;
  offset: vec2;
  progress: Input;

  constructor() {

    super({
      uid: MAT_ID,
      vert: vShader(),
      frag: fShader(),
    });

    const inputs = this.inputs;

    this.offset = vec2.create();

    inputs.add(this.version = new Version('300 es'));
    inputs.add(this.precision = new Precision('highp'));
    inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));
    // inputs.add(this.baseColor = new Input("basecolor", 3));

  }

  prepare(prg: Program, node: Node, camera: nanoglCamera<ICameraLens>): void {

    // prg.uVP(camera._viewProj);
    prg.uWorldMatrix(node._wmatrix);
    prg.uCameraPosition(camera._wposition);
    prg.uBaseColor(this.color);
    prg.uOffset(this.offset);
    prg.uViewMatrix(camera._view);
    prg.uProjectionMatrix(camera.lens.getProjection());

  }

}