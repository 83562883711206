import * as Net   from '@/webgl/assets/Net'

import NGL_IBL    from 'nanogl-pbr/lighting/Ibl'
import Input, { Uniform }      from 'nanogl-pbr/Input'
import Enum       from 'nanogl-pbr/Enum'
import Texture2D    from 'nanogl/texture-2d'
import { GLContext } from 'nanogl/types'
import { GammaModes, GammaModeEnum } from 'nanogl-pbr/GammaModeEnum'
import IIblMaterialPass from '@/webgl/glsl/IblMaterialPass'
import Paths from '@/core/Paths'
import LightSetup from 'nanogl-pbr/lighting/LightSetup'
import IBL from 'nanogl-pbr/lighting/Ibl'
import MuseumScene from '.'
import { Probes } from './Probes'
import { vec3 } from 'gl-matrix'






const INITIAL_IDX = 0;

const ENVS = [
  // 'sibl_HS-MIT-01',
  // 'env_musee',
  'env_patio',
]



const EXPO = 1.4
const GAMMA = 2.3

class Lighting {

  museum: MuseumScene
  gl: GLContext

  envTex: Texture2D
  envHi: Texture2D
  envBg: Texture2D

  probes : Probes
  
  ibl: IBL
  lightSetup: LightSetup
  
  gammaMode: GammaModeEnum

  gamma   : Input
  exposure: Input
  expoUniform : Uniform
  gammaUniform: Uniform

  shMul: number
  shBase: Float32Array
  
  reflectExpo=1
  reflectGamma=1

  constructor( museum:MuseumScene ){

    const gl = museum.scene.gl;

    this.museum = museum;
    this.gl = gl;
    

    this.probes = new Probes()

    this.lightSetup = new LightSetup()

    this.envTex = new Texture2D( this.gl, this.gl.RGBA );
    
    this.ibl    = new IBL( this.envTex, null );

    this.envTex.setFilter( false );

    this.lightSetup.add( this.ibl );

    this.shMul = 1.0;


    this.gammaMode  = new Enum( 'gammaMode', GammaModes);
    this.gamma      = new Input( 'gamma',       1, Input.ALL );
    this.exposure   = new Input( 'exposure',    1, Input.ALL );


    this.expoUniform = this.exposure .attachUniform( 'utmExpo' )
    this.gammaUniform = this.gamma    .attachUniform( 'uTMGamma')
    this.expoUniform.set( EXPO );
    this.gammaUniform.set( 1/GAMMA );


    // if( museum.post.post.enabled ){
    //   this.gammaMode.set( 'GAMMA_NONE' )
    // } else {
      this.gammaMode.set( 'GAMMA_STD' )
    // }


  }

  /**
   * 
   * find nearest lightprobe and setup ibl's SH accordingly
   */
  setupLightingForPosition( pos:vec3){
    const p = this.probes.getNearestProbe(pos)
    this.ibl.sh = NGL_IBL.convert(p.sh, this.shMul )
  }

  setupForReflect(){
    this.expoUniform.set( this.reflectExpo );
    this.gammaUniform.set( this.reflectGamma );
  }
  
  restoreFromReflect(){
    this.expoUniform.set( EXPO );
    this.gammaUniform.set( 1/GAMMA );
  }


  setupMat( m : IIblMaterialPass ){

    m.setLightSetup( this.lightSetup );

    m.iGamma   .proxy( this.gamma )
    m.iExposure.proxy( this.exposure )
    m.gammaMode.proxy( this.gammaMode )

  }

  setupExpoGmma( m : IIblMaterialPass ){

    m.iGamma   .proxy( this.gamma )
    m.iExposure.proxy( this.exposure )
    m.gammaMode.proxy( this.gammaMode )

  }

  dispose(){

    this.envTex?.dispose();

    this.envTex = null;
    this.envHi  = null;
    this.envBg  = null;

    this.ibl    = null;
    this.museum  = null;
    
    this.shBase = null;
    this.shMul  = 1.0;

    this.gamma.dispose()
    this.exposure.dispose()
    this.gammaMode.dispose()

  }


  loadDefault(){
    return this.load( 'envs/'+ENVS[INITIAL_IDX] )
  }


  load( dir:string ){
    // return Promise.resolve()
    return Promise.all( [
      Net.loadImage( Paths.resolve( 'assets/webgl/'+dir+'/env.png' ) ).then( (img)=>this.envTex.fromImage(img) ),
      Net.loadBytes( Paths.resolve( 'assets/webgl/'+dir+'/sh.bin' ) ).then( this.convertSH )
    ])
    
  }
  
  unload(){
    this.envTex.fromData(1, 1, new Uint8Array(4))
  }

  convertSH = (buf) => {
    this.shBase = new Float32Array(buf, 0, 9*3);
    this.ibl.sh = NGL_IBL.convert(this.shBase, this.shMul )
  }




  preRender(){

  }

}

export default Lighting;