
// Probe 
var coeffs = 
[
    /* Band 0 */  2.068773649023859296,  2.001137206243669198,  2.500428273490366671 ,
    /* Band 1 */ -0.192325860506525642, -0.259884788347264584, -0.349265525609833150 ,   0.829568066055671349,  0.792848634338141567,  0.893202392462233985, -0.161777948134264909, -0.144896538692128041, -0.159324268647875661,
    /* Band 2 */  -0.087095334822636247, -0.073433078495438897, -0.080140690940557993 ,  -0.382715655929971643, -0.438038641139901541, -0.562877766506709620 ,   0.798770438596309473,  0.790917112151736212,  0.943805028497868848 ,  -0.087370181471365410, -0.076898718269135941, -0.074692615165671161 ,  -0.106593579510339057, -0.117535703826774834, -0.140799326622214560 ,
    /* Band 3 */   0.002114351368301680, -0.008821218351255163, -0.014064300884175675 ,   0.047985112768936460,  0.051238399011931897,  0.071785181420830485 ,  -0.666260351910366255, -0.790165254862051691, -0.988137262435529684 ,  -0.121876655162526382, -0.097435307036722540, -0.096382066243270018 ,  -0.169885724277281130, -0.148958178018287368, -0.164813645334712461 ,  -0.010696604428682034, -0.043659558965123905, -0.056453313543428800 ,   0.072086341754307295,  0.067201785883624632,  0.081165720760607074 ,
    /* Band 4 */   0.058132105412583984,  0.057680490935347446,  0.069954460492912401 ,   0.052825311876677078,  0.044616574608031548,  0.054939630556536778 ,  -0.081369190738692199, -0.068026658134555135, -0.075820786167593954 ,  -0.188220131959060566, -0.215318260191557226, -0.274870269774593390 ,  -0.221527214033516412, -0.201466217647022661, -0.225979697259048762 ,  -0.042601451371066683, -0.043381712997347097, -0.042660887162123476 ,  -0.142324691035418005, -0.203396458520825330, -0.276467446428133978 ,  -0.017847260498992854, -0.014965433714361686, -0.020702690275500871 ,   0.006387778937505741,  0.007523339954278531,  0.009123731900738933 
];

module.exports = coeffs;