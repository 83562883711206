import { IActivity } from "@/webgl/activities/Activity";
import GalleryActivity from "@/webgl/activities/GalleryActivity";
import GalleryXRActivity from "@/webgl/activities/GalleryXRActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class GalleryActivityServiceImpl extends ActivityService {
  createActivity(xr: boolean): IActivity {
    const scene = GLApp.getInstance().scene
    return xr ? new GalleryXRActivity(scene) : new GalleryActivity(scene)
  }
}

const GalleryActivityService = new GalleryActivityServiceImpl('gallery')

export default GalleryActivityService

/////////////
/////////////////
//////////////////////
/////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////////////
 
//////////