import Camera from "nanogl-camera";
import { vec3, vec2, mat4 } from "gl-matrix";
import Ray from '@/webgl/math/Ray'
import Plane from "@/webgl/math/Plane";
import rayPlaneIntersection from "@/webgl/math/ray-plane-intersection";
import Scene from "@/webgl/Scene";

const ray = new Ray();

const V3A = vec3.create();
const V3B = vec3.create()

const V3BMIN = vec3.create();
const V3BMAX = vec3.create();
const R1 = new Ray();
const R2 = new Ray();
const PLANE = new Plane();
const IMODEL: mat4 = mat4.create();

const IMVP = mat4.create();

export default class CameraUtils {

  touchray: Ray;

  constructor(public scene: Scene) {

    this.touchray = new Ray();

  }

  unproject(screenPoint: ArrayLike<number>, out: Ray = null) {
    if (out == null) {
      this.touchray.unproject(screenPoint, this.scene.camera);
    } else {
      out.unproject(screenPoint, this.scene.camera);
    }
  }

  worldToScreen(out: vec3, worldPoint: vec3) {

    vec3.transformMat4(out, worldPoint, this.scene.camera._viewProj);

    out[0] += 1;
    out[1] += 1;
    out[0] /= 2;
    out[1] /= 2;

    out[0] *= window.innerWidth;
    out[1] *= window.innerHeight;

  }


  worldToClip(out: vec3, worldPoint: vec3) {
    vec3.transformMat4(out, worldPoint, this.scene.camera._viewProj);
  }

  screenToWorldPoint(out: vec3, screenPoint: ArrayLike<number>, projOnly: boolean = false) {

    if (projOnly) {
      ray.unprojectProjOnly(screenPoint, this.scene.camera);
    } else {
      ray.unproject(screenPoint, this.scene.camera, true);
    }

    out.set(ray.pos);
    vec3.scale(ray.dir, ray.dir, screenPoint[2]);
    vec3.add(out, out, ray.dir);

  }


  cameraScreenToWorldPoint(out: vec3, camera: Camera, screenPoint: ArrayLike<number>, projOnly: boolean = false) {
    if (projOnly) {
      ray.unprojectProjOnly(screenPoint, camera);
    } else {
      ray.unproject(screenPoint, camera, true);
    }

    out.set(ray.pos);
    vec3.scale(ray.dir, ray.dir, screenPoint[2]);
    vec3.add(out, out, ray.dir);
  }

  updateBounds() {

    const cam = this.scene.camera;

    V3A[0] = -1;
    V3A[1] = -1;
    V3A[2] = 1;

    V3B[0] = 1;
    V3B[1] = 1;
    V3B[2] = 1;

    this.cameraScreenToWorldPoint(V3BMIN, cam, V3A);
    this.cameraScreenToWorldPoint(V3BMAX, cam, V3B);

    R1.unproject(V3A, cam, false);
    R2.unproject(V3B, cam, false);

    PLANE.normal.set([0, 0, -1]);
    vec3.transformMat4(PLANE.normal, PLANE.normal, cam._parent._wmatrix);

    rayPlaneIntersection(V3BMIN, R1, PLANE);
    rayPlaneIntersection(V3BMAX, R2, PLANE);

    vec3.scale(PLANE.normal, PLANE.normal, 0.01);
    vec3.sub(V3BMIN, V3BMIN, PLANE.normal);
    vec3.add(V3BMAX, V3BMAX, PLANE.normal);


  }



}