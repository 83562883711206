
import { defineComponent } from 'vue'

export enum VARIANT {
  PLAIN = 'plain',
  GRADIENT = 'gradient'
}

export default defineComponent({
  props: {
    variant: {
      type: String as () => VARIANT,
      default: VARIANT.PLAIN
    }
  },
  data() {
    return {
      Variant: VARIANT
    }
  }
})
