import ArrayBuffer from "nanogl/arraybuffer";
import { GLContext } from "nanogl/types";
import Program from "nanogl/program";


import VS from './rainbowring.vert'
import FS from './rainbowring.frag'
import Scene from "@/webgl/Scene";
import { LocalConfig } from "nanogl-state";
import Camera from "nanogl-camera";
import { vec2, vec3, vec4 } from "gl-matrix";
import { TextureResource } from "@/webgl/assets/TextureResource";
import MuseumAssets from "@/assets/webgl/museum";
import WebglAssets from "@/assets/webgl";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import GL from "@/webgl/gl/GL";
import { clamp01 } from "@/webgl/math";

const FLOAT_PER_VERTS = 2
const RING_SEGS = 32

const V4A = vec4.create()

const Sources: vec4[] = [
  vec4.fromValues(200, 300, -300, 1),
  vec4.fromValues(200, 300, 300, 1),
  vec4.fromValues(-300, 300, 0, 1),
]

export default class RainbowRing {

  geom:  ArrayBuffer
  prg: Program;
  cfg: LocalConfig;
  texture: TextureResource;

  constructor( private scene:Scene){

    this.createGeom()

    this.prg = new Program(this.scene.gl, VS(), FS())

    this.cfg = scene.glstate.config()
    .enableBlend()
    .blendFunc( GL.ONE, GL.ONE_MINUS_SRC_COLOR )
    // this.cfg.


    
    this.texture = new TextureResource( new TextureSrcSet(WebglAssets.getPath("lensflares/rainbow-ring.png")), this.scene ) 
    this.texture.response().then(t=>t.repeat())

  }

  load():Promise<void>{
    return this.texture.load().then()
  }

  createGeom(){
    const vertices = new Float32Array(FLOAT_PER_VERTS*(RING_SEGS+1)*2)

    const da = Math.PI*2/RING_SEGS
    let j=0
    for (let i = 0; i <= RING_SEGS; i++) {
      const angle = da*i

      vertices[j++] = angle
      vertices[j++] = 0

      vertices[j++] = angle
      vertices[j++] = 1
    
    }

    const gl = this.scene.gl

    this.geom = new ArrayBuffer(gl, vertices)
    this.geom.attrib('aData', 2, gl.FLOAT)

  }

  render(camera : Camera){

    
    
    this.prg.use()
    this.geom.attribPointer(this.prg)
    this.cfg.apply()
    
    for( let i = 0; i < Sources.length; i++ ){
      const sunpos = Sources[i]
      vec4.transformMat4( V4A, sunpos, camera._viewProj )
      V4A[0] /= V4A[3]
      V4A[1] /= V4A[3]
      const sunangle = Math.atan2( V4A[1], V4A[0] )
      const sundist = vec2.length( V4A as any as vec2 )

      const opacity = clamp01(sundist-.1*5)

      if( V4A[3]>0 &&  opacity > 0.01 && sundist < (Math.SQRT2+.1)/.5 ){
        this.prg.uParams(sunangle, sundist)
        this.prg.uTexture(this.texture.texture)
        this.prg.uOpacity(opacity)
        this.geom.drawTriangleStrip()
      }

    }


  }
}