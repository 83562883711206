
// Probe 
var coeffs = 
[
    /* Band 0 */  2.692582594730761780,  2.642258338657178296,  3.193307272012934739 ,
    /* Band 1 */ -0.872288237078892692, -0.992437006974148939, -1.256874381325548073 ,   0.268381728398756070,  0.338615656610903315,  0.464157788215964873,  0.105648311742911935,  0.189270345398800499,  0.273643468844914972,
    /* Band 2 */  -0.347382671407589050, -0.350237922551663861, -0.415883180510299266 ,  -0.302493847407004801, -0.302229481726759230, -0.349541797121154407 ,  -0.381353253905900524, -0.406085563256002202, -0.467928227669531904 ,   0.475834161744358053,  0.496526941764172891,  0.588590753323645077 ,  -0.617622072853891368, -0.685925646279827328, -0.852485474087363970 ,
    /* Band 3 */   0.456641016607800376,  0.493550688837557039,  0.608779668613165015 ,  -0.063707986576936187, -0.092228710058102847, -0.132643515435800413 ,   0.322164151265893706,  0.401337955538497604,  0.537256005450549856 ,   0.221676483475641201,  0.207518066645070193,  0.222991645004016775 ,   0.191080013285459899,  0.197022310805943346,  0.220533264344922819 ,  -0.044544220591415502, -0.026477835671988508, -0.016812493150831671 ,   0.141417598675490946,  0.145312098330186018,  0.173013792003101324 ,
    /* Band 4 */  -0.008490508873431758, -0.021054321532447667, -0.038271897190620699 ,  -0.112894271121698808, -0.097555899510287508, -0.107089779977447327 ,   0.016662621171767773,  0.004925040203481897, -0.001178971325665644 ,   0.056512432504297647,  0.050491948527671748,  0.051485903833354531 ,   0.223188100885143875,  0.244440689857141819,  0.303645342781284633 ,   0.288352459570271169,  0.294436931670901403,  0.359968693140639062 ,   0.243179100822389610,  0.270503877402044790,  0.338470133546918461 ,  -0.185360751028213699, -0.179892767067834775, -0.210122378196553244 ,   0.266171116257313878,  0.305180228394251962,  0.385153377910913419 
];

module.exports = coeffs;