

/**
 * https://www.youtube.com/watch?v=RdN06E6Xn9E
 * https://loopit.dk/banding_in_games.pdf
 * https://www.shadertoy.com/view/MslGR8
*/
import BaseEffect, { EffectDependency } from 'nanogl-post/effects/base-effect'


import code from './dithering.glsl'
import preCode from './dithering_pre.glsl'
import Texture2D from 'nanogl/texture-2d';

const NOISE_SIZE = 64
const NUMPIX = NOISE_SIZE * NOISE_SIZE
const NOISE = new Uint8Array(3 * NUMPIX);
for (let i = 0; i < NUMPIX*3; i++) {
  // NOISE[i] =(Math.random()*Math.random()) * 255;
  NOISE[i] =(Math.random()+Math.random()) * 127;
}


export default class Dithering extends BaseEffect {
  _code: string;
  _preCode: string;
  _noiseTex: Texture2D;
  amount: number;
  adjust: number = .42;


  constructor() {
    super();

    this._code = code();
    this._preCode = preCode();

    this._noiseTex = null;

    this.amount = 0.015;

  }


  init() {
    var gl = this.post.gl;
    this._noiseTex = new Texture2D(gl, gl.RGB);

    this._noiseTex.fromData(NOISE_SIZE, NOISE_SIZE, NOISE);
    this._noiseTex.repeat()
    this._noiseTex.setFilter(false, false, false)
  }


  release() {
    this._noiseTex.dispose()
    this._noiseTex = null;
  }

  preRender(){
    
  }

  genCode(precode, code) {
    precode.push(this._preCode)
    code.push(this._code)
  }


  setupProgram(prg) {
    const bw = this.post.bufferWidth, bh = this.post.bufferHeight
    prg.tDitherNoise(this._noiseTex);
    prg.uDitherTCScale( bw/NOISE_SIZE, bh/NOISE_SIZE);
  }

  resize(){}
  
}