
// Probe 
var coeffs = 
[
    /* Band 0 */  3.574968955136414550,  3.397044063096035238,  4.097867343715913435 ,
    /* Band 1 */ -0.951025103781011794, -1.206571388281808765, -1.672651625385329854 ,   0.075021098928189450, -0.018316895032197272, -0.086012249696476514,  0.203390691959469266,  0.196028933701479835,  0.242060175189575122,
    /* Band 2 */  -0.142822779738609185, -0.157053228594977307, -0.203881754056772058 ,  -0.038843034917255451, -0.180352307005282569, -0.445655365395610514 ,   0.040738410832873287, -0.154528915231143538, -0.423928238165141857 ,   0.162553912368354342,  0.159057923247762051,  0.209088910101051961 ,  -0.474268424515268627, -0.533458210272816369, -0.695049517940010464 ,
    /* Band 3 */   0.104372734754505370,  0.158243755483048060,  0.258405882424793509 ,  -0.006405065129389319, -0.006416493314388815, -0.043255525054924762 ,   0.159920220144263187, -0.012906685702543851, -0.205634487067102162 ,   0.189810141187484543,  0.132241657823688435,  0.016279726097460291 ,  -0.242942113015427785, -0.243199673896578678, -0.306734092010455262 ,  -0.105733989725613250, -0.031159987721334059, -0.043323375149476165 ,   0.079471098129142964,  0.063286020572048018,  0.040365876223811409 ,
    /* Band 4 */   0.059634259138920398,  0.054286895576855274,  0.060005501883300595 ,  -0.041416033033715709,  0.035778819898652808,  0.150921337490633467 ,   0.102846342206809993,  0.134016911503242298,  0.144848220214067097 ,  -0.217942529493100723, -0.257465728675434991, -0.276143617061840596 ,  -0.457518834527914420, -0.460454787746588379, -0.561011828380935329 ,  -0.193470602640210404, -0.215479225667768726, -0.308097444519430885 ,   0.209433776123704979,  0.215038132135008275,  0.223702914551174914 ,  -0.002583412485524457, -0.013142452530262746, -0.047387253390045139 ,   0.123228170416803950,  0.129295266233462491,  0.175552332091317403 
];

module.exports = coeffs;