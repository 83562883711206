import i18n from "@/core/i18n";
interface VPVEvent {
  event: 'vpv',
  VPV: string
}

function sendVPV(VPV: string) {
  try {
    const locale = i18n.global.locale.value
    const layer:VPVEvent = {
      event: 'vpv',
      VPV: `${locale}/${VPV}`
    } 

    window.dataLayer.push(layer)
  } catch(error) {
    console.error(error);
  }
}

interface EventPushEvent {
  event: 'eventPush',
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number | string,
}

function sendEventPush(category: string, action: string, label?: string, value?: number | string) {
  try {

    const layer:EventPushEvent = {
      event: 'eventPush',
      eventAction: action,
      eventCategory: category,
      eventLabel: label,
      eventValue: value,
    } 

    window.dataLayer.push(layer)
  } catch(error) {
    console.error(error);
  }
}

interface TakePictureEvent {
  event: 'takePicture',
  eventCategory: 'takePicture',
  eventAction: string,
  pictureSaturation?: number
  pictureBrightness?: number
  pictureContrast?: number
  pictureWarmth?: number
  pictureRatio?: number
  pictureZoom?: number
}
function sendTakePicture(action, pictureSaturation, pictureBrightness, pictureContrast, pictureWarmth, pictureRatio, pictureZoom) {
  try {
    const layer:TakePictureEvent = {
      event: 'takePicture',
      eventCategory: 'takePicture',
      eventAction: action,
      pictureSaturation,
      pictureBrightness,
      pictureContrast,
      pictureWarmth,
      pictureRatio,
      pictureZoom,
    } 

    window.dataLayer.push(layer)
  } catch(error) {
    console.error(error);
  }
}

export default {
  sendVPV,
  sendEventPush,
  sendTakePicture
}