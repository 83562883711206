import { IActivity } from "@/webgl/activities/Activity";
import PaintingActivity from "@/webgl/activities/PaintingActivity";
import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import GLApp from "@/webgl/main";
import ActivityService from "./ActivityService";


class PaintingActivityServiceImpl extends ActivityService {
  createActivity(xr: boolean): IActivity {
    const scene = GLApp.getInstance().scene
    return xr ? new PaintingXRActivity(scene) : new PaintingActivity(scene)
  }
}

let PaintingActivityService = new PaintingActivityServiceImpl('painting')

export default PaintingActivityService


/////////////
/////////////////
//////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////////////
 
//////////

