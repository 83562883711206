import Node from "nanogl-node";
import Gamepads from "@/webgl/entities/xr-module/gamepad/Gamepads";
import Navigation from "@/webgl/entities/xr-module/navigation/Navigation";
import Ray from "@/webgl/math/Ray";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";
import { mat3, mat4, quat, vec2, vec3 } from "gl-matrix";
import { XRHand, XRHandedness } from "webxr";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { PovDatas } from "@/store/states/AppStateMachine";
import { mat4Zaxis } from "@/webgl/math/mat4-axis";
import mat4LookAt from "@/webgl/math/mat4-lookat";

const V3 = vec3.create();
const VNZ = vec3.fromValues(0.0, 0.0, -1.0);
const VRNZ = vec3.fromValues(0.0, 0.0, -1.0);
const R = quat.create();
quat.rotateX(R, R, -Math.PI / 5);
vec3.transformQuat(VRNZ, VRNZ, R);

const M3 = mat3.create();
const QUATA = quat.create();
const QUATB = quat.create();

const _elDIR = vec3.create();
const _elV0 = vec3.create();

export default class XRNavigationController {

  xrmodule: XRModule;
  aiming: boolean = false;
  ctrlhand: XRHandedness = "right";
  rotation: quat;
  _targetRotation: quat;
  _tgtAngle: number;
  _bound: boolean = false;
  _enabledControllers: Record<XRHandedness, boolean> = {
    "left": true,
    "right": true,
    "none": false
  };

  constructor(
    public readonly navigation: Navigation
  ) {

    this.xrmodule = navigation.xrmodule;
    this.rotation = quat.create();
    this._targetRotation = quat.create();
    this._tgtAngle = 0;

  }

  bind() {
    if (this._bound)
      return;
    this._bound = true;
    this.xrmodule.xrview.inputs.onBtnDown.on(this.onBtnDown);
  }

  unbind() {
    if (!this._bound)
      return;
    this._bound = false;
    this.xrmodule.xrview.inputs.onBtnDown.off(this.onBtnDown);
  }

  disableController(hand: XRHandedness) {
    this._enabledControllers[hand] = false;
    if (this.ctrlhand == hand && this.aiming) {
      this.navigation.active = false;
      this.aiming = true;
    }
  }

  enableController(hand: XRHandedness) {
    this._enabledControllers[hand] = true;
  }

  onBtnDown = (evt: ButtonEvent) => {

    if (this.aiming)
      return;

    if (this._enabledControllers.left) {
      switch (evt.name) {
        case "Left/Down":
        case "Left/Up":
        case "Left/Left":
        case "Left/Left":
          this.navigation.active = true;
          this.aiming = true;
          this.ctrlhand = "left";
          break;
        default:
          break;
      }
    }

    if (this._enabledControllers.right) {
      switch (evt.name) {
        case "Right/Down":
        case "Right/Up":
        case "Right/Right":
        case "Right/Left":
          this.navigation.active = true;
          this.aiming = true;
          this.ctrlhand = "right";
          break;
        default:
          break;
      }
    }

  }


  onStickRelease() {
    if (this.navigation.hasTarget && this.aiming) {
      this.navigation.moveToTarget();
    }
    this.aiming = false;
    this.navigation.active = false;
  }

  prepareRay(ray: Ray) {

    mat3.fromMat4(M3, this.xrmodule.gamepads.list.get(this.ctrlhand).skin._wmatrix);
    quat.fromMat3(QUATA, M3);

    vec3.transformQuat(ray.dir, VRNZ, QUATA);
    vec3.transformQuat(this.navigation.forward, VNZ, QUATA);
    vec3.normalize(ray.dir, ray.dir);
    vec3.normalize(this.navigation.forward, this.navigation.forward);

    ray.pos.set(this.xrmodule.gamepads.list.get(this.ctrlhand).skin._wposition);
    vec3.scaleAndAdd(ray.pos, ray.pos, ray.dir, 0.05);

  }

  update(dt: number) {

    if (!this.navigation.hasTarget) {
      vec3.scaleAndAdd(
        this.navigation.target,
        this.navigation.ray.pos,
        this.navigation.ray.dir,
        1.0
      );
    }

    // this.xrmodule.xrroot.y += this.xrmodule.gamepads.inputs.getAxes("left")[1];

    if (this.aiming) {

      const len = vec2.squaredLength(this.xrmodule.xrview.inputs.getAxes(this.ctrlhand));

      if (len < 0.1) {
        this.onStickRelease();
        return;
      }

      V3.set(this.navigation.ray.dir);
      V3[1] = 0;
      vec3.normalize(V3, V3);
      quat.identity(QUATA)
      quat.rotateY(
        QUATA,
        QUATA,
        Math.atan2(-V3[0], -V3[2])
      )

      const angle = Math.atan2(
        -this.xrmodule.xrview.inputs.getAxes(this.ctrlhand)[0],
        -this.xrmodule.xrview.inputs.getAxes(this.ctrlhand)[1]
      );

      quat.identity(QUATB);
      quat.rotateY(
        QUATB,
        QUATB,
        angle
      )

      quat.multiply(this._targetRotation, QUATA, QUATB);
      this.navigation.renderer.targetNode.rotation.set(this._targetRotation);

    }

  }

  setPovDatas(povDatas: PovDatas){

    const V3A = vec3.create();
    
    const ray = new Ray();
    ray.pos.set(povDatas.position);
    ray.dir[1] = -1.0;
    this.navigation.navmesh.raycast(ray, V3A);
    V3A[0] = povDatas.position[0];
    V3A[2] = povDatas.position[2];

    const QUAT = quat.create();
    QUAT.set(povDatas.rotation);
    const M4 = mat4.create();
    mat4.fromQuat(M4, QUAT);

    const m = mat4.create();
    mat4.fromRotationTranslation(m, QUAT, V3A);

    mat4Zaxis( _elDIR, m )
    _elDIR[1] = 0
    _elDIR[0] *=-1
    _elDIR[2] *=-1
    mat4LookAt( M4, _elV0, _elDIR, vec3.fromValues(0.0, 1.0, 0.0) )
    M4[12] = m[12]
    M4[13] = m[13]
    M4[14] = m[14]

    const M3 = mat3.create();
    mat3.fromMat4(M3, M4);
    quat.fromMat3(QUAT, M3);

    this.setTargetRotation(QUAT);
    this.moveTo(V3A);
    
  }

  setTargetRotation(rotation: quat){
    this._targetRotation.set(rotation);
  }

  reset() {
    quat.identity(this._targetRotation);
    quat.identity(this.rotation);
    this.moveTo(vec3.create());
  }

  moveTo(target: vec3) {

    quat.invert(QUATA, this.xrmodule.xrcamera.head.rotation);
    QUATA[0] = 0;
    QUATA[2] = 0;
    this.rotation.set(this._targetRotation);
    quat.multiply(this.rotation, this.rotation, QUATA);

    const tgt = vec3.create();
    tgt.set(target);
    const pose = this.xrmodule.xrview.pose.transform.position;
    const M4 = mat4.create();

    tgt[0] = tgt[0] - pose.x;
    tgt[2] = tgt[2] - pose.z;

    mat4.fromRotationTranslation(M4, this.rotation, tgt);
    this.navigation.xrmodule.xrroot.setMatrix(M4);
    this.navigation.xrmodule.xrroot.updateWorldMatrix();

  }

}