import { ArtworkId } from "@/store/states/Artworks";
import { Passes } from "@/webgl/glsl/Passes";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import gsap from "gsap";
import Node from "nanogl-node";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import { Uniform } from "nanogl-pbr/Input";
import UnlitPass from "nanogl-pbr/UnlitPass";


export default class ArtworkEntity {

  alphaUniform: Uniform;
  
  
  private _hoverActive = false
  private _hoverAlpha = 0; 
  public get hoverAlpha() {
    return this._hoverAlpha;
  }
  public set hoverAlpha(value) {
    this._hoverAlpha = value;
    this.alphaUniform.set(value)
  }


  constructor( 
    readonly id:ArtworkId, 
    readonly frame:Node, 
    readonly hoverMaterial: BaseMaterial, 
    readonly canvasMaterial: BaseMaterial){

      
    const hoverPass = hoverMaterial.getPass(Passes.DEFAULT).pass as UnlitPass
    this.alphaUniform = hoverPass.alphaFactor.attachUniform('uafactor')
    this.alphaUniform.set(this._hoverAlpha)
    
  }

  showHover( flag:boolean ){
    if( this._hoverActive === flag ) return;
    this._hoverActive = flag;
    gsap.to(this, { hoverAlpha: flag ? 1 : 0, duration: 0.2, ease: "linear" })
  }
}