import { PaintingStore } from "@/store/modules/PaintingStore";
import Viewport from "@/store/modules/Viewport";
import PaintingActivity from "@/webgl/activities/PaintingActivity";
import { DEG2RAD } from "@/webgl/math";
import Ray from "@/webgl/math/Ray";
import { vec2, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Fbo from "nanogl/fbo";
import { watch } from "vue";

const V3 = vec3.create();

export default class PaintingSnapshot {

  _isSnapPending: boolean = false;
  camera: Camera<PerspectiveLens>;
  private _outputCVS: HTMLCanvasElement;
  private _outputCTX: CanvasRenderingContext2D;

  constructor(
    public activity: PaintingActivity
  ) {

    this.camera = new Camera(new PerspectiveLens());
    this.camera.lens.setVerticalFov(55.0 * DEG2RAD);
    this.camera.lens.near = 0.1;
    this.camera.lens.far = 800.0;

    this._outputCVS = document.createElement("canvas");
    this._outputCTX = this._outputCVS.getContext("2d");

  }

  generateBlob(src: HTMLCanvasElement, resolve){

    const cvs = this._outputCVS;
    const ctx = this._outputCTX;
    cvs.width = src.width;
    cvs.height = src.height;
    ctx.drawImage( src, 0, 0, src.width, src.height );
    ctx.scale( 1, -1 );
    cvs.toBlob(resolve);

  }

  updateCamera(baseCamera: Camera<PerspectiveLens>, w: number, h: number) {

    this.camera.setMatrix(baseCamera._wmatrix);
    this.camera.updateWorldMatrix();

    this.camera.lens.aspect = w / h;

    const frame = PaintingStore.frame;
    const ray = new Ray();
    const coords = vec3.fromValues(
      (((frame.x + frame.width * 0.5) / Viewport.windowWidth) - 0.5) * 2.0, 
      -(((frame.y + frame.height * 0.5) / Viewport.windowHeight) - 0.5) * 2.0,
      0.0
    );

    ray.unproject(coords, baseCamera);
    V3.set(ray.pos);
    vec3.scale(ray.dir, ray.dir, 1);
    vec3.add(V3, V3, ray.dir);
    this.camera.lookAt(V3);
    this.camera.updateWorldMatrix();

    const fh = PaintingStore.frame.height;
    const gh = Viewport.windowHeight;
    const fov = baseCamera.lens.fov;
    const tf = fh / gh * fov;

    this.camera.lens.setVerticalFov(tf * 1.025);

    this.camera.updateViewProjectionMatrix(w, h);


  }

}