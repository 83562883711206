import Node from "nanogl-node";
import Gamepads from "@/webgl/entities/xr-module/gamepad/Gamepads";
import FPSMeter from "@/webgl/xr/FPSMeter/FPSMeter";
import Camera from "nanogl-camera";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import ButtonTooltipsRenderer from "@/webgl/entities/xr-module/gamepad/ButtonTooltipsRenderer";
import XRCamera from '@/webgl/xr/WebXRCamera';
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import WebXRView from "@/webgl/xr/WebXRView";
import Scene from "@/webgl/Scene";
import { XRTooltipManager } from "@/webgl/entities/xr-module/XRTooltipManager";
import WebXRInputs from "@/webgl/xr/WebXRInputs";
import RaycastLayer from "@/webgl/entities/xr-module/raycast-layer/RaycastLayer";
import RaycastLineRenderer from "./lineRenderer";

export default class XRModule {

  resources: ResourceGroup;
  xrview: WebXRView;
  xrroot: Node;
  xrcamera: XRCamera;

  ui: UIKit;
  gamepads: Gamepads;
  tooltips: XRTooltipManager;
  raycaster: RaycastLayer;
  raycastLineRenderer: RaycastLineRenderer;
  
  // DEV
  // fpsmeter: FPSMeter;

  private tooltipRenderer: ButtonTooltipsRenderer;
  private _loadPromise: Promise<void>;
  private _ready: boolean = false;

  get Ready() {
    return this._ready
  }

  get Inputs(): WebXRInputs {
    return this.xrview.inputs;
  }

  constructor(
    public readonly scene: Scene
  ) {

    this.resources = new ResourceGroup();

    this.gamepads = new Gamepads(this);
    this.ui = new UIKit(scene);
    this.ui.textLib.buildXR();

    this.tooltipRenderer = new ButtonTooltipsRenderer(this.gamepads);
    this.tooltips = new XRTooltipManager(this.tooltipRenderer);
    this.raycaster = new RaycastLayer(this);
    // this.fpsmeter = new FPSMeter(scene, this.ui);
    
    this.xrroot = new Node();
    scene.root.add(this.xrroot);
    scene.root.add(this.ui.root);
    
  }
  
  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }
  
  private async _doLoad() {
    await this.ui.load();
    await this.resources.load();
    // await this.fpsmeter.load();
    await this.scene.xrview.poseReady
    
    this.init();
  }
  
  
  private init() {
    
    this._ready = true;
    this.xrview = this.scene.xrview;
    this.xrcamera = new XRCamera(this.xrview);
    this.raycastLineRenderer = new RaycastLineRenderer(this);

    this.gamepads.init(this.xrview.inputs);
    this.tooltipRenderer.init()

    this.xrroot.add(this.xrcamera.node);
    this.xrroot.add(this.gamepads.node);

    // this.gamepads.list.get("right").skin.add(this.fpsmeter.text.node);
    // this.fpsmeter.text.node.rotateX(-Math.PI);
    // this.fpsmeter.text.node.rotateY(-Math.PI);

  }

  preRender() {

    this.xrcamera.update();
    this.ui.preRender();
    this.gamepads.preRender();
    this.raycaster.preRender();
    // this.fpsmeter.preRender();
    this.tooltipRenderer.preRender(this.scene.dt);

  }

  render(camera: Camera) {

    this.gamepads.render(camera);
    this.tooltipRenderer.render(camera);
    this.raycaster.renderTarget(camera);
    // this.fpsmeter.text.render(camera);
    this.ui.render(camera);

  }

}