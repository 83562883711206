import Fbo from "nanogl/fbo";
import Program from "nanogl/program";
import { GLContext } from "nanogl/types";

import VS from '@/webgl/glsl/divein/shader.vert'
import FS from '@/webgl/glsl/divein/shader.frag'
import Texture2D from "nanogl/texture-2d";
import Rect from "nanogl-primitives-2d/rect";
import gsap from "gsap";
import { CreatePane } from "@/dev/Tweak";
import { HexToVec, TmpHexToVec } from "@/webgl/lib/color";


const FBO_SIZE = 1024;


function now(){
  return Date.now()/1000
}

export class DiveinEffect {

  fbo: Fbo;
  prg: Program;
  quad: Rect;

  strength = .5
  refTime = 0
  
  get active():boolean{
    return this.strength > 0
  }

  diveIn(duration=3){
    this.refTime = now()
    gsap.to( this, {strength:1, duration})
  }

  reset(){
    gsap.killTweensOf(this)
    this.strength = 0
  }

  constructor(private gl:GLContext){

    this.fbo = new Fbo(gl)
    this.fbo.attachColor()
    this.fbo.resize( FBO_SIZE, FBO_SIZE )
    this.fbo.getColorTexture().mirror()

    this.prg = new Program( gl, VS(), FS() )

    this.quad = new Rect(gl)
    
/////////////////
//////////////////////////////////
/////////////////////////////////////////////////
//////////////

  }

  render(w:number, h:number){

    const gl = this.gl

    gl.bindFramebuffer(gl.FRAMEBUFFER, null)
    gl.viewport(0, 0, w, h);

    this.prg.use()
    this.prg.uTime( ((now()-this.refTime) % 5.0)*.3 )
    this.prg.uStrength( Math.pow(this.strength, 2.0) )
    this.prg.ufadeColor( TmpHexToVec(0xF6F3E4) )
    this.prg.tTex( this.fbo.getColorTexture() )

    this.quad.attribPointer(this.prg)
    this.quad.render()

  }

}