import { POST_SETTINGS } from "@/webgl/entities/post-process/PostSettingsController";
import XRSettingsWheel from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheel";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import { vec2 } from "gl-matrix";
import gsap from "gsap";

const V2 = vec2.create();

export default class XRSettingsWheelButton {

  isActive: boolean = false;
  isHover: boolean = false;
  isChanged: boolean = false;

  active: GltfNode;
  idle: GltfNode;
  changed: GltfNode;

  angle: number;
  _depth: number = 0;
  _tween: gsap.core.Tween;

  get Renderable(): MeshRenderer {
    return (this.isActive || this.isHover) ? this.active.renderable : this.idle.renderable;
  }

  get Renderables(): MeshRenderer[] {
    if(this.isChanged){
      return [(this.isActive || this.isHover) ? this.active.renderable : this.idle.renderable,  this.changed.renderable];
    } else {
      return [(this.isActive || this.isHover) ? this.active.renderable : this.idle.renderable];
    }
  }

  constructor(
    public wheel: XRSettingsWheel,
    public id: POST_SETTINGS,
    public node: GltfNode
  ) {

    this.active = node.findChild("active");
    this.idle = node.findChild("idle");
    this.changed = node.findChild("settings-active");

    vec2.set(V2, node.x, node.y);
    vec2.normalize(V2, V2);
    this.angle = Math.atan2(V2[0], V2[1]);

  }

  setActive() {
    this.isActive = true;
    gsap.killTweensOf(this, "_depth");
    this._tween = gsap.to(this, {
      _depth: 0.5, duration: 0.3, ease: "power2.out", onUpdate: () => {
        this.node.z = this._depth;
      }
    });
  }

  setHover() {
    this.isHover = true;
    gsap.killTweensOf(this, "_depth");
    this._tween = gsap.to(this, {
      _depth: 0.5, duration: 0.3, ease: "power2.out", onUpdate: () => {
        this.node.z = this._depth;
      }
    });
  }

  setIdle() {
    gsap.killTweensOf(this, "_depth");
    this._tween = gsap.to(this, {
      _depth: 0, duration: 0.3, ease: "power2.out", onUpdate: () => {
        this.node.z = this._depth;
      }
    });
    this.isActive = false;
    this.isHover = false;
  }

  setChanged(flag){
    this.isChanged = flag;
  }

}