import Paths from "@/core/Paths";
import GLTFResource from "@/webgl/assets/GLTFResource";
import { JsonResource } from "@/webgl/assets/Net";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Picking from "@/webgl/lib/Picking";
import Scene from "@/webgl/Scene";
import Node from 'nanogl-node'
import Camera from "nanogl-camera";
import GraniteProbe from "@/webgl/entities/granite-probe/GraniteProbe";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import MaterialOverrideExtension from "@/webgl/lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import GraniteStencilMaterial from "@/webgl/entities/granite-probe/GraniteStencilMaterial";
import { LocalConfig } from "nanogl-state";
import GraniteReflect from "@/webgl/entities/granite-probe/GraniteReflect";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import PaintingLighting from "@/webgl/scenes/PaintingScene/PaintingLighting";
import WindTree from "@/webgl/scenes/PaintingScene/WindTree";
import UnlitPass from "nanogl-pbr/UnlitPass";
import FlowMapEffect from "@/webgl/scenes/PaintingScene/FlowMapEffect";

export default class PaintingScene {

  loaded: boolean = false;
  gltf: Gltf;
  lighting: PaintingLighting;
  characters: Gltf;
  probe: GraniteProbe;
  reflect: GraniteReflect;
  sky: GltfNode;
  rotation: number = 0;
  windTree: WindTree;
  fountainFlow: FlowMapEffect;

  navmesh: Picking;
  stencilCfg: LocalConfig;

  readonly root = new Node()


  constructor(public scene: Scene) {

    const gl = scene.gl;
    this.stencilCfg = scene.glstate.config();
    this.stencilCfg
      .enableStencil(true)
      .stencilFunc(gl.EQUAL, 1 << 1, 0xFF)
      .stencilOp(gl.KEEP, gl.KEEP, gl.KEEP);
  }


  async load(): Promise<void> {
    this.loaded = true;

    this.scene.root.add(this.root);

    this.reflect = new GraniteReflect(this.scene);
    this.windTree = new WindTree();

    this.probe = new GraniteProbe(this.scene, "assets/webgl/granite_probe");
    this.probe.setReflectTex(this.reflect.fbo.getColorTexture());

    this.lighting = new PaintingLighting(this);
    this.fountainFlow = new FlowMapEffect(
      this.scene,
      Paths.resolve("assets/webgl/Decor_fountain_flowmap.png")
    );

    await Promise.all([
      this.probe.load(),
      this.lighting.load(),
      this.fountainFlow.load(),
      this._loadPaintingGltf(),
      this._loadCharactersGltf(),
      this._loadNavMesh()
    ]);

    const noReflectMat = [
      "batiment_02",
      "batiment_loin_3_4",
      "Statue1"
    ]
    for (const material of [...this.characters.materials, ...this.gltf.materials]) {

      if ((material as UnlitMaterial).materialPass && noReflectMat.indexOf(material.name) === -1) {
        (material as UnlitMaterial).materialPass.mask = Masks.OPAQUE | Masks.REFLECTED;
      }

      if (material.name == "wind_tree" || material.name == "wind_tree_2") {
        this.windTree.install((material as UnlitMaterial).materialPass as UnlitPass);
      }

      if (material.name == "Decor_fountain_flow") {
        const pass = (material as UnlitMaterial).materialPass;
        pass.mask = Masks.BLENDED;
        pass.glconfig
          .depthMask(false)
          .enableBlend(true)
          .blendFunc(this.scene.gl.SRC_ALPHA, this.scene.gl.ONE_MINUS_SRC_ALPHA);
        this.fountainFlow.install(pass);
      }

    }

    this.sky = this.gltf.getElementByName(GltfTypes.NODE, "SKY") as GltfNode;

    const bowlMaterial = this.gltf.getElementByName(GltfTypes.MATERIAL, "granite_bowl") as UnlitMaterial;
    bowlMaterial.materialPass.mask = Masks.OPAQUE;

    this.probe.setupMaterial(
      bowlMaterial
    )

  }



  unload(): void {
    if (!this.loaded)
    return;
    
    this.root.remove(this.gltf.root);
    this.root.remove(this.characters.root);
    this.scene.root.remove(this.root);
    this.gltf = null;
    this.characters = null;
    this.navmesh = null;

    this.reflect = null;
    this.windTree = null;

    this.probe.unload();
    this.probe = null;


    this.fountainFlow.unload();
    this.fountainFlow = null;

    this.lighting.unload();
    this.lighting = null;

    this.sky = null;
    console.log(this);

  }

  reflectPass(camera: Camera) {
    this.reflect.processCamera(camera);

    this.scene.glstate.push(this.reflect.globalCfg);
    this.scene.glstate.apply();

    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera, Masks.OPAQUE, Passes.STENCIL);
    }

    this.scene.glstate.push(this.stencilCfg);
    this.scene.glstate.apply();

    for (const renderable of this.characters.renderables) {
      renderable.render(this.scene, camera, Masks.REFLECTED, Passes.DEFAULT);
    }
    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera, Masks.REFLECTED, Passes.DEFAULT);
    }

    this.scene.glstate.pop();
    this.scene.glstate.pop();

    this.reflect.restoreCamera(camera);

  }

  preRender() {

    const r = Math.sin(this.scene.time * 0.02) * Math.PI / 8;
    const a = r - this.rotation;
    this.sky.rotateY(a);
    this.rotation = r;
    this.windTree.update(this.scene.dt);
    this.fountainFlow.update(this.scene.dt);

  }

  render(camera: Camera) {

    this.probe.prepare(camera);

    for (const renderable of this.characters.renderables) {
      renderable.render(this.scene, camera, Masks.OPAQUE, Passes.DEFAULT)
    }
    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera, Masks.OPAQUE, Passes.DEFAULT)
    }

    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera, Masks.BLENDED, Passes.DEFAULT)
    }


  }


  private async _loadPaintingGltf() {
    const overrides = new MaterialOverrideExtension();
    overrides.overrides = {
      "granite_bowl_stencil": new GraniteStencilMaterial(this.scene.gl)
    };

    this.gltf = await new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/painting/painting.gltf"),
      this.scene,
      {
        extensions: [overrides]
      }
    ).load();

    this.root.add(this.gltf.root);
  }


  private async _loadCharactersGltf() {

    this.characters = await new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/characters/characters.gltf"),
      this.scene
    ).load();

    for (const mat of this.characters.materials) {
      (mat as UnlitMaterial).materialPass.glconfig.enableCullface(false);
    }

    this.root.add(this.characters.root);
  }


  private async _loadNavMesh() {
    const navmeshData = await new JsonResource(Paths.resolve("assets/webgl/navmesh/painting-navmesh.json")).load();
    this.navmesh = new Picking(
      this.root,
      new Uint16Array(navmeshData.indices),
      new Float32Array(navmeshData.vertices),
      true
    )
  }

}