// import gui from '@/webgl/dev/Gui'
import { DEG2RAD } from "@/webgl/math";
import Scene from "@/webgl/Scene";
import { mat4, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import OrthographicLens from 'nanogl-camera/ortho-lens';
import PerspectiveLens from "nanogl-camera/perspective-lens";
import CameraControler from './CameraController';
import MaxController from './MaxController';
import OrbitController from '@/webgl/camera/OrbitController';
import CameraUtils from '@/webgl/camera/CameraUtils';
// import LastNightContoller from '@/webgl/camera/LastNightController';
import { CreatePane, Presetpane } from "@/dev/Tweak";


export default class CamerasManager {

  // mainCamera: Camera<PerspectiveLens>
  mainCamera: Camera<OrthographicLens | PerspectiveLens>
  devCamera: Camera<OrthographicLens | PerspectiveLens>

  mainCamCtrl: CameraControler
  devCamCtrl: CameraControler

  maxcam: MaxController


  useDebugCam: boolean

  debugCam: () => void
  mainCam: () => void
  logDebugCam: () => void

  focalDist = 15
  orbitcam: OrbitController;
  utils: CameraUtils;
  // lastnight: LastNightContoller;


  constructor(readonly scene: Scene) {

    this.utils = new CameraUtils(scene);

    // CAMERA
    // ======
    this.mainCamera = this.makeCamera();
    this.devCamera = this.makeCamera();

    // CONTROLERS
    // ======
    this.devCamCtrl = new CameraControler(scene, this.devCamera)
    this.mainCamCtrl = new CameraControler(scene, this.mainCamera)

    this.maxcam = new MaxController(scene.ilayer)
    this.orbitcam = new OrbitController(scene.ilayer, { center: vec3.fromValues(0.0, 1.0, 0.0) });

    // GRAPH
    // ======
    scene.root.add(this.mainCamera)
    scene.root.add(this.devCamera)

    this.mainCamCtrl.setControler(this.orbitcam);
    this.devCamCtrl.setControler(this.maxcam);

    // this.useDebugCam = true;
/////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////
///////////////
/////////////
//////
////////////////////////////////////////////////
///////////////
//////////////
//////

////////////////////////////////////////
//////////////

  }

  /**
   * the rendered camera
   */
  get camera(): Camera<PerspectiveLens | OrthographicLens> {
    return this.useDebugCam ? this.devCamera : this.mainCamera;
  }

  get controler(): CameraControler {
    return this.useDebugCam ? this.devCamCtrl : this.mainCamCtrl;
  }


  preRender() {
    this.controler.preRender();
  }

  makeOrtho() {

    const camera = new Camera(new OrthographicLens());
    camera.lens.near = 0.1
    camera.lens.far = 10
    camera.x = 0;
    camera.z = -5;
    camera.y = 0;
    camera.lookAt(vec3.fromValues(0, 0, 0));
    return camera;
  }

  makeCamera() {

    const camera = new Camera(new PerspectiveLens());
    // camera.lens.setAutoFov(45.0 * DEG2RAD)
    camera.lens.setVerticalFov(55.0 * DEG2RAD)
    camera.lens.near = 0.05
    camera.lens.far = 50

    camera.x = 0;
    camera.z = -5;
    camera.y = 0;
    camera.lookAt(vec3.fromValues(0, 0, 0));

    // camera.setMatrix(<mat4>new Float32Array([
    //   1, 0, 0, 0, 0, 1, 4.371138828673793e-8, 0, 0, -4.371138828673793e-8, 1, 0, 0, -8.742277657347586e-8, 2, 1
    // ]
    // ));

    return camera
  }


}