import { AppState } from "@/store/states/AppStateMachine";
import UrlParams from "@/utils/UrlParams";
import GalleryBackground from "@/webgl/entities/gallery-background/GalleryBackground";
import XRGalleryInputHelper from "@/webgl/activities/GalleryXRActivity/XRGalleryInputHelper";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Camera from "nanogl-camera";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import AppService from "@/store/states/AppService";
import Browser from "@/utils/Browser";
import {
  InteractionEvent,
  InteractionEventDetail,
} from "../GalleryActivity/types";
import GalleryData from "../GalleryActivity/data";
import { mat4, quat, vec3 } from "gl-matrix";
import ArtworkStrip from "@/webgl/activities/GalleryActivity/Artwork/Strip";
import XRGalleryUI from "@/webgl/activities/GalleryXRActivity/XRGalleryUI";
import XRGalleryInteractions from "@/webgl/activities/GalleryXRActivity/XRGalleryInteractions";
import XRGalleryFloor from "@/webgl/entities/xr-gallery-floor/XRGalleryFloor";
import Gtm from "@/utils/Gtm";

const post_backbuffer = UrlParams.getBool("debug-xr");

export default class GalleryXRActivity implements IActivity {
  readonly priority: number = 0;

  inputHelper: XRGalleryInputHelper;
  background: GalleryBackground;
  strip: ArtworkStrip;
  title: XRGalleryUI;
  data: GalleryData;
  interactions: XRGalleryInteractions;
  floor: XRGalleryFloor;
  useSample = false;

  get xrmodule(): XRModule {
    return this.scene.xrmodule;
  }

  constructor(public scene: Scene) {
    this.onInteraction = this.onInteraction.bind(this);

    this.background = new GalleryBackground(this.scene);

    this.data = new GalleryData();

  }

  async load(): Promise<void> {
    await Promise.all([this.scene.loadXRModule()]);

    this.strip = new ArtworkStrip(this.scene);
    this.strip.mode = "xr";
    this.strip.root.y = 1.5;
    this.strip.root.z = -0.1;
    this.strip.root.setScale(0.005);
    this.strip.curve.depth = 1100;
    this.strip.curve.width = 2800;
    this.strip.gap = 10;
    this.strip.fog.radius = 600;
    this.strip.fog.edge = 0.2;
    this.strip.autoScroll.enabled = true;
    this.strip.introTween.width = 1100;

    this.strip.introTween.user = this.data.loadUserMedia();


    this.inputHelper = new XRGalleryInputHelper(this);

    const { interactions } = this.strip;
    interactions.drag.speedMultiplier = 10;
    interactions.events.addEventListener("i:resize", this.onInteraction);
    interactions.events.addEventListener("i:click", this.onInteraction);
    interactions.events.addEventListener("i:over", this.onInteraction);
    interactions.events.addEventListener("i:out", this.onInteraction);
    interactions.events.addEventListener("i:dragstart", this.onInteraction);
    interactions.events.addEventListener("i:dragend", this.onInteraction);
    interactions.events.addEventListener("i:load", this.onInteraction);
    this.data.listeners.add(this.strip.append);
    this.data.datas.forEach(this.strip.append);

    this.title = new XRGalleryUI(this);

    this.interactions = new XRGalleryInteractions(this, this.strip);
    this.floor = new XRGalleryFloor(this.scene);

    await Promise.all([
      this.background.load(),
      this.strip.load(),
      this.floor.load(),
      this.data.load(this.useSample),
    ]);

    await this.data.loading.Promise;
  }

  unload(): void {
    const { interactions } = this.strip;
    interactions.events.removeEventListener("i:resize", this.onInteraction);
    interactions.events.removeEventListener("i:click", this.onInteraction);
    interactions.events.removeEventListener("i:over", this.onInteraction);
    interactions.events.removeEventListener("i:out", this.onInteraction);
    interactions.events.removeEventListener("i:dragstart", this.onInteraction);
    interactions.events.removeEventListener("i:dragend", this.onInteraction);
    interactions.events.removeEventListener("i:load", this.onInteraction);
    this.data.listeners.add(this.strip.append);
    this.strip = null;
  }

  resetPOV() {
    const QUATA = quat.create();
    quat.invert(QUATA, this.xrmodule.xrcamera.head.rotation);
    QUATA[0] = 0;
    QUATA[2] = 0;

    const tgt = vec3.create();
    const pose = this.xrmodule.xrview.pose.transform.position;
    const M4 = mat4.create();

    tgt[0] = -pose.x;
    tgt[2] = -pose.z;

    mat4.fromRotationTranslation(M4, QUATA, tgt);
    this.xrmodule.xrroot.setMatrix(M4);
    this.xrmodule.xrroot.updateWorldMatrix();
  }

  start(): void {
    this.resetPOV();
    this.xrmodule.xrroot.updateWorldMatrix();
    this.xrmodule.tooltips.sync();
    this.scene.root.add(this.strip.root);
    this.scene.root.add(this.floor.node);
    this.strip.enable();
    this.strip.play();
    this.data.enable(this.strip);
    this.interactions.enable();
    this.background.show();
    this.floor.show();
    this.inputHelper.start();
    this.title.show();
  }

  stop(): void {
    this.scene.root.remove(this.strip.root);
    this.scene.root.remove(this.floor.node);
    this.strip.disable();
    this.data.disable();
    this.interactions.disable();
    this.inputHelper.stop();
    this.title.hide();
  }

  onStateUpdate(state: AppState): void { }

  preFrame(): void { }

  preRender(): void {
    this.strip.preRender(this.xrmodule.xrcamera.head);
    this.interactions.update();
    this.strip.minimap.updateState(this.interactions.CurrentHoverState)
    this.xrmodule.preRender();
    this.title.preRender();
  }

  render(): void {
    const gl = this.scene.gl;

    const gllayer = this.scene.xrview.gllayer;

    const w = gllayer.framebufferWidth;
    const h = gllayer.framebufferHeight;

    gl.bindFramebuffer(
      gl.FRAMEBUFFER,
      post_backbuffer ? null : gllayer.framebuffer
    );
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // RENDER
    // ========

    this.scene.glstate.apply();

    for (const camera of this.xrmodule.xrcamera.cameras) {
      const vp = camera.lens.viewport;
      gl.viewport(vp.x, vp.y, vp.width, vp.height);

      this.background.render(camera);
      this.strip.render(camera);
      this.title.render(camera);
      this.floor.render(camera);
      this.renderUI(camera);
    }

    this.scene.glstate.apply();
    this.strip.postRender();
  }

  renderUI(camera: Camera): void {
    this.xrmodule.render(camera);
  }

  rttPass(): void { }

  onInteraction(e: InteractionEvent) {
    switch (e.type) {
      case "i:resize": {
        break;
      }
      case "i:click": {
        const state = e.detail as InteractionEventDetail["i:click"];
        AppService.send("OPEN_PHOTO", { pov: state.data.pov });
        this.scene.glview.canvas.style.cursor = "";

        Gtm.sendEventPush(
          "photo-gallery",
          "choose-a-photo",
          "Click on a photo"
        );
        break;
      }
      case "i:over": {
        const state = e.detail as InteractionEventDetail["i:over"];
        AppService.send("GALLERY_HOVER", { pov: state.data.pov });
        this.scene.glview.canvas.style.cursor = "pointer";
        break;
      }
      case "i:out": {
        AppService.send("GALLERY_OUT");
        this.scene.glview.canvas.style.cursor = "";
        break;
      }
      case "i:dragstart": {
        AppService.send("GALLERY_DRAGSTART");
        this.scene.glview.canvas.style.cursor = "grabbing";
        break;
      }
      case "i:dragend": {
        AppService.send("GALLERY_DRAGEND");
        this.scene.glview.canvas.style.cursor = "";
        break;
      }
      case "i:load": {
        this.data.load(this.useSample).then(() => {
          this.strip.interactions.nextLoading.pending = false;
        });;
        break;
      }
    }
  }
}
