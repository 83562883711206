import XRSettingsWheel from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheel";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import { quat, vec2 } from "gl-matrix";
import gsap from "gsap";

const QUAT = quat.create();

export default class XRSettingsWheelTarget {

  angle: number = 0;
  _angle: number = 0;

  shown: boolean = false;
  scale: number = 0;

  get Renderable() {
    return (this.node._children[0] as GltfNode).renderable;
  }

  constructor(
    public wheel: XRSettingsWheel,
    public node: GltfNode
  ) {
    this.node.setScale(0);
  }

  setShown(flag) {
    if (flag && !this.shown) {
      this.show();
    }
    if (!flag && this.shown) {
      this.hide();
    }
  }

  setAngle(angle: number) {
    this.angle = -angle;
  }

  show() {
    this.shown = true;
    gsap.killTweensOf(this, "scale");
    gsap.to(this, { scale: 1, duration: 0.2, ease: "power2.out", onUpdate: () => this.node.setScale(this.scale) });
  }
  
  hide() {
    this.shown = false;
    gsap.killTweensOf(this, "scale");
    gsap.to(this, { scale: 0, duration: 0.2, ease: "power2.out", onUpdate: () => this.node.setScale(this.scale) });
  }

  preRender(dt: number) {

    if (this.angle !== this._angle) {
      this.node.rotation.set(QUAT);
      this.node.rotateZ(this.angle);
      this._angle = this.angle;
    }

  }

}