
import { gsap } from 'gsap';
import { defineComponent } from 'vue';

import AppService from '@/store/states/AppService';
import { useActor } from '@xstate/vue';
import PhotoSettings from '@/components/PhotoSettings/PhotoSettings.vue';
import UIBtnCamera from '@/components/UIBtn/UIBtnCamera.vue';

import { DIRECTION as UIBtnSecondaryDirection } from '@/components/UIBtn/UIBtnSecondary.vue'
import Gtm from '@/utils/Gtm';

export default defineComponent({

  components: {
    PhotoSettings,
    UIBtnCamera
  },

  setup() {
    const { state, send } = useActor(AppService)
    Gtm.sendVPV('immersive-painting')

    return { state, send }
  },

  data() {
    return {
      flash: false,
      UIBtnSecondaryDirection
    }
  },

  methods: {
    onEnterPhoto() {
      AppService.send('ENTER_PHOTO')

      Gtm.sendVPV('camera-mode')
      Gtm.sendEventPush(
        'immersive-painting',
        'camera-mode',
        `Open camera mode`
      )
    },
    goToMuseum() {
      AppService.send('GOTO_MUSEUM')
      Gtm.sendEventPush(
        'immersive-painting',
        'back-to-museum',
        `Leave immersive painting`
      )
    },
    onEnterUI(el, onComplete) {
      const $ = gsap.utils.selector(el)

      gsap.set('.AppHeader', { autoAlpha: 0})
      const tl = gsap.timeline({
        onComplete,
        delay: 0.2
      })
      tl
      .set('.AppHeader', { autoAlpha: 1})
      .from([$('.PaintingExplore__CtaBack'), '.UIBtnGallery', $('.UIBtnCamera')], {
        autoAlpha: 0,
        duration: 0.6,
        transition: 'none',
        y: 20,
        ease: 'customOut2',
        stagger: 0.1
      }, 'start')
     
    },
    onEnterCancelledUI(el) {
      // const $ = gsap.utils.selector(el)
      // gsap.set([$('.PaintingExplore__CtaBack'), '.UIBtnGallery', $('.UIBtnCamera')], { autoAlpha: 0 })
    },
    onLeaveUI(el, onComplete) {
      const tl = gsap.timeline(
        {onComplete}
      )
      tl.to(el, {
        autoAlpha: 0,
        ease: 'customOut2',
        duration: 0.3
      })
    },
  },

  watch: {
    state: {
      handler(newState, oldState) {

        if (this.state.matches('painting.explore.init')) {
          gsap.set('.AppHeader', { autoAlpha: 0})
        }
       
        if (this.state.matches('painting.snapshot')) {
          this.flash = true

          setTimeout(() => {
            this.flash = false
          }, 1000)
        }
      },

      immediate: true,
    },
  },

})
