import { MsaaFbo } from "@/webgl/gl/MsaaFbo";
import Chromashift from "@/webgl/glsl/chromashift";
import Scene from "@/webgl/Scene";
import Post from "nanogl-post";
import Fetch from "nanogl-post/effects/fetch";
import Grain from "nanogl-post/effects/grain";
import Hejl from "nanogl-post/effects/hejl";
import GammaCorrection from "nanogl-post/effects/gamma_correction";
import Dithering from "@/webgl/glsl/dithering";
import Fbo from "nanogl/fbo";

export default class PostProcessing {

  setup: Post
  fetch: Fetch;
  msaa: MsaaFbo;


  public get enabled() {
    return this.setup.enabled;
  }
  public set enabled(value) {
    this.setup.enabled = value;
  }

  constructor(private scene:  Scene, msaa=true ) {

    this.setup = new Post(scene.gl, false);
    this.setup.enabled = true;

    this.fetch = new Fetch();
    this.setup.add(this.fetch);




    // MSAA
    this.msaa = new MsaaFbo(scene.gl, msaa?16:0);
    this.setup.mainColor = this.msaa.blitFbo.getColorTexture();
    this.setup.mainFbo = this.msaa.blitFbo;

    const t = this.msaa.getColorTexture();
    t.bind();
    t.clamp().setFilter(true, false, false);

  }

  // resize framebuffers and bind render
  // bind creen FB if dpost disabled
  bindFrameBuffer(vpw:number, vph:number, fallbackFbo:WebGLFramebuffer = null) {
    const gl = this.scene.gl

    if( this.enabled ){
      this.setup.preRender(vpw, vph);
      this.msaa.setSize(vpw, vph);
      this.msaa.renderFbo.bind();
    } else {
      gl.bindFramebuffer(gl.FRAMEBUFFER, fallbackFbo );
    }
    
    gl.viewport(0, 0, vpw, vph);
    gl.clearColor(0, 0, 0, 1)
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);

  }

  bindColor() {
    // TO UPDATE
    // this.setup.bindColor();

    // MSAA
    
  }


  blit(w = this.setup.renderWidth, h = this.setup.renderHeight, toFbo:WebGLFramebuffer = null) {

    const post = this.setup;
    
    if (!post.enabled) {
      return;
    }


    const gl = post.gl;
    this.msaa.blitMsaa();

    // mipmap mainFbo here
    // post.mainColor.bind();
    // post.mainColor.setFilter(true, false, false);
    // if (post.mipmap) {
    //   gl.generateMipmap(gl.TEXTURE_2D);
    // }

    for (var i = 0; i < post._effects.length; i++) {
      post._effects[i].preRender()
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, toFbo)
    gl.viewport(0, 0, w, h);

    // gl.clearColor(.0, .0, .0, 1.0);
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);


    if (post._shaderInvalid) {
      post.buildProgram();
    }

    post.prg.use();

    for (var i = 0; i < post._effects.length; i++) {
      post._effects[i].setupProgram(post.prg);
    }



    // MSAA
    // post.prg.tInput(post.mainColor);
    post.prg.tInput(this.msaa.getColorTexture());
    this.msaa.getColorTexture().clamp()

    if (post._needDepth()) {
      if (post.hasDepthTexture){
        // MSAA
        // post.prg.tDepth(post.mainFbo.getDepth());
        post.prg.tDepth(this.msaa.blitFbo.getDepth());
      }
      else
        throw "no depth texture"
      //post.prg.tDepth( post.depthFbo.color );
    }

    this.scene.glstate.apply()
    post.fillScreen(post.prg);

  }

}

export class MuseumPostProcessing extends PostProcessing {

  chromaShift: Chromashift;
  dithering: Dithering;


  constructor(scene: Scene) {
    super(scene, scene.glview.pixelRatio<2);


    this.chromaShift = new Chromashift();
    this.chromaShift.amount = 0.015
    this.setup.add(this.chromaShift);


    this.dithering = new Dithering();
    this.setup.add(this.dithering);

  }
}