import Deferred from "@/core/Deferred";
import { ArtworkId, stringIsArtworkId } from "@/store/states/Artworks";
import GLTFResource from "@/webgl/assets/GLTFResource";
import { TextureResource } from "@/webgl/assets/TextureResource";
import GL from "@/webgl/gl/GL";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Mesh from "@/webgl/lib/nanogl-gltf/lib/elements/Mesh";
import Node from "nanogl-node";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/Scene";
import MaterialFactory from "@/webgl/scenes/MuseumScene/MaterialFactory";
import { mat4, quat } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import { Sampler, Uniform } from "nanogl-pbr/Input";
import MaterialPass from "nanogl-pbr/MaterialPass";
import { StandardMetalness } from "nanogl-pbr/StandardPass";
import TexCoord from "nanogl-pbr/TexCoord";
import { LocalConfig } from "nanogl-state";
import GLConfig from "nanogl-state/config";
import { GLContext } from "nanogl/types";
import MuseumXRActivity from ".";
import Gamepads from "@/webgl/entities/xr-module/gamepad/Gamepads";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";
import AppService from "@/store/states/AppService";
import i18n from "@/core/i18n";
import Delay from "@/core/Delay";

const Q_ID = quat.create()

const BackCfg = new GLConfig()
  // .frontFace( GL.CW )
  .cullFace( GL.FRONT )

class CardMaterial {

  texRes: TextureResource;
  private _loadPromise: Promise<any>;
  

  constructor( readonly gl:GLContext, readonly texName: string ){
    this.texRes = new TextureResource( MaterialFactory.makeBBCTextureSet(this.texName+'.jpg'  , false ), this )
    this._loadPromise = this.texRes.load()
  }


  async load(){
    await this._loadPromise
    this.texRes.texture.setFilter(true, false, false)
    // this.texRes.texture.bind()
    // this.gl.generateMipmap(this.texRes.texture._target)
  }

  unload(){
    this.texRes.unload()
  }

}


class CardMaterials {

  _materials : CardMaterial[] = []

  constructor( readonly gl:GLContext ){}
  
  getMaterial(texname: string): CardMaterial {
    let m = this._materials.find( m => m.texName === texname )
    if( !m ){
      m = new CardMaterial( this.gl, texname )
      this._materials.push( m )
    }
    return m
  }

  dispose(){
    this._materials.forEach( m => m.unload() )
    this._materials = null
  }

}

const AXLE_R = 1.2
class Card {

  private _closing: boolean;

  opacity = 1
  axleRotation = 0
  flipRotation = 0
  private _isflipped = false

  transform = new Node()

  constructor( 
    private renderable: MeshRenderer,
    private rectoMat:CardMaterial,
    private versoMat:CardMaterial,
    private canflip = false,
    readonly xaction:Function
    ){}


    async open(){
    this.opacity = 0
    await Promise.all([
      this.rectoMat.load(),
      this.versoMat.load()
    ])
    if( this._closing )return 

    const defer = new Deferred<void>()
    this.axleRotation = AXLE_R
    gsap.to(this,{ opacity:1, duration:.5, onComplete:defer.resolve })
    gsap.to(this,{axleRotation:0,duration:2,ease:'elastic.out(1,0.5)'})
    await defer.promise
  }
  
  
  close(): Promise<void> {
    this._closing = true
    const defer = new Deferred<void>()
    gsap.to(this,{opacity:0,duration:.5, onComplete:defer.resolve })
    gsap.to(this,{axleRotation:-AXLE_R,duration:2,ease:'elastic.out(1,0.5)'})
    return defer.promise
  }
  
  flip(){
    if( !this.canflip ) return
    this._isflipped = !this._isflipped
    gsap.to(this,{flipRotation: this._isflipped?Math.PI:0,duration:1.4,ease:'elastic.out(1,0.5)'})
  }

  render(scene:Scene, camera: Camera, cardMaterialPass: StandardMetalness) {
    if( this.opacity > 0 ){
      const s = cardMaterialPass.surface.baseColor.param as Sampler
      (cardMaterialPass.alpha.param as Uniform).set(this.opacity)
      s.set( this.rectoMat.texRes.texture )
      this.renderable.render(scene, camera, Masks.BLENDED, Passes.COLOR )

      s.set( this.versoMat.texRes.texture )
      this.renderable.render(scene, camera, Masks.BLENDED, Passes.COLOR, BackCfg )

    }
  }


}

export type CardId = ArtworkId | 'ticket'


export default class InfoCards {

  
  private _cardId: CardId | null = null;
  root: Node
  axle: Node
  cardNode: Node;
  
  public get cardId(): CardId | null {
    return this._cardId;
  }
  
  public set cardId(value: CardId | null) {
    if( this._cardId === value )return
    this._cardId = value;
    if( stringIsArtworkId( this._cardId ) ){
      this.openArtworkCard( this._cardId )
    } else if( this._cardId === 'ticket' ){
      this.openTicket()
    } else  {
      this.close()
    }

    AppService.send({type:'ENTER', xr:true})
  }
  
  
  unload() {
    this.materials.dispose()
  }
  
  _current :Card = null
  
  private cards :Card[] = []
  
  artworkCardMesh: MeshRenderer
  ticketCardMesh: MeshRenderer;
  materials: CardMaterials
  cardMaterialPass: StandardMetalness;
  ticketPending: boolean = false;


  get gamepads(): Gamepads {
    return this.activity.scene.xrmodule.gamepads
  }

  constructor( private readonly activity : MuseumXRActivity ) {
    this.materials = new CardMaterials(activity.scene.gl)
    
  }


  async load(){
    const gltf = await new GLTFResource(
      require( '@/assets/webgl/museum/cards.glb'),
      this.activity.scene,
    ).load()

    this.root = gltf.getElementByName( GltfTypes.NODE, 'CardRoot')
    this.axle = gltf.getElementByName( GltfTypes.NODE, 'CardAxle')
    this.cardNode = gltf.getElementByName( GltfTypes.NODE, 'CardParent')
    this.artworkCardMesh = gltf.getElementByName( GltfTypes.NODE, 'ArtworkCard').renderable as MeshRenderer
    this.ticketCardMesh = gltf.getElementByName( GltfTypes.NODE, 'Ticket').renderable as MeshRenderer


    this.cardMaterialPass = this.artworkCardMesh.materials[0].getPass(Passes.COLOR).pass as StandardMetalness
    this.cardMaterialPass.surface.baseColor.attachSampler('tcolor', TexCoord.create())
    this.cardMaterialPass.alpha.attachUniform('ualpha')
    this.cardMaterialPass.alphaMode.set('BLEND')
    const lum = .9
    this.cardMaterialPass.surface.baseColorFactor.attachConstant( [lum, lum, lum, 1] )
    this.cardMaterialPass.surface.roughness.attachConstant( .9 )
    this.cardMaterialPass.surface.metalness.attachConstant( 0 )
    this.activity.scene.museumScene.lighting.setupMat( this.cardMaterialPass )
    this.cardMaterialPass.mask = Masks.BLENDED
  }


  start(){
    this.gamepads.list.get('left').skin.add( this.root )
    this.gamepads.inputs.onBtnDown.on( this._onBtnDown )
  }
  
  stop(){
    this.gamepads.list.get('left').skin.remove( this.root )
    this.close()
  }

  _onBtnDown = (btn: ButtonEvent) => {

    if(this.ticketPending){
      if( btn.name === 'Y' ){
        this.releaseTicketAction(false);
      } else if(btn.name == "X"){
        this.releaseTicketAction(true);
      }
      return;

    }

    if( btn.name === 'Y' ){
      this._current?.flip()
    }

    else if( btn.name === 'X' ){
      this._current?.xaction?.()
    }

  }
  
  openArtworkCard(artwork : ArtworkId) {
    this.open(
      this.artworkCardMesh,
      `card_${artwork}_${this.getLocal()}_L0`,
      `card_verso_L0`,
    )
  }


  getLocal():'EN'|'DE' {
    return i18n.global.locale.value === 'de' ? 'DE' : 'EN'
  }

  
  openTicket(){
    this.open(
      this.ticketCardMesh,
      `ticket_recto_${this.getLocal()}_L0`,
      `ticket_verso_${this.getLocal()}_L0`,
      true,
      this.onGetTicketAction
    )
  }

  open( mesh:MeshRenderer, recto:string, verso:string, canflip=false, xaction:Function = null ){
    
    if(this.gamepads.inputs.getInput('left'))
      this.gamepads.inputs.getInput('left').gamepad.pulse(.5, .05)

    this._closeCurrent()
    const c = new Card(
      mesh,
      this.materials.getMaterial(recto),
      this.materials.getMaterial(verso),
      canflip, xaction
    )
    this.cards.push(c)
    this._current = c
    this._current.open()
  }

  close(){
    this.releaseTicketAction();
    this._closeCurrent()
  }

  onGetTicketAction = async ()=>{

    if(this.ticketPending)
      return;

    this.activity.navigation.disable();
    this.ticketPending = true;
    
    this.activity.scene.xrmodule.tooltips.push("X", "xr.leave_xp");
    this.activity.scene.xrmodule.tooltips.push("Y", "xr.resume_xp");
    
  }
  
  releaseTicketAction(redirect: boolean = false){
    
    this.ticketPending = false;
    this.activity.navigation.enable();
    this.activity.scene.xrmodule.tooltips.pop("X", "xr.leave_xp");
    this.activity.scene.xrmodule.tooltips.pop("Y", "xr.resume_xp");

    if(redirect){
      this.activity.scene.xrmodule.xrview.session.end()
      window.location.href = i18n.global.t('about.exhibition_ticket_link');
    }

  }


  render( camera:Camera){
    for( let c of this.cards ){
      
      quat.rotateY( this.cardNode.rotation, Q_ID, c.flipRotation + Math.PI )
      this.cardNode.invalidate()

      quat.rotateX( this.axle.rotation, Q_ID, c.axleRotation )
      this.axle.invalidate()
      this.axle.updateWorldMatrix(true)
      
      c.render(this.activity.scene, camera, this.cardMaterialPass)

    }
  }
  

  private _closeCurrent(){
    const c = this._current
    this._current = null
    if( c ){
      c.close().then( ()=>this._removeCard(c) )
    }
  }
  private _removeCard(c: Card): any {
    const i = this.cards.indexOf(c)
    if( i !== -1 ){
      this.cards.splice(i,1)
    }
  }

}