import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules';
import store from "@/store";

export const enum BREAKPOINTS {
  DESKTOP = '1050px'
}

@Module
export class Viewport extends VuexModule {
  public windowWidth = window.innerWidth
  public windowHeight = window.innerHeight

  public get isDesktop() {
    return this.windowWidth >= parseInt(BREAKPOINTS.DESKTOP)
  }

  public get isLandscape() {
    return this.windowWidth > this.windowHeight
  }

  init() {
    window.addEventListener('resize', () => this.onResize())
  }

  dispose() {
    window.removeEventListener('resize', () => this.onResize())
  }

  @Action
  onResize() {
    this._SET_SIZE([window.innerWidth, window.innerHeight])
  }

  @Mutation
  private _SET_SIZE(size: [number, number]) {
    this.windowWidth = size[0]
    this.windowHeight = size[1]
  }

}

const viewport = new Viewport({ store, name: 'viewport' })

export default viewport;
