import Delay from "@/core/Delay";
import GLApp from "@/webgl/main";
import { assign, createMachine, sendParent, State } from "xstate";
import { send } from "xstate/lib/actionTypes";
import GalleryActivityService from "./GalleryActivityService";
import LoadingActivityService from "./LoadingActivityService";
import MuseumActivityService from "./MuseumActivityService";
import PaintingActivityService from "./PaintingActivityService";


export enum XRStateEnum {
  INACTIVE = 'inactive' ,
}

export type XRStateValue =`${XRStateEnum}`


export interface XRStateContext {
}


export type XRStateType = {
  value: XRStateValue
  context: XRStateContext
}


export type XRStateEvent = {
  type: "REQUEST_SESSION" | "SESSION_END" | "EXIT"
}


export type XRState = State<XRStateContext, XRStateEvent, any, XRStateType>



const XRStateMachine = createMachine({

  id: "XR_fsm",

  initial: "inactive",

  context: {
  },


  states: {

    inactive: {
      entry: ['setupStdActivities', 'notifyChange'],
      on: {
        REQUEST: 'requesting'
      }
    },


    requesting: {

      on: {
        SESSION_END: 'inactive',
        EXIT: 'inactive'
      },

      invoke: {
        src: 'requestXR',
        onDone: 'active',
        onError: 'inactive'
      }
    },


    active: {
      entry: [ 'setupXrActivities', 'notifyChange'],
      on: {
        SESSION_END: 'inactive',
        EXIT: 'inactive'
      }
    },

  }

},{

  services: {
    requestXR: () => {
      console.log("requesting XR session");
      return GLApp.getInstance().requestXR();
      return Delay(1000)
    }
  },

  actions: {

    notifyChange: sendParent('XR_STATE_CHANGE'),
    
    setupXrActivities(){
      sendParent('XR_STATE_CHANGE');
      console.log("setting up XR activities");
      PaintingActivityService.xrmode = true
      MuseumActivityService.xrmode = true
      LoadingActivityService.xrmode = true
      GalleryActivityService.xrmode = true
    },
    
    setupStdActivities(){
      console.log("setting up STD activities");
      PaintingActivityService.xrmode = false
      MuseumActivityService.xrmode = false
      LoadingActivityService.xrmode = false
      GalleryActivityService.xrmode = false
    }
  }
  
})


export type XRStateMachineType = typeof XRStateMachine


export default XRStateMachine