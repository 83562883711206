import Deferred from "@/core/Deferred";
import { CreatePane } from "@/dev/Tweak";
import Signal from "@/webgl/lib/signal";
import { AbortController } from "@azure/abort-controller";
import MuseumScene from ".";


export interface QualityLevel {
  reflect: boolean
  reflectResolution: number,
  reflectAA:boolean,
  post:boolean
}

function Level(
  reflect: boolean,
  reflectResolution: number,
  reflectAA: boolean,
  post:boolean
): QualityLevel {
  return { reflect, reflectResolution, reflectAA, post }
}

const Levels: QualityLevel[] = [

  // level 0 aka best
  //    
  Level(true , 512 , true , true),
  //reduce reflection quality
  Level(true , 512 , false, true),
  Level(false, 512 , false, true),
  
  //reduce postprocessing quality

  Level(false, 512 , false, false),
]


export type FPSMonitorResult = {
  meanFrame: number
}

function delay(ms: number) {
  return new Promise((r) => setTimeout(r, ms))
}

function pnow() {
  return performance.now() / 1000
}

class FPSMonitor {



  private _running = false;
  private lastFrameTime = -1
  private startTime = -1
  private _sampleDuration: number;

  private record: number[] = []

  private _defer: Deferred<FPSMonitorResult>


  start(duration: number = 1): Promise<FPSMonitorResult> {
    if (this._running) return;
    this._sampleDuration = duration;
    this._defer = new Deferred()
    this.startTime = pnow();
    this._running = true;
    requestAnimationFrame(this.onRaf)
    return this._defer.promise;
  }

  stop() {
    this._defer.reject("stopped")
    this._stop()
  }

  _stop() {
    this._defer = null;
    this._running = false;
    this.record.length = 0;
  }

  onRaf = () => {
    if (!this._running) return;

    const now = pnow();
    if (this.lastFrameTime > 0) {
      this.record.push(now - this.lastFrameTime);
    }
    this.lastFrameTime = now;

    if (now - this.startTime > this._sampleDuration) {
      this._defer.resolve(this.getResult());
      this._stop();
      return;
    }

    requestAnimationFrame(this.onRaf)
  }


  getResult(): FPSMonitorResult {
    let rawmean: number = 0;
    const record = this.record
    for (let i = 0; i < record.length; i++) {
      const f = record[i];
      rawmean += f
    }
    rawmean /= record.length;


    let mean: number = 0;
    let c = 0;
    for (let i = 0; i < record.length; i++) {
      const f = record[i];
      const delta = f / rawmean
      if (delta > .25 && delta < 4) {
        mean += f
        c++
      }
    }

    mean /= c;

    return {
      meanFrame: mean
    }
  }

}


const FPS_TARGET = 50

export default class MuseumQuality {
  setLevel(level: number) {
    this.levelIndex = level
  }

  readonly onChange = new Signal()

  _currentLevel = 0; // best

  _forceQuality = false
  
///////////////
//////////////////////////

///////////////////////////
////////////////////////////
///
//
////////////////////////////////
////////////////////////////////////////
/////////////////////////////
//////////////////////////////
//////////////////////
///
////////////

  constructor() {
    this.levelIndex = 0

/////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////
  }

  async startAutoLevel(cts: AbortController) {
    const monitor = new FPSMonitor();
    const targetFrame = 1 / FPS_TARGET

    await delay(1500)

    while (true) {

      let res = await monitor.start()
      if (cts.signal.aborted) throw ""

      console.log(`fps ${1 / res.meanFrame}`);

      if (res.meanFrame > targetFrame) {
        if (!this.reduceQuality()) return;
        await delay(200)
      } else {
        return;
      }
    }
  }

  private set levelIndex(l: number) {
    if (l < 0 || l >= Levels.length) return;
    this._currentLevel = l;
    this._applyLevel()
  }
  
  
  private get levelIndex() {
    return this._currentLevel
  }
  
  get level() {
/////////////////
////////////////////////////
/////////////////////////////////////////////////////
/////
//////////////
    
    return Levels[this._currentLevel]
  }
  
  private reduceQuality() {
    if (this.levelIndex >= Levels.length - 1) return false
    this.levelIndex++;
    console.log(`reduced quality to ${this.levelIndex}`)
    return true;
  }
  
  private _applyLevel() {
    this.onChange.emit(this)
  }
}