
// Probe 
var coeffs = 
[
    /* Band 0 */  3.125844457392937592,  2.997647047097592221,  3.617143994120624484 ,
    /* Band 1 */ -0.493837744910176235, -0.637918248634859464, -0.847039729231239269 ,   1.100156874991418121,  1.101538855583447107,  1.294084435038518333, -0.491575769197001355, -0.442500345619943891, -0.479527771607666464,
    /* Band 2 */  -0.121886423952274253, -0.096108499245928736, -0.093495627890652563 ,  -0.840869425866021092, -0.943768840074670434, -1.166173784713577577 ,  -0.100748293697696889, -0.052344962297514654, -0.017862869192230905 ,  -0.100401056610822825, -0.087324746080577539, -0.089987784969987161 ,   0.150890950180250782,  0.067405272160566979,  0.034996517707958252 ,
    /* Band 3 */   0.143592269838892173,  0.130579297659751098,  0.153767333810510165 ,  -0.037362386825510109, -0.025756592414473672, -0.022045248273506672 ,  -0.412777723383977280, -0.492995150118744618, -0.629937828626563889 ,  -0.583470396275840852, -0.588323686455118677, -0.683822998832079199 ,   0.090627247993809612,  0.096331414226050988,  0.115638352958955248 ,  -0.069403455971086020, -0.150309521045617761, -0.227274641803733607 ,  -0.069165441768779548, -0.062135124828296251, -0.051949278657676901 ,
    /* Band 4 */   0.000831461779772575,  0.011504755175962975,  0.023351927867968157 ,   0.175184118517788590,  0.179348194905770725,  0.219672354707972278 ,  -0.079263595283111388, -0.065193662898119925, -0.070151410726266614 ,   0.069221537893885229,  0.072271446270345874,  0.083613135060619279 ,  -0.088958679446634686, -0.078394285927307183, -0.065383016297045515 ,   0.059885615233363387,  0.054079804016768360,  0.058503089094833087 ,  -0.227317293358005323, -0.301299358936949979, -0.397130155460015677 ,  -0.090740908226976377, -0.072829273517603640, -0.063066879510923685 ,   0.189446943241852822,  0.205676689116444045,  0.233008423599304459 
];

module.exports = coeffs;