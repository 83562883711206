import { IActivity } from "@/webgl/activities/Activity";
import GLApp from "@/webgl/main";
import { throwStatement } from "@babel/types";
import { actions, assign, createMachine, interpret, Interpreter, State } from "xstate";
import AppService from "./AppService";
import { AppState } from "./AppStateMachine";





export interface ActivityStateContext {
  started: boolean
  activity: IActivity
}


export type ActivityStateType = {
  value: any
  context: ActivityStateContext
}

type StateUpdateEvent = {
  type:'STATE_UPDATE',
  state: AppState
}

export type ActivityStateEvent = {
  type: "LOAD" | "UNLOAD" | "START" | "STOP"
} | StateUpdateEvent





const ActivityStateMachine = createMachine<ActivityStateContext, ActivityStateEvent, ActivityStateType>({

  id: "Activity_fsm",

  initial: "unloaded",

  context: {
    started:false,
    activity:null
  },
  

  on: {
    START: {
      actions: assign({started:(ctx)=>true}),
    },
    STOP: {
      actions: assign({started:(ctx)=>false}),
    },
  },

  states: {

    unloaded: {
      on:{
        LOAD: 'loading',
        START: 'loading',
      }
    },

    
    loading: {

      on: {
        UNLOAD: {
          target:'unloaded',
          actions: 'unloadActivity'
        }
      },

      invoke: {
        src: 'loadActivity',
        onDone: 'loaded'
      }
    },


    loaded: {

      on: {
        UNLOAD: {
          target:'unloaded',
          actions: 'unloadActivity'
        }
      },

      
      initial: "stopped",

      states: {
        
        stopped:{
          always: {
            target: 'started', cond: (ctx)=>ctx.started
          }
        },

        started:{
          always: {
            target: 'stopped', cond: (ctx)=>!ctx.started
          },

          on:{
            STATE_UPDATE: {
              actions: (ctx, evt)=>{ ctx.activity.onStateUpdate(evt.state) }
            }
          },
          entry: 'startActivity',
          exit: 'stopActivity'
        },
          
      }
    },


  }

},{

  services: {

    loadActivity(ctx:ActivityStateContext):Promise<void> {
      return ctx.activity.load()
    }
    
  },
  
  actions: {
    
    unloadActivity(ctx:ActivityStateContext){
      return ctx.activity.unload();
    },

    startActivity(ctx:ActivityStateContext){
      console.log(`[Activity AAA start`);
      ctx.activity.start();
      ctx.activity.onStateUpdate( AppService.getSnapshot() )
      GLApp.activities.registerActivity(ctx.activity)
    },
    
    stopActivity(ctx:ActivityStateContext){
      GLApp.activities.unregisterActivity(ctx.activity)
      ctx.activity.stop();
    },

  }
  
})



export default abstract class ActivityService {
  
  
  private _xrmode = false;
  service: Interpreter<ActivityStateContext, any, ActivityStateEvent, ActivityStateType>;

  private _load: boolean;
  private _started: boolean;



  constructor(protected readonly id:string) {
  }
  
  abstract createActivity( xr:boolean ) : IActivity

  public set xrmode(value) {
    if( this._xrmode === value) return
    this._xrmode = value;
    this.allocateActivity()
  }


  load() : Promise<void>{
    this._lazyAllocate()
    this._load = true
    console.log(`[Activity ${this.id}] load`);
    
    this.service.send('LOAD')
    return this.awaitServiceLoading()
  }
  
  unload(){
    this._lazyAllocate()
    this._load = false
    this.service.send('UNLOAD')
  }
   

  start() {
    this._lazyAllocate()
    if( !this._load) this.load()
    this._started = true
    this.service.send('START')
  }
  
  stop(){
    this._lazyAllocate()
    this._started = false
    this.service.send('STOP')
  }
  
  onStateUpdate( state:AppState ) {
    this.service.send({type:'STATE_UPDATE', state})
  }

  _lazyAllocate(){
    if( !this.service ) this.allocateActivity()
  }

  allocateActivity() {
    this.releaseActivity()
    
    const activity = this.createActivity(this._xrmode)
    const started = this._started
    this.service = interpret( ActivityStateMachine.withContext({activity,started}))
    this.service.start()

    if( this._load )
      this.service.send('LOAD')
    
  }


  releaseActivity() {
    if( this.service ){
      this.service.send('UNLOAD')
      this.service.stop()
      this.service = null
    }
  }
  
  awaitServiceLoading(){
    return new Promise<void>((resolve,reject)=>{
      this.service.onTransition(state=>{
        if( state.matches('loaded') ) resolve()
        if( state.matches('unloaded') ) resolve()
      })
    })
  }


///////////////
/////////////////
///////////////////////////
///
////////////

}
