import { clamp01 } from "@/webgl/math";
import { mat4, vec3 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import LabelMesh from "./LabelMesh";
import ArtworkState from "./State";

export default class Label {
  root = new Node();
  content: string = "LABEL";

  renderable = false;
  visible = true;
  scale = 1;

  show() {
    if (this.visible) return;
    this.visible = true;
    gsap.killTweensOf(this);
    gsap.to(this, { scale: 1, duration: 0.25 });
  }

  hide() {
    if (!this.visible) return;
    this.visible = false;
    gsap.killTweensOf(this);
    gsap.to(this, { scale: Number.EPSILON, duration: 0.25 });
  }

  render(camera: Camera, mesh: LabelMesh, state: ArtworkState) {
    if (!this.renderable) return;

    const radius = state.fog.worldRadius;
    const outside =
      clamp01(vec3.length(this.root._wposition as vec3) / (radius * 2)) === 1;

    if (outside && this.visible) {
      this.hide();
    } else if (!outside && !this.visible) {
      this.show();
    }

    this.root.setScale(this.scale);

    this.root.add(mesh.root);
    mesh.root.invalidate();
    mesh.root.updateWorldMatrix(true);
    mesh.setText(this.content);
    mesh.render(camera);
    // this.root.remove(mesh.root);
  }
}
