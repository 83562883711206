import RaycastTarget from "@/webgl/entities/xr-module/raycast-layer/RaycastTarget";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { IRaycastable } from "@/webgl/lib/Raycastable";
import Plane from "@/webgl/math/Plane";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { XRHandedness } from "webxr";
import rayPlaneIntersection from "@/webgl/math/ray-plane-intersection";
import { TextureResource } from "@/webgl/assets/TextureResource";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import Paths from "@/core/Paths";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";
import gsap from "gsap/all";

interface IHoverState {
  hand: XRHandedness;
  castidx: number;
}

export default class RaycastLayer {

  public handedness: XRHandedness = "none";

  private _active: boolean;
  private _renderAlpha: number = 0;
  private _castableItems: IRaycastable[];
  private _castPlane: Plane;
  private _castPoint: vec3;
  private _hoveringList: IHoverState[];
  private _targets: Map<XRHandedness, RaycastTarget> = new Map<XRHandedness, RaycastTarget>();

  public get Targets(): Map<XRHandedness, RaycastTarget>{
    return this._targets;
  }

  public get target(): RaycastTarget {
    return this._targets.get("right");
  }

  get IsActive(): boolean {
    return this._active;
  }

  constructor(
    public readonly xrmodule: XRModule
  ) {

    this._active = false;
    this._castableItems = [];
    this._hoveringList = [];
    this._targets.set("right", new RaycastTarget(xrmodule));
    this._targets.set("left", new RaycastTarget(xrmodule));

    xrmodule.resources.add(
      new TextureResource(new TextureSrcSet(Paths.resolve("assets/webgl/raycast_target.png")), xrmodule.scene),
      "raycast-target"
    );
    this._castPlane = new Plane();
    this._castPoint = vec3.create();

  }

  enable() {
    this._active = true;
    gsap.killTweensOf(this, "_renderAlpha");
    gsap.to(this, {_renderAlpha: 1.0, duration: 0.4});
    this.xrmodule.Inputs.onBtnDown.on(this.onInputDown);
  }

  disable() {
    // this._active = false;
    gsap.killTweensOf(this, "_renderAlpha");
    gsap.to(this, {_renderAlpha: 0.0, duration: 0.4, onComplete: ()=> this._active = false});
    this.xrmodule.Inputs.onBtnDown.off(this.onInputDown);
  }

  onInputDown = (evt: ButtonEvent) => {

    let skip = false;
    let phand: XRHandedness = "none"

    switch (evt.name) {
      case "Left/Trigger":
      case "Left/Squeeze":
      case "Left/Thumbstick":
      case "X":
      case "Y":
        phand = "left";
        break;
      case "Right/Trigger":
      case "Right/Squeeze":
      case "Right/Thumbstick":
      case "A":
      case "B":
        phand = "right";
        break;

      default:
        skip = true;
        break;

    }

    for (let i = 0; i < this._hoveringList.length; i++) {
      const _idx = this._hoveringList[i].castidx;
      const _hand = this._hoveringList[i].hand
      if (_hand == phand) {
        this._castableItems[_idx]?.click(evt.name);
      }
    }

  }

  udpdatePlane(
    origin: vec3,
    normal: vec3
  ) {
    this._castPlane.origin.set(origin);
    this._castPlane.normal.set(normal);
  }

  add(castableItem: IRaycastable) {
    if (this._castableItems.indexOf(castableItem) == -1) {
      this._castableItems.push(castableItem);
    }
  }

  remove(castableItem: IRaycastable) {
    const idx = this._castableItems.indexOf(castableItem);
    if (idx !== -1) {
      this._castableItems.splice(idx, 1);
    }
    this._hoveringList.length = 0;
  }

  clear() {
    this._castableItems.length = 0;
  }

  raycastHands(hands: XRHandedness[]){

    const rays = [this.xrmodule.gamepads.list.get(hands[0]).ray, this.xrmodule.gamepads.list.get(hands[1]).ray]

    for (let i = 0; i < this._castableItems.length; i++) {

      const _wasHover = this._castableItems[i].isHover();
      const handIdxHit = this._castableItems[i].raycastList(rays);

      if (this._castableItems[i].isHover()) {

        this._hoveringList.push({
          hand: hands[handIdxHit],
          castidx: i
        })

        if (!_wasHover) {
          this.xrmodule.Inputs.getInput(hands[handIdxHit]).gamepad.pulse(1.0, 3);
        }

      }

    }

    for(let i = 0; i < hands.length; i++){
      const hit = rayPlaneIntersection(this._castPoint, rays[i], this._castPlane);
      this._targets.get(hands[i]).setPosition(this._castPoint, this._castPlane.normal);
      this._targets.get(hands[i]).setHit(hit);
    }
    
  }

  preRender() {

    if (!this._active)
      return;

    this._hoveringList.length = 0;

    if (this.handedness == "none") {
      this.raycastHands(["right", "left"]);
    } else {
      this.raycastHands([this.handedness]);
    }


  }

  renderTarget(camera: Camera) {

    if (!this._active)
      return;

    if (this.handedness == "none") {
      this._targets.forEach((value, key) => value.render(camera));
    } else {
      this._targets.get(this.handedness).render(camera);
    }

    const xrm = this.xrmodule
    const lr = xrm.raycastLineRenderer
    lr.prepare(camera)

    let dist = vec3.distance(xrm.gamepads.list.get('left').skin._wposition as vec3, this.Targets.get("left").wposition)
    lr.draw('left', this._renderAlpha, Math.min(1, dist));

    dist = vec3.distance(xrm.gamepads.list.get('right').skin._wposition as vec3, this.Targets.get("right").wposition)
    lr.draw('right', this._renderAlpha, Math.min(1, dist));

  }

}