import { createArtwork } from "@/api/artwork"
import { generateShareURL } from "@/api/share"
import Delay from "@/core/Delay"
import Paths from "@/core/Paths"
import UrlParams from "@/utils/UrlParams"
import { spawn, send, assign, createMachine, State, ActorRefFrom, EventObject } from "xstate"
import { ArtworkId } from "./Artworks"
import GalleryActivityService from "./GalleryActivityService"
import MuseumActivityService from "./MuseumActivityService"
import PaintingActivityService from "./PaintingActivityService"
import XRStateMachine, { XRStateMachineType } from "./XRStateMachine"


export enum AppStateEnum {
  HOME             = 'home'            ,
  MUSEUM_LOADING   = 'museum_loading'  ,
  INIT_XR          = 'initxr'          ,
  MUSEUM           = 'museum'          ,
  PAINTING_LOADING = 'painting_loading',
  PAINTING         = 'painting'        ,
  GALLERY_LOADING  = 'gallery_loading' ,
  GALLERY          = 'gallery'         ,
  SHARE            = 'share'           ,
}

export type AppStateValue = `${AppStateEnum}`


export type PostProcessingConfig = {
  contrast   :number,
  brightness :number,
  saturation :number,
  temperature:number,

  /**
   * [-1, 1] act on fov
   */
  zoom : number
}


export type PhotoFormat = 'portrait' | 'landscape' | 'square'

export const PhotoFormatDimensions = {
  "portrait": {
    width: 1080,
    height: 1440,
  },
  "landscape": {
    width: 1440,
    height: 1080,
  },
  "square": {
    width: 1080,
    height: 1080,
  }
} as Record<PhotoFormat, {width: number, height: number}>

/**
 * serializable state of a painting point of view
 */
export type PovDatas = {
  /**
   * absolute position of the pov
   */
  position:[number,number, number],
  /**
   * rotation quaternion of the pov
   */
  rotation:[number,number,number, number],

  format: PhotoFormat
} & PostProcessingConfig

export const DefaultPovData: PovDatas = {
  position:[
    // std
    1.9391047954559326,
    1.5000003576278687,
    7.205869674682617

    // FOUNTAIN DEBUG
    // -1.4722809791564941,1.5000003576278687,-5.795301914215088

    // STAIRS
    // 7.2052226066589355, 2.8780038356781006, 9.970667839050293
  ],
  rotation: [
    // std
    0.058132853358983994,
    0.1375393271446228,
    -0.00808647833764553,
    0.9887558221817017

    // FOUNTAIN DEBUG
    // 0.024199729785323143,0.023501699790358543,-0.0005690361140295863,0.9994307160377502

    // STAIRS
    // -0.06271182000637054, 0.34362471103668213, 0.023004846647381783, 0.9367283582687378

  ],
  format:'landscape',
  brightness:0.5,
  contrast:0.5,
  saturation:0.5,
  temperature:0.5,
  zoom:0.5
}


/**
 *
 */
export type SharedMedia = {
  blob:Blob,
  url: string,
  loaded: boolean,
  artworkId: string
}


export type ShareService = 'facebook' | 'instagram'


export interface AppStateContext {
  /**
   * @private
   */
  _xr: ActorRefFrom<XRStateMachineType>

  /**
   * true if xr mode is up and running
   */
  xrActive: boolean,


  /**
   * selected artwork
   */
  selectedArtworkId?: ArtworkId
  /**
   * the nearby artwork
   */
  nearbyArtworkId?: ArtworkId
  /**
   * hovered artwork
   */
  hoveredArtworkId?: ArtworkId

  paintingTutorialCompleted:boolean
  paintingPhotoTutorialCompleted:boolean

  /**
   * shared snapshot data
   */
  sharedMedia:SharedMedia

  povDatas:PovDatas

  /**
   * gallery
   */
  galleryPovDatas: PovDatas;
  galleryFocusedBounds: { left: number; top: number; right: number; bottom: number; };

  openPhotoPending: boolean

  galleryReferer: 'museum' | 'painting' | null
}


export type AppStateType = {
  value: string
  context: AppStateContext
}


async function shareService(media:SharedMedia, infos:PovDatas, service:ShareService ): Promise<void> {
  // console.log('doShareOnService', service)
  // await Delay(2000)
}

async function uploadPhotoService(media:SharedMedia, povDatas:PovDatas): Promise<SharedMedia>{
  const response = await createArtwork({
    Image: media.blob,
    Artwork: {
      Format: povDatas.format,
      PointOfView: {
        Position: povDatas.position,
        Rotation: povDatas.rotation
      },
      PostProcessing: {
        Brightness: povDatas.brightness,
        Contrast: povDatas.contrast,
        Saturation: povDatas.saturation,
        Temperature: povDatas.temperature,
        Zoom: povDatas.zoom
      }
    }
  });
  const { ArtworkID } = response.expect("Failed to create the artwork.");
  const url = generateShareURL({ ArtworkID, lang: "en" });
  media.url = url;
  media.artworkId = ArtworkID;
  return media;
}

/**
 * Generic Events
 */
type BaseEvent = {
  type: "INIT" |
    'SUBMIT' |
    'CANCEL' |

   'LOADING_INTRO_COMPLETE' |
   'LOADING_OUTRO_COMPLETE' |
   'LOADING_COMPLETE' |

   'PAINTING_TRANSITION_COMPLETE' |

   'GOTO_HOME' |
   'GOTO_MUSEUM' |
   'GOTO_GALLERY' |
   'GOTO_PAINTING' |

   'COMPLETE_TUTORIAL' |
   'ENTER_PHOTO' |
   'XR_STATE_CHANGE'
}

export type EnterEvent = {
  type: "ENTER"
  xr: boolean
}
export type ShareRequestEvent = {
  type: "SHARE"
  service: ShareService
}

export type SelectArtworkEvent = {
  type: "SELECT_ARTWORK"
  artworkId: ArtworkId | null
}

export type HoverArtworkEvent = {
  type: "HOVER_ARTWORK"
  artworkId: ArtworkId | null
}

export type NearbyArtworkEvent = {
  type: "NEARBY_ARTWORK"
  artworkId: ArtworkId | null
}

export type UpdatePovEvent = {
  type: "UPDATE_POV_DATA"
  pov:Partial<PovDatas>
}

export type OpenPhotoEvent = {
  type: "OPEN_PHOTO"
  pov:PovDatas
}

export type SnapshotReadyEvent = {
  type: "SNAPSHOT_READY"
  media:SharedMedia
}

export type GalleryEvent = {
  type: 'GALLERY_HOVER';
  pov: PovDatas;
  bounds: { left: number; top: number; right: number; bottom: number; };
} | {
  type: 'GALLERY_OUT';
} | {
  type: 'GALLERY_DRAGSTART';
} | {
  type: 'GALLERY_DRAGEND';
};


/////////////
///
//////////////////
////////////////////////////////////////////////////////////////
 
///
/////////
type DevEvents = {type: never}
//////////
//*/



export type AppStateEvent = BaseEvent | ShareRequestEvent | EnterEvent | SelectArtworkEvent | HoverArtworkEvent | NearbyArtworkEvent | UpdatePovEvent | OpenPhotoEvent | SnapshotReadyEvent | GalleryEvent | DevEvents

export type AppState = State<AppStateContext, AppStateEvent, any, AppStateType>




const AppStateMachine = createMachine<AppStateContext, AppStateEvent, AppStateType>({

  id: "app",

  initial: "initial",

  context: {
    _xr: null,
    xrActive: false,

    nearbyArtworkId:null,
    selectedArtworkId: null,

    paintingTutorialCompleted: false,
    paintingPhotoTutorialCompleted: false,

    sharedMedia: null,
    povDatas: DefaultPovData,

    galleryPovDatas: { ...DefaultPovData },
    galleryFocusedBounds: { left: 0, top: 0, right: 0, bottom: 0 },

    openPhotoPending: false,
    galleryReferer: null
  },

  entry: ['spawnXR'],

  on: {
    XR_STATE_CHANGE:{
      actions: assign({
        xrActive: (ctx, evt) => {
          return ctx._xr.getSnapshot().matches('active')
        }
      })
    },

/////////////////
/////////////////////////
///////////////////////////
/////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////

  },

  // always: {
  //   actions: ['updateServices']
  // },


  states: {

    initial: {
      on: {
        // INIT: 'home',
        INIT: AppStateEnum.HOME
        // INIT: 'museum_loading'
      }
    },

    [AppStateEnum.HOME]: {


      entry : [
        'exitXr',
      ],

      meta: {
        views:['HomeVue']
      },

      on:{
        ENTER: [{
          target:"museum_loading.fromhome", cond: (ctx,evt) => !evt.xr,
          actions: send( (ctx,evt)=>({ type: evt.xr?"REQUEST":"EXIT" }), { to: "xr" } )
        },{
          target:"startXR", cond: (ctx,evt) => evt.xr,
        },{
        }]
      },
    },


    startXR: {
      entry: send( (ctx,evt)=>({ type: "REQUEST" }), { to: "xr" } ),
      on: {
        XR_STATE_CHANGE: [{
          target: UrlParams.getBool("painting") ? "painting_loading" : "museum_loading",
          actions:assign({ xrActive: (ctx, evt) => true })
          // target: "painting_loading"
        }]
      }
    },



    [AppStateEnum.MUSEUM_LOADING]: {


      meta: {
        views:['LoadingVue'],
        activities:[ 'loading' ],
      },

      initial: 'intro',

      states:{

        fromhome:{
          meta: {
            views:['HomeVue'],
          },

          on:{
            LOADING_INTRO_COMPLETE:'default'
          }
        },

        intro:{
          on:{
            LOADING_INTRO_COMPLETE:'default'
          }
        },

        default: {

          invoke: {
            src: 'loadMuseumActivity',
            onDone: 'outro'
          },

          // DEBUG
          on:{
            LOADING_COMPLETE: "outro",
          }
        },

        outro:{

          meta: {
            views:[ 'LoadingVue', 'MuseumVue' ],
            activities:[ 'museum', 'loading' ],
          },

          on:{
            LOADING_OUTRO_COMPLETE: "#app.museum",
          }
        }
      }

    },


    [AppStateEnum.MUSEUM]: {

      meta: {
        views:[ 'MuseumVue' ],
        activities:[ 'museum' ],
      },

      on:{
        GOTO_HOME: 'home',
        GOTO_GALLERY: {
          actions: 'museumReferer',
          target:'gallery_loading',
        }
      },

      initial: 'explore',

      states:{

        /**
         * for loading transition time, camera is locked and look at the big text
         */
        intro: {
          // debug : use delay for now
          after: {
            500: 'explore'
          }
        },

        /**
         * navigation in the museum
         */
        explore:{

          exit: assign({
            nearbyArtworkId:   (ctx, evt) => null,
            selectedArtworkId: (ctx, evt) => null,
            hoveredArtworkId:  (ctx, evt) => null,
          }),

          on:{
            /**
             * if nearby the artwork and visual transition possible, transition to `toPainting`
             */
            GOTO_PAINTING:'toPainting',

            SELECT_ARTWORK: {
              actions: assign({
                selectedArtworkId: (ctx, evt) => evt.artworkId
              })
            },

            HOVER_ARTWORK: {
              actions: assign({
                hoveredArtworkId: (ctx, evt) => evt.artworkId
              })
            },

            NEARBY_ARTWORK: {
              actions: assign({
                nearbyArtworkId: (ctx, evt) => evt.artworkId
              })
            },

          },
        },


        /**
         * trigger painting transitin (look at the artwork and load the painting activity)
         */
        toPainting: {
          // invoke:{
          //   src:'loadPaintingActivity',
          //   onDone:'#app.painting'
          // },
          on:{
            PAINTING_TRANSITION_COMPLETE: '#app.painting_loading'
          },
          // after: {
          //   1000: 'painting_loading'
          // }
        }

      }

    },


    [AppStateEnum.PAINTING_LOADING]: {

      meta: {
        views:['LoadingVue'],
        activities:[ 'loading' ],
      },

      initial: 'intro',

      states:{

        fromgallery: {

        },

        intro:{
          on:{
            LOADING_INTRO_COMPLETE:'default'
          }
        },

        default: {

          invoke:{
            src:'loadPaintingActivity',
            onDone:'outro'
          },

        },

        outro:{
          meta: {
            views:['LoadingVue'],
            activities:['loading', 'painting' ],
          },

          on:{
            LOADING_OUTRO_COMPLETE: [
              {target:'#app.painting.photo', cond:'isOpeningPhoto' },
              {target:UrlParams.getBool("gallery") ? '#app.gallery' : '#app.painting'}
            ]
          }
        }
      }

    },

    [AppStateEnum.PAINTING]: {

      meta: {
        views:[ 'PaintingVue' ],
        activities: ['painting'],
      },


      /**
       * if enter in Painting while not loaded redirect to `painting_loading` to show a generic loader
       */
      // always: [
      //   { target: 'painting_loading', cond: 'needPaintingActivity' },
      // ],

      on:{
        GOTO_MUSEUM: 'museum_loading',
        GOTO_GALLERY: 'gallery_loading',
        /**
         * mutate povDatas
         */
        UPDATE_POV_DATA: {
          actions: assign({
            povDatas: (ctx,evt)=>({
              ...ctx.povDatas,
              ...evt.pov
            })
          }),
        },
      },

      initial: 'explore',

      states:{

        /**
         * EXPLORE
         * initial step, navigate through the painting
         */
        explore: {

          initial: 'init',

          on:{
            ENTER_PHOTO: "#app.painting.photo",
          },

          states:{
            init: {
              after:{
                100: {target: 'default', cond:'tutorialCompleted'},
                0: {target: 'tutorial', cond:'needTutorial'},
              },
            },


            default:{

            },


            /**
             * tutorial step, activate
             */
            tutorial:{

              meta: {
                views:['PaintingTutorialVue']
              },

              on:{

                /**
                 * mark tutorial as completed, return to explore
                 */
                COMPLETE_TUTORIAL: {

                 actions:['completePaintingNavigationTutorial'],

                  target: 'default'
                }

              }
            },
          },
        },

        /**
         * mire and postprocessing setting step
         */
        photo:{

          entry:assign({
            openPhotoPending:(ctx)=>false
          }),

          on:{

            /**
             * exit photo mode and return to explore
             */
            CANCEL: '#app.painting.explore',

            /**
             * enter snapshot
             */
            SUBMIT: 'snapshot',

          },

          initial: 'default',

          states:{

            default:{
              after:{
                300: {target: 'tutorial', cond:'needPhotoTutorial'}
              },
            },

            tutorial:{
              on: {
                /**
                * mark tutorial as completed, return to explore
                */
               COMPLETE_TUTORIAL: {
                 actions:['completePaintingPhotoTutorial'],
                 target: 'default'
               }
              },

            },

          },
        },

        snapshot:{

          on:{
            SNAPSHOT_READY: {
              target:'#app.share.preview',
              actions: assign({
                sharedMedia: (context, event) => {
                  return {
                    ...event.media,
                    loaded: false,
                  };
                }
              })
            }
          }

        },



      },

    },



    [AppStateEnum.SHARE]: {


      meta: {
        views:[ 'PaintingVue', 'ShareVue' ],
        activities: ['painting'],
      },

      // entry: [ PaintingActivityService.enterShare() ],
      // exit:  [ PaintingActivityService.exitShare() ],

      on:{
        GOTO_HOME: 'home',
        GOTO_GALLERY: 'gallery_loading.fromshare',
      },

      initial:'preview',

      states: {
        /**
         * transition fade in step from painting photo preview
         */
        preview: {

          // entry: [ PaintingActivityService.enterPreview() ],
          // exit:  [ PaintingActivityService.exitPreview() ],

          on:{
            /**
             * exit preview and return to photo
             */
            CANCEL: '#app.painting.photo',
            SUBMIT: 'upload'
          }
        },

        /**
         * upload image to server
         */
        upload: {
          invoke:{
            src: async (context, event) => {
              const mediaUrl = await uploadPhotoService( context.sharedMedia, context.povDatas);
              // context.sharedMedia.url = mediaUrl;
              context.sharedMedia.loaded = true;
            },
            onError: 'error',
            onDone: 'select'
          }
        },

        /**
         * service selection (fb/inta btns)
         */
        select: {

          // entry: [ PaintingActivityService.enterShare() ],
          // exit:  [ PaintingActivityService.exitShare() ],
          on:{
            CANCEL: '#app.painting.photo',
            SHARE:'pending',
          }
        },

        /**
         * share process is pending
         */
        pending: {
          invoke:{
            src: (context, event) => shareService( context.sharedMedia, context.povDatas, (event as ShareRequestEvent).service),
            onError: 'error',
            onDone: 'success'
          }
        },

        /**
         * share API error
         */
        error:{
          // toto: unassign sharedMedia
        },

        /**
         * share complete, go to gallery
         */
        success:{
          // toto: unassign sharedMedia
          after:{
            10: [
              {target: '#app.gallery_loading'},
            ]
          }

        }


      }
    },


    [AppStateEnum.GALLERY_LOADING]: {

      meta: {
        views:['LoadingVue'],
        activities:['loading'],
      },

      initial: 'intro',

      states:{
        fromshare:{
          meta: {
            views:['ShareVue'],
          },

          on:{
            LOADING_INTRO_COMPLETE:'default'
          }
        },

        intro:{

          on:{
            LOADING_INTRO_COMPLETE:'default'
          }

        },

        default: {

          invoke:{
            src:'loadGalleryActivity',
            onDone:'outro'
          },

        },


        outro:{
          meta: {
            views:['LoadingVue', 'GalleryVue'],
            activities:['loading', 'gallery' ],
          },

          on:{
            LOADING_OUTRO_COMPLETE: '#app.gallery'
          }
        }
      }

    },

    [AppStateEnum.GALLERY]: {

      meta: {
        views:[ 'GalleryVue' ],
        activities:['gallery']
      },

      on:{
        GOTO_HOME: 'home',
        GOTO_PAINTING: 'painting_loading',

        GOTO_MUSEUM: 'museum_loading',

        OPEN_PHOTO: {
          actions: assign({
            povDatas: (ctx, evt) => {
              console.log('assign pov data to ', evt.pov, evt.pov.position);
              return {
                ...ctx.povDatas,
                ...evt.pov
              }
            },
            openPhotoPending:(ctx)=>true
          }),
          target: 'painting_loading'
        },

        GALLERY_HOVER: {
          actions: assign({
            galleryPovDatas: (ctx, evt) => ({
              ...ctx.galleryPovDatas,
              ...evt.pov,
            }),
            galleryFocusedBounds: (ctx, evt) => ({
              ...ctx.galleryFocusedBounds,
              ...evt.bounds,
            }),
          })
        },

        GALLERY_OUT: {},

        GALLERY_DRAGSTART: {},

        GALLERY_DRAGEND: {},

      },

      exit: [ 'resetReferer' ],
    }
  }

}, {

  services: {

    loadMuseumActivity(){
      GalleryActivityService.unload();
      PaintingActivityService.unload();
      return MuseumActivityService.load();
    },

    loadPaintingActivity(){
      GalleryActivityService.unload();
      MuseumActivityService.unload();
      return PaintingActivityService.load();
    },

    loadGalleryActivity(){
      PaintingActivityService.unload();
      MuseumActivityService.unload();
      return GalleryActivityService.load();
    }

  },

  actions: {


    startMuseumActivity(){
      return MuseumActivityService.start()
    },

    stopMuseumActivity(){
      return MuseumActivityService.stop()
    },

    startPaintingActivity(){
      return PaintingActivityService.start()
    },

    stopPaintingActivity(){
      return PaintingActivityService.stop()
    },


    spawnXR: assign<AppStateContext, AppStateEvent>({
      _xr: ()=>spawn( XRStateMachine, 'xr')
    }),

    exitXr: send( {type: "EXIT" }, { to: "xr" }),

    completePaintingNavigationTutorial: assign<AppStateContext, AppStateEvent>({
      paintingTutorialCompleted:true
    }),

    completePaintingPhotoTutorial: assign<AppStateContext, AppStateEvent>({
      paintingPhotoTutorialCompleted:true
    }),

    museumReferer: assign<AppStateContext, AppStateEvent>({
      galleryReferer:'museum'
    }),
    
    resetReferer: assign<AppStateContext, AppStateEvent>({
      galleryReferer:null
    }),

  },

  guards:{

    tutorialCompleted: (ctx:AppStateContext)=>{
      return ctx.paintingTutorialCompleted
    },

    needTutorial: (ctx:AppStateContext)=>{
      return !ctx.paintingTutorialCompleted
    },

    needPhotoTutorial: (ctx:AppStateContext)=>{
      return !ctx.paintingPhotoTutorialCompleted
    },

    isOpeningPhoto: (ctx:AppStateContext)=>{
      return ctx.openPhotoPending
    }
  }


})


export default AppStateMachine


export function collectAppStateViews( state: AppState ) : string[]{
  const metas = state.meta
  const res = []
  for( const meta of Object.values(metas) ) {
    const m:any = meta
    if( m.views ) res.push( ...m.views )
  }
  return Array.from(new Set(res).values());
}

export function collectAppStateActivities( state: AppState ) : string[]{
  const metas = state.meta
  const res = []
  for( const meta of Object.values(metas) ) {
    const m:any = meta
    if( m.activities ) res.push( ...m.activities )
  }
  return Array.from(new Set(res).values());
}
