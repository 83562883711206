
import { gsap } from 'gsap'
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import lottie from 'lottie-web/build/player/lottie_light'
import { useActor, useSelector } from '@xstate/vue'
import AppService from '@/store/states/AppService'
import Paths from '@/core/Paths'
import markdown from '@/utils/Markdown'

const NB_STEPS = 3

export default defineComponent({

  setup() {
    const { send } = useActor(AppService)
    const { t, tm } = useI18n()
    const tutorialStep = ref(0)
    const lottieContainer = ref(null)

    const currentStep = computed(() => {
      const index = tutorialStep.value
      const labels = tm(`painting_tutorial.steps`)

      const path: string = Paths.resolve(`./assets/lottie/painting-tutorial-${index + 1}.json`)
      const animation = lottie.loadAnimation({
        container: lottieContainer.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path,
      })

      return {
        index,
        animation,
        ...labels[index]
      }
    })

    const ctaNext = markdown(t('painting_tutorial.cta_next'))

    function gotoStep(step: number) {
      if (step < 0 || step >= NB_STEPS) {
        return
      }
      tutorialStep.value = step
    }
    function gotoNextStep() {
      const nextStep = tutorialStep.value + 1
      if (nextStep < NB_STEPS) {
        showNextStep(() => {
          gotoStep(nextStep)
        })
      } else {
        onSkip()
      }
    }

    onMounted(() => {
      onEnter()
      showModal(0.5)
    })

    function showModal(delay = 0) {
      const tl = gsap.timeline({
        delay
      })

      tl
      .from('.Modal', {
        autoAlpha: 0,
        duration: 0.4,
        ease: 'customOut'
      }, 'start')
      .from('.Modal', {
        y: 200,
        duration: 0.8,
        ease: 'customOut3'
      }, 'start')
      .from('.Modal__Background', {
        scaleY: 1.4,
        transformOrigin: 'top left',
        duration: 0.8,
        ease: 'quint.out'
      }, 'start')
      .from(['.Modal__Inner .Step__Animation', '.Modal__Inner .Step__Title', '.Modal__Inner .Step__Description', '.Modal__Inner .UIBtn', '.Modal__Inner .Link'], {
        y: 50,
        autoAlpha: 0,
        duration: 0.8,
        ease: 'quint.out',
        stagger: 0.02
      }, 'start+=0.2')
    }

    function showNextStep(cb) {
      const tl = gsap.timeline({
      })

      tl
      .to(['.Modal__Inner .Step__Animation', '.Modal__Inner .Step__Title', '.Modal__Inner .Step__Description', '.Modal__Inner .UIBtn', '.Modal__Inner .Link'], {
        y: -20,
        autoAlpha: 0,
        duration: 0.6,
        ease: 'quint.in',
        stagger: 0.05,
        onComplete: () => {
          cb()
        }
      })
      .set(['.Modal__Inner .Step__Animation', '.Modal__Inner .Step__Title', '.Modal__Inner .Step__Description', '.Modal__Inner .UIBtn', '.Modal__Inner .Link'], {
        y: 20,
        autoAlpha: 0,
      })
      .to(['.Modal__Inner .Step__Animation', '.Modal__Inner .Step__Title', '.Modal__Inner .Step__Description', '.Modal__Inner .UIBtn', '.Modal__Inner .Link'], {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'quint.out',
        stagger: 0.03,
      })
    }

    function onEnter() {
      const tl = gsap.timeline({
        delay:0.5
      })

      tl.from('.Backdrop', {
        autoAlpha: 0,
        duration: 0.8,
      }, 'start')
    }

    function onSkip() {
      const tl = gsap.timeline({
        onComplete: () => {
          send('COMPLETE_TUTORIAL')
        }
      })

      tl
      .to('.PaintingTutorial', {
        autoAlpha: 0,
        ease: 'customOut',
        duration: 0.4
      }, 'start+=0.1')
      .to('.Modal', {
        scale: 0.9,
        duration: 0.4,
        ease: 'customOut2'
      }, 'start')
    }

    return { 
      onSkip,
      send,
      currentStep,
      ctaNext,
      gotoStep,
      gotoNextStep,
      nbSteps: NB_STEPS,
      lottieContainer
    }
  }

})

