import AppService from "@/store/states/AppService";
import { ArtworkId } from "@/store/states/Artworks";
import Ray from "@/webgl/math/Ray";
import MuseumScene from "@/webgl/scenes/MuseumScene";


export default class HoverController {

  private _currentArtwork: ArtworkId | null = null;
  
  public get currentArtwork(): ArtworkId {
    return this._currentArtwork;
  }

  public set currentArtwork(value: ArtworkId) {

    if( this._currentArtwork === value) return;

    AppService.send({type:'HOVER_ARTWORK', artworkId:value});
    
    if( this._currentArtwork !== null ){
      this.museumScene.artworks.get(this._currentArtwork).showHover(false);
    }

    this._currentArtwork = value
    
    if( this._currentArtwork !== null ){
      this.museumScene.artworks.get(this._currentArtwork).showHover(true);
    }
  }

  
  constructor( private museumScene : MuseumScene ){ }

  preRender( ray:Ray ){
    this.currentArtwork = this.museumScene.raycastArtworks( ray );
  }


}