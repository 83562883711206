import ArtworkInteractions, { IArtworkDrag } from "@/webgl/activities/GalleryActivity/Artwork/Interactions";
import ArtworkState from "@/webgl/activities/GalleryActivity/Artwork/State";
import ArtworkStrip from "@/webgl/activities/GalleryActivity/Artwork/Strip";
import XRPointer from "@/webgl/activities/GalleryActivity/Inputs/XRPointer";
import GalleryXRActivity from "@/webgl/activities/GalleryXRActivity";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Picking from "@/webgl/lib/Picking";
import { IRaycastable } from "@/webgl/lib/Raycastable";
import { clamp } from "@/webgl/math";
import Ray from "@/webgl/math/Ray";
import { ButtonEvent, XRGamepadButton } from "@/webgl/xr/WebXRGamepad";
import { mat4, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import { XRHandedness } from "webxr";


const PICKABLE_POOL_COUNT = 20;


// =====
// =====
// DRAG
// =====
// =====

class GalleryDrag {

  private _pdown: boolean = false;
  private _hasDragged: boolean = false;
  private _handedness: XRHandedness = "none";

  private _downPos: vec3 = vec3.create();
  private _currentPos: vec3 = vec3.create();
  private _drag: IArtworkDrag;
  private _stripScale: number = 1.0;
  private _dragDirection: number = 0.0;
  private _stickHand: XRHandedness = "none";
  private get isStickDragging(): boolean {
    return this._dragDirection !== 0.0;
  }

  constructor(
    public readonly interactions: ArtworkInteractions,
    public readonly xrmodule: XRModule
  ) {

    this._drag = interactions.drag;
    this._drag.speedMultiplier = 20.0;
    this._stripScale = 1 / this.interactions.strip.root.scale[0];

  }

  start() {

    this._hasDragged = false;
    this.xrmodule.Inputs.onBtnDown.on(this.onBtnDown);
    this.xrmodule.Inputs.onBtnUp.on(this.onBtnUp);
    this.xrmodule.tooltips.push("Right/Squeeze", "xr.slide_picture");
    this.xrmodule.tooltips.push("Left/Squeeze", "xr.slide_picture");

  }

  stop() {
    this.xrmodule.Inputs.onBtnDown.off(this.onBtnDown);
    this.xrmodule.Inputs.onBtnUp.off(this.onBtnUp);
    this._pdown = false;
    this._handedness = "none";
    this.xrmodule.tooltips.pop("Right/Squeeze", "xr.slide_picture");
    this.xrmodule.tooltips.pop("Left/Squeeze", "xr.slide_picture");
    this.xrmodule.tooltips.pop("Right/Squeeze", "");
    this.xrmodule.tooltips.pop("Left/Squeeze", "");

  }

  onBtnDown = (evt: ButtonEvent) => {

    if (evt.name !== "Right/Squeeze" && evt.name !== "Left/Squeeze" || this.isStickDragging || this._pdown) {
      return;
    }

    if (!this._hasDragged) {
      this.xrmodule.tooltips.pop("Right/Squeeze", "xr.slide_picture");
      this.xrmodule.tooltips.pop("Left/Squeeze", "xr.slide_picture");
      this.xrmodule.tooltips.push("Right/Squeeze", "");
      this.xrmodule.tooltips.push("Left/Squeeze", "");
    }

    this.downStart(evt.name === "Right/Squeeze" ? "right" : "left");

  }

  onBtnUp = (evt: ButtonEvent) => {

    if (!this._pdown || this.isStickDragging)
      return;

    if (
      !this.isStickDragging && (
        (evt.name === "Right/Squeeze" && this._handedness == "right") ||
        (evt.name === "Left/Squeeze" && this._handedness == "left")
      )
    ) {
      this.downEnd();
      return;
    }

  }

  fetchTargetPosition(out: vec3, hand: XRHandedness) {
    const tgt = this.xrmodule.raycaster.Targets.get(hand);
    if (!tgt || !tgt.hit)
      return;
    out.set(tgt.wposition);
    // out[0] = clamp(out[0], -this.interactions.strip.fog.width, this.interactions.strip.fog.width)
    const radius = this.interactions.strip.fog.radius; // * 2;
    out[0] = clamp(out[0], -radius, radius)
    vec3.scale(out, out, this._stripScale);
  }


  stickStart() {
    this._drag.enabled = true;
    this._drag.isPointerDown = true;
    this._drag.startTime = Date.now();
    this._drag.startX = 0.0;
    this._drag.x = 0.0;
  }

  stickEnd() {
    this._drag.isPointerDown = false;
    this._drag.enabled = false;
    this._drag.startTime = 0;
    this.interactions.strip.offset += this._drag.x;
    this._drag.startX = 0;
    this._drag.x = 0;
    this._drag.clientX = 0;
  }

  downStart(hand: XRHandedness) {
    this._pdown = true;
    this._handedness = hand;

    this.fetchTargetPosition(this._downPos, hand);

    this._drag.enabled = true;
    this._drag.isPointerDown = true;
    this._drag.startTime = Date.now();
    this._drag.startX = this._downPos[0];
    this._drag.x = 0;

    this.interactions.events.dispatchEvent(new CustomEvent("i:dragstart"));

  }

  downEnd() {
    this._handedness = "none";
    this._pdown = false;
    this._drag.isPointerDown = false;
    this._drag.enabled = false;
    const distance = (this._drag.clientX - this._drag.startX);

    const duration = Date.now() - this._drag.startTime;
    const speed = distance / duration;
    this.interactions.strip.offset += distance;
    this.interactions.events.dispatchEvent(new CustomEvent("i:dragend"));
    this._drag.startTime = 0;
    this._drag.startX = 0;
    this._drag.x = 0;
    this._drag.clientX = 0;
    this._drag.inertia = speed * this._drag.speedMultiplier;

  }

  updateStick(dt: number) {

    const leftAxes = this.xrmodule.Inputs.getAxes("left");
    const rightAxes = this.xrmodule.Inputs.getAxes("right");
    const absLeft = Math.abs(leftAxes[0]);
    const absRight = Math.abs(rightAxes[0]);

    if (!this._pdown && !this.isStickDragging && (absLeft > 0.5 || absRight > 0.5)) {

      let direction = 0;
      if (leftAxes[0] !== 0) {
        direction = -Math.sign(leftAxes[0]);
        this._stickHand = "left";
      }
      if (rightAxes[0] !== 0) {
        direction = -Math.sign(rightAxes[0]);
        this._stickHand = "right";
      }

      this.stickStart();
      this._dragDirection = direction;

    }

    if (this.isStickDragging) {

      const axes = this.xrmodule.Inputs.getAxes(this._stickHand);
      const absAxis = Math.abs(axes[0]);
      this._drag.x += dt * this._dragDirection * 1000.0 * absAxis;
      if (absAxis < 0.5) {
        this.stickEnd();
        this._dragDirection = 0;
      }

    }

  }

  updateDrag(dt: number) {
    if (!this.isStickDragging) {
      this.fetchTargetPosition(this._currentPos, this._handedness);

      this._drag.x = (this._currentPos[0] - this._drag.startX);
      this._drag.clientX = this._currentPos[0];
    }
  }

  update(dt: number) {

    this.updateStick(dt);
    this.updateDrag(dt);

  }


}








// =======
// =======
// PICKING
// =======
// =======

const INDICES = [0, 3, 2, 0, 1, 3];
const VERTICES = [
  -1, -1, 0,
  1, -1, 0,
  -1, 1, 0,
  1, 1, 0,
]
const pickable = new Picking(new Node(), INDICES, VERTICES);

class PickableArtworkState implements IRaycastable {

  private _state: ArtworkState = null;
  private _castPos: vec3 = vec3.create();

  get Assigned(): boolean {
    return this._state !== null;
  }

  get State(): ArtworkState {
    return this._state;
  }

  constructor(public readonly awInteractions: ArtworkInteractions) { }


  releaseState() {
    this._state = null;
  }

  assignState(state: ArtworkState) {
    this._state = state;
  }

  isHover(): boolean {
    return this._state.focused;
  }

  raycast(ray: Ray) {
    return this.raycastList([ray]);
  }

  raycastList(rays: Ray[]): number {

    let cast = 0;
    let idx = 0;
    pickable.node = this._state.staticBounds.node;
    for (let i = 0; i < rays.length; i++) {
      const rc = pickable.raycast(rays[i], this._castPos);
      if (rc != 0) {
        cast = rc;
        idx = i;
      }
    }
    this.awInteractions.onFocus(this._state, cast != 0);

    return idx;
  }

  click(btn: XRGamepadButton) {

    if (btn == "Left/Squeeze" || btn == "Right/Squeeze" || btn == "A" || btn == "B")
      return;

    this.awInteractions.events.dispatchEvent(
      new CustomEvent("i:click", {
        detail: this._state,
      })
    );

  }

}





// =====
// =====
// MAIN
// =====
// =====

export default class XRGalleryInteractions {

  public pointer: XRPointer;
  public xrmodule: XRModule;

  private _pickablePool: PickableArtworkState[] = [];
  private _enabled: boolean = false;

  private _drag: GalleryDrag;
  private _currentHoverState: ArtworkState = null;
  public get CurrentHoverState(): ArtworkState {
    return this._currentHoverState;
  }

  constructor(
    public activity: GalleryXRActivity,
    public strip: ArtworkStrip
  ) {

    this.xrmodule = activity.xrmodule;

    this.pointer = ((this.strip.interactions.inputs as unknown) as XRPointer);
    this.strip.interactions.pointer.active = true;

    for (let i = 0; i < PICKABLE_POOL_COUNT; i++) {
      this._pickablePool.push(new PickableArtworkState(this.strip.interactions));
    }

    this._drag = new GalleryDrag(this.strip.interactions, activity.xrmodule);

  }

  enable() {

    this._enabled = true;

    this.xrmodule.raycaster.enable();
    this.xrmodule.raycaster.udpdatePlane(vec3.fromValues(0.0, 0.0, -4.0), vec3.fromValues(0.0, 0.0, 1.0));
    this._drag.start();

  }

  disable() {

    this._enabled = false;
    this._pickablePool.map((pas) => pas.releaseState());
    this.xrmodule.raycaster.disable();
    this.xrmodule.raycaster.clear();
    this._drag.stop();

  }

  update() {

    if (!this._enabled) {
      return;
    }

    this._currentHoverState = null;
    this.xrmodule.raycaster.clear();
    this._pickablePool.map((pas) => {
      pas.releaseState();
      this.xrmodule.raycaster.remove(pas);
    });

    for (let i = 0; i < this.strip.visibleStates.length; i++) {
      this._pickablePool[i].assignState(this.strip.visibleStates[i]);
      this.xrmodule.raycaster.add(this._pickablePool[i]);
    }

    for (let i = 0; i < this._pickablePool.length; i++) {
      if (this._pickablePool[i].Assigned && this._pickablePool[i].isHover()) {
        this._currentHoverState = this._pickablePool[i].State;
      }
    }

    this._drag.update(this.activity.scene.dt);

  }

}
