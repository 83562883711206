import Signal from "@/core/Signal";
import AppService from "@/store/states/AppService";
import { ArtworkId, artworkIsMainPainting } from "@/store/states/Artworks";
import RaycastLineRenderer from "@/webgl/entities/xr-module/lineRenderer";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { clamp01 } from "@/webgl/math";
import { createFrameRaycastResult } from "@/webgl/math/raycastFrame";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { XRHandedness } from "webxr";
import MuseumXRActivity from ".";

const TPOS = vec3.create();
const MAX_PICKINGDIST = 15

export default class ArtworkPicking {

  _hovered: Set<ArtworkId> = new Set();

  onArtworkClicked = new Signal<ArtworkId>()

  leftRaycastResult = createFrameRaycastResult()
  rightRaycastResult = createFrameRaycastResult()
  leftLineDisplay = 0
  rightLineDisplay = 0

  get leftHit() {
    return this.leftRaycastResult.inBounds
  }

  get rightHit() {
    return this.rightRaycastResult.inBounds
  }
  
  get xrmodule() :XRModule {
    return this.activity.scene.xrmodule;
  }

  constructor(private activity: MuseumXRActivity) {
  }

  start() {
    this.xrmodule.gamepads.inputs.onBtnDown.on(this.btnDown)
  }

  stop() {
    this.xrmodule.gamepads.inputs.onBtnDown.off(this.btnDown)

    this.xrmodule.tooltips.pop("Right/Trigger", "xr.dive_in");
    this.xrmodule.tooltips.pop("Left/Trigger", "xr.dive_in");
  }

  btnDown = (evt: ButtonEvent) => {

    switch (evt.name) {
      case "Left/Trigger":
        this.handleTrigger('left')
        break
      case "Right/Trigger":
        this.handleTrigger('right')
        break
    }
  }

  handleTrigger(hand: XRHandedness) {
    
    let ray = this.xrmodule.gamepads.list.get(hand).ray;
    let artwork = this.activity.scene.museumScene.raycastArtworks( ray , undefined, MAX_PICKINGDIST);
    if( artwork === null ) return;

    if( artworkIsMainPainting( artwork ) ){
      AppService.send('GOTO_PAINTING')
    } else {
      this.onArtworkClicked.emit(artwork)
    }
  }


  preRender() {
    const dt = this.activity.scene.dt
    const previouslyHovered = Array.from(this._hovered);
    this._hovered.clear();

    const xrmodule = this.xrmodule
    const museumScene = this.activity.scene.museumScene


    // left
    const leftHasHit = this.leftHit
    let ray = xrmodule.gamepads.list.get('left').ray;
    let artwork = museumScene.raycastArtworks(ray, this.leftRaycastResult, MAX_PICKINGDIST);
    if (artwork) {
      museumScene.artworks.get(artwork).showHover(true);
      this._hovered.add(artwork)
      this.leftLineDisplay += dt / .2
    } else {
      this.leftLineDisplay -= dt / .2
    }
    
    if( this.leftHit !== leftHasHit ){
      xrmodule.gamepads.inputs.getInput('left').gamepad.pulse(this.leftHit ?.5:.2, .05)
      // if( this.leftHit && artworkIsMainPainting(artwork) ){
      //   this.xrmodule.tooltips.push("Left/Trigger", "xr.dive_in");
      // }else{
      //   this.xrmodule.tooltips.pop("Left/Trigger", "xr.dive_in");
      // }
    }
    
    
    // right
    const rightHasHit = this.rightHit
    ray = xrmodule.gamepads.list.get('right').ray;
    artwork = museumScene.raycastArtworks(ray, this.rightRaycastResult, MAX_PICKINGDIST);

    if (artwork) {
      museumScene.artworks.get(artwork).showHover(true);
      if( !previouslyHovered.includes(artwork) ) {
        xrmodule.gamepads.inputs.getInput('right').gamepad.pulse(.5, .01)
      }
      this._hovered.add(artwork)
      this.rightLineDisplay += dt / .2
    } else {
      this.rightLineDisplay -= dt / .2
    }
    if( this.rightHit !== rightHasHit ){
      xrmodule.gamepads.inputs.getInput('right').gamepad.pulse(this.rightHit ?.5:.2, .05)
      if( this.rightHit &&  artworkIsMainPainting(artwork) ){
        this.xrmodule.tooltips.push("Right/Trigger", "xr.dive_in");
      }else{
        this.xrmodule.tooltips.pop("Right/Trigger", "xr.dive_in");
      }
    }


    // remove hover from previously hovered

    for (const id of previouslyHovered) {
      if (!this._hovered.has(id)) {
        museumScene.artworks.get(id).showHover(false);
      }
    }


    this.leftLineDisplay = clamp01(this.leftLineDisplay);
    this.rightLineDisplay = clamp01(this.rightLineDisplay);

  }

  render(camera: Camera) {
    const xrm = this.xrmodule
    const lr = xrm.raycastLineRenderer
    const tr = xrm.raycaster.target

    if (this.leftLineDisplay > 0 || this.rightLineDisplay > 0) {

      // LINES

      lr.prepare(camera)

      if (this.leftLineDisplay > 0) {
        const dist = vec3.distance(xrm.gamepads.list.get('left').skin._wposition as vec3, this.leftRaycastResult.worldPos)
        lr.draw('left', this.leftLineDisplay, Math.min(2, dist));
      }


      if (this.rightLineDisplay > 0) {
        const dist = vec3.distance(xrm.gamepads.list.get('right').skin._wposition as vec3, this.rightRaycastResult.worldPos)
        lr.draw('right', this.rightLineDisplay, Math.min(2, dist));
      }

      // DOTS
    }


    if( this.leftHit || this.rightHit ){
      tr.prepare()

      if (this.leftHit) {
        vec3.scaleAndAdd(TPOS, this.leftRaycastResult.worldPos, this.leftRaycastResult.worldNrm, -.03)
        tr.renderImmediate(camera, TPOS, this.leftRaycastResult.worldNrm);
      }

      if (this.rightHit) {
        vec3.scaleAndAdd(TPOS, this.rightRaycastResult.worldPos, this.rightRaycastResult.worldNrm, -.03)
        tr.renderImmediate(camera, TPOS, this.rightRaycastResult.worldNrm);
      }
    }

  }

}