import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import Paths from "@/core/Paths";
import GLTFResource from "@/webgl/assets/GLTFResource";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import PaintingScene from "@/webgl/scenes/PaintingScene";
import { vec3 } from "gl-matrix";
import { Howl } from "howler";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"


const SPRITE_MAPING = {
  // 1/ Le chien seul
  "dog": ["dog_1", "dog_2"],
  // 2/ le garde devant l’œuvre +le monsieur canne / lunettes / haut de forme
  "uhlan_cantian": ["uhlan_cantian_1", "uhlan_cantian_2"],
  // 3/ les deux enfants (avec celui qui montre du doigt)
  "kids_looking_bowl": ["kids_looking_bowl_1", "kids_looking_bowl_2"],
  // 4/ les femmes robe verte + jaune
  "women_yellow_green": ["women_yellow_green_1", "women_yellow_green_2"],
  // 5/ le soldat au bout du chemin qui mène à la fontaine
  "uhlan_solo": ["uhlan_solo_1", "uhlan_solo_2"],
  // 6/ le deuxième groupe d’enfants (avec le bâton)
  "kids_walking": ["kids_walking_1", "kids_walking_2"],
  // 7/ le perso veste verte chapeau haut de forme + le perso âgé avec sa canne au sol
  "man_oldman": ["man_oldman_1", "man_oldman_2"],
}


class AudioPool {

  _playDelay: number = 0;
  _time: number = 0;

  constructor(
    public readonly audio: Howl,
    public audioList: string[],
    public position: vec3,

  ) {

    this.updateDelay();

  }

  updateDelay() {
    this._time = 0;
    this._playDelay = 7.0 + Math.random() * 5.0;
  }

  update(dt: number) {

    this._time += dt;
    if (this._time >= this._playDelay)
      this.trigger();

  }

  trigger() {
    this.updateDelay();

    const idx = Math.floor(Math.random() * this.audioList.length);
    let id = this.audio.play(this.audioList[idx]);

    this.audio.once('play', () => {
      // Set the position of the speaker in 3D space.
      this.audio.pos(this.position[0], this.position[1], this.position[2], id);

      // Tweak the attributes to get the desired effect.
      this.audio.pannerAttr({
        panningModel: 'HRTF',
        refDistance: 0.8,
        rolloffFactor: 1.8,
        distanceModel: 'exponential'
      }, id);
      this.audio.volume(1.0, id);

    }, id);

  }

}


export default class PaintingAudio {

  gltf: Gltf;
  audioFx: any;
  audioPools: AudioPool[] = [];

  private _playing: boolean = false;
  private _loadPromise: Promise<void> = null;

  constructor(
    public readonly pscene: PaintingScene
  ) {

    this.audioFx = AudioManager.getAudio(AUDIO_ID.PAINTING_FX);

  }

  setup() {

    this.audioPools.length = 0;
    const node = this.gltf.getElementByName(GltfTypes.NODE, "audio_positions");

    for (const srcNode of node._children as GltfNode[]) {
      this.addPool(SPRITE_MAPING[srcNode.name], srcNode.position);
    }

  }

  loadPositions() {
    if (this._loadPromise === null) {
      this._loadPromise = this._doLoad()
    }
    return this._loadPromise
  }

  private async _doLoad() {
    const res = new GLTFResource(Paths.resolve("assets/webgl/gltfs/audio_positions/audio_positions.gltf"), this.pscene.scene);
    this.gltf = await res.load();
    this.setup();
  }

  addPool(list: string[], pos: vec3) {
    this.audioPools.push(
      new AudioPool(this.audioFx, list, pos)
    )
  }

  start() {
    this._playing = true;
  }

  stop() {
    this._playing = false;
  }

  update(dt: number) {

    if (!this._playing)
      return;

    for (const audiopool of this.audioPools) {
      audiopool.update(dt);
    }

  }


}