import { MuseumStore } from "@/store/modules/MuseumStore";
import { ArtworkId, MainArtworkId } from "@/store/states/Artworks";
import { InputTouch } from "@/webgl/lib/inputs";
import { mix } from "@/webgl/math";
import { mat4Yaxis, mat4Zaxis } from "@/webgl/math/mat4-axis";
import mat4lerp from "@/webgl/math/mat4-lerp";
import mat4LookAt from "@/webgl/math/mat4-lookat";
import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import MuseumActivity from ".";
import { getArtworkPov, Pov } from "./ArtworkPov";
import Ola from 'ola'
import Signal from "@/webgl/lib/signal";
import CameraControler from "@/webgl/camera/CameraController";
import { CreatePane } from "@/dev/Tweak";
import { getPathRange, PathRanges } from "./PathRanges";
import AppService from "@/store/states/AppService";
import Delay from "@/core/Delay";
import Deferred from "@/core/Deferred";
// import { CreatePane } from "@/dev/Tweak";


const LOOKAT_ATTENUATION_SPEED = 30



function loopProgress(n:number ) {
  n=n%1
  if( n<0) n+=1
  return n
}

const V4A   = vec4.create()
const DELTA = vec2.create()
const Q_ID  = quat.create()
const Q_1   = quat.create()
const Q_2   = quat.create()
const M0    = mat4.create()
const M1    = mat4.create()


/**
 *  Split matrix in leveled one and remainder
 */
const _elV0 = vec3.create()
const _elDIR = vec3.create()
const _elUP = vec3.create()
const _elINV = mat4.create()
function extractLeveledMatrix( m:mat4, leveled:mat4, remainder:mat4 ){
  mat4Zaxis( _elDIR, m )
  mat4Yaxis( _elUP, m )
  _elDIR[1]=0
  _elDIR[0] *=-1
  _elDIR[2] *=-1
  mat4LookAt( leveled, _elV0, _elDIR, _elUP )
  leveled[12] = m[12]
  leveled[13] = m[13]
  leveled[14] = m[14]
  mat4.invert( _elINV, leveled )
  mat4.multiply( remainder, _elINV, m )
}



class LookAtController {

  currentTouch: InputTouch | null = null;

  rotations = vec2.create()
  lastFrameRotation = vec2.create()
  rotationsVelocity = vec2.create()
  startRotations = vec2.create()

  private _rotationMatrix = mat4.create()

  constructor( private camctrl: MuseumCameraController ){}

  start(){
    this.camctrl.activity.scene.inputs.onTouchAdded.on( this.onTouchDown )
    this.camctrl.dragCtrl.startDrag.on( this.onDragStart )
  }
  
  
  stop(){
    this.currentTouch = null
    this.camctrl.activity.scene.inputs.onTouchAdded.off( this.onTouchDown )
    this.camctrl.dragCtrl.startDrag.off( this.onDragStart )
  }


  _storeVelocity(dt:number){

    vec2.sub( DELTA, this.rotations, this.lastFrameRotation )
    if( vec2.length(DELTA) > 0 )
      vec2.scale( this.rotationsVelocity, DELTA, 1/dt )
  }


  update( dt: number ){


    this.lastFrameRotation.set(this.rotations)

    if( this.currentTouch ){
      this.currentTouch.getDelta( DELTA )

      const dx = DELTA[0] * this.camctrl.activity.camera.lens._hfov * .5
      const dy = DELTA[1] * this.camctrl.activity.camera.lens._vfov * .5
      
      this.rotations[0] = this.startRotations[0] + dx
      this.rotations[1] = this.startRotations[1] + dy
      this._storeVelocity(dt)
    }
    
    else if( this.camctrl.dragCtrl.mode === DragMode.HORIZONTAL ){
      
      const dx = this.camctrl.dragCtrl.delta * this.camctrl.activity.camera.lens._hfov * .5
      this.rotations[0] = this.startRotations[0] + dx
      this._storeVelocity(dt)
    }
    else {
      vec2.scale(this.rotationsVelocity, this.rotationsVelocity, .9 )
      vec2.scaleAndAdd( this.rotations, this.rotations, this.rotationsVelocity, dt )
    }

    
  }

  attenuateRotations( scale: number ){
    this.rotations[0] *= scale
    this.rotations[1] *= scale
  }

  reset(){
    this.attenuateRotations( 0 )
  }

  onTouchDown = (touch: InputTouch)=>{
    if( touch.nativeTouch.identifier.toString() !== 'mouse0') return
    
    this.startRotations.set( this.rotations)
    this.currentTouch = touch
    this.currentTouch.onEnd.on( this.onTouchUp )
  }
  
  onTouchUp = (touch: InputTouch)=>{
    this.currentTouch = null
  }


  onDragStart = (mode:DragMode)=>{
    this.startRotations.set( this.rotations)
  }


  getRotationMatrix():mat4{

    quat.rotateY( Q_1, Q_ID, this.rotations[0] )
    quat.rotateX( Q_2, Q_ID, -this.rotations[1] )
    quat.multiply( Q_1, Q_1, Q_2 )
    mat4.fromQuat( this._rotationMatrix, Q_1 )
    return this._rotationMatrix
  }

}



enum DragMode {
  IDLE,
  HORIZONTAL,
  VERTICAL
}



const DRAG_DETECT_THRESHOLD = .05

export class DragController{

  private _mode = DragMode.IDLE;
  
  public get mode() {
    return this._mode;
  }


  private currentTouch: InputTouch | null = null;

  startDrag : Signal<DragMode> = new Signal()
  stopDrag : Signal = new Signal()

  get delta(){
    if( this.currentTouch ){
      this.currentTouch.getDelta( DELTA ) 
      return this._mode === DragMode.HORIZONTAL ? DELTA[0] : DELTA[1]
    }
    return 0
  }

  constructor( private activity: MuseumActivity ){

  }


  start(){
    this.activity.scene.inputs.onTouchAdded.on( this.onTouchDown )
  }
  
  
  stop(){
    this.currentTouch = null
    this.activity.scene.inputs.onTouchAdded.off( this.onTouchDown )
    this.activity.scene.inputs.onTouchMove.off( this.onTouchMove )
    this._stopDrag()
  }
  

  
  onTouchDown = (touch: InputTouch)=>{
    if( touch.nativeTouch.identifier.toString() === 'mouse0' ) return

    this.currentTouch = touch
    this.currentTouch.onEnd.on( this.onTouchUp )
    this.activity.scene.inputs.onTouchMove.on( this.onTouchMove )
  }
  
  onTouchMove = ()=>{

    if( this._mode === DragMode.IDLE ){
      this.currentTouch.getDelta( DELTA )
      DELTA[1] /= window.innerWidth / window.innerHeight
      const length = vec2.length( DELTA )
      if( length > DRAG_DETECT_THRESHOLD ){
        const dx = Math.abs( DELTA[0] )
        const dy = Math.abs( DELTA[1] )
        this._mode = dx > dy ? DragMode.HORIZONTAL : DragMode.VERTICAL
        this.startDrag.emit( this._mode )
      }
    }

  }
  
  onTouchUp = (touch: InputTouch)=>{
    this._mode = DragMode.IDLE
    this.currentTouch = null
    this.activity.scene.inputs.onTouchMove.off( this.onTouchMove )
    this._stopDrag()
  }

  _stopDrag(){
    
    if( this._mode !== DragMode.IDLE ){
      this.stopDrag.emit()
    }
    this._mode = DragMode.IDLE
  }

}



function normalizedPathProgress():number {
  let p = MuseumStore.pathProgress % 1
  if( p < 0 ) p += 1
  return p
}


export class MuseumCameraController {


  
  smoothScroll:any
  absScroll = 0
  deltaScroll = 0
  startDragScroll = 0
  lastFrameScroll = 0

  smoothProgress = 0;
  camera: Camera<PerspectiveLens>;
  lookat: LookAtController;
  dragCtrl: DragController;

  artworkPovProgress = 0;
  diveinPovProgress = 0;

  private _currentArtworkId: ArtworkId = null
  private _nearbyArtworkId: ArtworkId = null

  _artworkPov : Pov = {
    matrix: mat4.create(),
    fov: 0
  }



  get pathFov(){
    return (this.activity.scene.aspect < 1 ) ? .9 : .75
  }


  setCurrentArtwork( artwork:ArtworkId|null ){
    if( artwork === this._currentArtworkId ) return
    if( artwork ){
      // enter artwork
      gsap.to( this, {artworkPovProgress:1, duration:1.0, ease:"power2.inOut"} )
    } else {
      // set pathProgress to mid 
      MuseumStore.pathProgress = 
      this.smoothProgress = getPathRange(this._currentArtworkId).mid
      this.lookat.reset()

      gsap.to( this, {artworkPovProgress:0, duration:1.0, ease:"power2.inOut"} )
    }
    this._currentArtworkId = artwork
    
  }


  setNearbyArtwork( artwork:ArtworkId|null ){
    if( this._nearbyArtworkId === artwork ) return
    this._nearbyArtworkId = artwork
    
    AppService.send({type:'NEARBY_ARTWORK', artworkId: artwork})
  }

  
  constructor( readonly activity: MuseumActivity ){
    
    this.smoothProgress = MuseumStore.pathProgress
    this.smoothScroll = Ola({value:0}, 1000)
    
    this.camera = Camera.makePerspectiveCamera()
    this.camera.lens.setAutoFov( .8 )
    this.camera.lens.near = .1
    this.camera.lens.far = 90
    this.camera.lookAt(vec3.fromValues(0, 0, 0));
    
    this.activity.root.add( this.camera )

    this.dragCtrl = new DragController( activity )
    this.lookat = new LookAtController( this )
    
/////////////////
////////////////////////////////////////////
//////////////////////////////////////////
////////////////////
/////////////////
/////////////////
///////
////
////////////////////////////////////////
////////////////////
///////////////
///////////////
///////
//////////////
    
  }
  
  start(){
    this.diveinPovProgress = 0
    this.activity.scene.ilayer.addEventListener( "wheel", this.onMouseWheel )
    this.lookat.start()
    this.dragCtrl.start()
    this.dragCtrl.startDrag.on(this.onStartDrag)
  }
  
  stop(){
    this.activity.scene.ilayer.removeEventListener( "wheel", this.onMouseWheel )
    this.lookat.stop()
    this.dragCtrl.stop()
    this.dragCtrl.startDrag.off(this.onStartDrag)
  }
  
  updateArtworkPov(){
    if( this._currentArtworkId){
      const artwork = this.activity.scene.museumScene.artworks.get( this._currentArtworkId )
      getArtworkPov(this._artworkPov, artwork.frame, this.activity.scene, this.activity.camera )
    }
  }

  preRender() {
    
    // scroll mobile
    if( this.dragCtrl.mode === DragMode.VERTICAL ){
      this.absScroll = this.startDragScroll + this.dragCtrl.delta * -.08
      this.smoothScroll.set( {value:this.absScroll}, 10)
    }
    

    // scroll wheel
    //
    this.deltaScroll =  this.smoothScroll.value - this.lastFrameScroll
    this.lastFrameScroll = this.smoothScroll.value
    MuseumStore.pathProgress += this.deltaScroll
    
    
    
    // path progress
    //
    this.smoothProgress += ( MuseumStore.pathProgress - this.smoothProgress ) * Math.min( 1, 4 * this.activity.scene.dt )
    const m = this.camera._matrix
    
    // resolve nearby Artwork
    //
    this.setNearbyArtwork( this.resolveNearbyArtwork() )
    
    // path camera transform
    //
    this.activity.scene.museumScene.cameraPath.getTransformAt( m, loopProgress(this.smoothProgress))
    
    // extract leveled matrix, and remainder
    extractLeveledMatrix( m, M0, M1 )


    // attenuateRotations
    const attenuation = Math.max( 0, 1.0 - LOOKAT_ATTENUATION_SPEED*Math.abs(this.deltaScroll) )
    this.lookat.attenuateRotations( attenuation )

    // update free lookat and apply to laveled matrix
    this.lookat.update( this.activity.scene.dt)
    mat4.multiply( m, M0, this.lookat.getRotationMatrix() )

    // add remainder on top of that
    mat4.multiply( m, m, M1 )



    // artwork focused transition
    if( this.artworkPovProgress > 0 ){

      this.updateArtworkPov()
      
      mat4lerp( m, m, this._artworkPov.matrix, this.artworkPovProgress )
      const finalFov = mix( this.pathFov, this._artworkPov.fov, this.artworkPovProgress )
      // this.camera.lens.setVerticalFov( finalFov )
    }

    
    if( this.diveinPovProgress > 0 ){
      const artwork = this.activity.scene.museumScene.artworks.get( MainArtworkId )
      getArtworkPov(this._artworkPov, artwork.frame, this.activity.scene, this.activity.camera, true, .8 )
      mat4lerp( m, m, this._artworkPov.matrix, this.diveinPovProgress )
    }

    // this.camera.lens.setVerticalFov( fov )

    this.camera.setMatrix( m )
    
  }

  onMouseWheel = (e:any)=>{
    const me = e as WheelEvent
    const scroll = me.deltaY / 15000
    this.absScroll += scroll
    this.smoothScroll.set( {value:this.absScroll} )
  }


  onStartDrag = ()=>{
    this.startDragScroll = this.absScroll
  }


  resolveNearbyArtwork(){
    const progress = normalizedPathProgress()
    
    for (const p of PathRanges) {
      if( progress > p.start && progress < p.end ){
        if( this.artworkInView(p.id))
          return p.id
        else {
          return null
        }
      }
    } 
    return null
  }


  artworkInView( artworkId:ArtworkId ):boolean {
    const artwork = this.activity.scene.museumScene.artworks.get(artworkId)
    V4A.set( artwork.frame._wposition )
    V4A[3] = 1
    vec4.transformMat4( V4A, V4A, this.camera._viewProj )
    
    V4A[0] /= V4A[3]
    V4A[1] /= V4A[3]
    
    return !( V4A[3] < 0 || Math.abs(V4A[0]) > 1 || Math.abs(V4A[1]) > 1 )
  }


  canGoToMainPainting():boolean {
    const mainRange = getPathRange( MainArtworkId)
    return Math.abs( MuseumStore.pathProgress - mainRange.mid ) < .1
  }

  goToMainPainting(duration = 3):Promise<void> {
    const defer = new Deferred<void>()
    
    gsap.to( this, {diveinPovProgress:1, duration, ease:"power2.inOut", onComplete:defer.resolve} )
    return defer.promise
  }


}



if( module.hot ){
  module.hot.accept( ['./ArtworkPov'], ()=>{
  })
}