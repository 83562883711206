import Node from "nanogl-node";
import { mat4, quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import mat4unscale from "@/webgl/math/mat4-unscale";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Scene from "@/webgl/Scene";
import GLApp from "@/webgl/main";

const FRAME_EXTENTS = 1.6


// CSS datas
const CSS_MAX_WIDTH = 30*16
const CSS_PADDING = 5*16


const V3     : vec3 = vec3.create();
const INV_W  : mat4 = mat4.create();
const M4A    : mat4 = mat4.create();
const M4B    : mat4 = mat4.create();

const ZERO = vec3.create()
const Q1 = quat.create()
const Q_ID = quat.create()

const NORMALIZE_FRAME_QUAT = quat.create()
quat.rotateY( NORMALIZE_FRAME_QUAT, NORMALIZE_FRAME_QUAT, Math.PI );



type Space = {
  w:number, h:number
}


function getAvailableSpace():Space{
  const w = 1.0 - (CSS_MAX_WIDTH + CSS_PADDING ) / window.innerWidth
  const h = .4
  return {w, h}
}

const FULL_SPACE = {w:1, h:1}

export type Pov = {
  matrix:mat4,
  fov:number
}

export function getArtworkPov( out:Pov,  frame:Node, scene:Scene, camera: Camera<PerspectiveLens> , full=false, extent = FRAME_EXTENTS):void {

  const space = full? FULL_SPACE : getAvailableSpace()
  const fov = getCameraOffset( V3, Q1, camera, frame, space, extent )
  quat.multiply( Q1, NORMALIZE_FRAME_QUAT, Q1)
  // quat.copy( Q1, NORMALIZE_FRAME_QUAT )
  mat4.fromRotationTranslation( M4A, Q1, V3 )
  
  quat.rotateY( Q1, Q_ID, 0 )//this.povs.paralax[0]*this.paralaxStrength )
  quat.rotateX( Q1, Q1, 0 )//this.povs.paralax[1]*this.paralaxStrength )

  mat4.fromRotationTranslation( M4B, Q1, ZERO )
  
  mat4.multiply( M4A, M4B, M4A );
  
  mat4unscale( M4B, frame._wmatrix )
  mat4.multiply( out.matrix, M4B, M4A );

  
  const p = camera._parent
  if( p ){
    mat4.invert( M4A, p._wmatrix );
    mat4.multiply( out.matrix, M4A, out.matrix );
  }

}




export function getCameraOffset( out:vec3, rot: quat, camera : Camera<PerspectiveLens>, frame: Node, space: Space, extent= FRAME_EXTENTS ) : number {

  const frameW = (frame.scale[0]+.21)*extent
  const frameH = (frame.scale[1]+.21)*extent

  let vpBaseSizeW:number
  let vpBaseSizeH:number

  const frameAspect = frameW/frameH;
  const viewportAspect = camera.lens.aspect
  
  const destopMode = viewportAspect > 1

  quat.identity( rot )

  out[0] = 0
  out[1] = 0

  let availablePart = .5
  
  
  if( destopMode ){
    availablePart = space.w
    
    // fit artwork on the half left side of the screen

    if( frameAspect < viewportAspect*availablePart ){
      
      // vp wider then frame, fit height
      vpBaseSizeH =  frameH
      vpBaseSizeW =  vpBaseSizeH * viewportAspect
      
      // out[2] = -.5*frameH / Math.tan( camera.lens._vfov/2 );

      out[0] = (1.0-availablePart)*vpBaseSizeW * .5
      out[1] = 0
      out[2] = -.5*vpBaseSizeH / Math.tan( camera.lens._vfov/2 );
      

    } else {
      // vp higher then frame, fit width
      vpBaseSizeW =  frameW/availablePart
      vpBaseSizeH =  vpBaseSizeW / viewportAspect;
      // out[2] = -frameW / Math.tan( camera.lens._hfov/2 );

      out[0] = (1.0-availablePart)*vpBaseSizeW * .5
      out[1] = 0//(vpBaseSizeH - frameH ) /2
      out[2] = -.5*vpBaseSizeW / Math.tan( camera.lens._hfov/2 );
    }
    
    const ry = camera.lens._hfov /4
    // quat.rotateY( rot, rot, ry )
    
    
    
  } else {
    availablePart = .5//1.0 - (GLApp.getInstance().scene.inputs.mouseCoords[1] * .5 + .5)
    
    if( frameAspect < viewportAspect/availablePart ){
      
      // vp wider then frame, fit height
      vpBaseSizeH =  frameH/availablePart
      vpBaseSizeW =  vpBaseSizeH * viewportAspect
      
      // out[2] = -.5*frameH / Math.tan( camera.lens._vfov/2 );

      out[0] = 0
      out[1] = -(1.0-availablePart)*vpBaseSizeH * .5
      out[2] = -.5*vpBaseSizeH / Math.tan( camera.lens._vfov/2 );
      

    } else {
      // vp higher then frame, fit width
      vpBaseSizeW =  frameW
      vpBaseSizeH =  vpBaseSizeW / viewportAspect;
      // out[2] = -frameW / Math.tan( camera.lens._hfov/2 );

      out[0] = 0
      out[1] = -(1.0-availablePart)*vpBaseSizeH * .5
      out[2] = -.5*vpBaseSizeW / Math.tan( camera.lens._hfov/2 );
    }

    // limit the distance camera is lowered down to avoid going underground
    const glimit = 1.0
    if( out[1] < -glimit ){
      const remaing = out[1]+glimit
      out[1] = -glimit

      const t = Math.tan( camera.lens._vfov/2 ) * out[2]
      const pa = Math.atan( (t-remaing)/out[2] )
      const rx = camera.lens._vfov/2 - pa
      quat.rotateX( rot, rot, -rx )
    }

  }

  return -1
}
