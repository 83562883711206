import Paths from "@/core/Paths";
import GLTFResource from "@/webgl/assets/GLTFResource";
import Masks from "@/webgl/gl/Masks";
import GradientBorder from "@/webgl/entities/UIKit/gradient-border/GradientBorder";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Node from "nanogl-node";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import Camera from "nanogl-camera";
import { Sampler } from "nanogl-pbr/Input";
import UnlitPass from "nanogl-pbr/UnlitPass";
import { IRenderingContext } from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Texture2D from "nanogl/texture-2d";

export default class GradientPrimitives {

  gltf: Gltf;
  gradient: GradientBorder;
  time: number = 0;
  gradientTexture: Texture2D;

  rect: MeshRenderer;
  btn: MeshRenderer;

  constructor(
    public context: IRenderingContext
  ) {
    this.gradient = new GradientBorder();
  }

  async load() {
    const res = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/gradient_primitives/gradient_primitives.gltf"),
      this.context
    )
    this.gltf = await res.load();
    this.setup();
  }

  setup = () => {

    for (const material of this.gltf.materials as UnlitMaterial[]) {
      const rectpass = material.materialPass as UnlitPass;
      rectpass.inputs.add(this.gradient);

      const tex = (rectpass.baseColor.param as Sampler)._tex;
      tex.bind();
      tex.repeat();
      rectpass.baseColor.detach();
      rectpass.alpha.detach();
      const s = rectpass.baseColor.attachSampler("baseColor", this.gradient.texcoord);
      s.set(tex);

    }

    this.btn = this.gltf.getElementByName(GltfTypes.NODE, "rounded_btn").renderable;
    this.rect = this.gltf.getElementByName(GltfTypes.NODE, "rounded_rect").renderable;
    this.gradientTexture = this.gltf.getElementByName(GltfTypes.TEXTURE, "gradient_purple").glTexture;

  }

  preRender(dt: number) {
    this.time += dt;
    this.gradient.update(this.time);
  }

  renderRect(camera: Camera, node: Node, width: number, height: number, offset: number, color: ArrayLike<number>) {
    this.rect.node = node as GltfNode;
    this.gradient.size[0] = width;// + 0.01;
    this.gradient.size[1] = height;// + 0.01;
    this.gradient.size[2] = offset;

    this.gradient.color[0] = color[0];
    this.gradient.color[1] = color[1];
    this.gradient.color[2] = color[2];

    this.rect.render(
      this.context,
      camera,
      Masks.OPAQUE,
      Passes.DEFAULT
    )
  }

  renderBtn(camera: Camera, node: Node, width: number, height: number, offset: number, color: ArrayLike<number>) {
    this.btn.node = node as GltfNode;
    this.gradient.size[0] = width;// + 0.01;
    this.gradient.size[1] = height;// + 0.01;
    this.gradient.size[2] = offset;

    this.gradient.color[0] = color[0];
    this.gradient.color[1] = color[1];
    this.gradient.color[2] = color[2];

    this.btn.render(
      this.context,
      camera,
      Masks.OPAQUE,
      Passes.DEFAULT
    )
  }

}