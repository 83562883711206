
import AudioManager from '@/core/audio/AudioManager';
import { defineComponent } from 'vue'

export default defineComponent({
  methods:{
    onMouseEnter(){
      AudioManager.playUI("ui_rollover_long");
    },
    onClick(){
      AudioManager.playUI("ui_click");
    }
  }
})
