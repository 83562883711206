import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import nanoglCamera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import Node from "nanogl-node";
import Rect from "nanogl-primitives-2d/rect";
import Program from "nanogl/program";
import frame_vert from "./xrframe.vert";
import frame_frag from "./xrframe.frag";
import Texture2D from "nanogl/texture-2d";
import { LocalConfig } from "nanogl-state";
import XRSnapshot from "@/webgl/activities/PaintingXRActivity/xr-snapshot/XRSnapshot";
import { PhotoFormat } from "@/store/states/AppStateMachine";
import gsap from "gsap";

const ZERO = vec3.create();
const V3A = vec3.create();
const V3C = vec3.create();
const V3D = vec3.create();

const MODEL_VIEW = mat4.create();
const PROJECTION = mat4.create();
const V4MODEL = vec4.create();
const V4PERS = vec4.fromValues(0.0, 0.0, 0.0, 1.0);

const size = 0.15;
const snapdist = 0.5;

export default class XRSnapshotFrame {


  node: Node;
  quad: Rect;
  prg: Program;
  cfg: LocalConfig;
  cfgPreview: LocalConfig;
  wposition: vec3 = vec3.create();
  scale: vec2 = vec2.fromValues(1.0, 1.0);
  _scale: vec2 = vec2.create();
  _scaleX: number = 1.0;
  _scaleY: number = 1.0;
  _offsetY: number = 0.0;
  _scaleMode: vec4 = vec4.create();
  _landscape: number = 0;
  _square: number = 0;
  _portrait: number = 0;

  public processMode: number = 0;


  private _frameTex: Texture2D;

  get Rect() {
    return {
      x: -0.5 * this.scale[0] * size,
      y: -0.5 * this.scale[1] * size,
      width: this.scale[0] * size,
      height: this.scale[1] * size
    }
  }

  constructor(public snapshot: XRSnapshot) {

    const scene = this.snapshot.scene;

    this.node = new Node();

    this.quad = new Rect(scene.gl,
      -size * 0.5,
      -size * 0.5,
      size,
      size
    );

    this.prg = new Program(
      scene.gl,
      frame_vert(),
      frame_frag(),
      scene.programs.getGlobalDefinitions()
    )

    this.cfg = scene.glstate.config();
    this.cfg
      .enableBlend(true)
      .enableDepthTest(true)
      .blendFunc(scene.gl.ONE, scene.gl.ONE);

    this.cfgPreview = scene.glstate.config();
    this.cfgPreview
      .enableBlend(false)
      .enableDepthTest(true)
      .depthMask(true);

  }

  init() {
    this.snapshot.xrmodule.gamepads.list.get("right").skin.add(this.node);
    this.node.y = 0.135;
    this.node.z = -0.3 * size;
  }

  unload(){
    this.snapshot.xrmodule.gamepads.list.get("right").skin.remove(this.node);
  }

  setScale(scale: vec2, ss: PhotoFormat) {

    this.scale.set(scale);

    gsap.to(this, {
      _scaleX: this.scale[0],
      _scaleY: this.scale[1],
      _offsetY: (this.scale[1] - 1) * size * 0.5,
      _landscape: ss == "landscape" ? 1.0 : 0.0,
      _square: ss == "square" ? 1.0 : 0.0,
      _portrait: ss == "portrait" ? 1.0 : 0.0,
      duration: 0.25,
      onUpdate: () => {
        this.node.y = 0.135 + this._offsetY;
        this._scaleMode[0] = this._landscape;
        this._scaleMode[1] = this._square;
        this._scaleMode[2] = this._portrait;
      }
    });

  }

  setFrameTexture(tex: Texture2D) {
    this._frameTex = tex;
  }


  getFOV(campos: vec3): number {
    const top = vec3.create();
    top[1] = this.Rect.height / 2;
    vec3.transformMat4(top, top, this.node._wmatrix);

    V3A.set([0, 0, 0]);
    vec3.transformMat4(V3A, V3A, this.node._wmatrix);
    vec3.sub(V3C, top, campos);
    vec3.sub(V3D, V3A, top);

    return Math.atan(vec3.length(V3D) / vec3.length(V3C)) * 2.0;
  }

  getTarget(): vec3 {
    return this.wposition;
  }

  preRender() {
    vec3.transformMat4(this.wposition, ZERO, this.node._wmatrix);
    // this.active = vec3.dist(this.snapshot.camera._wposition as vec3, this.wposition) < snapdist;
  }

  render(camera: nanoglCamera<ICameraLens>) {

    this.prg.use();

    const cam = camera;
    mat4.copy(MODEL_VIEW, this.node._wmatrix);
    mat4.multiply(MODEL_VIEW, cam._view, MODEL_VIEW);
    vec4.transformMat4(V4MODEL, V4PERS, MODEL_VIEW);

    mat4.copy(PROJECTION, cam.lens.getProjection());

    this.prg.uV4Model(V4MODEL);
    this.prg.uProjection(PROJECTION);
    this.prg.tTex(this.snapshot.HasSnapshot ? this.snapshot.Texture : this._frameTex);

    const blinkPhase = -0.5 + (Math.sin( this.snapshot.scene.time * 12.0 ) + 1.0) * 0.5;
    this.prg.uProcessMode(blinkPhase * this.processMode * 0.4);

    this._scaleMode[3] = this.snapshot.HasSnapshot ? 0.0 : 1.0;
    this._scale[0] = this._scaleX;
    this._scale[1] = this._scaleY;
    this.prg.uScale(this._scale);
    this.prg.uScaleMode(this._scaleMode);

    this.quad.bind();
    this.quad.attribPointer(this.prg);

    this.snapshot.scene.glstate.push(this.snapshot.HasSnapshot ? this.cfgPreview : this.cfg);
    this.snapshot.scene.glstate.apply();
    this.quad.render();

    this.snapshot.scene.glstate.pop();

  }


}