import { vec2 } from "gl-matrix";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Node from "nanogl-node";
import Camera from "nanogl-camera";
import { LocalConfig } from "nanogl-state";
import { clamp01 } from "@/webgl/math";
import { ButtonEvent } from "@/webgl/xr/WebXRGamepad";
import { PostSettings, POST_SETTINGS } from "@/webgl/entities/post-process/PostSettingsController";
import XRSnapshot from "@/webgl/activities/PaintingXRActivity/xr-snapshot/XRSnapshot";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import Scene from "@/webgl/Scene";
import MaterialOverrideExtension from "@/webgl/lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import GradientLineMaterial from "@/webgl/glsl/gradient-line/GradientLineMaterial";
import GLTFResource from "@/webgl/assets/GLTFResource";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import Paths from "@/core/Paths";

import XRSettingsWheelButton from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheelButton";
import XRSettingsToggleButton from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsToggleButton";
import XRSettingsWheelSlider from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheelSlider";
import XRSettingsWheelTarget from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheelTarget";
import gsap from "gsap";
import UIText2 from "@/webgl/entities/UIKit/text/UIText2";
import i18n from '@/core/i18n'
import AppService from "@/store/states/AppService";
const { t } = i18n.global


const V2 = vec2.create();

export default class XRSettingsWheel {

  uilib: Gltf;
  wheelGltf: Gltf;
  node: Node;
  wheelRoot: Node;

  icon: Node;

  toggleBtn: XRSettingsToggleButton;
  buttons: XRSettingsWheelButton[];
  slider: XRSettingsWheelSlider;
  target: XRSettingsWheelTarget;

  cfg: LocalConfig;

  enabled: boolean = true;
  active: boolean = false;

  editMode: boolean = false;
  hoverIdx: number = -1;
  activeIdx: number = -1;
  locked: boolean = false;
  clickTime: number = 0;
  clickDelay: number = 0.1;
  resetTitle: UIText2;

  private _inReset: boolean = false;
  get InReset(): boolean {
    return this._inReset;
  }

  get scene(): Scene {
    return this.xrmodule.scene;
  }

  resources: ResourceGroup;
  private _loadPromise: Promise<void>;

  renderables: MeshRenderer[] = [];

  constructor(
    public readonly xrmodule: XRModule,
    public readonly snapshot: XRSnapshot,
    public readonly postsettings: Map<POST_SETTINGS, PostSettings>
  ) {

    this.resources = new ResourceGroup();

    this.node = new Node();

    this.toggleBtn = null;
    this.buttons = [];

    var materialOverride = new MaterialOverrideExtension();
    materialOverride.overrides = {
      "SliderLine": new GradientLineMaterial(this.scene.gl),
      "GradientCircle": new GradientLineMaterial(this.scene.gl)
    };

    const wheel = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/settings_wheel/settings_wheel.gltf"),
      this.scene,
      {
        extensions: [
          materialOverride
        ]
      }
    )
    this.resources.add(wheel, "settings-wheel");

    this.resetTitle = this.xrmodule.ui.textLib.createUIText();
    this.resetTitle.setStyle({
      fontSize: 0.015,
      center: true
    });
    this.resetTitle.setText(t('xr.reset'));
    this.resetTitle.node.y = 0.05;
    this.node.add(this.resetTitle.node);

    this.cfg = this.scene.glstate.config();
    this.cfg
      .enableCullface(false)
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.SRC_ALPHA, this.scene.gl.ONE_MINUS_SRC_ALPHA);

  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  private async _doLoad() {
    console.log("Loading settings wheel");

    await this.resources.load();
    console.log("Loaded settings wheel");
    this.init();
  }


  init() {

    this.xrmodule.gamepads.list.get("left").skin.add(this.node);
    const n = this.xrmodule.gamepads.list.get("left").skin.findChild("b_thumbstick");
    n.add(this.node);
    this.node.rotateY(Math.PI / 2);
    this.node.rotateX(Math.PI);
    this.node.x = -0.014;

    this.wheelGltf = this.resources.get("settings-wheel");
    const root = this.wheelRoot = this.wheelGltf.root._children[0] as GltfNode;

    this.node.add(root);

    this.toggleBtn = new XRSettingsToggleButton(
      this,
      root.findChild("stick-btn")
    )

    const settings = root.findChild("settings") as GltfNode;
    this.slider = new XRSettingsWheelSlider(this, settings.findChild("slider"));

    this.createButton(POST_SETTINGS.BRIGHTNESS, settings.findChild(POST_SETTINGS.BRIGHTNESS));
    this.createButton(POST_SETTINGS.CONTRAST, settings.findChild(POST_SETTINGS.CONTRAST));
    this.createButton(POST_SETTINGS.SATURATION, settings.findChild(POST_SETTINGS.SATURATION));
    this.createButton(POST_SETTINGS.TEMPERATURE, settings.findChild(POST_SETTINGS.TEMPERATURE));
    this.createButton(POST_SETTINGS.NONE, settings.findChild("close"));

    this.target = new XRSettingsWheelTarget(this, settings.findChild("settings_target_container"));


  }

  unload() {
    this.xrmodule.gamepads.list.get("left").skin.remove(this.node);
  }

  createButton(setting: POST_SETTINGS, node: GltfNode) {
    const btn = new XRSettingsWheelButton(
      this,
      setting,
      node
    )
    this.buttons.push(btn);
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  setActive(flag: boolean) {

    this.active = flag;
    if (flag) {
      this.toggleBtn.setActive();
      this.xrmodule.xrview.inputs.onBtnDown.on(this.onBtnDownActive);
    } else {
      this.leaveReset(true);
      this.xrmodule.xrview.inputs.onBtnDown.off(this.onBtnDownActive);
      this.toggleBtn.setIdle();
      this.editMode = false;
      this.slider.setActive(false);
      this.setHover(-1);
      this.activeIdx = -1;
      this.hoverIdx = -1;
    }

  }

  onBtnDownActive = (evt: ButtonEvent) => {
    if (this.locked)
      return;

    switch (evt.name) {
      case "Left/Squeeze":
        if (!this._inReset) {
          this.enterReset();
        } else {
          this.leaveReset();
        }
        break;
      default:
        break;
    }

    if (this._inReset) {
      switch (evt.name) {
        case "X":
          this.reset();
          this.leaveReset();
          return;
          break;
        case "Y":
          this.leaveReset();
          return;
          break;
        default:
          break;
      }
      return;
    }

    switch (evt.name) {
      case "X":
      case "Y":
        if (this.hoverIdx !== -1 && this.activeIdx === -1) {
          this.select();
          return;
        }
        if (this.activeIdx !== -1) {
          this.back();
          return;
        }
        break;
      default:
        break;

    }

  }

  enterReset() {
    this._inReset = true;
    this.xrmodule.tooltips.push("X", "xr.yes");
    this.xrmodule.tooltips.push("Y", "xr.no");
    this.xrmodule.tooltips.pop("Left/Trigger", "");
    this.xrmodule.tooltips.pop("Left/Thumbstick", "");
    gsap.killTweensOf(this.wheelRoot, "z,x")
    gsap.to(this.wheelRoot, { z: -0.05, y: -0.05, duration: 0.5 });
  }


  leaveReset(instant: boolean = false) {
    this._inReset = false;
    this.xrmodule.tooltips.pop("X", "xr.yes");
    this.xrmodule.tooltips.pop("Y", "xr.no");
    if (this.active) {
      this.xrmodule.tooltips.push("Left/Trigger", "");
      this.xrmodule.tooltips.push("Left/Thumbstick", "");
    }
    if (!instant) {
      gsap.killTweensOf(this.wheelRoot, "z,y")
      gsap.to(this.wheelRoot, { z: 0, y: 0, duration: 0.5 });
    } else {
      this.wheelRoot.z = 0;
      this.wheelRoot.y = 0;
    }
  }

  reset() {
    // this.slider.value = 0.5;
    this.slider.setValue(0.5);
    for (const settings of this.postsettings) {
      settings[1].setMix(0.5);
    }
  }

  select() {
    if (this.hoverIdx == -1 || !this.active)
      return;

    this.xrmodule.Inputs.getInput("left").gamepad.pulse(1.0, 1);
    this.activeIdx = this.hoverIdx;
    this.editMode = true;

    // SHOULD CLOSE
    if (this.buttons[this.activeIdx].id === POST_SETTINGS.NONE) {
      this.activeIdx = -1;
      this.editMode = false;
      if (AppService.state.context.paintingPhotoTutorialCompleted) {
        AppService.send("CANCEL");
      }
      return;
    }

    // HAS POST SETTINGS
    this.buttons[this.activeIdx].setActive();
    this.slider.setValue(this.postsettings.get(this.buttons[this.activeIdx].id as POST_SETTINGS).mix);
    this.slider.setActive(true);
  }

  back() {
    if (this.hoverIdx == -1)
      return;
    this.xrmodule.Inputs.getInput("left").gamepad.pulse(0.5, 1);
    this.buttons[this.hoverIdx].setHover();
    this.hoverIdx = this.hoverIdx;
    this.activeIdx = -1;
    this.editMode = false;
    this.slider.setActive(false);
  }

  setHover(idx: number) {
    if (this.hoverIdx !== -1) {
      this.buttons[this.hoverIdx].setIdle();
    }


    if (idx !== -1) {
      if (idx != this.hoverIdx)
        this.xrmodule.Inputs.getInput("left").gamepad.pulse(1.0, 1);
      this.hoverIdx = idx;
      this.buttons[this.hoverIdx].setHover();
    }
  }

  preRender() {

    if (!this.enabled)
      return


    const axes = this.scene.xrview.inputs.getAxes("left");
    V2.set(axes);
    const stickEdit = vec2.sqrLen(V2) > 0.5 && !this._inReset;
    if (stickEdit && !this.editMode && !this.locked && this.active) {

      let angle = Math.atan2(V2[0], -V2[1]);
      let tgt = -1;
      for (let i = 0; i < this.buttons.length; i++) {
        if (Math.abs(angle - this.buttons[i].angle) < 0.5) {
          tgt = i;
        }
      }
      this.setHover(tgt);
      this.target.setAngle(angle);

    }

    this.target.setShown(stickEdit);

    this.clickTime += this.scene.dt;

    if (this.editMode && axes != undefined && !this.locked && !this._inReset && this.active) {

      const settings = this.postsettings.get(this.buttons[this.activeIdx].id as POST_SETTINGS);

      this.slider.processInput(this.scene.dt);
      const mix = this.slider.value;

      if (settings.mix !== mix && !this.slider.InDeadZone) {
        if (this.clickTime > this.clickDelay) {
          this.xrmodule.Inputs.getInput("left").gamepad.pulse(0.4, 5);
          this.clickTime = 0;
        }
        settings.setMix(mix);
      }
      if (this.slider.InDeadZone) {
        settings.setMix(0.5);
      }

    }

    for (const btn of this.buttons) {
      if (btn.id !== POST_SETTINGS.NONE) {
        btn.setChanged(this.postsettings.get(btn.id).mix != 0.5);
      }
    }

    this.resetTitle.preRender();
    this.target.preRender(this.scene.dt);
    this.slider.preRender(this.scene.dt);
    this.toggleBtn.preRender(this.scene.dt);

  }

  collectRenderables(renderables: MeshRenderer[]) {
    renderables.length = 0;

    if (!this.enabled)
      return;

    renderables.push(...this.toggleBtn.Renderable);

    if (this.active) {
      renderables.push(...this.slider.Renderables);
      for (const button of this.buttons) {
        renderables.push(...button.Renderables);
      }

      if (!this.editMode)
        renderables.push(this.target.Renderable);
    }

  }

  render(camera: Camera) {

    this.collectRenderables(this.renderables);
    this.scene.glstate.push(this.cfg);
    this.scene.glstate.apply();

    for (const renderable of this.renderables) {
      renderable.render(
        this.scene,
        camera,
        Masks.SPRITE,
        Passes.DEFAULT
      )
    }

    if (this.active && this.hoverIdx !== -1) {

      const key = `xr.${this.buttons[this.hoverIdx].id !== POST_SETTINGS.NONE ? this.buttons[this.hoverIdx].id : "close_settings"}`
      this.xrmodule.ui.textRenderer.render(
        key,
        this.slider.text._wmatrix,
        [1.0, 1.0, 1.0, this._inReset ? 0.5 : 1.0]
      )
    }

    if (this._inReset) {
      this.resetTitle.render(camera);
    }

    this.scene.glstate.pop();
    this.scene.glstate.apply();

  }

}