import type { InputEvent } from "../types";
import Dispatcher from "./Dispatcher";
import type { IPointer } from "./IPointer";

export default class BrowserPointer implements IPointer {
  private dispatcher?: Dispatcher;
  private metrics = { width: 0, height: 0 };

  constructor() {
    this.onPointer = this.onPointer.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  setDispatcher(dispatcher: Dispatcher): void {
    this.dispatcher = dispatcher;
  }

  enable() {
    window.addEventListener("pointerup", this.onPointer);
    window.addEventListener("pointerdown", this.onPointer);
    window.addEventListener("pointermove", this.onPointer);
    window.addEventListener("pointercancel", this.onPointer);
    window.addEventListener("pointerleave", this.onPointer);
    window.addEventListener("pointerout", this.onPointer);
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  disable() {
    window.removeEventListener("pointerup", this.onPointer);
    window.removeEventListener("pointerdown", this.onPointer);
    window.removeEventListener("pointermove", this.onPointer);
    window.removeEventListener("pointercancel", this.onPointer);
    window.removeEventListener("pointerleave", this.onPointer);
    window.removeEventListener("pointerout", this.onPointer);
    window.removeEventListener("resize", this.onResize);
  }

  protected onPointer(e: PointerEvent) {
    this.dispatcher.dispatch({
      type: e.type as InputEvent["type"],
      pointerType: e.pointerType as InputEvent["pointerType"],
      clientX: e.clientX,
      clientY: e.clientY,
      normalizedX: e.clientX / this.metrics.width,
      normalizedY: e.clientY / this.metrics.height,
    });
  }

  protected onResize() {
    this.metrics.width = window.innerWidth;
    this.metrics.height = window.innerHeight;
  }
}
