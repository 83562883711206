import { mat4, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import Plane from "./Plane";
import Ray from "./Ray";
import rayPlaneIntersection from "./ray-plane-intersection";

const plane: Plane = new Plane();
const V3: vec3 = vec3.create();
const INV_W: mat4 = mat4.create();

export interface FrameRaycastResult {
  inBounds: boolean
  distance: number
  localPos: vec3
  worldPos: vec3
  worldNrm: vec3
}

export function createFrameRaycastResult(): FrameRaycastResult {
  return {
    inBounds: false,
    distance: 0,
    localPos: vec3.create(),
    worldPos: vec3.create(),
    worldNrm: vec3.create()
  }
}

export function copyFrameRaycastResult(src: FrameRaycastResult, dst:FrameRaycastResult) {
  dst.inBounds = src.inBounds
  dst.distance = src.distance
  dst.localPos.set( src.localPos )
  dst.worldPos.set( src.worldPos )
  dst.worldNrm.set( src.worldNrm )
}

export function raycastFrame(out: FrameRaycastResult, ray: Ray, frame: Node): void {


  plane.origin.set(frame._wposition)
  plane.normal.set([
    frame._wmatrix[8],
    frame._wmatrix[9],
    frame._wmatrix[10]
  ]);

  if (rayPlaneIntersection(out.worldPos, ray, plane)) {
    mat4.invert(INV_W, frame._wmatrix);
    vec3.transformMat4(out.localPos, out.worldPos, INV_W);
    out.worldNrm.set( plane.normal );
    out.inBounds = Math.abs(out.localPos[0]) < .5 && Math.abs(out.localPos[1]) < .5;
    out.distance = vec3.distance(out.worldPos, ray.pos);
  } else {
    out.inBounds = false;
  }

}


export function raycastNearestFrame(res: FrameRaycastResult, ray: Ray, frames: Node[]): number {
  let index = -1
  res.distance = Infinity
  res.inBounds = false

  let wrr = createFrameRaycastResult();

  for (let i = 0; i < frames.length; i++) {
    const frame = frames[i];
    
    raycastFrame(wrr, ray, frame);
    if (wrr.inBounds && wrr.distance < res.distance) {
      index = i
      copyFrameRaycastResult(wrr, res);
    }
  }

  return index
}