
import AudioManager from '@/core/audio/AudioManager'
import Gtm from '@/utils/Gtm'
import { defineComponent, ref, computed } from 'vue'

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark'
}

export default defineComponent({
  props: {
    theme: {
      type: String as () => THEME,
      default: THEME.LIGHT
    }
  },
  setup() {
    const muted = ref(false)
    const isMuted = computed(() => {
      return muted.value
    })

    function toggleSound() {
      muted.value = !muted.value
      AudioManager.setMute(muted.value);

      Gtm.sendEventPush(
        'footer',
        'click-sound',
        'Click button sound',
        muted.value ? 1 : 0
      )
    }

    return {
      isMuted,
      toggleSound
    }
  },
})
