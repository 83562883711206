import { ICameraLens } from "nanogl-camera/ICameraLens";
import { mat4 } from "gl-matrix";
import { XREye, XRViewport } from "webxr";

export default class WebXRLens implements ICameraLens {

  near: number;
  far: number;
  aspect: number;
  proj: mat4;

  eye: XREye;
  viewport: XRViewport;

  constructor(eye: XREye, vp: XRViewport) {
    this.update(eye, vp);
  }

  update(eye: XREye, vp: XRViewport) {
    this.eye = eye;
    this.viewport = vp;
  }

  getProjection(): mat4 {
    return this.proj;
  }

}