import { ArtworkId } from "@/store/states/Artworks"

export type PathRange = {
  readonly id : ArtworkId
  readonly mid : number
  readonly start : number
  readonly end : number
}

export const PathRanges : readonly PathRange[] = [
  {id:'052',mid:216/1080,  start:0.18, end:0.213},
  {id:'063',mid:263/1080,  start:0.23, end:0.286},
  {id:'069',mid:409/1080,  start:0.363, end:0.397},
  {id:'080',mid:480/1080,  start:0.433, end:0.454},
  {id:'083',mid:643/1080,  start:0.561, end:0.606},
  {id:'050',mid:803/1080,  start:0.716, end:0.751},
  {id:'109',mid:863/1080,  start:0.770, end:0.815},
] as const

export function getPathRange(id : ArtworkId) : PathRange {
  return PathRanges.find(r => r.id === id)
}