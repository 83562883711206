
import '@/utils/Ease'
import { defineComponent, ref } from 'vue';

import AppVersion from '@/components/AppVersion/AppVersion.vue';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import InformationPanel from '@/components/InformationPanel/InformationPanel.vue';
import GLViewer from '@/components/GLViewer/GLViewer.vue';
import UIAudioBtn from '@/components/UIAudioBtn/UIAudioBtn.vue';
import LegalPage from '@/components/LegalPage/LegalPage.vue';
import CookieBanner from '@/components/CookieBanner/CookieBanner.vue';

import Viewport from "@/store/modules/Viewport";

import HomeVue from './views/Home.vue';
import LoadingVue from './views/Loading.vue';
import MuseumVue from './views/Museum.vue';
import PaintingVue from './views/Painting.vue';
import PaintingTutorialVue from './views/PaintingTutorial.vue';
import { useActor, useSelector } from '@xstate/vue';
import AppService from './store/states/AppService';
import ShareVue from './views/Share.vue';
import { AppStateEnum, collectAppStateViews } from './store/states/AppStateMachine';
import GalleryVue from './views/Gallery.vue';
import { useI18n } from 'vue-i18n';


enum THEME {
  LIGHT = 'light',
  DARK = 'dark'
}

export default defineComponent({
  
  name: 'App',

  setup() {
    const { t } = useI18n()
    Viewport.init();

    // document.title = t('meta.title')
    // document.querySelector('meta[name="description"]').setAttribute("content", t('meta.description'));


    return { 
      xrActive: useSelector(AppService, (state)=>state.context.xrActive),
      ...useActor(AppService)
    }

  },

  components: {
    AppVersion,
    CookieBanner,
    GLViewer,
    AppHeader,
    InformationPanel,
    UIAudioBtn,
    LegalPage,
    HomeVue,
    LoadingVue,
    MuseumVue,
    PaintingVue,
    PaintingTutorialVue,
    ShareVue,
    GalleryVue
  },

  mounted() {
    this.send('INIT')
  },

  computed: {
    showHeader(): boolean {
      return !this.xrActive && [
        AppStateEnum.HOME,
        AppStateEnum.MUSEUM,
        `${AppStateEnum.PAINTING}.explore`,
        `${AppStateEnum.SHARE}.upload`,
        `${AppStateEnum.SHARE}.select`,
        AppStateEnum.GALLERY,
      ].some(this.state.matches)
    },

    viewList(): string[] {
      if( this.state.context.xrActive ) return []
      return collectAppStateViews(this.state)
    },

    showAudioBtn(): boolean {
      return !this.xrActive && Viewport.isDesktop && [
        AppStateEnum.HOME,
        AppStateEnum.MUSEUM,
        `${AppStateEnum.PAINTING}.explore`,
        AppStateEnum.GALLERY,
      ].some(this.state.matches)
    },

    uiTheme(): THEME {
      if (this.$store.state.legalPage.isOpen || [
          `${AppStateEnum.PAINTING}.explore`,
          AppStateEnum.SHARE,
          AppStateEnum.GALLERY,
        ].some(this.state.matches)
      ) {
        return THEME.DARK
      }

      return THEME.LIGHT
    }
  },

});

