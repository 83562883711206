import GLTFResource from "@/webgl/assets/GLTFResource";
import Gamepads from "@/webgl/entities/xr-module/gamepad/Gamepads";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { XRGamepadButton } from "@/webgl/xr/WebXRGamepad";
import { mat4, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { LocalConfig } from "nanogl-state";
import MaterialOverrideExtension from "@/webgl/lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import BtnHighlightMaterial from "@/webgl/entities/xr-module/gamepad/btn-highlight/BtnHighlightMaterial";
import BtnHighlightPass from "@/webgl/entities/xr-module/gamepad/btn-highlight/BtnHighlightPass";
import Paths from "@/core/Paths";
import ButtonTooltipEntity from "./ButtonTooltipEntity";

const M4 = mat4.create();


export default class ButtonTooltipsRenderer {

  _list: Map<XRGamepadButton, ButtonTooltipEntity>;
  lineConfig: LocalConfig;
  highlightMat: BtnHighlightMaterial;
  highlightPass: BtnHighlightPass;

  constructor(
    public ctx: Gamepads
  ) {

    this._list = new Map<XRGamepadButton, ButtonTooltipEntity>();

    var materialOverride = new MaterialOverrideExtension();
    this.highlightMat = new BtnHighlightMaterial(this.ctx.xrmodule.scene.gl);
    materialOverride.overrides = {
      "shell_quest2BtnMAT": this.highlightMat
    };
    const btnpass = this.highlightMat.getPass(Passes.DEFAULT).pass as BtnHighlightPass;
    this.highlightPass = btnpass;

    const btns = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/controllers/controllers_btns.gltf"),
      this.ctx.xrmodule.scene,
      {
        extensions: [
          materialOverride
        ]
      }
    )

    this.lineConfig = this.ctx.xrmodule.scene.glstate.config();
    this.lineConfig
      .enableDepthTest(true)
      .lineWidth(2.5);

    this.ctx.xrmodule.resources.add(btns, "controllers_btns");

  }

  init() {

    const btnGltf = this.ctx.xrmodule.resources.get("controllers_btns") as Gltf;
    this.highlightPass.color = btnGltf.getElementByName(GltfTypes.TEXTURE, "gradient_purple").glTexture;

    const leftbtns = btnGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "left_quest2_buttons") as Node;
    const rightBtns = btnGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "right_quest2_buttons") as Node;

    this.ctx.list.get("left").skin.add(leftbtns);
    this.ctx.list.get("right").skin.add(rightBtns);

    for (const btn of [...leftbtns._children, ...rightBtns._children] as Node[]) {

      const tooltip = new ButtonTooltipEntity(this.ctx.xrmodule.ui);
      tooltip.highlight = false;
      tooltip.label = false;
      tooltip.key = "";
      tooltip.nodeBtn = btn;
      tooltip.nodeLabelTarget = btn._children[0] as Node;

      this._list.set(btn.name as XRGamepadButton, tooltip);

    }

  }

  highlight(btn: XRGamepadButton, txt: string = null, highlight: boolean = true) {
    this._list.get(btn).highlight = highlight;
    if (txt !== "" && txt !== null)
      this._list.get(btn).attachText(this.ctx.xrmodule.scene.root as Node, txt);
  }

  release(btn: XRGamepadButton) {
    this._list.get(btn).highlight = false;
    this._list.get(btn).attachText(this.ctx.xrmodule.scene.root as Node, null);
  }

  syncPosition = () => {
    this._list.forEach((tooltip) => {
      tooltip.syncPosition();
    })
  }

  preRender(dt: number) {

    this.highlightPass.offset[0] = Math.sin(this.ctx.xrmodule.scene.time * 0.3);
    this.highlightPass.offset[1] = Math.cos(this.ctx.xrmodule.scene.time * 0.3);

    this._list.forEach((tooltip) => {

      if (tooltip.label)
        tooltip.updateLabel(dt, this.ctx.xrmodule.xrcamera.head._wposition as vec3);
    });

  }

  render(camera: Camera) {

    this._list.forEach((btn) => {

      if (btn.highlight) {

        btn.nodeBtn.renderable.render(
          this.ctx.xrmodule.scene,
          camera,
          Masks.BLENDED,
          Passes.DEFAULT
        );

      }

    });

    this._list.forEach((tooltip) => {

      if (tooltip.label) {

        tooltip.renderLabel(camera, [0.0270944, 0.0270944, 0.0270944]);

        this.ctx.xrmodule.scene.glstate.push(this.lineConfig);
        this.ctx.xrmodule.scene.glstate.apply();
        tooltip.renderLine(this.ctx.xrmodule.scene.programs.get("unlit-color"), camera.getMVP(M4));
        this.ctx.xrmodule.scene.glstate.pop();

      }

    });


  }

}