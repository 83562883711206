import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-623b1170"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["UIAudioBtn", [
      `UIAudioBtn--${_ctx.theme}`,
      { 'is-muted': _ctx.isMuted }
    ]]),
    title: _ctx.$t('common.toggle_sound'),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSound && _ctx.toggleSound(...args)))
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (n) => {
      return _createElementVNode("span", {
        key: n,
        class: "Bar"
      })
    }), 64))
  ], 10, _hoisted_1))
}