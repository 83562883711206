import UIText from "@/webgl/entities/UIKit/text/UIText";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import TextRenderer from "@/webgl/entities/UIKit/text/TextRenderer";
import TextLibrary from "@/webgl/entities/UIKit/TextLibrary";
import GradientPrimitives from "@/webgl/entities/UIKit/gradient-primitives/GradientPrimitives";
import Deferred from "@/core/Deferred";


export default class UIKit {

  public root: Node;

  public text: UIText;
  public textRenderer: TextRenderer;
  public gradientPrimitives: GradientPrimitives;

  public textLib: TextLibrary;

  _defer: Deferred;
  get response(): Promise<any> {
    return this._defer.promise;
  }

  constructor(
    public readonly scene: Scene
  ) {

    this.root = new Node();

    this._defer = new Deferred();

    this.textLib = new TextLibrary(this.scene.gl);

    this.textRenderer = new TextRenderer(this.scene);
    this.gradientPrimitives = new GradientPrimitives(this.scene);

    this.textLib.createFont("graphik-400", "Graphik-400-Regular_SDF");
    this.textLib.createFont("graphik-600", "Graphik-600-Semibold_SDF");
    this.textLib.createFont("new-paris-500", "NewParisHeadline-Medium_SDF");
    this.textLib.createFont("new-paris-400", "NewParisHeadline-Regular_SDF");

    this.textLib.attach(this.textRenderer);

  }

  async load(): Promise<any> {
    await this.textLib.load();
    await this.gradientPrimitives.load();

    this.textRenderer.setFonts(this.textLib.fonts);
    this.textRenderer.build();

    this._defer.resolve();
  }

  buildTextLibrary(content) {

  }

  resize() {

  }

  preRender() {
    this.gradientPrimitives.preRender(this.scene.dt);
  }

  render(camera: Camera) {

    this.textRenderer.prepare();
    this.textRenderer.renderQueuedList(camera);
    this.textRenderer.flush();

  }

}