import { project } from "@/webgl/math/camera";
import { vec2, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import Node from "nanogl-node";

const V3 = vec3.create();
const PLANE = {
  a: vec3.fromValues(-1, -1, 0),
  b: vec3.fromValues(1, -1, 0),
  c: vec3.fromValues(1, 1, 0),
  d: vec3.fromValues(-1, 1, 0),
};

export default class ArtworkBounds {
  node = new Node();

  screen = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  };

  world = {
    a: vec3.create(),
    b: vec3.create(),
    c: vec3.create(),
    d: vec3.create(),
  };

  compute(camera: Camera) {
    // Compute bounds
    project(vec3.copy(V3, PLANE.a), this.node, camera);
    this.screen.left = V3[0];
    this.screen.top = V3[1];

    project(vec3.copy(V3, PLANE.c), this.node, camera);
    this.screen.right = V3[0];
    this.screen.bottom = V3[1];

    // Compute world bounds
    vec3.transformMat4(this.world.a, PLANE.a, this.node._wmatrix);
    vec3.transformMat4(this.world.b, PLANE.b, this.node._wmatrix);
    vec3.transformMat4(this.world.c, PLANE.c, this.node._wmatrix);
    vec3.transformMat4(this.world.d, PLANE.d, this.node._wmatrix);
  }

  intersectScreen(v: vec2) {
    return (
      v[0] >= this.screen.left &&
      v[0] <= this.screen.right &&
      v[1] >= this.screen.top &&
      v[1] <= this.screen.bottom
    );
  }

  isScreenVisible() {
    return (
      ((this.screen.left >= 0 && this.screen.left <= 1) ||
        (this.screen.right >= 0 && this.screen.right <= 1)) &&
      ((this.screen.top >= 0 && this.screen.top <= 1) ||
        (this.screen.bottom >= 0 && this.screen.bottom <= 1))
    );
  }

  isRadiusVisible(radius: number) {
    return (
      vec3.length(this.world.a) < radius ||
      vec3.length(this.world.b) < radius ||
      vec3.length(this.world.c) < radius ||
      vec3.length(this.world.d) < radius
    );
  }
}
