import { useI18n} from 'vue-i18n'
import type { ArtworkId } from '@/store/states/Artworks'
import { artworkIsMainPainting } from '@/store/states/Artworks'

export type Artwork = {
  id: ArtworkId,
  title: string,
  date: string,
  material: string,
  museum: string,
  description: string,
  isMainPainting: boolean
}

export default function useArtwork(artworkId: ArtworkId): Artwork {
  const { tm } = useI18n()
  const labels = tm(`museum.artworks`)

  return {
    ...labels[artworkId],
    id: artworkId,
    isMainPainting: artworkIsMainPainting(artworkId)
  }
}
