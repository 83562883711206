import ButtonTooltipsRenderer from "@/webgl/entities/xr-module/gamepad/ButtonTooltipsRenderer";
import { XRButtonList, XRGamepadButton } from "@/webgl/xr/WebXRGamepad";

export class XRTooltipManager {

  _list: Map<XRGamepadButton, string[]>;

  constructor(
    public readonly tooltipRenderer: ButtonTooltipsRenderer
  ) {

    this._list = new Map<XRGamepadButton, string[]>();
    for (const btn of XRButtonList) {
      this._list.set(btn, []);
    }

  }

  push(btn: XRGamepadButton, txt: string, highlight: boolean = true) {
    if (this._list.get(btn).indexOf(txt) != -1)
      return;
    this._list.get(btn).push(txt);
    this.tooltipRenderer.release(btn);
    this.tooltipRenderer.highlight(btn, txt, highlight);

  }

  pop(btn: XRGamepadButton, key: string) {


    const idx = this._list.get(btn).indexOf(key);

    if (idx == -1)
      return;

    this._list.get(btn).splice(idx, 1);
    this.tooltipRenderer.release(btn);
    const len = this._list.get(btn).length;

    if(len != 0){
      this.tooltipRenderer.highlight(btn, this._list.get(btn)[len - 1]);
    }

  }

  clear() {
    for (const btn of XRButtonList) {
      this._list.set(btn, []);
      this.tooltipRenderer.release(btn);
    }
  }

  sync() {
    this.tooltipRenderer.syncPosition();
  }

}