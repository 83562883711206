import { Result } from "@/utils/Result";

export interface RecaptchaVerifyResponse {
  success: boolean;
  challenge_ts: string;
  hostname: string;
  score: string;
  action: string;
  "error-codes"?: string[];
}

export const createToken = () => {
  return new Promise<Result<string, string>>((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, { action: "submit" })
        .then((token) => {
          resolve(Result.Ok(token));
        })
        .catch((err) => {
          reject(Result.Err(`Failed to create reCaptcha token.`));
        });
    });
  });
};
