import NavigationRenderer from "@/webgl/entities/xr-module/navigation/NavigationRenderer";
import Camera from "nanogl-camera";
import Ray from "@/webgl/math/Ray";
import { vec3 } from "gl-matrix";
import XRNavigationController from "@/webgl/entities/xr-module/navigation/XRNavigationController";
import Signal from "@/core/Signal";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Scene from "@/webgl/Scene";
import Picking from "@/webgl/lib/Picking";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import GLTFResource from "@/webgl/assets/GLTFResource";
import { TextureFiltering, TextureSrcSet, TextureWrap } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Paths from "@/core/Paths";


export default class Navigation {

  ray: Ray;

  target: vec3;
  normal: vec3;
  forward: vec3;
  /**
   * offset between the actual navmesh and the rendered stuffs
   */
  offset=0

  hasTarget: boolean;
  renderer: NavigationRenderer;

  xrctrl: XRNavigationController;

  navmesh?: Picking

  active: boolean = false;

  onMove: Signal<any>;

  scene: Scene;
  resources: ResourceGroup;
  private _loadPromise: Promise<void>;

  constructor(
    public xrmodule: XRModule
  ) {

    this.scene = xrmodule.scene;
    this.ray = new Ray();

    this.target = vec3.create();
    this.normal = vec3.create();
    this.forward = vec3.create();

    this.renderer = new NavigationRenderer(this);
    this.xrctrl = new XRNavigationController(this);
    this.onMove = new Signal();

    this.resources = new ResourceGroup();
    
    const mesh = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/aim_target/aim_target.gltf"), 
      this.scene
    );
    this.resources.add(mesh, "aim_target");

    const srcSet = new TextureSrcSet(Paths.resolve("assets/webgl/ray_line.png"));
    srcSet.options = {
      bbc: false,
      flipY: true,
      genMips: false,
      wrap: TextureWrap.CLAMP,
      filtering: TextureFiltering.LINEAR
    }
    this.resources.add(new TextureResource(srcSet, this.scene), "ray_line");

  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  private async _doLoad() {
    await this.resources.load();
    this.init();
  }

  init() {
    this.renderer.init();
    this.scene.root.add(this.renderer.targetNode);
  }
  
  release(){
    this.xrctrl.unbind();
    this.scene.root.remove(this.renderer.targetNode);
  }

  enable(){
    this.xrctrl.bind();
  }

  disable(){
    this.xrctrl.unbind();
  }

  preRender() {

    this.xrctrl.prepareRay(this.ray)

    this.hasTarget = false
    if( this.navmesh ){
      const valid = this.navmesh.raycast(this.ray, this.target, this.normal);
      if (valid) {
        vec3.scaleAndAdd(this.target, this.target, this.normal, this.offset )
      }
      this.hasTarget = valid !== 0;
      
        
    }

    this.xrctrl.update(this.xrmodule.scene.dt);

    this.renderer.preRender();

  }

  moveToTarget() {
    this.xrctrl.moveTo(this.target);
    this.xrmodule.tooltips.sync();
    this.onMove.emit();
  }

  render(camera: Camera) {
    if (this.active)
      this.renderer.render(camera);
  }

}