import XRModule from "@/webgl/entities/xr-module/XRModule";
import type { InputEvent } from "../types";
import Dispatcher from "./Dispatcher";

export default class XRPointer {
  private dispatcher?: Dispatcher;

  private xrmodule: XRModule;

  constructor() {
    this.onPointer = this.onPointer.bind(this);
  }

  setDispatcher(dispatcher: Dispatcher): void {
    this.dispatcher = dispatcher;
  }

  registerXRModule(xrmodule: XRModule) {
    if (!this.xrmodule)
      this.xrmodule = xrmodule;
  }

  enable() {

  }

  disable() {

  }

  preRender() {

  }

  protected onPointer(_: unknown) {
    this.dispatcher.dispatch({
      type: "pointerup" as InputEvent["type"],
      pointerType: "xr_controller",
      clientX: 0,
      clientY: 0,
      normalizedX: 0,
      normalizedY: 0,
    });
  }
}
