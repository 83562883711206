import BaseEffect from "nanogl-post/effects/base-effect";
import Post from "nanogl-post";
import Scene from "@/webgl/Scene";
import Fetch from "nanogl-post/effects/fetch";
import Saturation from "nanogl-post/effects/saturation";
import Contrast from "nanogl-post/effects/contrast";
import Vignette from "nanogl-post/effects/vignette";
import { CreatePane, Presetpane } from "@/dev/Tweak";
import PostSettingsController from "@/webgl/entities/post-process/PostSettingsController";
import { MsaaFbo } from "@/webgl/gl/MsaaFbo";
import Temperature from "@/webgl/entities/post-process/temperature/Temperature";

export default class PostProcess {

  _effectList: Array<BaseEffect> = [];
  setup: Post;

  fetch: Fetch;
  saturation: Saturation;
  vignette: Vignette;
  contrast: Contrast;
  temperature: Temperature;

  settings: PostSettingsController;

  msaa: MsaaFbo;

  get enabled(): boolean {
    return this.setup.enabled;
  }
  set enabled(value) {
    this.setup.enabled = value;
  }

  constructor(
    public readonly scene: Scene
  ) {

    this.setup = new Post(scene.gl, false);
    this.setup.enabled = true;
    this.fetch = new Fetch();
    this.setup.add(this.fetch);

    // this.vignette = new Vignette([0, 0, 0], 1.0, 0.1);
    this.saturation = new Saturation(1.0);
    this.contrast = new Contrast(1.0, 1.0, 1.0);
    this.temperature = new Temperature();

    // this.setup.add(this.vignette);
    this.setup.add(this.saturation);
    this.setup.add(this.temperature);
    this.setup.add(this.contrast);

    this.settings = new PostSettingsController(this);

    // MSAA
    this.msaa = new MsaaFbo(scene.gl, 2);
    this.setup.mainColor = this.msaa.blitFbo.getColorTexture();
    this.setup.mainFbo = this.msaa.blitFbo;

    const t = this.msaa.getColorTexture();
    t.bind();
    t.clamp().setFilter(true, false, false);

/////////////////
/////////////////////
//////////////

  }

  paneSetup() {
/////////////////

////////////////////////////////////////
///////////////////////////////////////
////
///////////////////////////////////////////
////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

//////////////

  }

  init() {

  }

  preRender(w, h) {
    this.setup.preRender(w, h);
    this.msaa.setSize(w, h);
  }

  bindColor() {
    // TO UPDATE
    // this.setup.bindColor();

    // MSAA
    this.msaa.renderFbo.bind();
    this.scene.gl.viewport(0, 0, this.setup.renderWidth, this.setup.renderHeight);
    this.msaa.renderFbo.clear();
  }

  render(x = 0, y = 0,w = this.setup.renderWidth, h = this.setup.renderHeight, toFbo = null) {

    const post = this.setup;

    if (!post.enabled) {
      return;
    }


    const gl = post.gl;
    this.msaa.blitMsaa();

    // mipmap mainFbo here
    // post.mainColor.bind();
    // post.mainColor.setFilter(true, false, false);
    // if (post.mipmap) {
    //   gl.generateMipmap(gl.TEXTURE_2D);
    // }

    for (var i = 0; i < post._effects.length; i++) {
      post._effects[i].preRender()
    }

    if (toFbo !== null) {
      // toFbo.resize( w, h );
      // toFbo.bind();
      gl.bindFramebuffer(gl.FRAMEBUFFER, toFbo)
    } else {
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }


    // gl.viewport(0, 0, w + x * 2, h + y * 2);
    
    gl.clearColor(1.0, 1.0, 1.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    gl.viewport(x, y, w, h);


    if (post._shaderInvalid) {
      post.buildProgram();
    }

    post.prg.use();

    for (var i = 0; i < post._effects.length; i++) {
      post._effects[i].setupProgram(post.prg);
    }



    // MSAA
    // post.prg.tInput(post.mainColor);
    post.prg.tInput(this.msaa.getColorTexture());

    // if (post._needDepth()) {
    //   if (post.hasDepthTexture){
    //     // MSAA
    //     // post.prg.tDepth(post.mainFbo.getDepth());
    //     post.prg.tDepth(this.msaa.blitFbo.getDepth());
    //   }
    //   else
    //     throw "no depth texture"
    //   //post.prg.tDepth( post.depthFbo.color );
    // }


    post.fillScreen(post.prg);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

  }

}