import Scene from "@/webgl/Scene";
import { ICameraController } from "./ICameraController";
import Camera from "nanogl-camera";



export default class CameraControler {
  
  scene: Scene;
  _current: ICameraController;
  camera: any;


  constructor( scene : Scene, camera : Camera ){
    this.scene = scene;
    this.camera = camera;
    this._current = null;
  }


  setControler( ctrl:ICameraController ){
    this._current?.stop()
    this._current = ctrl;
    ctrl?.start( this.camera );
  }


  preRender (){
    if( this._current ){
      this._current.update( this.scene.dt );
    }
  }

}
