import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules';
import store from "@/store";
import UrlParams from '@/utils/UrlParams';

export const enum APP_STATE {
  INTRO = "intro",
  LOADING = "loading",
  GALLERY = "gallery",
  PAINTING = "painting"
}

export const enum APP_MODE {
  STANDARD = "standard",
  XR = "xr"
}

@Module
export class AppModule extends VuexModule {

  public debug: boolean = process.env.VUE_APP_DEBUG == 'true';

  public load_progress: number = 0;
  public glloaded: boolean = false;

  // init() {
  //   if (UrlParams.getString("state"))
  //     this._SET_STATE(UrlParams.getString("state") as APP_STATE);
  // }


  

  @Action
  setLoadProgress(value: number) {
    this._SET_LOAD_PROGRESS(value);
  }


  @Mutation
  private _SET_GL_LOADED(flag: boolean) {
    this.glloaded = flag;
  }

  @Mutation
  private _SET_LOAD_PROGRESS(value: number) {
    this.load_progress = value;
  }


}

const appmodule = new AppModule({ store, name: 'app' })



export default appmodule
