import GLArrayBuffer from 'nanogl/arraybuffer';
const TMP_ARRAY = new Float32Array([
    -1, -1, 0,
    0, 0, 1,
    0, 1,

    -1, 1, 0,
    0, 0, 1,
    0, 0,

    1, -1, 0,
    0, 0, 1,
    1, 1,

    1, 1, 0,
    0, 0, 1,
    1, 0
]);


export const Indices = new Int8Array([
    0, 3, 1,
    0, 2, 3
]);


export default class Rect3D extends GLArrayBuffer {
    constructor(gl, x = -1, y = -1, w = 2, h = 2) {
        super(gl);
        const a = TMP_ARRAY;
        a[0] = a[8] = x;
        a[1] = a[17] = y;
        a[16] = a[24] = x + w;
        a[9] = a[25] = y + h;
        this.data(a);
        this.attrib('aPosition', 3, gl.FLOAT);
        this.attrib('aNormal', 3, gl.FLOAT);
        this.attrib('aTexCoord', 2, gl.FLOAT);
    }
    render() {
        this.drawTriangleStrip();
    }
}
