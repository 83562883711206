import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Rect3D from "@/webgl/lib/Quad3D";
import { vec2 } from "gl-matrix";
import Camera from "nanogl-camera";
import GLConfig from "nanogl-state/config";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import gsap from "gsap";

const IDLE_Y = -0.5;
const IDLE_ANGLE = Math.PI / 8;
const ACTIVE_ANGLE = -0.1;

export default class XRSharePhotoPreview {

  node: Node;

  private _prg: Program;
  private _geom: Rect3D;
  private _texture: Texture2D;
  private _opaqueCfg: GLConfig;
  private _blendedCfg: GLConfig;
  private _alpha: number = 0;
  private _rotation: number = IDLE_ANGLE;
  private _tgtRotation: number = IDLE_ANGLE;

  get cfg(): GLConfig {
    return this._alpha !== 1 ? this._blendedCfg : this._opaqueCfg;
  }

  constructor(
    public readonly activity: PaintingXRActivity
  ) {

    this.node = new Node();
    this.node.rotateY(Math.PI);
    this.node.rotateZ(this._rotation);
    this.node.y = IDLE_Y;
    this.node.z = -0.1;
    this._geom = new Rect3D(activity.scene.gl, -0.5, -0.5, 1.0, 1.0);
    this._prg = activity.scene.programs.get("unlit-texture");
    this._blendedCfg = new GLConfig();
    this._opaqueCfg = new GLConfig();

    const gl = activity.scene.gl;
    this._blendedCfg
      .enableBlend(true)
      .blendFunc(gl.CONSTANT_ALPHA, gl.ONE_MINUS_CONSTANT_ALPHA)
      .enableCullface(true)
      .cullFace(gl.BACK);

    this._opaqueCfg
      .enableBlend(false)
      .enableDepthTest(true)
      .depthMask(true)
      .enableCullface(true)
      .cullFace(gl.BACK);

  }

  show(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this.node, {
        y: 0,
        ease: "power3.out",
        duration: 1.5
      })
      gsap.to(this, {
        _tgtRotation: ACTIVE_ANGLE,
        ease: "power3.out",
        duration: 1.5,
        onUpdate: ()=>{
          this.node.rotateZ(this._tgtRotation - this._rotation);
          this._rotation = this._tgtRotation;
        }
      });
      gsap.to(this, { 
        _alpha: 1, 
        duration: 1.5,
        onComplete: resolve 
      })
    });
  }

  hide(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this.node, {
        y: IDLE_Y,
        ease: "power3.out",
        duration: 1.5
      })
      gsap.to(this, {
        _tgtRotation: IDLE_ANGLE,
        ease: "power3.out",
        duration: 1.5,
        onUpdate: ()=>{
          this.node.rotateZ(this._tgtRotation - this._rotation);
          this._rotation = this._tgtRotation;
        }
      });
      gsap.to(this, { 
        _alpha: 0, 
        duration: 1.5, 
        onComplete: resolve 
      })
    });
  }

  updateScale(scale: vec2) {
    this.node.scale[0] = scale[0] * -2.0;
    this.node.scale[1] = scale[1] * -2.0;
    this.node.invalidate();
  }

  setTexture(tex: Texture2D) {
    this._texture = tex;
  }

  render(camera: Camera) {

    this._prg.use();
    this._prg.uMVP(camera.getMVP(this.node._wmatrix));
    this._prg.tTexture(this._texture);

    if(this._alpha !== 1){
      this._blendedCfg.blendColor(1, 1, 1, this._alpha)
    }
    
    this.activity.scene.glstate.push(this.cfg);
    this.activity.scene.glstate.apply();

    this._geom.bind();
    this._geom.attribPointer(this._prg);
    this._geom.render();

    this.activity.scene.glstate.pop();

  }

}