import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppVersion = _resolveComponent("AppVersion")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_GLViewer = _resolveComponent("GLViewer")!
  const _component_UIAudioBtn = _resolveComponent("UIAudioBtn")!
  const _component_InformationPanel = _resolveComponent("InformationPanel")!
  const _component_LegalPage = _resolveComponent("LegalPage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$store.state.app.debug)
      ? (_openBlock(), _createBlock(_component_AppVersion, { key: 0 }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_AppHeader, { theme: _ctx.uiTheme }, null, 8, ["theme"]), [
      [_vShow, _ctx.showHeader]
    ]),
    _createVNode(_component_GLViewer),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewList, (c) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(c), { key: c }))
    }), 128)),
    (_ctx.showAudioBtn)
      ? (_openBlock(), _createBlock(_component_UIAudioBtn, {
          key: 1,
          class: "z-ui",
          theme: _ctx.uiTheme
        }, null, 8, ["theme"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_InformationPanel, null, null, 512), [
      [_vShow, _ctx.$store.state.informationPanel.isOpen]
    ]),
    _withDirectives(_createVNode(_component_LegalPage, null, null, 512), [
      [_vShow, _ctx.$store.state.legalPage.isOpen]
    ])
  ]))
}