import { anime } from "@/utils/anime";
import { vec2 } from "gl-matrix";
import gsap from "gsap";
import Inputs from "../Inputs";
import { InputEvent } from "../types";
import ArtworkState from "./State";
import ArtworkStrip from "./Strip";

export interface IArtworkDrag {
  enabled: boolean;
  isPointerDown: boolean;
  startTime: number;
  startX: number;
  clientX: number;
  x: number;
  inertia: number;
  speedMultiplier: number;
  friction: number;
}

export default class ArtworkInteractions {
  public pointer = {
    type: "mouse" as InputEvent["pointerType"],
    active: false,
    canClick: false,
    value: vec2.fromValues(-1, -1),
  };

  public screen = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  public focus = {
    enabled: false,
    active: [] as ArtworkState[],
    items: [] as ArtworkState[],
    distance: 200,
    gap: 50,
    transitioning: false,
    auto: {
      state: undefined as ArtworkState,
    },
  };

  public drag: IArtworkDrag = {
    enabled: false,
    isPointerDown: false,
    startTime: 0,
    startX: 0,
    clientX: 0,
    x: 0,
    inertia: 0,
    speedMultiplier: 10,
    friction: 0.96,
  };

  public snap = {
    enabled: false,
    active: false,
    snapping: false,
    time: 0,
    delay: 2_000, // in milliseconds
  };

  public nextLoading = {
    enabled: false,
    stripOffset: 0,
    stripLength: 0,
    stride: 800,
    progress: 0,
    time: 0,
    pending: false,
    timeTarget: 10000, // in milliseconds
  };

  events = document.createElement("div");
  inputs: Inputs;

  constructor(public readonly strip: ArtworkStrip) {
    this.onDrag = this.onDrag.bind(this);
    this.onPointer = this.onPointer.bind(this);
    this.onResize = this.onResize.bind(this);
    this.inputs = new Inputs();
  }

  enable() {
    this.inputs.add("pointerup", this.onDrag);
    this.inputs.add("pointerdown", this.onDrag);
    this.inputs.add("pointermove", this.onDrag);
    this.inputs.add("pointercancel", this.onDrag);
    this.inputs.add("pointerleave", this.onDrag);
    this.inputs.add("pointerout", this.onDrag);

    this.inputs.add("pointermove", this.onPointer);
    this.inputs.add("pointercancel", this.onPointer);
    this.inputs.add("pointerleave", this.onPointer);
    this.inputs.add("pointerout", this.onPointer);
    this.inputs.add("pointerdown", this.onPointer);
    this.inputs.add("pointerup", this.onPointer);

    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  disable() {
    this.inputs.remove("pointerup", this.onDrag);
    this.inputs.remove("pointerdown", this.onDrag);
    this.inputs.remove("pointermove", this.onDrag);
    this.inputs.remove("pointercancel", this.onDrag);
    this.inputs.remove("pointerleave", this.onDrag);
    this.inputs.remove("pointerout", this.onDrag);

    this.inputs.remove("pointermove", this.onPointer);
    this.inputs.remove("pointercancel", this.onPointer);
    this.inputs.remove("pointerleave", this.onPointer);
    this.inputs.remove("pointerout", this.onPointer);
    this.inputs.remove("pointerdown", this.onPointer);
    this.inputs.remove("pointerup", this.onPointer);

    window.removeEventListener("resize", this.onResize);
  }

  update(dt: number) {

    const nl = this.nextLoading;
    const delta = Math.abs(nl.stripLength + nl.stripOffset);
    if (this.nextLoading.enabled && delta < nl.stride && !this.nextLoading.pending) {
      this.events.dispatchEvent(new CustomEvent("i:load"));
      this.nextLoading.pending = true;
    }


    // if (this.nextLoading.enabled) {
    //   this.nextLoading.time += dt;
    //   if (
    //     this.nextLoading.progress > 0.75 &&
    //     this.nextLoading.time >= this.nextLoading.timeTarget
    //   ) {
    //     this.nextLoading.time = 0;
    //     this.events.dispatchEvent(new CustomEvent("i:load"));
    //   }
    // }

    if (this.strip.mode === "mobile") {
      const closest = this.strip.findClosestFromCenter();
      if (!closest) return;

      // Snap
      if (!this.drag.isPointerDown && this.snap.enabled) {
        if (!this.snap.active) {
          this.snap.time += dt;

          if (this.snap.time >= this.snap.delay) {
            this.snap.active = true;
            this.snap.time = 0;
            this.onSnap();
          }
        }
      } else {
        this.snap.time = 0;
      }

      // Update minimap
      if (this.focus.auto.state !== closest) {
        this.focus.auto.state = closest;
        this.events.dispatchEvent(
          new CustomEvent("i:over", {
            detail: closest,
          })
        );
      }
      return;
    }
  }

  /**
   * Dragging
   */
  onDrag(e: InputEvent) {
    if (this.strip.introTween.running || this.snap.snapping) return;

    switch (e.type) {
      case "pointerdown": {
        // this.stopSnap();
        this.drag.isPointerDown = true;
        this.drag.startTime = Date.now();
        this.drag.startX = e.clientX;
        this.drag.x = 0;
        this.drag.clientX = e.clientX;
        break;
      }
      case "pointermove": {
        if (!this.drag.isPointerDown) return;
        this.drag.x = e.clientX - this.drag.startX;
        this.drag.clientX = e.clientX;
        if (!this.drag.enabled && Math.abs(this.drag.x) >= 10) {
          // this.stopSnap();
          this.drag.enabled = true;
          this.events.dispatchEvent(new CustomEvent("i:dragstart"));
        }
        break;
      }
      case "pointerout":
      case "pointerleave":
      case "pointercancel":
      case "pointerup": {
        if (!this.drag.isPointerDown) return;
        const duration = Date.now() - this.drag.startTime;
        const distance = this.drag.clientX - this.drag.startX;
        const speed = distance / duration;
        this.strip.offset += distance;
        this.events.dispatchEvent(new CustomEvent("i:dragend"));
        this.drag.enabled = false;
        this.drag.isPointerDown = false;
        this.drag.startTime = 0;
        this.drag.startX = 0;
        this.drag.x = 0;
        this.drag.clientX = 0;
        this.drag.inertia = speed * this.drag.speedMultiplier;
        this.snap.active = false;
        break;
      }
    }
  }

  /**
   * Pointer tracking
   */
  onPointer(e: InputEvent) {
    if (this.strip.introTween.running || this.snap.snapping) return;

    this.pointer.type = e.pointerType;
    if (this.pointer.type !== "touch") {
      this.pointer.active = true;
    }

    if (e.type === "pointerout" && this.pointer.type === "touch") {
      return;
    }

    switch (e.type) {
      case "pointerup": {
        if (this.pointer.type !== "touch") {
          this.click();
        }
        this.snap.time = 0;
        break;
      }
      case "pointerdown": {
        if (this.pointer.type === "touch") {
          vec2.set(this.pointer.value, e.normalizedX, e.normalizedY);
          this.pointer.active = !this.pointer.active;
          if (!this.pointer.active) this.click();
        }
        this.pointer.canClick = true;
        this.snap.time = 0;
        break;
      }
      case "pointermove": {
        vec2.set(this.pointer.value, e.normalizedX, e.normalizedY);
        this.pointer.canClick = false;
        this.snap.time = 0;
        break;
      }
      case "pointerout":
      case "pointerleave":
      case "pointercancel": {
        vec2.set(this.pointer.value, -1, -1);
        this.pointer.canClick = false;
        this.pointer.active = false;
        this.snap.time = 0;
        break;
      }
    }
  }

  private click() {
    const focused = Array.from(this.focus.items).find((i) => i.focused);
    if (focused) {
      const intersected = focused.staticBounds.intersectScreen(
        this.pointer.value
      );
      if (intersected && !this.drag.enabled && this.pointer.canClick) {
        this.cleanup();
        this.events.dispatchEvent(
          new CustomEvent("i:click", {
            detail: focused,
          })
        );
      }
    }
    this.pointer.canClick = false;
  }

  /**
   * Resize handler
   */
  onResize() {
    this.screen.width = window.innerWidth;
    this.screen.height = window.innerHeight;

    this.strip.computePositions();

    // Emit event
    this.events.dispatchEvent(new CustomEvent("i:resize"));
  }

  /**
   * Focus handler
   */
  onFocus(state: ArtworkState, enabled: boolean) {
    if (this.strip.mode === "mobile") {
      return; // see update() method
    }

    enabled = enabled && this.pointer.active;
    if (
      !this.focus.transitioning &&
      enabled &&
      !this.focus.items.includes(state)
    ) {
      this.focus.items.push(state);
      this.events.dispatchEvent(
        new CustomEvent("i:over", {
          detail: state,
        })
      );
      if (this.strip.mode === "xr") {
        // state.setMinimap(this.strip.minimap, "card");
      }
      this.focus.transitioning = true;
      state.focus(true, this.focus).then(() => {
        this.focus.transitioning = false;
        this.focus.active.push(state);
      });
    } else if (!enabled && this.focus.active.includes(state)) {
      this.focus.active.splice(this.focus.active.indexOf(state), 1);
      this.events.dispatchEvent(
        new CustomEvent("i:out", {
          detail: state,
        })
      );
      state.focus(false, this.focus).then(() => {
        this.focus.items.splice(this.focus.items.indexOf(state), 1);
        // if (this.strip.mode === "xr") {
        //   state.unsetMinimap();
        // }
      });
    }

    this.focus.enabled = this.focus.items.length > 0;
  }

  async onSnap() {
    const closest = this.strip.findClosestFromCenter();
    if (!closest) return;

    this.stopSnap();
    this.cleanup();
    this.snap.snapping = true;
    await anime(this.strip, {
      offset: -closest.posX,
    });
    this.snap.snapping = false;
  }

  stopSnap() {
    gsap.killTweensOf(this.strip);
  }

  cleanup() {
    for (const state of this.focus.items) {
      this.onFocus(state, false);
    }
    this.focus.items = [];
    vec2.set(this.pointer.value, -1, -1);
    this.pointer.canClick = false;
    this.pointer.active = false;
    this.drag.enabled = false;
    this.drag.isPointerDown = false;
    this.drag.startTime = 0;
    this.drag.startX = 0;
    this.drag.x = 0;
    this.drag.clientX = 0;
    this.drag.inertia = 0;
  }
}
