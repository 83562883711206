import GradientPrimitives from "@/webgl/entities/UIKit/gradient-primitives/GradientPrimitives";
import UIText2 from "@/webgl/entities/UIKit/text/UIText2";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import Scene from "@/webgl/Scene";
import { vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import Node from "nanogl-node";

const COLOR = vec3.fromValues(0.0, 0.0, 0.0);

export default class LabelMesh {
  root = new Node();
  rectNode = new Node();

  textNode = new Node();
  texts = new Map<string, UIText2>();
  byText: UIText2;
  authorText: UIText2;

  metrics = { width: 0, height: 0, padding: 10 };

  content: string = "JOHN";

  primitives: GradientPrimitives;
  ui: UIKit;

  constructor(scene: Scene) {
    this.ui = new UIKit(scene);
    // this.primitives = new GradientPrimitives(scene);

    this.root.add(this.rectNode);
    this.root.add(this.textNode);
  }

  async load() {
    await Promise.all([this.ui.load()]);
    // await Promise.all([this.ui.load(), this.primitives.load()]);
    this.setText("by");
    this.byText = this.texts.get("by");
    this.authorText = this.texts.get("by");
  }

  setText(text: string) {
    if (!this.texts.has(text)) {
      const uiText = this.ui.textLib.createUIText();
      uiText.setText(text.toUpperCase());
      uiText.setStyle({
        font: text === "by" ? "graphik-600" : "graphik-400",
        fontSize: 12,
        center: true,
      });
      uiText.preRender(); // Build text
      this.textNode.add(uiText.node);
      this.texts.set(text, uiText);
    }

    const by = this.texts.get("by");
    const author = this.texts.get(text);

    this.authorText = author;

    by.node.x = 0;
    author.node.x = by.metrics.width + author.metrics.width * 0.5;

    this.metrics.height = Math.max(author.metrics.height, by.metrics.height);
    this.metrics.width = by.metrics.width * 1.5 + author.metrics.width;

    this.textNode.x = this.metrics.width * -0.5 + by.metrics.width * 0.5;
    this.textNode.y = -3;
    this.textNode.z = 1;

    this.root.x = this.metrics.width * 0.5;
    this.root.x += this.metrics.padding;
    this.root.x += 10;
  }

  render(camera: Camera<ICameraLens>) {
    this.ui.gradientPrimitives.renderBtn(
      camera,
      this.rectNode,
      this.metrics.width + this.metrics.padding,
      this.metrics.height,
      0,
      COLOR
    );
    this.byText.render(camera);
    this.authorText.render(camera);
  }
}
