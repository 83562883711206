import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import StateHelper from "@/webgl/activities/StateHelper";

export default class AudioState {

  _stateHelper: StateHelper;

  constructor() {

    this._stateHelper = new StateHelper([
      { match: "museum", enter: this.enterMuseum, exit: this.exitMuseum },
      { match: "museum.toPainting", enter: this.enterToPainting },
      { match: "painting", enter: this.enterPainting, exit: this.exitPainting },
      { match: "share", enter: this.enterShare, exit: this.exitShare },
      { match: "gallery", enter: this.enterGallery, exit: this.exitGallery },
    ]);

    this._stateHelper.start();

  }

  enterMuseum = () => {

    AudioManager.play(AUDIO_ID.GALLERY_MUSIC);
    AudioManager.fadeIn(AUDIO_ID.GALLERY_MUSIC, 0.4);
    AudioManager.play(AUDIO_ID.OUTSIDE_PAINTING);

  }

  exitMuseum = () => {
    AudioManager.fadeOutStop(AUDIO_ID.GALLERY_MUSIC);
    AudioManager.fadeOutStop(AUDIO_ID.OUTSIDE_PAINTING);
  }

  enterToPainting = () => {
    AudioManager.fadeOut(AUDIO_ID.GALLERY_MUSIC).then(() => AudioManager.stop(AUDIO_ID.GALLERY_MUSIC));
    AudioManager.play(AUDIO_ID.TRANSITION_PAINTING);
  }

  enterPainting = () => {
    AudioManager.play(AUDIO_ID.INSIDE_PAINTING);
    AudioManager.fadeIn(AUDIO_ID.INSIDE_PAINTING, 0.4);
  }

  exitPainting = () => {
    AudioManager.fadeOutStop(AUDIO_ID.INSIDE_PAINTING);
  }

  enterShare = () => {
    AudioManager.fadeIn(AUDIO_ID.INSIDE_PAINTING, 0.4);
  }

  exitShare = () => {
    AudioManager.fadeOutStop(AUDIO_ID.INSIDE_PAINTING);
  }

  enterGallery = () => {
    AudioManager.fadeIn(AUDIO_ID.INSIDE_PAINTING, 0.4);
  }

  exitGallery = () => {
    AudioManager.fadeOutStop(AUDIO_ID.INSIDE_PAINTING);
  }

}

let state = null;

export function createAudioState() {

  if (state === null)
    state = new AudioState();

}