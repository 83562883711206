// // Todo test fork to implement filter + spatial
// // Currently this fork doesnt work with spatial
// // Using a fork that handle audio filters
// // https://github.com/RenaudRohlinger/howler.js
// // import "./vendor/howler.filter.min.js"
// // Maybe this can do the job as a global low filter effect
// // https://www.npmjs.com/package/howler-plugin-effect-chain

import { Howl, Howler, HowlOptions } from "howler";

import Paths from "@/core/Paths";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import UISprite from "./ui.json";
import PaintingSprite from "./painting.json";

export const enum AUDIO_ID {
  GALLERY_MUSIC = "Gallery_Music",
  INSIDE_PAINTING = "Inside_Painting",
  OUTSIDE_PAINTING = "Outside_Painting",
  TRANSITION_PAINTING = "Transition_Painting",
  PAINTING_FX = "Painting_FX",
  UI = "UI"
}


const VZERO = vec3.fromValues(0.0, 0.0, 0.0);
const VUP = vec3.fromValues(0.0, 1.0, 0.0);
const VZ = vec3.fromValues(0.0, 0.0, -1.0);
const V3A = vec3.create();
const V3B = vec3.create();
const V3C = vec3.create();
const QUAT = quat.create();
const MAT3 = mat3.create();

export class AudioLib {

  _audio: Map<AUDIO_ID, Howl> = new Map<AUDIO_ID, Howl>();

  constructor() {

    Howler.autoSuspend = false;
    Howler.html5PoolSize = 0;
    Howler.usingWebAudio = true;

    this.addAudio(AUDIO_ID.GALLERY_MUSIC, { loop: true, volume: 0 });

    this.addAudio(AUDIO_ID.OUTSIDE_PAINTING, { loop: true }).pannerAttr({
      panningModel: 'HRTF',
      refDistance: 1.0,
      rolloffFactor: 1.5,
      distanceModel: 'exponential'
    }).pos(-0.0, 1.6786199808120728, -16);

    this.addAudio(AUDIO_ID.INSIDE_PAINTING, { loop: true, volume: 0 });
    this.addAudio(AUDIO_ID.TRANSITION_PAINTING);

    const psprite = PaintingSprite;
    (psprite as any).volume = 0;
    this._audio.set(AUDIO_ID.PAINTING_FX, new Howl(psprite));
    this._audio.set(AUDIO_ID.UI, new Howl(UISprite));

  }

  updateListener(listenerMatrix: mat4) {

    vec3.transformMat4(V3A, VZERO, listenerMatrix);
    mat3.fromMat4(MAT3, listenerMatrix);
    quat.fromMat3(QUAT, MAT3);
    vec3.transformMat3(V3B, VZ, MAT3);
    vec3.transformMat3(V3C, VUP, MAT3);
    Howler.pos(V3A[0], V3A[1], V3A[2]);
    Howler.orientation(V3B[0], V3B[1], V3B[2], V3C[0], V3C[1], V3C[2]);

  }

  setMute(flag: boolean) {
    Howler.volume(flag ? 0 : 1);
  }

  getAudio(id: AUDIO_ID) {
    return this._audio.get(id);
  }

  play(id: AUDIO_ID) {
    const howl = this._audio.get(id);
    if (howl.playing())
      return;
    howl.seek(0);
    return howl.play()
  }

  stop(id: AUDIO_ID) {
    this._audio.get(id).stop();
  }

  playUI(name: string) {
    if (Howler.state != "running")
      return;
    const howl = this._audio.get(AUDIO_ID.UI);
    howl.play(name);
  }

  fadeOut(id: AUDIO_ID, duration: number = 1000): Promise<any> {
    const howl = this._audio.get(id);
    howl.off("fade");
    howl.fade(howl.volume(), 0, duration);
    return new Promise((resolve) => {
      howl.once("fade", resolve);
    })
  }

  fadeIn(id: AUDIO_ID, volume: number = 1.0, duration: number = 1000): Promise<any> {
    const howl = this._audio.get(id);

    if (!howl.playing())
      this.play(id);

    howl.off("fade");
    howl.fade(howl.volume(), volume, duration);

    return new Promise((resolve) => {
      howl.once("fade", resolve);
    })

  }

  fadeOutStop(id: AUDIO_ID, duration: number = 1000) {
    this.fadeOut(id, duration).then(() => this.stop(id));
  }

  addAudio(id: AUDIO_ID, opts: HowlOptions = {}) {

    const _opts = {
      preload: true,
      autoplay: false
    };
    Object.assign(_opts, opts);

    const howl = new Howl({
      src: [Paths.resolve(`assets/audio/${id}.mp3`)],
      ..._opts
    });

    this._audio.set(id, howl);

    return howl;

  }

}

export default new AudioLib();