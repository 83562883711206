import UrlParams from "@/utils/UrlParams"
import { interpret, State } from "xstate"
import { isState } from "xstate/lib/State"
import AppStateMachine, { AppStateContext, collectAppStateActivities } from "./AppStateMachine"
import GalleryActivityService from "./GalleryActivityService"
import LoadingActivityService from "./LoadingActivityService"
import MuseumActivityService from "./MuseumActivityService"
import PaintingActivityService from "./PaintingActivityService"


const AppService = interpret(AppStateMachine)

AppService.onTransition( (state) => {
  const alist = collectAppStateActivities(state)
  
  if( alist.includes('painting') ) {
    PaintingActivityService.start()
  } else {
    PaintingActivityService.stop()
  }
  

  if( alist.includes('museum') ) {
    MuseumActivityService.start()
  } else {
    MuseumActivityService.stop()
  }

  if( alist.includes('gallery') ) {
    GalleryActivityService.start()
  } else {
    GalleryActivityService.stop()
  }

  if( alist.includes('loading') ) {
    LoadingActivityService.start()
  } else {
    LoadingActivityService.stop()
  }


  MuseumActivityService  .onStateUpdate(state)
  PaintingActivityService.onStateUpdate(state)
  GalleryActivityService .onStateUpdate(state)
  LoadingActivityService .onStateUpdate(state)

})



AppService.start()


/////////////
//////////////////////////////////////

/////////
/////////////////
////////////////////////////////////////////////////
 


/////////////////////////////////////
///////////////////////////////////////////
//

//////////


export default AppService