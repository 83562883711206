import GLTFResource from "@/webgl/assets/GLTFResource";
import GamepadEntity from "@/webgl/entities/xr-module/gamepad/GamepadEntity";
import Camera from "nanogl-camera";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { StandardPass } from "@/webgl/lib/nanogl-pbr/src/StandardPass";
import WebXRInputs from "@/webgl/xr/WebXRInputs";
import { XRHandedness } from "webxr";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Paths from "@/core/Paths";
import LightSetup from "nanogl-pbr/lighting/LightSetup";

export default class Gamepads {

  node: Node;
  inputs: WebXRInputs;
  list: Map<XRHandedness, GamepadEntity>;
  gltf: Gltf;

  constructor(
    public readonly xrmodule: XRModule
  ) {

    this.list = new Map<XRHandedness, GamepadEntity>();

    const ctrls = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/controllers/controllers.gltf"),
      this.xrmodule.scene
    )


    this.node = new Node();
    this.xrmodule.resources.add(ctrls, "controllers");

    this.xrmodule.resources.response()
      .then(this.onLoaded);

  }

  init(inputs: WebXRInputs) {
    this.inputs = inputs;
  }

  onLoaded = () => {

    const ctrlGltf = this.xrmodule.resources.get("controllers") as Gltf;
    this.gltf = ctrlGltf;
    const node = ctrlGltf.root as GltfNode;
    this.node.add(node);
    console.log(ctrlGltf);

    this.list.set(
      "left",
      new GamepadEntity(
        "left",
        ctrlGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "left_quest2_controller_world"),
        ctrlGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "left_quest2_mesh")
      )
    );

    this.list.set(
      "right",
      new GamepadEntity(
        "right",
        ctrlGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "right_quest2_controller_world"),
        ctrlGltf.getElementByName<GltfTypes.NODE>(GltfTypes.NODE, "right_quest2_mesh")
      )
    );

    for (let i = 0; i < ctrlGltf.materials.length; i++) {
      this.xrmodule.scene.lights.registerPass((ctrlGltf.materials[i] as Material).materialPass as StandardPass);
    }

  }

  setCustomLightSetup( setup:LightSetup){
    for (let i = 0; i < this.gltf.materials.length; i++) {
      ((this.gltf.materials[i] as Material).materialPass as StandardPass).setLightSetup(setup);
    }
  }

  restoreDefaultLightSetup(){
    for (let i = 0; i < this.gltf.materials.length; i++) {
      this.xrmodule.scene.lights.registerPass((this.gltf.materials[i] as Material).materialPass as StandardPass);
    }
  }

  preRender() {

    for (const input of this.inputs.list) {
      this.list.get(input.source.handedness)
        .update(input);
    }

  }

  render(
    camera: Camera
  ) {

    for (const renderable of this.gltf.renderables) {
      renderable.render(
        this.xrmodule.scene,
        camera,
        Masks.OPAQUE,
        Passes.DEFAULT
      )
    }

  }

}