import Deferred from "@/core/Deferred";
import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import Fader from "@/webgl/entities/fader/Fader";
import LabelRect from "@/webgl/entities/UIKit/label-rect/LabelRect";
import UIText2 from "@/webgl/entities/UIKit/text/UIText2";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { XRHandedness } from "webxr";
import Node from "nanogl-node";
import RaycastButton from "@/webgl/entities/UIKit/raycast-button/RaycastButton";
import i18n from '@/core/i18n'
const { t } = i18n.global

const VZ = vec3.fromValues(0.0, 0.0, 1.0);
const V3A = vec3.create();
const V3B = vec3.create();
const M4 = mat4.create();
const M3 = mat3.create();
const QUAT = quat.create();

const STEPS = [
  {
    hand: "left" as XRHandedness,
    key: "xr.tuto_settings_left"
  },
  {
    hand: "right" as XRHandedness,
    key: "xr.tuto_aim_photo"
  },
  {
    hand: "right" as XRHandedness,
    key: "xr.tuto_format_photo"
  },
  {
    key: "xr.angle_of_view"
  }
]

export default class XRPhotoTutorial {

  public node: Node;
  public label: LabelRect;

  public step: number = 0;
  public active: boolean = false;
  public title: UIText2;
  public nextBtn: RaycastButton;
  public skipBtn: RaycastButton;

  private _fader: Fader;
  private _complete: boolean = false;
  private _time: number = 0;
  private _deffered: Deferred;
  private _pulseCount: number;

  get xrmodule(): XRModule {
    return this.activity.xrmodule;
  }

  constructor(
    public activity: PaintingXRActivity
  ) {

    const xrmodule = activity.xrmodule;
    this.label = new LabelRect(xrmodule.ui);
    this.label.setPadding(0.35, 0.35);

    this._fader = new Fader(activity.scene);

    this.nextBtn = new RaycastButton(this.xrmodule.ui, "xr.next");
    this.nextBtn.onClick.on(this.next);

    this.skipBtn = new RaycastButton(this.xrmodule.ui, "xr.skip");
    this.skipBtn.onClick.on(this.skip);

    this.title = activity.xrmodule.ui.textLib.createUIText();
    this.title.setStyle({
      fontSize: 0.15,
      center: true
    });

    this.node = new Node();

    this.node.add(this.label.node);
    this.node.add(this.title.node);
    this.node.add(this.nextBtn.node);
    this.node.add(this.skipBtn.node);

    this.label.node.z = 0;
    this.label.node.y = 0.1;

    this.title.node.z = 0;
    this.title.node.y = 0.75;

    this.nextBtn.node.y = -1.0;
    this.skipBtn.node.y = -1.0;
    this.nextBtn.node.x = -this.xrmodule.ui.textRenderer.texts.get("xr.next").width * 1.5;
    this.skipBtn.node.x = this.xrmodule.ui.textRenderer.texts.get("xr.skip").width * 1.5;

    this._deffered = new Deferred();

  }

  updatePosition() {

    mat4.invert(M4, this.xrmodule.xrroot._wmatrix);
    mat3.fromMat4(M3, M4);
    vec3.transformMat3(V3A, this.xrmodule.xrcamera.forward, M3);
    vec3.scale(V3A, V3A, 4.5);

    this.node.x = V3A[0];
    this.node.y = this.xrmodule.xrcamera.head.position[1] + 0.2;
    this.node.z = V3A[2];

    V3A[1] = 0;
    vec3.normalize(V3A, V3A);
    const a = Math.atan2(V3A[0], V3A[2]);
    quat.identity(QUAT)
    quat.rotateY(QUAT, QUAT, Math.PI);
    quat.rotateY(QUAT, QUAT, a);
    this.node.rotation.set(QUAT);

    this.node.updateWorldMatrix();

    mat3.fromMat4(M3, this.node._wmatrix);
    vec3.transformMat3(V3A, VZ, M3);

    vec3.scaleAndAdd(V3B, this.node._wposition as vec3, V3A, 0.07);
    this.xrmodule.raycaster.udpdatePlane(V3B, V3A);

  }

  updateTitle() {
    this.title.setText(
      `${t('xr.tutorial')} ${this.step + 1}/${STEPS.length}`
    )
  }

  start(): Promise<any> {

    if (!this._complete) {

      this.xrmodule.xrroot.add(this.node);

      this.label.show();
      this.updatePosition();

      this.xrmodule.raycaster.enable();
      this.xrmodule.raycaster.add(this.nextBtn);
      this.xrmodule.raycaster.add(this.skipBtn);

      this._fader.fadeIn(0.4);
      this.nextBtn.show(0.5);
      this.skipBtn.show(0.8);

      this.active = true;
      this.step = 0;
      this.applyStep();

    }

    return this._deffered.promise;

  }

  stop() {
    this.nextBtn.onClick.off(this.next);
    this.skipBtn.onClick.off(this.skip);
    this._fader.fadeOut();
    this.nextBtn.hide();
    this.skipBtn.hide();
    this.xrmodule.raycaster.remove(this.nextBtn);
    this.xrmodule.raycaster.remove(this.skipBtn);
    this.xrmodule.raycaster.disable();
    this.xrmodule.xrroot.remove(this.node);
    this.active = false;
    this._complete = true;
    this.activity.postprocess.settings.reset();
    this.activity.xrmodule.xrcamera.head.remove(this.label.node);
    this._deffered.resolve();
  }

  next = () => {

    this.step += 1;
    if (this.step == STEPS.length) {
      this.stop();
      return;
    }
    this.applyStep();

  }

  skip = () => {

    this.step = STEPS.length
    this.stop();

  }

  applyStep() {

    this._time = 0;
    this._pulseCount = 0;
    this.label.setText(STEPS[this.step].key);
    this.updateTitle();

    if (this.step >= 1) {
      this.activity.snapshot.enable();
      this.activity.postprocess.settings.reset();
    }

    if (STEPS[this.step].hand) {
      this.activity.xrmodule.xrview.inputs.getInput(STEPS[this.step].hand).gamepad.pulse(0.5, 100);
    }

  }

  preRender() {
    if (!this.active)
      return;

    this.title.preRender();

    this._time += this.activity.scene.dt;

    if (this._time > 1 && this._pulseCount < 1) {

      this._time = 0;
      this._pulseCount += 1;

      if (STEPS[this.step].hand) {
        this.activity.xrmodule.xrview.inputs.getInput(STEPS[this.step].hand).gamepad.pulse(0.25, 100);
      }

    }

  }

  render(camera: Camera) {

    if (!this.active)
      return;

    this._fader.render(camera);
    this.nextBtn.render(camera);
    this.skipBtn.render(camera);

    this.label.renderRect(camera);
    this.title.render(camera);
    this.label.renderText([0.0270944, 0.0270944, 0.0270944, 1.0]);

  }

}