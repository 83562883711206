
// Probe 
var coeffs = 
[
    /* Band 0 */  2.575372908273038330,  2.587364026377832804,  3.153452692519524092 ,
    /* Band 1 */ -0.643956698963439989, -0.726680450110987253, -0.906727573832293410 ,   0.265707031994279030,  0.389430667293904853,  0.553942648778842917, -0.316742989505655770, -0.273441522212743926, -0.288542336485435036,
    /* Band 2 */   0.227391895834642838,  0.262802063221328819,  0.331959736268884087 ,  -0.417031162737808325, -0.424938609404174605, -0.532065636767851902 ,   0.143012342660083364,  0.134599671831291456,  0.140140321005044532 ,  -0.066120090641575119, -0.075839595141751134, -0.090669375760962914 ,  -0.141153908422792213, -0.142343002404043073, -0.170307044428286036 ,
    /* Band 3 */   0.174059645670002761,  0.184008521935684621,  0.220466738062293693 ,   0.098493751711653352,  0.091505580071581674,  0.116863039495125151 ,   0.308269281293653219,  0.313268241992384788,  0.412941334036962204 ,   0.285070289201069971,  0.287171108577903456,  0.357468565999956911 ,  -0.166452887952374340, -0.149629428092176803, -0.163067056607888089 ,  -0.005162056391614828, -0.020084149602361104, -0.022918214963706416 ,   0.238770486183405128,  0.247470618497776212,  0.294393106466694088 ,
    /* Band 4 */   0.221683125896788846,  0.232305574791665276,  0.283456810904737400 ,   0.228058935979696592,  0.250811898505269859,  0.313839436384270309 ,  -0.166121822507471706, -0.161573817996444075, -0.201489537939821134 ,   0.056134094333117793,  0.044153441515043371,  0.028680149096048282 ,   0.418910104360961100,  0.451878573659488247,  0.543116446838649458 ,  -0.003333708470091854, -0.010063076687920438, -0.016413415128114686 ,  -0.141201101557341696, -0.113361268936945661, -0.123286880432687665 ,  -0.022880765092579056, -0.015053671445046692, -0.026467753995402622 ,   0.282433116810780038,  0.291365505818562687,  0.353083744426447910 
];

module.exports = coeffs;