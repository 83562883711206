import TextRenderer, { FontFamily } from "@/webgl/entities/UIKit/text/TextRenderer";

import i18n from '@/core/i18n'
import GLFontResource from "@/webgl/entities/UIKit/GLFontResource";
import { GLContext } from "nanogl/types";
import Paths from "@/core/Paths";
import UIText2 from "./text/UIText2";


const { t } = i18n.global

export default class TextLibrary {

  public fonts: Map<FontFamily, GLFontResource>;
  private renderer: TextRenderer;

  constructor(
    public readonly gl: GLContext
  ) {
    this.fonts = new Map<FontFamily, GLFontResource>();
  }

  // Todo on locale change
  clearText() {

  }

  createFont(family: FontFamily, file: string) {
    this.fonts.set(family, new GLFontResource(
      this.gl,
      Paths.resolve("assets/webgl/fonts"),
      file
    ))

  }

  load() {
    const p = [];
    this.fonts.forEach((font, key) => {
      p.push(font.load());
    })
    return Promise.all(p);
  }

  attach(renderer: TextRenderer) {
    this.renderer = renderer;
  }

  buildXR() {

    const renderer = this.renderer;

    let k = "";
    renderer.registerText(k = "xr.photo_gallery", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.painting", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.museum", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.teleport", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.dive_in", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.photo_mode", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.explore_mode", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.cancel", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.validate", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.yes", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.no", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.leave_xp", t(k), {center: true, fontSize: 0.015});
    renderer.registerText(k = "xr.resume_xp", t(k), {center: true, fontSize: 0.015});
    renderer.registerText(k = "xr.reset", t(k), { center: true, fontSize: 0.015 });
    renderer.registerText(k = "xr.contrast", t(k), { center: true, fontSize: 0.5 });
    renderer.registerText(k = "xr.brightness", t(k), { center: true, fontSize: 0.5 });
    renderer.registerText(k = "xr.saturation", t(k), { center: true, fontSize: 0.5 });
    renderer.registerText(k = "xr.temperature", t(k), { center: true, fontSize: 0.5 });
    renderer.registerText(k = "xr.download", t(k), { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText(k = "xr.image_downloaded", t(k), { center: true, fontSize: 0.15, font: "graphik-400" });

    renderer.registerText(k = "xr.slide_picture", t(k), { center: true, fontSize: 0.015 });

    renderer.registerText("xr.close_settings", t("xr.explore_mode"), { center: true, fontSize: 0.5 });
    renderer.registerText("photo_gallery_btn", t("xr.photo_gallery"), { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText("painting_btn", t("xr.painting"), { center: true, fontSize: 0.15, font: "graphik-400" });

    // TUTORIAL PHOTO
    renderer.registerText(k = "xr.try_photo", t(k), { center: true, fontSize: 0.035 });
    renderer.registerText(k = "xr.tuto_settings_left", t(k), { center: true, fontSize: 0.2 * 0.9 });
    renderer.registerText(k = "xr.tuto_aim_photo", t(k), { center: true, fontSize: 0.2 * 0.9 });
    renderer.registerText(k = "xr.tuto_format_photo", t(k), { center: true, fontSize: 0.2 * 0.9 });
    renderer.registerText(k = "xr.angle_of_view", t(k), { center: true, fontSize: 0.2 * 0.9 });

    renderer.registerText(k = "xr.next", t(k), { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText(k = "xr.skip", t(k), { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText(k = "xr.expo_name", t(k), { center: true, fontSize: 1.5, font: "new-paris-400" });
    renderer.registerText(k = "loading.claim", t(k).toUpperCase(), { justifyCenter: true, fontSize: 0.1, lineHeight: 1.5, font: "graphik-400", boxWidth: 0.52 });

    renderer.registerText(k = "gallery.title", t(k).toUpperCase(), { center: true, fontSize: 0.4, font: "new-paris-400" });
    renderer.registerText(k = "gallery.subtitle", t(k).toUpperCase(), { center: true, fontSize: 0.08, font: "graphik-400" });


    renderer.registerText("photo_1", "Photo 1", { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText("photo_2", "Photo 2", { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText("photo_3", "Photo 3", { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText("photo_4", "Photo 4", { center: true, fontSize: 0.15, font: "graphik-400" });
    renderer.registerText("photo_5", "Photo 5", { center: true, fontSize: 0.15, font: "graphik-400" });



    // this.renderer = renderer;

  }

  createUIText(): UIText2 {

    const txt = new UIText2(this.renderer.context);
    txt.renderer.setFonts(this.fonts);
    return txt;

  }

}