import ArtworkMesh from "./Mesh";
import ArtworkTexturePool from "./TexturePool";
import type { ArtworkData } from "../types";
import type Camera from "nanogl-camera";
import ArtworkProxy from "./Proxy";
import Node from "nanogl-node";
import { clamp } from "@/webgl/math";
import { quat } from "gl-matrix";
import ArtworkInteractions from "./Interactions";
import MinimapMesh from "./MinimapMesh";
import LabelMesh from "./LabelMesh";
import Scene from "@/webgl/Scene";
import ArtworkState from "./State";
import { anime, animeFromTo } from "@/utils/anime";
import ArtworkFog from "./Fog";

const QUAT = quat.create();
let id = 0;

export default class ArtworkStrip {
  public root = new Node();

  private entries: ArtworkProxy[] = [];

  public gap = 15;
  public offset = 0;

  private length = 0;
  private oldLength = 0;
  public minLength = 4000;

  public curve = {
    width: 0,
    depth: 500,
    minWidth: 2560,
  };

  private loop = {
    length: Number.EPSILON,
    count: 3,
    repeat: 1,
  };

  public autoScroll = {
    enabled: true,
    speed: 0.0,
    _speed: 0.0,
    speedTarget: 0.5,
    delay: {
      value: 0,
      target: 250,
    },
  };

  public introTween = {
    running: false,
    gap: 0,
    user: false,
    tweakX: 0,
    width: 1000,
  };

  public time = {
    current: 0,
    delta: 0,
  };

  public next = {
    timeoutID: -1,
    delay: 250, // in milliseconds
    pixelPerSecond: 1_000,
  };

  public mesh: ArtworkMesh;
  public textures: ArtworkTexturePool;
  public interactions: ArtworkInteractions;
  public minimap: MinimapMesh;
  public label: LabelMesh;
  public fog = new ArtworkFog();

  public appendBehavior = "default" as "default" | "reveal";

  public visibleStates: ArtworkState[] = [];
  public states = {
    index: -1,
  };

  private _mode: "desktop" | "mobile" | "xr" = "desktop";

  get mode() {
    return this._mode;
  }

  set mode(value) {
    if (value === this._mode) return;
    this._mode = value;
    this.interactions.inputs.mode = value === "xr" ? "xr" : "browser";
  }

  constructor(scene: Scene) {
    this.prepend = this.prepend.bind(this);
    this.append = this.append.bind(this);
    this.textures = new ArtworkTexturePool(scene.gl);

    this.interactions = new ArtworkInteractions(this);
    this.minimap = new MinimapMesh(scene);
    this.mesh = new ArtworkMesh(scene.gl, scene.glstate);
    this.label = new LabelMesh(scene);

    this.root.add(this.fog.root);

    this.root.add(this.minimap.node);
    this.minimap.node.z = -600.0;
    this.minimap.node.y = -160.0;
    this.minimap.node.setScale(70);

  }

  enable() {
    this.interactions.enable();
    this.time.current = performance.now();
  }

  disable() {
    this.interactions.disable();
  }

  load() {
    return Promise.all([
      this.textures.alloc(),
      this.minimap.load(),
      this.label.load(),
    ]);
  }

  prepend(data: ArtworkData) {
    const proxy = new ArtworkProxy(this);
    proxy.setData(data);
    proxy.posX = 0;
    this.entries.unshift(proxy);
    this.computePositions();
  }

  /**
   * Create a node and update strip metrics
   */
  append(data: ArtworkData) {
    const proxy = new ArtworkProxy(this);
    proxy.queued = this.appendBehavior === "reveal";
    proxy.setData(data);

    let x = this.length;
    proxy.posX = x + proxy.width * 0.5;
    x = x + proxy.width + this.gap;
    this.length = x;
    // if (!proxy.queued)
    this.oldLength = this.length;

    this.root.add(proxy.root);
    this.entries.push(proxy);
    this.addQueue(proxy);

    const count = Math.ceil(
      Math.max(this.minLength, this.length) / this.length
    );
    this.loop.repeat = count;
    this.loop.count = 2;
    this.loop.length = this.length * count;
  }

  computePositions() {
    this.length = 0;
    for (const proxy of this.entries) {
      let x = this.length;
      proxy.posX = x + proxy.width * 0.5;
      x = x + proxy.width + this.gap;
      this.length = x;
    }
  }

  /**
   * A complete rebuild of the strip
   */
  rebuild() {
    for (let i = 0; i < this.entries.length; i++) {
      const entry = this.entries[i];
      this.root.remove(entry.root);
      // this.textures.free(entry.source);
    }

    const datas = this.entries.slice(0).map((proxy) => proxy.data);
    this.length = 0;
    this.entries = [];

    const appendBehavior = this.appendBehavior;
    this.appendBehavior = "default";
    datas.forEach(this.append, this);
    this.appendBehavior = appendBehavior;
  }

  preRender(camera: Camera) {
    const currentTime = performance.now();
    this.time.delta = currentTime - this.time.current;
    this.time.current = currentTime;
    this.textures.prepare(this.time.delta / 1000);

    // Compute strip position
    let offset = 0;

    // AutoScroll
    const canAutoScroll =
      !this.interactions.drag.isPointerDown && this.autoScroll.enabled;
    this.autoScroll._speed = canAutoScroll ? this.autoScroll.speedTarget : 0;
    this.autoScroll.speed +=
      (this.autoScroll._speed - this.autoScroll.speed) *
      0.05 *
      (this.time.delta * 0.1);

    this.offset -= this.autoScroll.speed * (this.time.delta * 0.1);

    // Dragging
    if (this.interactions.drag.isPointerDown) {
      this.offset = this.loopOffset(this.offset);
      offset = this.offset;
      offset += this.interactions.drag.x;
      offset = this.loopOffset(offset);
    } else {
      this.interactions.drag.inertia *= this.interactions.drag.friction;
      this.offset += this.interactions.drag.inertia;
      this.offset = this.loopOffset(this.offset);
      offset = this.offset;
    }

    // Render entry. This is the finite strip.
    for (let i = 0; i < this.entries.length; i++) {
      const proxy = this.entries[i];
      this.placeItem(proxy, 0, camera, offset);
    }

    this.root.updateWorldMatrix();

    for (let i = 0; i < this.entries.length; i++) {
      const proxy = this.entries[i];
      const state = proxy.currentState();
      // Update node state
      state.update(camera);

      // Handle focus state
      if (this._mode !== "xr") {
        this.interactions.onFocus(
          state,
          !this.interactions.drag.enabled &&
          state.staticBounds.intersectScreen(this.interactions.pointer.value)
        );
      }

      // Register visible state
      if (state.visible) this.visibleStates.push(state);

    }

    // Generate extra item. This make the strip infinite.
    // this.generate(camera, offset, 1); // Right
    // if (!this.introTween.running) this.generate(camera, offset, -1); // Left
    // Update interactions
    this.interactions.nextLoading.stripOffset = offset;
    this.interactions.nextLoading.stripLength = this.length;
    this.interactions.update(this.time.delta);
  }

  // Make it loop
  loopOffset(offset: number) {
    return clamp(offset, -this.length, 0.0);
  }

  render(camera: Camera) {
    for (let i = 0; i < this.entries.length; i++) {
      const proxy = this.entries[i];
      proxy.render(camera, this.mesh, this.label);
    }

    if (this.mode == "xr")
      this.minimap.render(camera);

  }

  postRender() {
    // Reset state index to -1 and free unused resources
    for (let i = 0; i < this.entries.length; i++) {
      const proxy = this.entries[i];
      proxy.resetState();
      // this.textures.free(proxy.source);
    }

    // Empty visibles states
    this.visibleStates = [];

    // Swap previous/current state list
    this.states.index = -1;
  }

  addQueue(proxy: ArtworkProxy) {
    if (!proxy.queued) return;
    clearTimeout(this.next.timeoutID);
    this.next.timeoutID = setTimeout(
      this.revealQueued.bind(this),
      this.next.delay
    );
  }

  async revealQueued() {
    const duration = (this.length - this.oldLength) / this.next.pixelPerSecond;

    const onUpdate = (force = false) => {
      for (const proxy of this.entries) {
        if (proxy.queued) {
          const posX = proxy.posX + proxy.width * 0.5;
          if (force || (this.oldLength >= posX && proxy.queued)) {
            proxy.queued = false;
            proxy.eachState((state) => state.reveal());
          }
        }
      }
    };

    await anime(this, {
      oldLength: this.length,
      duration,
      onUpdate: () => onUpdate(false),
      onComplete: () => {
        this.oldLength = this.length;
        onUpdate(true);
      },
    });
  }

  /**
   * Generate extra nodes to avoid gap
   */
  private generate(camera: Camera, offset: number, direction = 1) {
    const maxIteration = this.loop.count * this.loop.repeat;
    let jump = 0;

    // Render extra nodes
    for (let iteration = 1; iteration <= maxIteration; iteration++) {
      jump = this.oldLength * iteration;
      for (let i = 0; i < this.entries.length; i++) {
        const proxy = this.entries[i];
        this.placeItem(proxy, jump * direction, camera, offset);
      }
    }
  }

  /**
   * Create a state, compute position and update state
   */
  private placeItem(
    proxy: ArtworkProxy,
    posX: number,
    camera: Camera,
    offset: number
  ) {
    const state = proxy.createState(this);
    state.index = ++this.states.index;

    // Set posX
    state.posX = proxy.posX + posX;
    // state.posX += this.fog.width * this.fog.dpi;

    // Set position
    state.root.x = state.posX * (this.introTween.gap + 1);

    // Apply movement
    state.root.x += offset;

    for (const focused of this.interactions.focus.items) {
      if (state.posX > focused.posX) {
        state.root.x += focused.gap;
      } else if (state.posX < focused.posX) {
        state.root.x -= focused.gap;
      }
    }

    const cuv = this.curve.width * 0.5;

    // Compute position and angle
    const x0 = state.root.x - state.width * 0.5;
    const x1 = state.root.x + state.width * 0.5;
    const t0 = (cuv + clamp(x0, -cuv, cuv)) / (cuv * 2);
    const t1 = (cuv + clamp(x1, -cuv, cuv)) / (cuv * 2);
    const y0 = Math.sin(t0 * Math.PI) * this.curve.depth;
    const y1 = Math.sin(t1 * Math.PI) * this.curve.depth;

    const x = x0 + (x1 - x0) * 0.5;
    const y = y0 + (y1 - y0) * 0.5;
    const angle = Math.atan2(y1 - y0, x1 - x0);

    // Apply position
    state.root.x = x;
    state.root.z = -y;

    // Apply rotation
    quat.identity(QUAT);
    quat.rotateY(QUAT, QUAT, angle);
    quat.copy(state.root.rotation, QUAT);

    // Update node state
    // state.update(camera);

    // // Handle focus state
    // if (this._mode !== "xr") {
    //   this.interactions.onFocus(
    //     state,
    //     !this.interactions.drag.enabled &&
    //       state.staticBounds.intersectScreen(this.interactions.pointer.value)
    //   );
    // }

    // // Register visible state
    // if (state.visible) this.visibleStates.push(state);
  }

  async play() {
    this.introTween.running = true;
    const autoScroll = this.autoScroll.enabled;
    const snap = this.interactions.snap.enabled;
    this.autoScroll.enabled = false;
    this.interactions.snap.enabled = false;
    this.offset = 0;

    this.centerArtworkForIntro();

    const from = 0; //this.introTween.width - this.introTween.tweakX;
    const to = this.introTween.width * -1 - this.introTween.tweakX;

    await Promise.all([
      animeFromTo(this, {
        offset: [from, to],
        delay: 1,
        duration: 2,
      }),

      animeFromTo(this.introTween, {
        gap: [4, 0],
        delay: 1,
        duration: 2,
      }),
    ]);

    this.autoScroll.enabled = autoScroll;
    this.autoScroll._speed = autoScroll ? this.autoScroll.speedTarget : 0;
    this.autoScroll.speed = autoScroll ? this.autoScroll.speedTarget : 0;
    this.autoScroll.delay.value = this.autoScroll.delay.target;
    this.interactions.snap.enabled = snap;
    this.interactions.snap.active = true;
    this.interactions.snap.time = this.interactions.snap.delay + 1;
    this.introTween.running = false;
    this.interactions.nextLoading.enabled = true;
  }

  private centerArtworkForIntro() {
    const center = this.fog.radius * 2;

    // If the first image is from user swap
    if (this.introTween.user) {
      const first = this.entries[0];

      let length = 0;
      let index = 0;
      for (let i = 0; i < this.entries.length; i++) {
        const proxy = this.entries[i];
        length += proxy.width + this.gap;
        index = i;
        if (length > center) break;
      }

      this.entries.splice(index + 1, 0, first);
      this.entries.shift();

      this.computePositions();

      this.introTween.tweakX = this.entries[index].posX - center;
    } else {
      const closest = {
        index: -1,
        entry: null as ArtworkProxy,
        diff: Number.MAX_VALUE,
        adiff: Number.MAX_VALUE,
      };

      for (let i = 0; i < this.entries.length; i++) {
        const entry = this.entries[i];
        const diff = entry.posX - center;
        const adiff = Math.abs(diff);
        if (adiff < closest.adiff) {
          closest.diff = diff;
          closest.adiff = adiff;
          closest.entry = entry;
          closest.index = i;
        }
      }

      this.introTween.tweakX = closest.diff;
    }
  }

  findClosestFromCenter() {
    const center = 0.5;
    let diff = Number.MAX_VALUE;
    let focused: ArtworkState = null;
    for (const state of this.visibleStates) {
      const { screen } = state.staticBounds;
      const d = Math.min(
        Math.abs(screen.left - center),
        Math.abs(screen.right - center)
      );
      if (d < diff) {
        diff = d;
        focused = state;
      }
    }
    return focused;
  }
}
