
import './Config';

import GLView from './GLView'
import Scene from './Scene'

import ActivitiesManager from './activities/ActivitiesManager';



export default class GLApp {

  static get activities() : ActivitiesManager {
    return this.getInstance().scene.activities
  }
  


  glview: GLView
  scene: Scene


  private _loadPromise: Promise<void> = null
  
  private static _instance: GLApp = null
  
  static getInstance(): GLApp {
    if (this._instance === null) {
      this._instance = new GLApp()
    }
    return this._instance
  }

  readonly canvas: HTMLCanvasElement


  constructor() {
    this.canvas = document.createElement('canvas')
    this.glview = new GLView(this.canvas);
    this.scene = new Scene();
    this.scene.ilayer = this.canvas;
    // this.scene.ilayer = document.body
    this.scene.init(this.glview);
  }


  getResources() {
    return this.scene.Resources;
  }


  load(): Promise<void> {
    if (this._loadPromise === null) {
      this._loadPromise = this._doLoad()
    }
    return this._loadPromise
  }

  async _doLoad() {
    await this.scene.load();
  }

  start() {
    this.glview.start();
  }

  stop() {
    this.glview.stop();
  }


  requestXR(): Promise<void> {
    return this.scene.requestXR()
  }

}