import GraniteStencilPass from "@/webgl/entities/granite-probe/GraniteStencilPass";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import MaterialPass from "nanogl-pbr/MaterialPass";
import { GLContext } from "nanogl/types";

const MAT_ID = "granite-stencil"

const GL_KEEP       = 0x1E00;
const GL_REPLACE    = 0x1E01;
const GL_INCR       = 0x1E02;
const GL_DECR       = 0x1E03;
const GL_INVERT     = 0x150A;
const GL_INCR_WRAP  = 0x8507;
const GL_DECR_WRAP  = 0x8508;
const GL_ALWAYS     = 0x0207;

export default class GraniteStencilMaterial extends BaseMaterial {

  materialPass: MaterialPass;

  constructor(gl: GLContext, name: string = MAT_ID) {

    super(gl, MAT_ID);

    this.mask = Masks.OPAQUE;
    
    this.materialPass = new GraniteStencilPass();
    this.addPass(this.materialPass, Passes.STENCIL)

    this.glconfig
      .enableDepthTest(false)
      .enableBlend(false)
      .enableCullface(true)
      .enableStencil()
      .colorMask(false, false, false, false)
      .stencilOp( GL_KEEP, GL_KEEP, GL_REPLACE );

    this.setMask(1);

  }

  setMask( mask : number ){
    this.glconfig
      .stencilFunc(GL_ALWAYS, 1<<mask, 1<<mask )
      // .stencilFunc(GL_ALWAYS, 1<<mask, 1<<mask )
      .stencilMask( 1<<mask )
  }


}