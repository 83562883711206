import GradientLinePass from "@/webgl/glsl/gradient-line/GradientLinePass";
import XRSettingsWheel from "@/webgl/activities/PaintingXRActivity/settings-wheel/XRSettingsWheel";
import { Passes } from "@/webgl/glsl/Passes";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { vec2 } from "gl-matrix";
import gsap from "gsap";
import { Uniform } from "nanogl-pbr/Input";

export default class XRSettingsToggleButton {

  isActive: boolean = false;

  active: GltfNode;
  idle: GltfNode;
  gradientCircle: GltfNode;

  _tween: gsap.core.Tween;
  _scale: number = 0.5;

  time: number = 0;
  gradientOffset: Uniform;
  _goffset: vec2 = vec2.create();
  z: number = 0;

  _renderables: MeshRenderer[];
  get Renderable(): MeshRenderer[] {
    return this._renderables
  }

  constructor(
    public wheel: XRSettingsWheel,
    public node: GltfNode
  ) {

    this.node.x = 0.5;
    this.node.setScale(2);

    this.active = node.findChild("active");
    this.idle = node.findChild("idle");
    this.gradientCircle = node.findChild("gradient-circle");
    this.active.setScale(0);
    this.gradientCircle.setScale(0.5);

    const gpass = (this.gradientCircle.renderable as MeshRenderer).materials[0].getPass(Passes.DEFAULT).pass as GradientLinePass;
    this.gradientOffset = gpass.texcoordOffset.attachUniform("uGradientOffset", 2);
    this.gradientOffset.set(...this._goffset);
    gpass.color = wheel.wheelGltf.getElementByName<GltfTypes.TEXTURE>(GltfTypes.TEXTURE, "gradient_purple").glTexture;

    this._renderables = [
      this.idle.renderable,
      this.gradientCircle.renderable,
    ]

  }

  preRender(dt: number) {

    this.time += dt;
    this._goffset[0] = Math.cos(this.time) * 0.25;
    this._goffset[1] = Math.sin(this.time) * 0.25;
    this.gradientOffset.set(...this._goffset)
    // this._renderables[0] = this.isActive ? this.active.renderable : this.idle.renderable;

  }

  setActive() {
    gsap.killTweensOf(this);
    this.isActive = true;
    gsap.to(this, {
      _scale: 0.0,
      z: 0.1,
      duration: 0.3,
      ease: "power2.out",
      onUpdate: () => {
        this.gradientCircle.setScale(this._scale);
        this.gradientCircle.z = this.z;
        this.idle.setScale(this._scale * 2.0);
        // this.active.setScale(this._scale);
        // this.active.z = this.z;
      }
    });
  }

  setIdle() {
    gsap.killTweensOf(this);
    gsap.to(this, {
      _scale: 0.5,
      duration: 0.3,
      z: 0,
      ease: "power2.out",
      onUpdate: () => {
        this.gradientCircle.setScale(this._scale);
        this.gradientCircle.z = this.z;
        this.idle.setScale(this._scale * 2.0);
        // this.active.setScale(this._scale);
        // this.active.z = this.z;
      },
      onComplete: () => {
        this.isActive = false;
      }
    });
  }

}