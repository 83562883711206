
import Config from 'nanogl-state/config'
import Fbo    from 'nanogl/fbo'
import Blur   from '@/webgl/glsl/blur'
import {mat4} from 'gl-matrix'
import Scene from '@/webgl/Scene';
import Plane from '@/webgl/math/Plane';
import Texture2D from 'nanogl/texture-2d';
import Camera from 'nanogl-camera';
import { isWebgl2 } from 'nanogl/types';
import RenderBuffer from 'nanogl/renderbuffer';
import { MsaaFbo } from '@/webgl/gl/MsaaFbo';
import { CreatePane } from '@/dev/Tweak';


const M4A   = mat4.create();
const M4B   = mat4.create();



// const SUBSCALE = 4

export default class GraniteReflect {

  viewprojCopy: mat4;
  scene: Scene;
  plane: Plane;
  size: number;
  fbo: MsaaFbo;
  groundHeight = 1.80;
  globalCfg: Config;
  scale: number = 0.6;

  get Scale(): number {
    return this.scale * 1 / this.scene.glview.pixelRatio;
  }

  constructor( scene:Scene, plane?:Plane ){


    this.viewprojCopy = mat4.create()
    this.scene = scene;
    this.plane = plane;

    this.size = 256;
    
    const gl = scene.gl;

    const fbo = new MsaaFbo( gl, 1, true );
    fbo.setSize( this.size, this.size );
    this.globalCfg = new Config()
      .frontFace( scene.gl.CW )
    
    const color = fbo.getColorTexture()
    color.bind()
    color.clamp()
    color.setFilter( true, false, false )

    this.fbo = fbo;

/////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////

  }


  blitRenderBuffer(){
    this.fbo.blitMsaa();
  }

  prepare( w:number, h:number ){
    this.fbo.renderFbo.bind();
    this.fbo.setSize(w * this.Scale, h * this.Scale);
    const gl = this.scene.gl;
    gl.viewport( 0, 0, w * this.Scale, w * this.Scale );
    gl.clearColor( 0, 0, 0, 1 );
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT );
  }

  viewport(gl, x, y, w, h){
    gl.viewport(x * this.Scale, y * this.Scale, w * this.Scale, h * this.Scale);
  }

  getOutput(){
    return this.fbo.getColorTexture()
  }

  processCamera( cam:Camera ){
    this.viewprojCopy.set( cam._viewProj )

    mat4.identity( M4A )
    M4A[5] = -1
    M4A[13] = 2 * this.groundHeight

    mat4.multiply( M4B, M4A, cam._wmatrix );
    mat4.invert( M4B, M4B );
    mat4.multiply( cam._viewProj, cam.lens.getProjection(), M4B );

  }
  
  restoreCamera( cam:Camera ){
    cam._viewProj.set( this.viewprojCopy );
  }

}