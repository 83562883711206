// import TextureResourceGroup from "./TextureResourceGroup";
import { GLContext } from "nanogl/types";
// import TexturesLoader from "./TextureLoader";
// import { TextureResource } from "./TextureResource";
// import { TextureSrcSet } from "./TextureRequest";
import Texture2D from "nanogl/texture-2d";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Image from "@/webgl/lib/nanogl-gltf/lib/elements/Image";
import Deferred from "@/core/Deferred";
// import Config from "@webgl/config";
import uuid from "@/webgl/lib/uuid";



export class StreamingAllocator {

  id: string = uuid();
  resourceIdx: number = -1;
  loading: boolean = false;
  loaded: boolean = false;
  locked: boolean = false;
  // srcSet: TextureSrcSet = null;
  src: string = "";
  allocatedTime: number;
  loadedTime: number = Number.MAX_VALUE;

}


//
// RESIZE
//
const TEX_SIZE = 512
let resizeContext: CanvasRenderingContext2D;
const _HAS_CIB: boolean = (window.createImageBitmap !== undefined);

function resizeImage(img: HTMLImageElement): Promise<HTMLImageElement | ImageBitmap> {
  // return createImageBitmap( resizeContext.canvas, 0, 0, TEX_SIZE, TEX_SIZE );
  if (!resizeContext) {
    resizeContext = document.createElement('canvas').getContext('2d');
    resizeContext.canvas.width = resizeContext.canvas.height = TEX_SIZE
  }
  resizeContext.drawImage(img, 0, 0, TEX_SIZE, TEX_SIZE);
  if (_HAS_CIB) {
    return createImageBitmap(resizeContext.canvas, 0, 0, TEX_SIZE, TEX_SIZE);
  } else {
    return Promise.resolve(img);
  }

}

class StreamedTextureResource {

  texture: Texture2D;
  img: HTMLImageElement;
  _defer: Deferred<StreamedTextureResource>;
  id: string;

  constructor(gl: GLContext) {

    this.texture = new Texture2D(gl);
    this.texture.bind();
    this.texture.setFilter(true, false, false);
    this.texture.clamp();
    this.img = document.createElement("img");
    this.img.crossOrigin = "anonymous";
    this.img.decoding = "async";

  }

  load(src: string, id: string = null): Promise<StreamedTextureResource> {
    // console.log("LOOAD : ", src);
    this.id = id;
    this._defer = new Deferred<StreamedTextureResource>();
    this.img.addEventListener("load", this.onImageLoaded);
    this.img.src = src;
    return this._defer.promise;
  }

  onImageLoaded = async () => {
    this.img.removeEventListener("load", this.onImageLoaded);
    const resized = await resizeImage(this.img);
    this.texture.fromImage(resized);
    this._defer.resolve(this);
  }

}

export class StreamedResource {

  id: string;
  tex: StreamedTextureResource;
  img: Image | HTMLImageElement;
  loading: boolean;
  allocator: StreamingAllocator;

  promise: Promise<any>;

  get available(): boolean {
    return (this.allocator === null) || (!this.allocator.locked) && !this.loading;
  }

  constructor(tex: StreamedTextureResource, id: string) {

    this.id = id;
    this.tex = tex;
    this.allocator = null;

  }

  async setAllocator(allocator: StreamingAllocator) {

    // console.log("SETTING ALLOCATOR");
    if (this.allocator != null) {
      this.allocator.loadedTime = Number.MAX_VALUE;
      this.allocator.resourceIdx = -1;
    }

    if (this.allocator === allocator)
      return;


    this.allocator = allocator;
    this.allocator.loadedTime = Number.MAX_VALUE;

    if (this.allocator != null) {
      this.load();
    }

  }


  async load(): Promise<any> {

    if (this.allocator)
      this.allocator.loading = true;

    this.loading = true;
    return this.tex.load(this.allocator.src, this.allocator.id).then(this.onTexLoaded.bind(this));

  }

  onTexLoaded(loadRes: StreamedTextureResource) {

    if (this.allocator === null || (this.allocator != null && loadRes.id === this.allocator.id)) {
      if (this.allocator) {
        this.allocator.loadedTime = Date.now();
        this.allocator.loaded = true;
        this.allocator.loading = false;
      }
    }

    this.loading = false;

  }

}


export default class TextureStreamingGroup {

  private _poolCount: number = 1;

  protected _streamedPool: StreamedResource[];
  protected _allocators: Array<StreamingAllocator>;

  protected _bindedRegistry: Array<number>;

  protected _emptyTex: StreamedTextureResource;

  gl: GLContext;
  loader: any;

  _lockloading: boolean;
  _lockTime: number = 0;

  _emptySrc: string;

  get EmptyTexture(): Texture2D { return this._emptyTex.texture }

  constructor(gl: GLContext, poolCount: number, emptySrc: string = "stream_empty.png") {

    this._poolCount = poolCount;
    this.gl = gl;

    this._streamedPool = [];
    this._allocators = [];

    this._emptySrc = emptySrc;
    this._emptyTex = new StreamedTextureResource(this.gl);
    this._emptyTex.load(emptySrc)

    this._bindedRegistry = [];
    this._lockloading = false;

  }


  alloc(): Promise<any> {
    const p: Array<Promise<any>> = [];
    for (var i = 0; i < this._poolCount; i++) {

      let tr = new StreamedTextureResource(this.gl);
      let sr = new StreamedResource(tr, "sidx_" + i);
      p.push(tr.load(this._emptySrc));
      this._streamedPool.push(sr);

    }

    return Promise.all(p);

  }

  prepare(dt: number) {
    this._lockTime += dt;
    if (this._lockTime > 0.024) {
      this._lockTime = 0.0;
      this._lockloading = false;
    }
  }

  registerAllocator(allocator: StreamingAllocator) {
    this._allocators.push(allocator);
  }

  isTexReady(allocator: StreamingAllocator): boolean {
    const idx = allocator.resourceIdx;
    if (idx === -1)
      return false;

    if (this._streamedPool[idx].loading)
      return false;

    return true;
  }

  getTex(allocator: StreamingAllocator): Texture2D {


    let idx = allocator.resourceIdx;

    let hasResource = idx !== -1;

    if (this._lockloading && !hasResource)
      return this._emptyTex.texture;

    if (hasResource) {
      if (this._streamedPool[idx].loading)
        return this._emptyTex.texture;
      return this._streamedPool[idx].tex.texture;
    }


    let resourceIdx: number = -1;
    for (let i = 0; i < this._poolCount; i++) {
      if (this._streamedPool[i].available) {
        resourceIdx = i;
        break;
      }
    }


    allocator.resourceIdx = resourceIdx;

    if (resourceIdx === -1) {
      return this._emptyTex.texture;
    }

    this._streamedPool[resourceIdx].setAllocator(allocator);
    this._lockloading = true;

    if (this._streamedPool[resourceIdx].loading) {
      return this._emptyTex.texture
    } else {
      return this._streamedPool[resourceIdx].tex.texture;
    }

  }

}

function uuidv4(): string {
  throw new Error("Function not implemented.");
}
