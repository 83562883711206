import Signal from "@/core/Signal";
import { ITextRenderInfo } from "@/webgl/entities/UIKit/text/TextRenderer";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import Picking from "@/webgl/lib/Picking";
// import Rect3D from "@/webgl/lib/Quad3D";
import { IRaycastable } from "@/webgl/lib/Raycastable";
import { mix } from "@/webgl/math";
import Ray from "@/webgl/math/Ray";
import { XRGamepadButton } from "@/webgl/xr/WebXRGamepad";
import { mat4, vec2, vec3, vec4 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
// import { LocalConfig } from "nanogl-state";

const M4 = mat4.create();
const LOCAL_POS = vec3.create();

/*
2 -- 3
|    |
0 -- 1
*/
const INDICES = [0, 3, 2, 0, 1, 3];
const VERTICES = [
  -0.5, -0.5, 0,
  0.5, -0.5, 0,
  -0.5, 0.5, 0,
  0.5, 0.5, 0,
]

export default class RaycastButton implements IRaycastable {

  public node: Node;

  private pickable: Picking;
  private _isHover: boolean = false;
  private _txtRenderInfo: ITextRenderInfo;

  private _bgIdleColor: number[];
  private _txtIdleColor: number[];

  private _bgHoverColor: number[];
  private _txtHoverColor: number[];

  private _bgColor: vec3;
  private _txtColor: vec4;

  private _castPos: vec3;

  private _hoverProgress: number = 0;
  private _txtAlpha: number = 0;
  private _scaleX: number = 0;
  private _scaleY: number = 0;

  private _padding: vec2 = vec2.fromValues(0.2, 0.15);
  get Padding():vec2 {
    return this._padding;
  }

  private _clickButton: XRGamepadButton = null;
  get ClickButton(): XRGamepadButton {
    return this._clickButton;
  }

  public onClick: Signal<RaycastButton>;

  constructor(
    public ui: UIKit,
    public _txtKey: string
  ) {
    this.node = new Node();

    this._bgIdleColor = [0.0270944, 0.0270944, 0.0270944];
    this._txtIdleColor = [0.9647058, 0.952941176, 0.89411764, 1.0];

    this._bgHoverColor = [0.9647058, 0.952941176, 0.9];
    this._txtHoverColor = [0.0270944, 0.0270944, 0.0270944, 1.0];

    this._bgColor = vec3.create();
    this._txtColor = vec4.create();
    this._bgColor.set(this._bgIdleColor);
    this._txtColor.set(this._txtIdleColor)

    this.pickable = new Picking(this.node, INDICES, VERTICES, true);
    this._castPos = vec3.create();

    this._txtRenderInfo = this.ui.textRenderer.texts.get(this._txtKey);

    this.onClick = new Signal<RaycastButton>();

  }

  isHover() {
    return this._isHover;
  }

  setPadding(x: number, y: number) {
    this._padding[0] = x;
    this._padding[1] = y;
  }

  updateRect() {

    if (!this._txtRenderInfo)
      return;

    const w = this._txtRenderInfo.width + this._padding[0] * 2.0;
    const h = this._txtRenderInfo.height + this._padding[1];
    this.pickable.vertices = [
      -0.5 * w, -h, 0,
      0.5 * w, -h, 0,
      -0.5 * w, h, 0,
      0.5 * w, h, 0,
    ];

  }

  show(delay: number = 0) {
    gsap.to(this, { _scaleX: 1, duration: 0.6, delay: delay, ease: "power2.out" });
    gsap.to(this, { _scaleY: 1, duration: 0.2, delay: delay, ease: "power2.out" });
    gsap.to(this, { _txtAlpha: 1, duration: 0.4, delay: delay + 0.2, ease: "power2.out" });
  }

  hide(delay: number = 0) {
    gsap.to(this, { _scaleX: 0, duration: 0.6, delay: delay, ease: "power2.out" });
    gsap.to(this, { _scaleY: 0, duration: 0.2, delay: delay + 0.4, ease: "power2.out" });
    gsap.to(this, { _txtAlpha: 0, duration: 0.4, delay: delay, ease: "power2.out" });
  }

  click(btn: XRGamepadButton = null) {
    this._clickButton = btn;
    this.onClick.emit(this);
  }

  raycastList(rays: Ray[]): number{

    this.updateRect();

    let cast = 0;
    let idx = 0;
    for(let i = 0; i < rays.length; i++){
      const rc = this.pickable.raycast(rays[i], this._castPos);
      if(rc != 0){
        cast = rc;
        idx = i;
      }
    }

    const hover = cast != 0;
    
    if (this._isHover && !hover)
      gsap.to(this, { _hoverProgress: 0, duration: 0.3 });

    if (!this._isHover && hover)
      gsap.to(this, { _hoverProgress: 1, duration: 0.3 });

    this._isHover = cast != 0;

    return idx;
    
  }

  raycast(ray: Ray) {

    this.updateRect();
    const cast = this.pickable.raycast(ray, this._castPos);
    const hover = cast != 0;

    if (this._isHover && !hover)
      gsap.to(this, { _hoverProgress: 0, duration: 0.3 });

    if (!this._isHover && hover)
      gsap.to(this, { _hoverProgress: 1, duration: 0.3 });

    this._isHover = cast != 0;

  }

  render(camera: Camera) {

    if(this._scaleX === 0)
      return;

    vec3.lerp(this._bgColor, this._bgIdleColor, this._bgHoverColor, this._hoverProgress);
    vec4.lerp(this._txtColor, this._txtIdleColor, this._txtHoverColor, this._hoverProgress);

    this._txtColor[3] = this._txtAlpha;

    this.ui.gradientPrimitives.renderBtn(
      camera,
      this.node,
      (this._txtRenderInfo.width + this._padding[0]) * this._scaleX,
      (this._txtRenderInfo.height + this._padding[1]) * this._scaleY,
      this._txtRenderInfo.height * 0.9 * 0.333,
      this._bgColor
    )

    this.ui.textRenderer.render(
      this._txtKey,
      this.node._wmatrix,
      this._txtColor
    )

  }

}