import { CreatePane } from "@/dev/Tweak";
import { vec2, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input from "nanogl-pbr/Input";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Texture2D from "nanogl/texture-2d";
import TextureCube from "nanogl/texture-cube";

export default class GraniteProbeEffect extends Chunk {

  cubemap: TextureCube;
  reflectTex: Texture2D;
  cameraPosition: vec3 = vec3.create();
  viewport: vec2 = vec2.create();
  specular: number = 0.15;
  smoothness: number = 0.65;
  rfactor: number = 0.54;

  constructor() {

    super(true, true);
/////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////////

  }

  setCubemap(cubemap: TextureCube) {
    this.cubemap = cubemap;
  }

  setReflectTex(texture: Texture2D) {
    this.reflectTex = texture;
  }

  install(pass: UnlitPass) {
    const nrm = pass.inputs.add(new Input("localNormal", 3));
    nrm.attachAttribute("aNormal", 3);
    pass.inputs.add(this);
  }

  prepare(camera: Camera) {
    this.cameraPosition.set(camera._wposition);
  }

  setup(prg) {

    prg.uCameraPosition(this.cameraPosition);
    prg.tProbeCubemap(this.cubemap);
    prg.tReflectTex(this.reflectTex);
    prg.uViewport(this.viewport);
    prg.uSpecSmoothDarken([this.specular, this.smoothness, this.rfactor]);

  }

  protected _genCode(slots: ChunksSlots): void {

    slots.add('pv',
      /*glsl*/`
      OUT highp vec3 vWorldNormal;
      OUT highp vec3 vWorldPosition;
      vec3 rotate( mat4 m, vec3 v )
      {
        return m[0].xyz*v.x + m[1].xyz*v.y + m[2].xyz*v.z;
      }
      `);

    slots.add('vertex_warp_world',
      /*glsl*/`
      vWorldNormal = normalize( rotate( vertex.worldMatrix, aNormal ) );
      vWorldPosition = vertex.worldPos;
      `);

    slots.add('pf',
      /*glsl*/`

      #if __VERSION__ == 300
      #define textureCube(a,b) texture( a, b )
      #endif
      
      uniform vec3 uCameraPosition;
      uniform samplerCube tProbeCubemap;
      uniform sampler2D tReflectTex;
      uniform vec2 uViewport;
      uniform vec3 uSpecSmoothDarken;
      
      IN highp vec3 vWorldNormal;
      IN highp vec3 vWorldPosition;

      // Schlick approx
      // [Schlick 1994, "An Inexpensive BRDF Model for Physically-Based Rendering"]
      // https://github.com/EpicGames/UnrealEngine/blob/dff3c48be101bb9f84633a733ef79c91c38d9542/Engine/Shaders/BRDF.usf#L168
      vec3 F_Schlick( float VoH,vec3 spec,float glo )
      {
        float factor = glo*glo * pow( 1.0-VoH, 5.0 );
        return( 1.0 - factor )*spec + factor;
      }
      const float MaxRangeRGBD = 255.0;
      vec3 decodeRGBD(vec4 rgbd)
      {
          float a = max(rgbd.a, 0.00);
          return rgbd.rgb * ((MaxRangeRGBD / 255.0) / a);
      }
      

      `);

    slots.add('postf',
      /*glsl*/`
      vec3 viewDir = normalize( uCameraPosition - vWorldPosition );
      
      vec3 worldReflect = normalize(reflect( -viewDir, vWorldNormal ));
      float NoV = sdot( viewDir, vWorldNormal );
      float NoR = sdot( worldReflect, vWorldNormal );
      
      vec3 specular = textureCube(tProbeCubemap, worldReflect).rgb;
      
      vec2 rcoord = gl_FragCoord.xy / uViewport;
      vec3 rcolor = texture2D(tReflectTex, rcoord).rgb;
      specular = mix(specular, rcolor, vertexColor().r);

      specular *= F_Schlick( NoV, vec3(uSpecSmoothDarken.x), uSpecSmoothDarken.y ); 
      
      // FragColor.rgb = vertexColor() + (baseColor() + specular) * 0.000001;
      FragColor.rgb = baseColor() * uSpecSmoothDarken.z + specular;
      // FragColor.rgb = FragColor.rgb * 0.000001 + vec3(rcoord, 0.0);
      `);
  }

}