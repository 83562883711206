import { PovDatas } from "@/store/states/AppStateMachine";
import { anime } from "@/utils/anime";
import * as Net from "@/webgl/assets/Net";
import { quat, vec3 } from "gl-matrix";
import gsap from "gsap";
import Texture from "nanogl/texture";

const V3 = vec3.create();
const METRICS = {
  D2: {
    MIN: { x: 54.58, y: 70.87 },
    MAX: { x: 177.67, y: 188.14 },
  },
  D3: {
    MIN: { x: -8.67, y: -6.77 },
    MAX: { x: 9.04, y: 10.121 },
  },
  MAP: {
    width: 237,
    height: 237,
  },
};

const DEG90 = Math.PI * 0.5;

export interface Minimap2DOptions {
  data: Pick<PovDatas, "position" | "rotation">;
  view?: Minimap2DView;
  target?: Texture | HTMLImageElement;
}

export type Minimap2DView = "card" | "seamless";

export default class Minimap2D {
  private ctx: CanvasRenderingContext2D;
  private mapImg: HTMLImageElement;
  private cursorImg: HTMLImageElement;
  private dotImg: HTMLImageElement;
  public dpi = 2;
  public transform = { angle: 0, x: 0, y: 0 };
  public defaultSize = { width: 237, height: 237 };
  public cursor = {
    x: 0,
    y: 0,
    opacity: 0,
    visible: false,
    transitioning: false,
    next: {
      pending: false,
      data: null as Minimap2DOptions["data"],
    },
  };
  public metrics = { width: 0, height: 0, padding: 20, scale: 1 };
  public card = { radius: 15, color: "#F6F3E4" };
  public loaded = false;
  public view: Minimap2DView = "seamless";

  constructor() {
    this.draw = this.draw.bind(this);
    this.resize = this.resize.bind(this);

    const canvas = document.createElement("canvas");
    this.ctx = canvas.getContext("2d");
  }

  async load() {
    if (this.loaded) return;

    const [map, cursor, dot] = await Promise.all([
      Net.loadImage(require("@/assets/images/map.png")),
      Net.loadImage(require("@/assets/images/map-cursor.png")),
      Net.loadImage(require("@/assets/images/map-dot.png")),
    ]);

    this.mapImg = map;
    this.cursorImg = cursor;
    this.dotImg = dot;

    this.cursor.x = this.cursorImg.naturalWidth * -0.5 - 16;
    this.cursor.y = -this.cursorImg.naturalHeight + 100;

    this.resetCursor();
    this.resize();
    this.draw();

    this.loaded = true;
  }

  debug() {
    if (document.querySelector("#minimap-2d")) {
      window.location.reload();
      return;
    }

    const canvas = this.ctx.canvas;
    canvas.style.cssText = `position: fixed; top: 0px; left: 350px; z-index: 1000; outline: 1px red solid;`;
    document.body.prepend(canvas);
  }

  resize() {
    const parent = this.ctx.canvas.parentElement;

    if (parent) {
      this.metrics.width = parent.offsetWidth;
      this.metrics.height = parent.offsetHeight;

      if (this.metrics.width === 0 || this.metrics.height === 0) {
        this.metrics.width = this.defaultSize.width;
        this.metrics.height = this.defaultSize.height;
      }
    } else {
      this.metrics.width = METRICS.MAP.width + this.metrics.padding * 2;
      this.metrics.height = METRICS.MAP.height + this.metrics.padding * 2;
    }

    this.ctx.canvas.width = this.metrics.width * this.dpi;
    this.ctx.canvas.height = this.metrics.height * this.dpi;
    this.ctx.canvas.style.width = `${this.metrics.width}px`;
    this.ctx.canvas.style.height = `${this.metrics.height}px`;

    this.metrics.scale =
      this.metrics.width / (METRICS.MAP.width + this.metrics.padding * 2);
  }

  draw() {
    if (!this.loaded) return;

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    if (this.view === "card") {
      // Draw card
      this.drawCard();
    }

    // Draw map
    this.ctx.scale(this.metrics.scale, this.metrics.scale);
    this.ctx.drawImage(this.mapImg, this.metrics.padding * this.dpi, this.metrics.padding * this.dpi);
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);

    // Draw cursor
    this.ctx.scale(this.metrics.scale, this.metrics.scale);
    this.ctx.translate(this.transform.x * this.dpi, this.transform.y * this.dpi);
    this.ctx.rotate(this.transform.angle);
    this.drawCursor();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);

    // Draw dot
    this.ctx.scale(this.metrics.scale, this.metrics.scale);
    this.ctx.drawImage(this.dotImg, this.metrics.padding * this.dpi, this.metrics.padding * this.dpi);
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  private drawCursor(debug = false, fillStyle?: string) {
    if (debug) {
      this.ctx.fillStyle = fillStyle;
      this.ctx.beginPath();
      this.ctx.moveTo(-10, -5);
      this.ctx.lineTo(-10, 5);
      this.ctx.lineTo(0, 0);
      this.ctx.fill();
    } else {
      this.ctx.globalAlpha = this.cursor.opacity;
      this.ctx.drawImage(this.cursorImg, this.cursor.x, this.cursor.y);
      this.ctx.globalAlpha = 1;
    }
  }

  private drawCard() {
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.beginPath();
    this.ctx.arc(
      this.ctx.canvas.width - this.card.radius,
      this.ctx.canvas.height - this.card.radius,
      this.card.radius,
      0,
      DEG90
    );
    this.ctx.arc(
      this.card.radius,
      this.ctx.canvas.height - this.card.radius,
      this.card.radius,
      DEG90,
      DEG90 * 2
    );
    this.ctx.arc(
      this.card.radius,
      this.card.radius,
      this.card.radius,
      DEG90 * 2,
      DEG90 * 3
    );
    this.ctx.arc(
      this.ctx.canvas.width - this.card.radius,
      this.card.radius,
      this.card.radius,
      DEG90 * 3,
      DEG90 * 4
    );
    this.ctx.closePath();
    this.ctx.fillStyle = this.card.color;
    this.ctx.fill();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  render({ data, view, target }: Minimap2DOptions, forceCursor: boolean = false) {
    this.view = view;

    // Resize and draw
    this.update(data);
    if (forceCursor)
      this.cursor.opacity = 1.0
    this.resize();
    this.draw();

    // Render into a target if given
    if (target instanceof Texture) {
      target.fromImage(this.ctx.canvas);
    } else if (target instanceof HTMLImageElement) {
      target.src = this.ctx.canvas.toDataURL("image/png");
    }
  }

  update(data: Minimap2DOptions["data"]) {
    // Get direction
    vec3.transformQuat(
      V3,
      vec3.fromValues(0, 0, 1),
      data.rotation as unknown as quat
    );

    // Flip direction
    vec3.scale(V3, V3, -1); // Flip

    // Normalize 3D position
    let x =
      (data.position[0] - METRICS.D3.MIN.x) /
      (METRICS.D3.MAX.x - METRICS.D3.MIN.x);
    let y =
      (data.position[2] - METRICS.D3.MIN.y) /
      (METRICS.D3.MAX.y - METRICS.D3.MIN.y);

    // Convert position into 2D position
    x = METRICS.D2.MIN.x + x * (METRICS.D2.MAX.x - METRICS.D2.MIN.x);
    y = METRICS.D2.MIN.y + y * (METRICS.D2.MAX.y - METRICS.D2.MIN.y);

    // Add padding
    x += this.metrics.padding;
    y += this.metrics.padding;

    // Set transform
    this.transform.angle = Math.atan2(V3[2], V3[0]);
    this.transform.x = x;
    this.transform.y = y;
  }

  async updateCursor(data?: Minimap2DOptions["data"], force = false) {
    if (force) {
      this.cursor.visible = true;
      this.cursor.opacity = 1;
      gsap.killTweensOf(this.cursor);
      if (data) {
        this.update(data);
        this.emit("show");
      } else {
        this.emit("hide");
      }
      this.resize();
      this.draw();
      return;
    }

    if (this.cursor.transitioning) {
      this.cursor.next.data = data;
      this.cursor.next.pending = true;
      return;
    }

    this.cursor.transitioning = true;

    if (this.cursor.visible) {
      this.cursor.visible = false;
      this.emit("hide");
      gsap.killTweensOf(this.cursor);
      await anime(this.cursor, {
        opacity: 0,
        duration: 0.25,
        onUpdate: this.draw,
        onComplete: this.draw,
      });
      this.resetCursor();
    }

    if (data) {
      this.update(data);

      this.cursor.visible = true;
      this.emit("show");
      gsap.killTweensOf(this.cursor);
      await anime(this.cursor, {
        opacity: 1,
        duration: 0.25,
        onUpdate: this.draw,
        onComplete: this.draw,
      });
    }

    this.cursor.transitioning = false;

    if (this.cursor.next.pending) {
      data = this.cursor.next.data;
      this.cursor.next.pending = false;
      this.cursor.next.data = null;
      this.updateCursor(data);
    }
  }

  resetCursor() {
    this.transform.x = -this.cursorImg.naturalWidth;
    this.transform.y = -this.cursorImg.naturalHeight;
  }

  setParent(parent: HTMLElement | null | undefined) {
    if (parent instanceof HTMLElement) {
      parent.append(this.ctx.canvas);
      this.resize();
      this.draw();
    } else {
      this.ctx.canvas.remove();
    }
  }

  on(event: "show" | "hide", cb: (e: Partial<CustomEvent>) => void) {
    const type = `minimap:${event}`;
    this.ctx.canvas.addEventListener(type, cb);
  }

  once(event: "show" | "hide", cb: (e: Partial<CustomEvent>) => void) {
    const type = `minimap:${event}`;
    this.ctx.canvas.addEventListener(type, cb, { once: true });
  }

  off(event: "show" | "hide", cb: (e: Partial<CustomEvent>) => void) {
    const type = `minimap:${event}`;
    this.ctx.canvas.removeEventListener(type, cb);
  }

  emit(event: "show" | "hide") {
    const type = `minimap:${event}`;
    this.ctx.canvas.dispatchEvent(new CustomEvent(type));
  }

  private static shared: Minimap2D;
  public static Shared() {
    if (!Minimap2D.shared) {
      Minimap2D.shared = new Minimap2D();
    }
    return Minimap2D.Shared();
  }
}
