import XRModule from "@/webgl/entities/xr-module/XRModule";
import Rect3D from "@/webgl/lib/Quad3D";
import { mat3Lookat } from "@/webgl/math";
import Scene from "@/webgl/Scene";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import { LocalConfig } from "nanogl-state";

const UP = vec3.fromValues(0, 1.0, 0.0);
const M3 = mat3.create();
const M4 = mat4.create();
const QUAT = quat.create();
const SIZE = 0.05;


export default class RaycastTarget {

  rect: Rect3D;
  matrix: mat4;
  config: LocalConfig;
  scene: Scene;
  wposition: vec3;
  hit: boolean;

  constructor(
    public readonly xrmodule: XRModule
  ) {

    const scene = this.scene = xrmodule.scene;
    this.rect = new Rect3D(scene.gl, -SIZE * 0.5, -SIZE * 0.5, SIZE, SIZE);
    this.matrix = mat4.create();
    this.wposition = vec3.create();

    this.config = scene.glstate.config();
    this.config
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(scene.gl.SRC_ALPHA, scene.gl.ONE_MINUS_SRC_ALPHA)
      .enableCullface(false);

  }

  setPosition(worldPos: vec3, normal: vec3){

    this.wposition.set(worldPos);
    mat3Lookat(M3, normal);
    quat.fromMat3(QUAT, M3);
    mat4.fromRotationTranslation(this.matrix, QUAT, worldPos);

  }

  setHit(flag: boolean){
    this.hit = flag;
  }


  render(camera: Camera) {

    this.scene.glstate.push(this.config);
    this.scene.glstate.apply();
    const prg = this.scene.programs.get("unlit-texture");

    prg.use();
    prg.uMVP(camera.getMVP(this.matrix));
    // prg.uColor([1.0, 1.0, 1.0, 1.0]);
    prg.tTexture(this.xrmodule.resources.get("raycast-target"));

    this.rect.bind();
    this.rect.attribPointer(prg);
    this.rect.render();
    
    this.scene.glstate.pop();
    
  }
  
  prepare(){
    const prg = this.scene.programs.get("unlit-texture");
    prg.use();
    prg.tTexture(this.xrmodule.resources.get("raycast-target"));
    this.config.apply()
    this.rect.bind();
    this.rect.attribPointer(prg);
  }
  
  renderImmediate(camera: Camera, pos:vec3, nrm: vec3) {
    
    mat3Lookat(M3, nrm);
    quat.fromMat3(QUAT, M3);
    mat4.fromRotationTranslation(M4, QUAT, pos);
    
    
    const prg = this.scene.programs.get("unlit-texture");
    prg.uMVP(camera.getMVP(M4));
    this.rect.render();
  }


}