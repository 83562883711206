
import { gsap } from 'gsap'
import { defineComponent, ref, computed, onMounted, nextTick, watch, onBeforeMount } from 'vue'
import { useActor, useSelector } from '@xstate/vue'

import AppService from '@/store/states/AppService'
import Viewport from '@/store/modules/Viewport'
import { MuseumStore } from '@/store/modules/MuseumStore'

import UIBtnDive from '@/components/UIBtn/UIBtnDive.vue'
import ArtworkCaption from '@/components/ArtworkCaption/ArtworkCaption.vue'
import ArtworkDetails from '@/components/ArtworkCaption/ArtworkDetails.vue'
import CustomCursor from '@/components/CustomCursor/CustomCursor.vue'
import GetTicket from '@/components/GetTicket/GetTicket.vue'
import { artworkIsMainPainting } from '@/store/states/Artworks'
import Gtm from '@/utils/Gtm'

const MuseumVue = defineComponent({
  components: {
    UIBtnDive,
    ArtworkCaption,
    ArtworkDetails,
    CustomCursor,
    GetTicket
  },
  setup() {
    Gtm.sendVPV('museum')

    const { state, send } = useActor(AppService)
    const enterAnimDone = ref(false)
    const forceArtworkDescription = ref(false)
    const pathProgress = computed(() => {
      let n= MuseumStore.pathProgress %1
      if( n<0) n+=1
      return n
    })
    const showScrollHint = computed(() => (pathProgress.value >= 0 && pathProgress.value <= 0.1) && enterAnimDone.value)
    const showTickets = computed(() => (pathProgress.value >= 0.85 && pathProgress.value <= 0.92))
    // const showScrollHint = ref(false)
    

    const nearbyArtworkId = useSelector(AppService, state => state.context.nearbyArtworkId)
    const hoveredArtworkId = useSelector(AppService, state => state.context.hoveredArtworkId)
    const showArtwork = computed(() => !!nearbyArtworkId.value || forceArtworkDescription.value)
    const selectedArtworkId = useSelector(AppService, state => state.context.selectedArtworkId)

    const showArtworkDetails = computed(() => selectedArtworkId.value)

    const showMainPaintingCursor = computed(() => {
      // TODO: wait for webgl event to know if hover main painting or not
      return artworkIsMainPainting(hoveredArtworkId.value) && artworkIsMainPainting(showArtworkDetails.value) && Viewport.isDesktop
    })

    const showDiveBtn = computed(() =>Viewport.isDesktop && artworkIsMainPainting(nearbyArtworkId.value))


    const showArtworkCaption = computed(() => !!nearbyArtworkId.value)

    watch(state, (newState, oldState) => {
      if (state.value.matches('museum.explore') && !oldState.matches('museum.explore')) {
        nextTick(onEnter)
      }
      
      if (oldState) {
        if (
          state.value.event.type === 'SELECT_ARTWORK' && 
          selectedArtworkId.value === oldState.context.selectedArtworkId &&
          artworkIsMainPainting(selectedArtworkId.value)
        ) {
          AppService.send('GOTO_PAINTING')
          Gtm.sendEventPush(
            'dive-in',
            'access-to-immersive-painting',
            'Click on dive in'
          )
        }
      }
    })

    watch(selectedArtworkId, (id) => {
      forceArtworkDescription.value = id && !nearbyArtworkId.value
    })

    onMounted(() => {
      gsap.set('.Museum, .AppHeader', { autoAlpha: 0 })

      if (state.value.matches('museum.explore')) {
        nextTick(onEnter)
      }
    })

    onBeforeMount(() => {
      forceArtworkDescription.value = false
    })

    const onEnter = () => {
      const $ = gsap.utils.selector(document.body)

      const $logo1 = $('.AppHeader .AppHeader__Logo')
      const $btnGallery = $('.AppHeader .UIBtnGallery')
      const $btnInfos = $('.AppHeader .BtnInformation')
      const $gradientBottom = $('.Museum__gradient-bottom')

      gsap.set('.Museum, .AppHeader', { clearProps: 'all' })
       enterAnimDone.value = true
      const tl = gsap.timeline({
        onComplete: () => {
         
        }
      })
      tl
        .from([$logo1, $btnGallery, $btnInfos], {
          duration: 1.033,
          autoAlpha: 0,
          ease: 'customOut',
          stagger: 0.1
        }, 'start')
        .from($gradientBottom, {
          duration: 1.033,
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start')
        .from([$logo1, $btnGallery, $btnInfos], {
          duration: 1.033,
          y: 20,
          ease: 'customOut2',
          stagger: 0.1
        }, 'start')
        .to($gradientBottom, {
          duration: 0.6,
          autoAlpha: 0.5,
          ease: 'customOut',
        }, 'start+=5')
    }

    const scrollEnterAnim = (el, onComplete) => {
      const $ = gsap.utils.selector(document.body)
      const $text = el.querySelector('.ScrollHint__Label')
      const $bar1 = el.querySelector('.ScrollHint__Bar-1')
      const $bar2 = el.querySelector('.ScrollHint__Bar-2')

      const tl = gsap.timeline({
        onComplete: () => {
          el.classList.add('ScrollHint--showed')
          onComplete()
        }
      })
      tl
        .from($bar1, {
          duration: 1,
          y: 80,
          scaleY: 0,
          ease: 'customOut2',
        }, 'start')
       
        .to($bar1, {
          duration: 0.6,
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start')
        .from($text, {
          duration: 1,
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start+=0.33')
        .from($text, {
          duration: 1,
          y: 20,
          ease: 'customOut2',
        }, 'start+=0.33')

        .from($bar2, {
          duration: 1,
          y: 56,
          ease: 'customOut2',
        }, 'start+=0.433')
        .from($bar2, {
          duration: 1,
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start+=0.433')
    }
    const scrollLeaveAnim = (el, onComplete) => {
      const $text = el.querySelector('.ScrollHint__Label')
      const $bar1 = el.querySelector('.ScrollHint__Bar-1')
      const $bar2 = el.querySelector('.ScrollHint__Bar-2')

      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([el, $text, $bar1, $bar2], { clearProps: 'all' })
          onComplete()
        }
      })
      tl
        .to(el, {
          duration: 1,
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start')
    }

    const artworkEnterAnim = (el, onComplete) => {
      const tl = gsap.timeline({
        onComplete
      })

      tl
        .set(el, {
          clearProps: 'all'
        })
        .from(el, {
          autoAlpha: 0,
          duration: 0.8,
          ease: 'customOut'
        })
    }
    const artworkLeaveAnim = (el, onComplete) => {
      const tl = gsap.timeline({
        onComplete
      })
      tl.to(el, {
        autoAlpha: 0,
        duration: 0.4,
        ease: 'customOut'
      })
    }

    const onGoPainting = () => {
      send('GOTO_PAINTING')
      Gtm.sendEventPush(
        'header',
        'step-to-immersive-painting',
        'Click on Step to immersive painting'
      )
    }

    return {
      ...useActor(AppService),
      showArtwork,
      hoveredArtworkId,
      forceArtworkDescription,
      showDiveBtn,
      showScrollHint,
      showTickets,
      nearbyArtworkId,
      selectedArtworkId,
      showArtworkCaption,
      showMainPaintingCursor,
      pathProgress,
      onGoPainting,
      scrollEnterAnim,
      scrollLeaveAnim,
      artworkEnterAnim,
      artworkLeaveAnim
    }
  },
})

export default MuseumVue

