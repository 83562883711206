import Paths from "@/core/Paths";
import { CreatePane } from "@/dev/Tweak";
import { IGLContextProvider } from "@/webgl/assets/IGLContextProvider";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input from "nanogl-pbr/Input";
import MaterialPass from "nanogl-pbr/MaterialPass";
import Texture2D from "nanogl/texture-2d";

export default class FlowMapEffect extends Chunk {

  time: number = 0;
  timeScale: number = 1.0;
  flowSpeed: number = 0.15;

  _texture: Texture2D;
  texResource: TextureResource;

  constructor(
    public glp: IGLContextProvider,
    public flowMapPath: string
  ) {

    super(true, true);

    this.texResource = new TextureResource(
      new TextureSrcSet(flowMapPath), glp
    );

/////////////////
////////////////////////////////////////
////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
//////////////

  }

  async load() {
    this._texture = await this.texResource.load();
    this._texture.bind();
    this._texture.setFilter(true, false, false);
  }

  unload(){
    this._texture = null;
    this.texResource.unload();
  }

  install(pass: MaterialPass) {
    pass.inputs.add(this);
  }


  update(dt) {
    this.time += dt * this.timeScale;
  }


  setup(prg) {

    prg.uFlowMapConfig([this.time, this.flowSpeed]);
    prg.uFlowMap(this._texture);

  }


  protected _genCode(slots: ChunksSlots): void {

    slots.add('pf',
    /*glsl*/`
    uniform vec2 uFlowMapConfig;
    uniform sampler2D uFlowMap;
    `);


    slots.add('postf',
      /*glsl*/`

      vec3 flowDir = texture2D(uFlowMap, baseColor_texCoord()).rgb;
      flowDir = (flowDir * vec3(2.0)) - vec3(1.0);
      // flowDir.y = -flowDir.y;
      flowDir *= uFlowMapConfig.y;

      float phase0 = fract(uFlowMapConfig.x * 0.2 + 0.5);
      float phase1 = fract(uFlowMapConfig.x * 0.2 + 1.0);
      
      vec4 tex0 = texture2D(tex_basecolor, baseColor_texCoord()  + flowDir.xy * phase0);
      vec4 tex1 = texture2D(tex_basecolor, baseColor_texCoord()  + flowDir.xy * phase1);

      float flowLerp = abs((0.5 - phase0) / 0.5);
      vec4 finalColor = mix(tex0, tex1, flowLerp);

      finalColor.a = finalColor.a;
      
      // FragColor = finalColor * 0.00001 + texture(uFlowMap, baseColor_texCoord());
      FragColor = finalColor;
      `);
  }

}