import '@/styles/index.styl';

import i18n from '@/core/i18n';
import { setupViews } from '@/views/SetupViews';
import { createApp } from 'vue'
import App from './App.vue'
import store, { key } from './store'
import GlobalComponents from '@/components'
import { createAudioState } from '@/core/audio/AudioState';
import Browser from "@/utils/Browser";

createAudioState();

const app = createApp(App);

GlobalComponents.register(app);

setupViews(app);

app.use(store, key);

app.use(i18n);

app.mount('#app');
