import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import Browser from "@/utils/Browser";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import GalleryBackground from "../../entities/gallery-background/GalleryBackground";
import Scene from "../../Scene";
import { IActivity } from "../Activity";
import StateHelper from "../StateHelper";
import ArtworkStrip from "./Artwork/Strip";
import { InteractionEvent, InteractionEventDetail } from "./types";
import GalleryData from "./data";
import Gtm from "@/utils/Gtm";
import { anime } from "@/utils/anime";
// import { seconds } from "./utils";

export default class GalleryActivity implements IActivity {
  readonly priority = 0;

  private stateHelper = new StateHelper([]);
  private background?: GalleryBackground;
  private camera: Camera<PerspectiveLens>;
  private strip: ArtworkStrip;
  private data: GalleryData;
  private useSample = false;

  root: Node;

  constructor(public scene: Scene) {
    this.onInteraction = this.onInteraction.bind(this);

    this.root = new Node();
    this.background = new GalleryBackground(this.scene);

    this.camera = Camera.makePerspectiveCamera();
    this.camera.lens.far = 10;
    this.camera.lens.near = 0.1;
    this.root.add(this.camera);

    this.data = new GalleryData();

  }

  async load() {
    this.strip = new ArtworkStrip(this.scene);
    this.root.add(this.strip.root);

    this.strip.introTween.user = this.data.loadUserMedia();

    const { interactions } = this.strip;
    interactions.events.addEventListener("i:resize", this.onInteraction);
    interactions.events.addEventListener("i:click", this.onInteraction);
    interactions.events.addEventListener("i:over", this.onInteraction);
    interactions.events.addEventListener("i:out", this.onInteraction);
    interactions.events.addEventListener("i:dragstart", this.onInteraction);
    interactions.events.addEventListener("i:dragend", this.onInteraction);
    interactions.events.addEventListener("i:load", this.onInteraction);
    this.data.listeners.add(this.strip.append);
    this.data.datas.forEach(this.strip.append);

    await Promise.all([
      this.background.load(),
      this.strip.load(),
      this.data.load(this.useSample),
    ]);

    await this.data.loading.Promise;
  }

  unload(): void {
    const { interactions } = this.strip;
    interactions.events.removeEventListener("i:resize", this.onInteraction);
    interactions.events.removeEventListener("i:click", this.onInteraction);
    interactions.events.removeEventListener("i:over", this.onInteraction);
    interactions.events.removeEventListener("i:out", this.onInteraction);
    interactions.events.removeEventListener("i:dragstart", this.onInteraction);
    interactions.events.removeEventListener("i:dragend", this.onInteraction);
    interactions.events.removeEventListener("i:load", this.onInteraction);
    this.data.listeners.delete(this.strip.append);
    this.root.remove(this.strip.root);
    this.strip = null;
  }

  start(): void {
    this.background.show();
    this.strip.enable();
    this.data.enable(this.strip);
    this.strip.play();
    // this.data.loadUserMedia().then(() => {
    //   this.strip.play();
    // });
  }

  stop(): void {
    this.strip.disable();
    this.data.disable();
    this.background.hide();
  }

  onStateUpdate(state: AppState): void {}

  preFrame(): void {}

  render(): void {
    const gl = this.scene.gl;

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, this.scene.glview.width, this.scene.glview.height);

    gl.clearColor(0, 0, 0, 1);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);


    this.background.render(this.camera);
    this.strip.render(this.camera);
    this.strip.postRender();
  }

  preRender(): void {
    this.root.updateWorldMatrix();
    this.camera.updateViewProjectionMatrix(
      this.scene.viewport.width,
      this.scene.viewport.height
    );

    this.strip.preRender(this.camera);
  }

  rttPass(): void {}

  onInteraction(e: InteractionEvent) {
    switch (e.type) {
      case "i:resize": {
        this.strip.root.setScale(
          1 / (Math.max(window.innerWidth, window.innerHeight) * 0.5)
        );

        this.strip.fog.radius = window.innerWidth * 0.5;
        this.strip.fog.edge = 0.2;

        this.strip.curve.width = Math.max(
          window.innerWidth,
          this.strip.curve.minWidth
        );

        if (Browser.mobile) {
          this.strip.mode = "mobile";
          this.strip.interactions.drag.speedMultiplier = 20;
          this.strip.interactions.focus.distance = 100;
          this.strip.interactions.focus.gap = 50;
          this.strip.interactions.snap.enabled = true;
          this.strip.interactions.snap.delay = 1_000;
          this.strip.curve.depth = 0;
          this.strip.autoScroll.enabled = false;
          this.strip.introTween.width = window.innerWidth;
          this.camera.z = 0.75;

          if (window.innerHeight <= 568) {
            this.camera.z = 1.5;
          } else if (window.innerHeight < 680) {
            this.camera.z = 1.25;
          } else if (window.innerHeight < 800) {
            this.camera.z = 1;
          }
        } else {
          this.strip.mode = "desktop";
          this.strip.interactions.drag.speedMultiplier = 20;
          this.strip.interactions.focus.distance = 200;
          this.strip.interactions.focus.gap = 50;
          this.strip.interactions.snap.enabled = false;
          this.strip.interactions.snap.delay = 10_000;
          this.strip.curve.depth = 500;
          this.strip.autoScroll.enabled = true;
          this.strip.introTween.width = window.innerWidth;
          this.camera.z = 1;
        }

        break;
      }
      case "i:click": {
        const state = e.detail as InteractionEventDetail["i:click"];
        AppService.send("OPEN_PHOTO", { pov: state.data.pov });
        this.scene.glview.canvas.style.cursor = "";

        Gtm.sendEventPush(
          "photo-gallery",
          "choose-a-photo",
          "Click on a photo"
        );
        break;
      }
      case "i:over": {
        const state = e.detail as InteractionEventDetail["i:over"];
        AppService.send("GALLERY_HOVER", {
          pov: state.data.pov,
          bounds: state.movingBounds.screen,
        });
        this.scene.glview.canvas.style.cursor = "pointer";
        break;
      }
      case "i:out": {
        AppService.send("GALLERY_OUT");
        this.scene.glview.canvas.style.cursor = "";
        break;
      }
      case "i:dragstart": {
        AppService.send("GALLERY_DRAGSTART");
        this.scene.glview.canvas.style.cursor = "grabbing";
        break;
      }
      case "i:dragend": {
        AppService.send("GALLERY_DRAGEND");
        this.scene.glview.canvas.style.cursor = "";
        break;
      }
      case "i:load": {
        this.data.load(this.useSample).then(()=>{
          this.strip.interactions.nextLoading.pending = false;
        });
        break;
      }
    }
  }
}
