/*
 * register components globally
 */

import { App } from 'vue'
import UIBtnPrimary from './UIBtn/UIBtnPrimary.vue'
import UIBtnSecondary from './UIBtn/UIBtnSecondary.vue'
import UIBtnRound from './UIBtn/UIBtnRound.vue'
import UIIcon from './UIIcon/UIIcon.vue'
import Heading from './Heading/Heading.vue'
import InsetBorder from './InsetBorder/InsetBorder.vue'

const register = (app: App<Element>): void => {
  app.component('ui-btn', UIBtnPrimary) // alias of UIBtnPrimary
  app.component('ui-btn-primary', UIBtnPrimary)
  app.component('ui-btn-secondary', UIBtnSecondary)
  app.component('ui-btn-round', UIBtnRound)
  app.component('ui-icon', UIIcon)
  app.component('heading', Heading)
  app.component('inset-border', InsetBorder)
}

export default { register }