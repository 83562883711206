import type Camera from "nanogl-camera";
import Node from "nanogl-node";
import { mat3, quat, vec3, vec4 } from "gl-matrix";
import ArtworkProxy from "./Proxy";
import MinimapMesh from "./MinimapMesh";
import { ArtworkData, ArtworkMediaSource } from "../types";
import { Minimap2DView } from "@/webgl/entities/minimap";
import Label from "./Label";
import ArtworkMesh from "./Mesh";
import ArtworkBounds from "./Bounds";
import ArtworkTextureResource from "./TextureResource";
import { anime } from "@/utils/anime";
import Texture2D from "nanogl/texture-2d";
import { StreamingAllocator } from "@/webgl/activities/GalleryActivity/Artwork/TextureStreamingGroup";
import ArtworkTexturePool from "@/webgl/activities/GalleryActivity/Artwork/TexturePool";
import type ArtworkFog from "./Fog";
import { clamp01 } from "@/webgl/math";

const V3 = vec3.create();
const HEIGHT = 220;
const M3 = mat3.create();
const QUAT = quat.create();

export default class ArtworkState {
  public visible = false;
  public visibleOnScreen = false;
  public focused = false;
  public revealState = "done" as "done" | "idle" | "running";
  public index = -1;
  public posX = 0;

  public root = new Node();
  public focusNode = new Node();

  public direction = vec3.fromValues(0, 0, 0);

  public staticBounds = new ArtworkBounds();
  public movingBounds = new ArtworkBounds();

  public data: ArtworkData;
  public author = new Label();
  public fog: ArtworkFog;

  public color: vec4;
  public seed: number;
  public gap = 0;

  private allocator: StreamingAllocator = new StreamingAllocator();
  get Allocator(): StreamingAllocator {
    return this.allocator;
  }

  constructor(
    public readonly textures: ArtworkTexturePool
  ) {
    this.root.add(this.staticBounds.node);
    this.root.add(this.focusNode);
    this.focusNode.add(this.movingBounds.node);
    this.focusNode.add(this.author.root);
    this.color = vec4.fromValues(1.0, 1.0, 1.0, 1.0);
    this.seed = Math.random() * 100.0;
  }

  get width() {
    return this.staticBounds.node.scale[0] * this.root.scale[0] * 2;
  }

  get height() {
    return this.staticBounds.node.scale[1] * this.root.scale[1] * 2;
  }

  setData(data: ArtworkData) {
    this.data = data;
    this.allocator.src = data.src;

    // const REF = (HEIGHT / 1600) * window.innerWidth;
    const REF = HEIGHT;
    const ratio = this.data.width / this.data.height;
    const height = REF * 0.5;
    this.movingBounds.node.scale[0] = ratio * height;
    this.movingBounds.node.scale[1] = height;
    this.movingBounds.node.invalidate();
    this.staticBounds.node.scale[0] = this.movingBounds.node.scale[0];
    this.staticBounds.node.scale[1] = this.movingBounds.node.scale[1];
    this.staticBounds.node.invalidate();

    let format = "square" as "square" | "landscape" | "portrait";

    if (ratio < 1) {
      format = "portrait";
    } else if (ratio > 1) {
      format = "landscape";
    }

    // Author
    this.author.renderable = !!this.data.author;
    this.author.content = this.data.author || "";
    this.author.root.x = this.width * -0.5;
    this.author.root.y = this.height * 0.5;
    this.author.root.z = 1;

  }

  enable(proxy: ArtworkProxy) {
    proxy.root.add(this.root);

    if (proxy.queued) {
      this.revealState = "idle";
      this.focusNode.setScale(Number.EPSILON);
    }
  }

  disable(proxy: ArtworkProxy) {
    this.allocator.locked = false;
    proxy.root.remove(this.root);
  }

  update(camera: Camera) {

    this.staticBounds.compute(camera);
    this.movingBounds.compute(camera);

    // Update visibility
    this.visible = this.staticBounds.isRadiusVisible(this.fog.worldRadius * 2) && this.revealState !== 'idle';

    this.visibleOnScreen = this.staticBounds.isScreenVisible();

    this.allocator.locked = this.visible;

    // Update direction
    vec3.set(this.direction, 0, 0, 1);
    mat3.fromMat4(M3, this.root._wmatrix);
    quat.fromMat3(QUAT, M3);
    vec3.transformQuat(this.direction, this.direction, QUAT);
    vec3.normalize(this.direction, this.direction);
  }

  async focus(focused: boolean, options = { distance: 50, gap: 50 }) {
    if (this.focused === focused) return;
    this.focused = focused;

    vec3.scale(V3, this.direction, options.distance);

    await Promise.all([
      anime(this.focusNode, {
        x: focused ? V3[0] : 0,
        z: focused ? V3[2] : 0,
        duration: 0.25,
      }),
      anime(this, {
        gap: focused ? options.gap : 0,
        duration: 0.25,
      }),
    ]);
  }

  async reveal() {
    if (this.revealState !== "idle") return;
    this.revealState = "running";

    const scale = { value: Number.EPSILON };
    await anime(scale, {
      value: 1,
      onUpdate: () => {
        this.focusNode.setScale(scale.value);
      },
    });

    this.revealState = "done";
  }

  render(camera: Camera, mesh: ArtworkMesh, time: number) {
    if (!this.visible) return;

    const phase = ( Math.sin((time + this.seed) * 8.0) + 1.0 ) * 0.5;

    this.color[0] = phase * 0.18;
    this.color[1] = phase * 0.18;
    this.color[2] = phase * 0.18;
    this.color[3] = 0.0;
    if (this.allocator.loaded) {
      this.color[3] = clamp01(((Date.now() - this.allocator.loadedTime) / 1000) * 3.0);
    }

    mesh.render({
      camera,
      texture: this.textures.get(this.data.pov.format, this.Allocator),
      node: this.movingBounds.node,
      color: this.color,
      fog: this.fog,
    });
  }
}
