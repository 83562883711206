import type {
  InputEvent,
  InputEventCallback,
} from "../types";

interface Listener {
  type: InputEvent["type"];
  listener: InputEventCallback;
  once: boolean;
}

export default class Dispatcher {
  protected _listeners: Listener[] = [];

  constructor() {}

  protected indexOf(
    type: InputEvent["type"],
    listener: InputEventCallback
  ) {
    return this._listeners.findIndex(
      (item) => item.type === type && item.listener === listener
    );
  }

  add(
    type: InputEvent["type"],
    listener: InputEventCallback,
    options?: { once?: boolean }
  ) {
    this._listeners.push({ type, listener, once: !!options?.once });
  }

  remove(
    type: InputEvent["type"],
    listener: InputEventCallback
  ) {
    const index = this.indexOf(type, listener);
    if (index !== -1) this._listeners.splice(index, 1);
  }

  dispatch(e: InputEvent) {
    const onces: Listener[] = [];
    for (const entry of this._listeners) {
      const { listener, once, type } = entry;
      if (type !== e.type) continue;
      listener(e);
      if (once) onces.push(entry);
    }

    if (onces.length > 0) {
      for (const entry of onces) {
        this.remove(entry.type, entry.listener);
      }
    }
  }
}
