import Node from "nanogl-node";
import { quat, vec3 } from "gl-matrix";
import { MUSEUM_FLOOR_LEVEL } from ".";
import { mat4Zaxis } from "@/webgl/math/mat4-axis";
import XRNavigationController from "@/webgl/entities/xr-module/navigation/XRNavigationController";

const F_NORMAL = vec3.create()

export function GotoFrame( frame:Node, ctrl:XRNavigationController, distance = 1.5 ): void {
  const position = vec3.create();
  const rotation = quat.create();
  quat.rotateY( rotation, frame.rotation, Math.PI )
  
  mat4Zaxis(F_NORMAL, frame._wmatrix)
  vec3.normalize(F_NORMAL, F_NORMAL)
  vec3.scaleAndAdd( position, frame._wposition as vec3, F_NORMAL, -distance )

  const y = frame._wposition[1] > MUSEUM_FLOOR_LEVEL ? MUSEUM_FLOOR_LEVEL:0
  
  position[1] -= ctrl.xrmodule.xrview.pose.transform.position.y
  

  ctrl.setTargetRotation(rotation )
  ctrl.moveTo( position )
}