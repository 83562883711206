import { MsaaFbo } from "@/webgl/gl/MsaaFbo";
import Chromashift from "@/webgl/glsl/chromashift";
import Scene from "@/webgl/Scene";
import Post from "nanogl-post";
import Fetch from "nanogl-post/effects/fetch";
import Grain from "nanogl-post/effects/grain";
import Hejl from "nanogl-post/effects/hejl";
import GammaCorrection from "nanogl-post/effects/gamma_correction";
import Dithering from "@/webgl/glsl/dithering";
import Fbo from "nanogl/fbo";
import PostProcessing from "../MuseumActivity/PostProcessing";


export class MuseumXRPostProcessing extends PostProcessing {

  chromaShift: Chromashift;
  dithering: Dithering;

  
  constructor(scene: Scene) {
    super(scene);

    this.dithering = new Dithering();
    this.setup.add(this.dithering);

  }
}