import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import Gtm from '@/utils/Gtm'

@Module
export class InformationPanel extends VuexModule {
  public isOpen: boolean = false

  @Action
  open() {
    this._SET_OPEN(true)
    Gtm.sendVPV('information')
  }

  @Action
  close() {
    this._SET_OPEN(false)
  }

  @Action
  toggle() {
    this._SET_OPEN(!this.isOpen)
  }

  @Mutation
  private _SET_OPEN(isOpen: boolean) {
    this.isOpen = isOpen
  }
}

const informationPanel = new InformationPanel({ store, name: 'informationPanel' })

export default informationPanel