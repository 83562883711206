import MaterialPass from "@/webgl/lib/nanogl-pbr/src/MaterialPass";
import nanoglCamera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import Node from "nanogl-node";
import Program from "nanogl/program";
import vShader from "./stencil.vert";
import fShader from "./stencil.frag";
import Flag from 'nanogl-pbr/Flag'
import Precision from 'nanogl-pbr/ShaderPrecision'
import Version from 'nanogl-pbr/ShaderVersion'

const MAT_ID = "granite-stencil";

/**
 * @extends {BaseMaterial}
 */
export default class GraniteStencilPass extends MaterialPass {

  version: Version;
  precision: Precision;
  shaderid: Flag<string>;

  constructor() {

    super({
      uid: MAT_ID,
      vert: vShader(),
      frag: fShader(),
    });

    const inputs = this.inputs;

    inputs.add(this.version = new Version('100'));
    inputs.add(this.precision = new Precision('highp'));
    inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));

  }

  prepare(prg: Program, node: Node, camera: nanoglCamera<ICameraLens>): void {

    prg.uMVP(camera.getMVP(node._wmatrix));

  }

}