import TextRenderer, {
  ITextRenderInfo,
  ITextStyle,
} from "@/webgl/entities/UIKit/text/TextRenderer";
import Programs from "@/webgl/gl/Programs";
import GLState from "nanogl-state";
import { GLContext } from "nanogl/types";
import Node from "nanogl-node";
import { vec4 } from "gl-matrix";
import Camera from "nanogl-camera";

interface IRenderingContext {
  gl: GLContext;
  glstate: GLState;
  programs: Programs;
}

const TXT_KEY = "default";

export default class UIText2 {
  node: Node;
  renderer: TextRenderer;

  content: string = "";
  style: ITextStyle;
  color: vec4 = vec4.fromValues(1.0, 1.0, 1.0, 1.0);

  _valid: boolean = true;

  metrics: ITextRenderInfo;

  constructor(public readonly context: IRenderingContext) {
    this.node = new Node();
    this.renderer = new TextRenderer(context);
  }

  setStyle(style: ITextStyle) {
    this.style = style;
    this._valid = false;
  }

  setText(txt: string) {
    this.content = txt;
    this._valid = false;
  }

  preRender() {
    if (!this._valid) {
      this.renderer.clear();
      this.renderer.registerText(TXT_KEY, this.content, this.style);
      this.renderer.build();
      this.metrics = this.renderer.texts.get(TXT_KEY);
      this._valid = true;
    }
  }

  render(camera: Camera) {
    this.renderer.prepare();
    this.renderer.renderDirect(
      TXT_KEY,
      camera.getMVP(this.node._wmatrix),
      this.color
    );
    this.renderer.finish();
  }
}
