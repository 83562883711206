import { AppState } from "@/store/states/AppStateMachine";
import Navigation from "@/webgl/entities/xr-module/navigation/Navigation";
import Scene from "@/webgl/Scene";
import { AbortController } from "@azure/abort-controller";
import { IActivity } from "../Activity";
import {MuseumXRPostProcessing} from "./PostProcessing";
import ArtworkPicking from "./ArtworkPicking";
import { ArtworkId, ArtworkList } from "@/store/states/Artworks";
import { vec3 } from "gl-matrix";
import { GotoFrame } from "./GotoFrame";
import InfoCards from "./InfoCards";
import Camera from "nanogl-camera";
import { mat4Zaxis } from "@/webgl/math/mat4-axis";
import AppService from "@/store/states/AppService";
import StateHelper from "../StateHelper";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { MuseumStore } from "@/store/modules/MuseumStore";

export const MUSEUM_FLOOR_LEVEL = 4.0

const START_POS = vec3.fromValues(0, 0, 22.8414)

export default class MuseumXRActivity implements IActivity {

  readonly priority : number = 0;

  navigation     : Navigation            
  artworkPicking : ArtworkPicking        
  postProcessing : MuseumXRPostProcessing
  infoCards      : InfoCards

  private _startAbortController: AbortController;
  stateHelper: StateHelper;

  constructor( public scene: Scene ) {
    this.postProcessing = new MuseumXRPostProcessing( scene)

    this.stateHelper = new StateHelper([
      { match:'museum_loading.outro', enter : this.enterLockedMode, exit: this.exitLockedMode },
      { match:'museum.explore', enter : this.enterExplore, exit: this.exitExplore },
      { match:'museum.toPainting', enter : this.enterPaintingEntry, exit: this.exitPaintingEntry },
      // { match:'museum.toPainting', enter : this.enterLoadingOutro, exit: this.exitLoadingOutro },
    ])

  }
  
  onStateUpdate(state: AppState): void {
    this.infoCards.cardId = state.context.nearbyArtworkId || 'ticket'
  }

  
  async load(): Promise<void> {
    console.log("MuseumXRActivity.load()");

    this.infoCards      = new InfoCards(this)
    
    await Promise.all([
      this.scene.loadMuseumScene(),
      this.scene.loadXRModule(),
      this.infoCards.load(),
    ]).then(()=>{
      console.log("MuseumXRActivity.loaded()");
    })

    this.navigation = new Navigation( this.scene.xrmodule );
    this.navigation.offset = .1

    this.artworkPicking = new ArtworkPicking(this)

    this.artworkPicking.onArtworkClicked.on( this.onArtworkClicked )

    await Promise.all([
      this.navigation.load()
    ])

    this.navigation.navmesh = this.scene.museumScene.navmesh;
    
  }

  onArtworkClicked=(artworkId: ArtworkId)=>{
    this.moveToArtwork(artworkId)
  }


  unload(): void {
    this.infoCards.unload()
  }
  

  start(): void {

    this.stateHelper.start()

    this.scene.xrmodule.gamepads.setCustomLightSetup( this.scene.museumScene.lighting.lightSetup )
    this._startAbortController = new AbortController()
    this.scene.museumScene.quality.onChange.on( this.onQualityChange)
    this.onQualityChange()
    
    this.scene.xrview.session.updateRenderState({
      depthNear: 0.05,
      depthFar: 90
    } );
    
    // this.scene.museumScene.startQualityAutoLevel( this._startAbortController )
    this.scene.museumScene.quality.setLevel(2)

  }
  
  stop(): void {
    this.stateHelper.stop()
    this.scene.xrmodule.gamepads.restoreDefaultLightSetup()
    this._startAbortController.abort()
    this._startAbortController = null
  }
  

  enterLockedMode= ()=>{
    if( MuseumStore.xrpositionset ){
      this.navigation.xrctrl.rotation.set( MuseumStore.xrrotation )
      this.navigation.xrctrl.moveTo( new Float32Array(MuseumStore.xrposition) as vec3 )
    } else {
      this.navigation.xrctrl.moveTo(START_POS)
    }
  }
  
  exitLockedMode= ()=>{
    
  }
  
  enterExplore= ()=>{
    this.navigation.enable();
    this.navigation.xrctrl.enableController("left");
    this.navigation.xrctrl.enableController("right");
  
    this.infoCards.start()
    
    // this.scene.museumScene.quality.debugLevel = 5
    this.artworkPicking.start()

    this.scene.xrmodule.tooltips.push("Right/Thumbstick", "xr.teleport");

    this.navigation.onMove.on( this.onTeleportOccur )
    
  }
  
  exitExplore= ()=>{
    this.navigation.disable();
    this.navigation.xrctrl.disableController("left");
    this.navigation.xrctrl.disableController("right");
    this.artworkPicking.stop()
    this.infoCards.stop()
    
    this.scene.xrmodule.tooltips.pop("Right/Thumbstick", "xr.teleport");
    this.navigation.onMove.off( this.onTeleportOccur )
  }
  
  onTeleportOccur=()=>{
    MuseumStore.xrposition = Array.from(this.navigation.target)
    MuseumStore.xrrotation = Array.from(this.navigation.xrctrl.rotation)
    MuseumStore.xrpositionset = true

    this.scene.xrmodule.tooltips.pop("Right/Thumbstick", "xr.teleport");
  }



  enterPaintingEntry = ()=>{
    AppService.send( {type:'PAINTING_TRANSITION_COMPLETE'})
  }

  exitPaintingEntry = ()=>{
  }


  moveToArtwork(artworkId: ArtworkId) {
    const artwork = this.scene.museumScene.artworks.get(artworkId)
    GotoFrame(artwork.frame, this.navigation.xrctrl)
  }


  onQualityChange = ()=>{
    const lvl = this.scene.museumScene.quality.level
    this.postProcessing.enabled = lvl.post
  }



  preFrame(): void {}
  
  
  preRender(): void {
    this.scene.museumScene.lighting.setupLightingForPosition( this.scene.xrmodule.gamepads.list.get('right').skin._wposition as vec3 )


    this.scene.museumScene.preRender( this.scene.xrmodule.xrcamera.cameras )
    this.scene.xrmodule.preRender()
    this.navigation.preRender();
    this.artworkPicking.preRender()

    this.updateNearbyArtwork()

    AudioManager.updateListener(this.scene.xrmodule.xrcamera.head._wmatrix);
    // TODO PROPER WAY
    // AudioManager.getAudio(AUDIO_ID.OUTSIDE_PAINTING).pos(-0.9886183738708496, 1.6786199808120728, -15);
    
  }

  render(): void {

    const gl = this.scene.gl;
    const gllayer = this.scene.xrview.gllayer

    const vpw = this.scene.viewport.width
    const vph = this.scene.viewport.height

    gl.bindFramebuffer(gl.FRAMEBUFFER, gllayer.framebuffer);
    gl.clearColor(.1, 0, 0, 1)
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    
    
    this.postProcessing.bindFrameBuffer(vpw, vph, gllayer.framebuffer);
    
    // RENDER
    // ========
    for (const camera of this.scene.xrmodule.xrcamera.cameras) {
      const vp = camera.lens.viewport
      gl.viewport(vp.x, vp.y, vp.width, vp.height);
      
      this.scene.museumScene.render(camera)
      this.scene.xrmodule.render(camera)
      this.navigation.render(camera)
      this.artworkPicking.render(camera)
      this.infoCards.render(camera)
    }
    
    this.postProcessing.blit(vpw, vph, gllayer.framebuffer);
/////////////////
/////////////////////////////////////////////
//////////////
    
  }

  renderUI(): void {
    
  }

 

  rttPass(): void {
    this.scene.museumScene.reflectPass( this.scene.xrmodule.xrcamera.cameras )
  }



  private _nearbyArtworkId: ArtworkId = null
  private _lastNearbyCheck : number =0

  private updateNearbyArtwork(){
    const now = Date.now()
    if( now - this._lastNearbyCheck < 200 ) return
    this._lastNearbyCheck = now
    this.setNearbyArtwork( this.resolveNearbyArtwork(this.scene.xrmodule.xrcamera.cameras[0]) )
  }


  private setNearbyArtwork( artwork:ArtworkId|null ){
    if( this._nearbyArtworkId === artwork ) return
    this._nearbyArtworkId = artwork
    AppService.send({type:'NEARBY_ARTWORK', artworkId: artwork})
  }

  
  private findInRangeArtwork(pos:vec3, range:number ): ArtworkId[] {
    const artworks = this.scene.museumScene.artworks
    const rangeSq = range * range
    const res = []
    for(const id of ArtworkList){
      const artwork = artworks.get(id)
      const apos = artworks.get(id).frame._wposition as vec3
      
      // don't find artwork on other floors
      if(Math.abs(apos[1]-pos[1]) > 2) continue

      const distSq = vec3.squaredDistance(apos, pos)
      if (distSq < rangeSq) {
        res.push(artwork.id)
      }
    }
    return res
  }


  private resolveNearbyArtwork(camera:Camera): ArtworkId {
    const inRange = this.findInRangeArtwork(camera._wposition as vec3, 5)
    if (inRange.length === 0) return null
    if (inRange.length === 1) return inRange[0]

    let maxDot = -1
    let bestId = inRange[0]
    
    const ADIR = vec3.create()
    const VDIR = vec3.create()

    for (const id of inRange) {
      const artwork = this.scene.museumScene.artworks.get(id)
      vec3.sub(ADIR, artwork.frame._wposition as vec3, camera._wposition as vec3)
      vec3.normalize(ADIR, ADIR)
      
      mat4Zaxis(VDIR, camera._wmatrix)// z is backward
      vec3.normalize(VDIR, VDIR)
      const dot = -vec3.dot(VDIR, ADIR)

      if( dot>maxDot ){
        maxDot = dot
        bestId = id
      }
    }

    return bestId
    
  }

}