
import Camera from "nanogl-camera";
import Node from "nanogl-node";

import GLTFResource from "@/webgl/assets/GLTFResource";
import Skybox from "@/webgl/entities/skybox/Skybox";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Scene from "@/webgl/Scene";
import Lighting from "./IblManager";
import Reflect from "./Reflect";
import getViewport from "@/webgl/camera/getViewport";
import MaterialOverrideExtension2 from "@/webgl/lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension2";
import MaterialFactory from "./MaterialFactory";
import CameraPath from "./CameraPath";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Picking from "@/webgl/lib/Picking";
import Ray from "@/webgl/math/Ray";
import { ArtworkId, ArtworkList } from "@/store/states/Artworks";
import { copyFrameRaycastResult, createFrameRaycastResult, FrameRaycastResult, raycastNearestFrame } from "@/webgl/math/raycastFrame";
import ArtworkEntity from "./ArtworkEntity";
import MuseumQuality from "./MuseumQuality";
import { AbortController } from "@azure/abort-controller";
import SkyboxRenderer from "@/webgl/entities/pano/SkyboxRenderer";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import { clamp01 } from "@/webgl/math";
import i18n from "@/core/i18n";


const RaycastRes = createFrameRaycastResult()

const FloorHeight = 4.03

export default class MuseumScene {

  gltf      : Gltf
  lighting  : Lighting
  reflect   : Reflect
  materials : MaterialFactory
  cameraPath: CameraPath     
  navmesh   : Picking    
  skydome   : Node;
  quality   : MuseumQuality
  artworkFrames : Node[]   
  artworks : Map<string, ArtworkEntity> = new Map() 
  titles: Map<string, IRenderable> = new Map()

  private _reflectEnabled = true;
  private _loaded: boolean;

  public get reflectEnabled() {
    return this._reflectEnabled;
  }
  public set reflectEnabled(value) {
    if( this._reflectEnabled !== value ){
      this._reflectEnabled = value;
      if( !value ) this.reflect.clear()
    }
  }

  readonly root = new Node()
  private _loadPromise: Promise<void>;
  
  
  constructor( readonly scene: Scene ){
    this.scene.root.add(this.root);
    this.reflect    = new Reflect        (this.scene)
    this.cameraPath = new CameraPath     (this      )
    this.quality    = new MuseumQuality  (          )
  }
  
  
  async load(): Promise<void> {
    if( !this._loadPromise ){
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise;
  }
  
  private async _doLoad(): Promise<void> {
    console.log( "Load museum scene");
    this.materials  = new MaterialFactory(this      )
    this.lighting   = new Lighting       (this      )
    
    await this.lighting.loadDefault()
    await this._loadMuseumGltf()
    this.cameraPath.init()
    this.quality.onChange.on(this.onQualityChange )
    this.onQualityChange()
    this._loaded = true
  }
  
  onQualityChange = ()=>{
    this.reflectEnabled = this.quality.level.reflect
    this.reflect.setQuality( this.quality.level.reflectResolution, this.quality.level.reflectAA )
    this.materials.setQuality( this.quality.level )
  }
  
  
  unload(): void {
    if( !this._loaded ) return
    console.log( "unload museum scene");
    this._loaded = false
    
    this.quality.onChange.off(this.onQualityChange )
    this.materials.unload()
    this.lighting.unload()
    this.cameraPath.release()
    this.root.remove(this.gltf.root);
    this.gltf = null
    this.navmesh = null
    this._loadPromise = null
    this.artworkFrames.length = 0
    this.artworks.clear()
    this._loadPromise = null
    this.lighting.dispose()
    this.lighting = null
    this.skydome = null;
    this.titles = new Map();
    this.materials = null
  }

  startQualityAutoLevel(cts: AbortController) {
    return this.quality.startAutoLevel(cts)
  }

  preRender(cameras: Camera[]){
    this.materials.reflChunk.setSize( this.scene.viewport.width, this.scene.viewport.height )
    this.skydome.position.set( cameras[0]._wposition )
    this.skydome.invalidate()
  }
  
  reflectPass(cameras: Camera[]) {
        
    // REFLECT
    // =======

    
    // TODO: quality/perf switch
    if( this._reflectEnabled ){ 

      const camHeight = cameras[0]._wposition[1]
      const reflectHeight = FloorHeight * clamp01((camHeight - FloorHeight)/.1)
      this.reflect.groundHeight = reflectHeight


      const glstate = this.scene.glstate
      const gl = this.scene.gl

      glstate.push( this.reflect.globalCfg )
      glstate.apply()
      
      
      this.reflect.bindAndClear()
      

      for (const camera of cameras) {
        
        this.reflect.processCamera( camera )

        this.lighting.setupForReflect()
        this.renderPass( camera, Masks.REFLECTED_COLOR )
        this.renderPass( camera, Masks.REFLECTED_BLENDED )
        this.lighting.restoreFromReflect()
        
        glstate.apply()
        gl.colorMask( false, false, false, true );
        gl.clear( gl.DEPTH_BUFFER_BIT )
        this.renderPass( camera, Masks.REFLECTED_DEPTH, Passes.REFLECT_DEPTH);
        gl.colorMask( true, true, true, true );
        
        this.reflect.restoreCamera( camera );
      }

      glstate.pop()


      this.reflect.blitRenderBuffer();
      this.reflect.processOutput()

      // fbodebug.debug( this.reflect.getOutputFbo() );
    }
    
  }


  render( camera: Camera ) {
    this.renderPass( camera, Masks.OPAQUE )
    this.getCurrentTitle().render(this.scene, camera , Masks.OPAQUE, Passes.COLOR )
    this.renderPass( camera, Masks.BLENDED )
  }


  getCurrentTitle():IRenderable{
    const res = this.titles.get( i18n.global.locale.value )
    return res || this.titles.get( 'en' )
  }

  
  renderPass( camera: Camera, mask: Masks, pass: Passes = Passes.DEFAULT) {
    for (const renderable of this.gltf.renderables) {
      renderable.render(this.scene, camera , mask, pass )
    }
  }

  
  raycastArtworks( ray: Ray, res = RaycastRes, maxdist = 1000 ): ArtworkId | null {
    const i = raycastNearestFrame( res, ray, this.artworkFrames )
    if( i > -1 && res.distance < maxdist ){
      console.log(res.distance);
      
      return ArtworkList[i]
    }
    return null
  }


  private async _loadMuseumGltf() {

    const gltfOverride = new MaterialOverrideExtension2();
    gltfOverride.getMaterial = this.materials.getMaterial

    await this.materials.texs.load()
    
    this.gltf = await new GLTFResource(
      require( '@/assets/webgl/museum/museum.glb'),
      // Paths.resolve("assets/webgl/gltfs/museum/museum.gltf"),
      this.scene,
      {
        extensions: [gltfOverride]
      }
    ).load();

    this.root.add(this.gltf.root);
    this._extractNavMesh()
    this._extractTitles()
    this._extractArtworks()


    this.skydome = this.gltf.getElementByName( GltfTypes.NODE, 'SkyDome' )
    
    await this.materials.lightmaps.load()

    
    
  }
  

  private _extractNavMesh(){
    
    const navMeshMesh = this.gltf.getElementByName( GltfTypes.MESH, 'NavMesh' )
    const navMeshNode = this.gltf.getElementByName( GltfTypes.NODE, 'NavMesh' )

    console.assert( navMeshMesh!=null, 'NavMesh mesh not found' )
    console.assert( navMeshNode!=null, 'NavMesh node not found' )

    const i = this.gltf.renderables.findIndex( r=>r.node.name === 'NavMesh' )
    if( i !== -1 ){
      this.gltf.renderables.splice( i, 1 )
    }
  
    const pAccessor = navMeshMesh.primitives[0].attributes.getSemantic( 'POSITION' ).accessor
    const positions = pAccessor.createElementHolderArray( pAccessor.count )
    pAccessor.getValues( positions, 0, pAccessor.count )
  
    const iAccessor = navMeshMesh.primitives[0].indices
    const indices = iAccessor.createElementHolderArray( iAccessor.count )
    iAccessor.getValues( indices, 0, iAccessor.count )
  
    this.navmesh = new Picking(navMeshNode, indices, positions)

  }

  private _extractTitles(){
    
    const langages = ['en', 'de']

    for (const l of langages) {
      const i = this.gltf.renderables.findIndex( r=>r.node.name === `Title_${l.toUpperCase()}` )
      console.assert( i !== -1, `no mesh Title_${l} in scene` )  
      this.titles.set(l, this.gltf.renderables[i])
      this.gltf.renderables.splice( i, 1 )
    }

  }


  private _extractArtworks(){
    this.artworkFrames = []
    for (const id of ArtworkList) {
      const node = this.gltf.getElementByName( GltfTypes.NODE, `ArtworkFrame_${id}` )
      console.assert( node!=null, `ArtworkFrame_${id} not found` )
      this.artworkFrames.push(node)

      const entity = new ArtworkEntity( 
        id, 
        node, 
        this.materials.getMaterial(`ArtworkHover_${id}`),
        this.materials.getMaterial(`ArtworkCanvas_${id}`),
      )
      this.artworks.set( id, entity)
    }
  }

}