
// Probe 
var coeffs = 
[
    /* Band 0 */  3.778129624886073845,  3.669623792332020962,  4.410226134693353117 ,
    /* Band 1 */ -1.150172386217841680, -1.359591539697078577, -1.728307290403715779 ,   0.447804240728616132,  0.526558440095849289,  0.696149011177816246, -0.238368629278703975, -0.221845933883003832, -0.249791607356250056,
    /* Band 2 */  -0.051013414684819375, -0.025394555193965294, -0.005201056399162596 ,  -0.716173713426306535, -0.779438280782538295, -0.950175876047958501 ,  -0.718213992237831444, -0.711971707006843313, -0.790357967564136010 ,   0.167233777503790759,  0.157957361275079089,  0.182128667889736029 ,  -0.047667026635456168, -0.169125073216808058, -0.270654674121572181 ,
    /* Band 3 */  -0.059178244052873002, -0.080544208813215987, -0.103452495732627797 ,  -0.013366152013878571, -0.025692734845401233, -0.036488303734246684 ,   0.274120179274525644,  0.307437561757691102,  0.386174312113540530 ,  -0.047397913836131532, -0.103301948675911068, -0.142503636954686735 ,   0.094144791820434309,  0.085819871154285368,  0.098774854752496366 ,  -0.413927598092586613, -0.449422313553145991, -0.554719896739525375 ,   0.055093437923040632,  0.054292945165933366,  0.069260045615303342 ,
    /* Band 4 */   0.002491384893768759,  0.008688123099506971,  0.021242446135690721 ,   0.051769474370988493,  0.074706684677444124,  0.098500320370577502 ,  -0.022938432878300066, -0.028604632261613118, -0.030625420709333422 ,  -0.034724105817802484, -0.026422617608703212, -0.020544613442784708 ,   0.244102564701050767,  0.253719321329379022,  0.310992568128755364 ,   0.054797179722159077,  0.047137737615880602,  0.049515379675945159 ,  -0.260567507881626803, -0.263924992374563072, -0.308220016338996039 ,  -0.059896650058809751, -0.053716690642720895, -0.065184181615564926 ,  -0.018042384985786846, -0.061285049520511163, -0.106786112690995375 
];

module.exports = coeffs;