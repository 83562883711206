import Signal from "@/core/Signal";
import { XRHandedness } from "webxr";


interface IButton {
  pressed: boolean;
  touched: boolean;
  value: number;
}

export type XRGamepadButton = "Right/Trigger" | "Right/Squeeze" | "Right/Touchpad" | "Right/Thumbstick" | "A" | "B" | "Right/Up" | "Right/Down" | "Right/Left" | "Right/Right" | "Left/Trigger" | "Left/Squeeze" | "Left/Touchpad" | "Left/Thumbstick" | "X" | "Y" | "Left/Up" | "Left/Down" | "Left/Left" | "Left/Right"

export interface ButtonEvent {
  name: XRGamepadButton;
  button: GamepadButton;
}

type GamepadHapticActuator = {
  pulse(value: number, duration: number);
  playEffect();
  reset();
  type: GamepadHapticActuatorType;
}

const MAPPING_RIGHT = [
  "Right/Trigger",
  "Right/Squeeze",
  "Right/Touchpad",
  "Right/Thumbstick",
  "A",
  "B",
  "Right/Up",
  "Right/Down",
  "Right/Left",
  "Right/Right",
] as XRGamepadButton[]

const MAPPING_LEFT = [
  "Left/Trigger",
  "Left/Squeeze",
  "Left/Touchpad",
  "Left/Thumbstick",
  "X",
  "Y",
  "Left/Up",
  "Left/Down",
  "Left/Left",
  "Left/Right",
] as XRGamepadButton[]

export const XRButtonList = [...MAPPING_RIGHT, ...MAPPING_LEFT];

let MAPPING_NAME_TO_IDX = {};
for (let i = 0; i < MAPPING_RIGHT.length; i++) {
  MAPPING_NAME_TO_IDX[MAPPING_RIGHT[i]] = i;
}
for (let i = 0; i < MAPPING_LEFT.length; i++) {
  MAPPING_NAME_TO_IDX[MAPPING_LEFT[i]] = i;
}

export default class WebXRGamepad {

  mapping: XRGamepadButton[];

  get buttons(): readonly GamepadButton[] {
    return this.gamepad.buttons;
  }

  get axes(): readonly number[] {
    return this.gamepad.axes;
  };

  private _buttons: IButton[];
  private _axes: number[];

  onBtnDown: Signal<ButtonEvent>;
  onBtnUp: Signal<ButtonEvent>;

  constructor(
    public readonly handedness: XRHandedness,
    public readonly gamepad: Gamepad
  ) {

    this.mapping = handedness == "left" ? MAPPING_LEFT : MAPPING_RIGHT;
    this._buttons = [];
    this._axes = [0, 0];

    for (let i = 0; i < this.gamepad.buttons.length; i++) {
      this._buttons.push({ pressed: false, touched: false, value: 0 });
    }

    this.onBtnDown = new Signal<ButtonEvent>();
    this.onBtnUp = new Signal<ButtonEvent>();

  }

  pulse(intensity: number, duration: number): Promise<any> {
    if (!this.gamepad.hapticActuators) return Promise.resolve();
    return (this.gamepad.hapticActuators[0] as GamepadHapticActuator).pulse(intensity, duration);
  }

  getButton(name: string) {
    return this.buttons[MAPPING_NAME_TO_IDX[name]];
  }

  getAxes() {
    return this._axes;
  }

  update() {

    // 6: "Up",
    // 7: "Down",
    // 8: "Left",
    // 9: "Right",
    // LEFT
    if (this._axes[0] > -0.5 && this.axes[2] <= -0.5) {
      this.onBtnDown.emit({
        name: this.mapping[8],
        button: null
      })
    }

    // RIGHT
    if (this._axes[0] < 0.5 && this.axes[2] >= 0.5) {
      this.onBtnDown.emit({
        name: this.mapping[9],
        button: null
      })
    }

    // UP
    if (this._axes[1] > -0.5 && this.axes[3] <= -0.5) {
      this.onBtnDown.emit({
        name: this.mapping[6],
        button: null
      })
    }
    // DOWN
    if (this._axes[1] < 0.5 && this.axes[3] >= 0.5) {
      this.onBtnDown.emit({
        name: this.mapping[7],
        button: null
      })
    }

    this._axes[0] = this.axes[2];
    this._axes[1] = this.axes[3];

    for (let i = 0; i < this.gamepad.buttons.length; i++) {

      if (this.gamepad.buttons[i].pressed && !this._buttons[i].pressed) {
        this._buttons[i].pressed = true;
        this.onBtnDown.emit({
          name: this.mapping[i],
          button: this.gamepad.buttons[i]
        });
      }

      if (!this.gamepad.buttons[i].pressed && this._buttons[i].pressed) {
        this._buttons[i].pressed = false;
        this.onBtnUp.emit({
          name: this.mapping[i],
          button: this.gamepad.buttons[i]
        });
      }

    }

  }

}