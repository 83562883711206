
import { gsap } from 'gsap'
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useActor } from '@xstate/vue'

import WebXRView from '@/webgl/xr/WebXRView'

import ArtistName from '@/components/ArtistName/ArtistName.vue'
import PreLoader from '@/components/PreLoader/PreLoader.vue'
import SplitText from '@/utils/SplitText'
import AppService from '@/store/states/AppService'
import markdown from '@/utils/Markdown'
import UrlParams from '@/utils/UrlParams'
import { setFPS } from '@/utils/FrameToTime'
import Browser from "@/utils/Browser";
import Gtm from "@/utils/Gtm"

export default defineComponent({

  components: {
    ArtistName,
    PreLoader
  },

  setup() {
    Gtm.sendVPV('home')

    const { t, locale } = useI18n()
    const supportXR = ref(false)
    const { send } = useActor(AppService)
    const frames = setFPS(60)

    onMounted(() => {

      const oculusBrowser = Browser.oculus || UrlParams.getBool("debug-xr")

      WebXRView.IsSessionSupportedAsync('immersive-vr')
        .then((supported) => {

          supportXR.value = (supported && oculusBrowser) || UrlParams.getBool("debug-xr")

        });

    })

    const onStart = async (cb) => {
      await onLeave()
      
      send({ type: 'ENTER', xr: supportXR.value })

      let device = supportXR.value ? 'xr' : Browser.mobile ? 'mobile' : 'desktop'
      

      Gtm.sendEventPush(
        'home',
        `discover-experience-${device}`,
        'Click on Discover the experience'
      )
    }

    const onEnter = () => {
      const $ = gsap.utils.selector(document.body)

      const $partners = $('.Home__Partnership')
      const $logo1 = $('.AppHeader .AppHeader__Logo')
      const $logo2 = $('.Home__Logo')
      const $localeChanger = $('.AppHeader .LocaleChanger')
      const $btnInfos = $('.AppHeader .BtnInformation')
      const $btnAudio = $('.UIAudioBtn')

      const artistLines = $('.ArtistName__Line')
      //Double split to get line with a line wrapper
      new SplitText($('.Home__ExhibitionName .Inner'), { type: 'lines', linesClass: 'Line' })
      new SplitText($('.Home__ExhibitionName .Inner'), { type: 'lines', linesClass: 'LineWrap' })
      const titleLines = $('.Home__ExhibitionName .Inner .Line')
      const homeCta = $('.Home__Cta')

      const tl = gsap.timeline({
        delay: 1
      })
      tl
        .from([$logo1, $logo2, $localeChanger, $btnInfos, $btnAudio], {
          duration: frames(1.033 * 60),
          autoAlpha: 0,
          ease: 'customOut',
          stagger: frames(0.1 * 60)
        }, 'start')
        .from($partners, {
          duration: frames(1.033 * 60),
          autoAlpha: 0,
          ease: 'customOut',
        }, 'start')
        .from([$logo1, $logo2, $localeChanger, $btnInfos], {
          duration: frames(1.033 * 60),
          y: 20,
          ease: 'customOut2',
          stagger: frames(0.1 * 60)
        }, 'start')

        .from(artistLines, {
          duration: frames(0.967 * 60),
          autoAlpha: 0,
          ease: 'customOut',
          stagger: frames(0.1 * 60)
        }, 'start')
        .from(artistLines, {
          duration: frames(1.633 * 60),
          yPercent: 100,
          ease: 'customOut2',
          stagger: frames(0.1 * 60)
        }, 'start')


        .from(titleLines, {
          duration: frames(1.4 * 60),
          yPercent: 100,
          ease: '',
          stagger: frames(0.1 * 60)
        }, `start+=${frames(0.1 * 60)}`)

        .from(homeCta, {
          duration: frames(1.233 * 60),
          autoAlpha: 0,
          ease: 'customOut',
        }, `start+=${frames(0.8 * 60)}`)
        .from(homeCta, {
          duration: frames(1.6 * 60),
          y: 100,
          ease: 'customOut2',
        }, `start+=${frames(0.433 * 60)}`)

    }

    const onLeave = () => {
      return new Promise((resolve) => {
        const $ = gsap.utils.selector(document.body)
        const $header = $('.AppHeader')
        const $logo2 = $('.Home__Logo')
        const $cta = $('.Home__Cta')

        const tl = gsap.timeline({
          onComplete: () => {
            resolve('done')
            setTimeout(() => {
              gsap.set([$header, $logo2, $('.ArtistName__Line'), $('.Home__ExhibitionName')], {
                clearProps: 'all'
              })
            }, 1000)
          }
        })

        tl
          .to([$header, $logo2, $cta], {
            duration: frames(0.3 * 60),
            autoAlpha: 0,
            ease: 'customOut',
          }, 'start')
          .to([$('.ArtistName__Line'), $('.Home__ExhibitionName')], {
            duration: 0.400,
            autoAlpha: 0,
            ease: 'customOut',
          }, 'start+=0.067')
      })
    }


    const onPreloadComplete = () => {
      onEnter()
    }

    const ctaLabel = computed(() => {
      return markdown(t('home.cta'))
    })

    return {
      locale,
      ...useActor(AppService),
      supportXR,
      onStart,
      onPreloadComplete,
      ctaLabel
    }
  }

})
