import Delay from "@/core/Delay";
import AppService from "@/store/states/AppService";
import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import StateHelper from "@/webgl/activities/StateHelper";
import LabelRect from "@/webgl/entities/UIKit/label-rect/LabelRect";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Camera from "nanogl-camera";

export default class XRPhotoModePush {

  public label: LabelRect;

  private active: boolean;
  private shown: boolean;
  stateHelper: StateHelper;
  _activeTimeout: number;

  get xrmodule(): XRModule {
    return this.activity.xrmodule;
  }

  constructor(
    public activity: PaintingXRActivity
  ) {

    const xrmodule = activity.xrmodule;
    this.label = new LabelRect(xrmodule.ui);
    this.label.setPadding(0.05, 0.05);
    this.label.setText("xr.try_photo");
    this.label.node.z = -1.0;
    this.label.node.y = 0.15;

    this.stateHelper = new StateHelper([
      { match: 'painting.explore', enter: this.start, exit: this.stop }
    ]);

  }

  start = () => {
    if (AppService.state.context.paintingPhotoTutorialCompleted)
      return;
    this._activeTimeout = window.setTimeout(this.show, 30000);
  }

  stop = () => {
    if (!AppService.state.context.paintingPhotoTutorialCompleted)
      this.hide();
    window.clearTimeout(this._activeTimeout);
    this.stateHelper.stop();
  }

  show = () => {
    this.activity.xrmodule.xrview.inputs.getInput("left").gamepad.pulse(0.25, 100);
    this.active = true;
    this.shown = true;
    this.xrmodule.xrcamera.head.add(this.label.node);
    this.label.show();
  }

  hide = () => {
    this.shown = false;
    this.label.hide().then(() => {
      this.xrmodule.xrcamera.head.remove(this.label.node);
      this.active = false;
    })
  }

  preRender() {
    if (!this.active)
      return;
  }

  render(camera: Camera) {
    if (!this.active)
      return;
    this.label.renderRect(camera);
    this.label.renderText([0.0270944, 0.0270944, 0.0270944, 1.0]);
  }

}