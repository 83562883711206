import Deferred from "@/core/Deferred";
import { vec4 } from "gl-matrix";
import Texture2D from "nanogl/texture-2d";
import { GLContext } from "nanogl/types";

interface IFontInfo {
  lineHeight: number;
  scale: number;
  pointSize: number;
  atlasPadding: number;
  textureWidth: number;
  textureHeight: number;
}

interface ICharacter {
  unicode: number;
  glyphIndex: number;
  scale: number;
  glyph: IGlyph;
}

interface IGlyph {
  index: number;
  scale: number;
  glyphRect: IGlyphRect;
  metrics: IMetrics;
}

interface IMetrics {
  width: number;
  height: number;
  horizontalBearingX: number;
  horizontalBearingY: number;
  horizontalAdvance: number;
}

interface IGlyphRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface ISettingsResult {
  characters: ICharacter[];
  faceInfo: IFontInfo;
}


export default class GLFontResource {

  image: HTMLImageElement;

  _texture: Texture2D;

  _faceInfo: IFontInfo;
  get faceInfo(): IFontInfo {
    return this._faceInfo;
  }

  _faceInfoUniform: vec4;
  get faceInfoVec(): vec4{
    return this._faceInfoUniform;
  }
  
  _chars: Map<number, ICharacter>;

  _defer: Deferred;
  response: Promise<any>;

  constructor(
    public readonly gl: GLContext,
    public readonly path: string,
    public readonly name: string
  ) {

    this._defer = new Deferred();
    this.response = this._defer.promise;

    this.image = new Image()
    this._chars = new Map<number, ICharacter>();
    this._texture = new Texture2D(gl, gl.RGBA);
    this._texture.bind();
    this._texture.setFilter(true, false, false);
    this._faceInfoUniform = vec4.create();

  }

  load(): Promise<any> {

    return Promise.all([
      this.loadImage(),
      this.loadSettings()
    ]).then(() => {
      this._defer.resolve();
    })

  }

  loadImage(): Promise<any> {
    return new Promise((resolve) => {
      (this.image as HTMLImageElement).onload = () => {
        this._texture.fromImage(this.image);
        resolve(null);
      };
      this.image.src = this.path + "/" + this.name + ".png";
    });
  }

  loadSettings(): Promise<void> {

    return new Promise((resolve) => {

      fetch(this.path + "/" + this.name + ".json")
        .then((res) => res.json())
        .then((value) => {
          this.unpack(value as ISettingsResult);
          resolve();
        })
    });

  }

  getFromUnicode(unicode: number): ICharacter {
    return this._chars.get(unicode);
  }

  unpack(data: ISettingsResult) {

    this._faceInfo = data.faceInfo;

    this._faceInfoUniform[0] = data.faceInfo.atlasPadding;
    this._faceInfoUniform[1] = data.faceInfo.textureWidth;
    this._faceInfoUniform[2] = data.faceInfo.textureHeight;

    for (const character of data.characters) {
      this._chars.set(
        character.unicode,
        character
      )
    }

  }

}