import { mat4, vec3 } from "gl-matrix";
import LabelRect from "@/webgl/entities/UIKit/label-rect/LabelRect";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import GLArrayBuffer from "nanogl/arraybuffer";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Program from "nanogl/program";
import Camera from "nanogl-camera";

const V3A = vec3.create();
const V3B = vec3.create();

const ANCHORS = {
  "Right/Trigger": 0,
  "Right/Squeeze": 1,
  "Right/Touchpad": 0,
  "Right/Thumbstick": 0,
  "A": -1,
  "B": 1,
  "Right/Up": 0,
  "Right/Down": 0,
  "Right/Left": 0,
  "Right/Right": 0,
  "Left/Trigger": 0,
  "Left/Squeeze": -1,
  "Left/Touchpad": 0,
  "Left/Thumbstick": 0,
  "X": -1,
  "Y": 1,
  "Left/Up": 0,
  "Left/Down": 0,
  "Left/Left": 0,
  "Left/Right": 0,
}

export default class ButtonTooltipEntity {

  key: string;

  wrapper: Node;
  nodeBtn: Node;
  nodeLabelTarget: Node;
  get nodeLabel(): Node {
    return this.wrapper;
  }

  
  labelRect: LabelRect;

  private _linebuffer: GLArrayBuffer;
  private _linedata: Float32Array;
  private _lineAttach: vec3;
  private _lineTarget: vec3;
  private _highlight: boolean;
  private _label: boolean;


  get highlight(): boolean{
    return this._highlight;
  }

  set highlight(val: boolean){
    this._highlight = val;
  }

  get label(): boolean{
    return this._label;
  }

  set label(val: boolean){
    if(val){
      this.labelRect.show();
      this._label = true;
    } else {
      this.labelRect.hide().then(()=>{
        this._label = false;
      })
    }
  }

  constructor(
    public readonly ui: UIKit
  ) {

    const gl = ui.scene.gl;
    this._linebuffer = new GLArrayBuffer(gl, null, gl.DYNAMIC_DRAW);
    this._linebuffer.attrib("aPosition", 3, gl.FLOAT);
    this._linedata = new Float32Array(6);

    this._lineAttach = vec3.fromValues(0.0, 0.0, 0.0);
    this._lineTarget = vec3.fromValues(0.0, -0.015 * 0.5, 0.0);

    this.labelRect = new LabelRect(ui);
    this.labelRect.setPadding(0.015, 0.002);

    this.wrapper = new Node();
    this.wrapper.add(this.labelRect.node);

  }


  updateLabel(dt: number, headPosition: vec3) {

    vec3.lerp(this.nodeLabel.position, this.nodeLabel.position, this.nodeLabelTarget._wposition as vec3, dt * 4.0);
    this.nodeLabel.lookAt(headPosition as vec3);
    this.nodeLabel.rotateY(Math.PI);
    this.nodeLabel.invalidate();

  }

  syncPosition() {
    if (this.nodeLabelTarget)
      this.nodeLabel.position.set(this.nodeLabelTarget._wposition as vec3);
  }

  attachText(root: Node, txt: string) {

    if (txt == null) {
      root.remove(this.nodeLabel);
      this.key = "";
      this.label = false;
      return;
    }

    root.add(this.nodeLabel);
    this.nodeBtn.add(this.nodeLabelTarget);
    this.nodeLabel.position.set(this.nodeLabelTarget._wposition);
    this.nodeLabel.invalidate();
    this.label = true;
    this.key = txt;
    this.labelRect.setText(txt);
    const a = -ANCHORS[this.nodeBtn.name];
    this.labelRect.node.x = a * this.labelRect.TextInfo.width * 0.5 + a * 0.015 * 0.65;

  }

  renderLine(prg: Program, mvp: mat4) {

    vec3.transformMat4(V3A, this._lineAttach, this.nodeBtn._wmatrix);
    vec3.transformMat4(V3B, this._lineTarget, this.nodeLabel._wmatrix);
    vec3.lerp(V3B, V3A, V3B, this.labelRect.scaleX);
    this._linedata.set(V3A, 0);
    this._linedata.set(V3B, 3);
    this._linebuffer.data(this._linedata);

    prg.use();
    prg.uColor([1.0, 1.0, 1.0, 1.0]);
    prg.uMVP(mvp);


    this._linebuffer.bind();
    this._linebuffer.attribPointer(prg);
    this._linebuffer.drawLines();
  }

  renderLabel(camera: Camera, txtColor: number[]){
    this.labelRect.renderRect(camera);
    this.labelRect.renderText(txtColor);
  }

}