import type Camera from "nanogl-camera";
import type { ArtworkData } from "../types";
import type ArtworkMesh from "./Mesh";
import Node from "nanogl-node";
import ArtworkState from "./State";
import LabelMesh from "./LabelMesh";
import ArtworkStrip from "./Strip";

export default class ArtworkProxy {
  public root = new Node();
  public queued = false;
  public posX = 0;
  private ref: ArtworkState;

  private states = {
    index: -1,
    items: [] as ArtworkState[],
  };

  get width() {
    return this.ref.width;
  }

  get height() {
    return this.ref.height;
  }

  get data() {
    return this.ref.data;
  }

  constructor(
    public readonly strip: ArtworkStrip
  ){
    
  }

  setData(data: ArtworkData) {
    const state = new ArtworkState(this.strip.textures);
    state.setData(data);
    this.ref = state;
  }

  createState(strip: ArtworkStrip) {
    this.states.index++;
    if (!this.states.items[this.states.index]) {
      const state = new ArtworkState(this.strip.textures);
      state.setData(this.ref.data);
      this.states.items[this.states.index] = state;
    }

    const state = this.states.items[this.states.index];
    state.fog = strip.fog;
    state.posX = this.posX;
    state.enable(this);
    return state;
  }

  currentState(){
    return this.states.items[this.states.index];
  }

  resetState() {
    this.states.index = -1;
    for (let i = 0; i <= this.states.index; i++) {
      const state = this.states.items[this.states.index];
      state.disable(this);
    }
  }

  render(
    camera: Camera,
    mesh: ArtworkMesh,
    author: LabelMesh,
  ) {
    for (let i = 0; i <= this.states.index; i++) {
      const state = this.states.items[i];
      state.render(camera, mesh, this.strip.time.current / 1000);
      state.author.render(camera, author, state);
    }
  }

  eachState(cb: (state: ArtworkState, index: number) => void) {
    for (let i = 0; i < this.states.items.length; i++) {
      const state = this.states.items[i];
      cb(state, i);
    }
  }
}
