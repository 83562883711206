import Paths from "@/core/Paths";
import GLTFResource from "@/webgl/assets/GLTFResource";
import IIblMaterialPass from "@/webgl/glsl/IblMaterialPass";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import PaintingScene from "@/webgl/scenes/PaintingScene";
import Enum from "nanogl-pbr/Enum";
import { GammaModeEnum, GammaModes } from "nanogl-pbr/GammaModeEnum";
import Input, { Uniform } from "nanogl-pbr/Input";
import IBL from "nanogl-pbr/lighting/Ibl";
import LightSetup from "nanogl-pbr/lighting/LightSetup";

const EXPO = 1.4
const GAMMA = 2.3

export default class PaintingLighting {

  ibl: IBL;
  lightSetup: LightSetup;


  gamma   : Input
  exposure: Input
  expoUniform : Uniform
  gammaUniform: Uniform
  gammaMode: GammaModeEnum

  iblGltf: Gltf;

  constructor(
    public readonly painting: PaintingScene
  ){

    this.lightSetup = new LightSetup()

    this.gammaMode  = new Enum( 'gammaMode', GammaModes);
    this.gamma      = new Input( 'gamma',       1, Input.ALL );
    this.exposure   = new Input( 'exposure',    1, Input.ALL );

    this.expoUniform = this.exposure .attachUniform( 'utmExpo' )
    this.gammaUniform = this.gamma    .attachUniform( 'uTMGamma')
    this.expoUniform.set( EXPO );
    this.gammaUniform.set( 1/GAMMA );

  }

  setupMat( m : IIblMaterialPass ){

    m.setLightSetup( this.lightSetup );

    m.iGamma   .proxy( this.gamma )
    m.iExposure.proxy( this.exposure )
    m.gammaMode.proxy( this.gammaMode )

  }

  async load(){

    this.iblGltf = await new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/painting_ibl/PaintingIBL.gltf"),
      this.painting.scene
    ).load();

    this.lightSetup.add(this.iblGltf.extras.lightsImageBased.list[0].ibl)

  }

  unload(){
    this.lightSetup.remove(this.iblGltf.extras.lightsImageBased.list[0].ibl);
    this.iblGltf = null;
  }
  

}