import { AppModule } from '@/store/modules/AppModule'
import { Viewport } from '@/store/modules/Viewport'
import { InformationPanel } from '@/store/modules/InformationPanel'
import { LegalPage } from '@/store/modules/LegalPage'
import { InjectionKey } from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree, useStore as baseUseStore, Store } from 'vuex'
import { createStore } from 'vuex'

const strict = process.env.VUE_APP_VUEX_STRICT === 'true'
const logger = process.env.VUE_APP_VUEX_LOGGER === 'true'

export interface IStoreState {
  preloaded: boolean,
  app?: AppModule,
  viewport?: Viewport,
  informationPanel?: InformationPanel
  legalPage?: LegalPage,
  showCookieBanner?: boolean
}

export interface IRootState { }

export const key: InjectionKey<Store<IStoreState>> = Symbol()

const state: IStoreState = {
  preloaded: false,
  showCookieBanner: true
}

const actions: ActionTree<IStoreState, IRootState> = {
  onLoad({ commit }, preloaded: boolean) {
    commit('_SET_PRELOADED', preloaded)
  },
  toggleCookieBanner({ commit }, preloaded: boolean) {
    commit('_SET_COOKIE_BANNER', preloaded)
  },
}

const mutations: MutationTree<IStoreState> = {
  _SET_PRELOADED(currentState: IStoreState, payload: boolean) {
    currentState.preloaded = payload
  },
  _SET_COOKIE_BANNER(currentState: IStoreState, payload: boolean) {
    currentState.showCookieBanner = payload
  },
}

const getters: GetterTree<IStoreState, IRootState> = {
  isPreloaded(currentState: IStoreState) {
    return currentState.preloaded
  },
}

const store = createStore<IStoreState>({
  state,
  actions,
  mutations,
  getters,
  strict,
  plugins: logger ? [Vuex.createLogger()] : []
});

export default store;

export function useStore() {
  return baseUseStore(key)
}