import { listArtwork, ListArtwork200Response } from "@/api/artwork";
import AppService from "@/store/states/AppService";
import { PhotoFormatDimensions } from "@/store/states/AppStateMachine";
import ArtworkStrip from "./Artwork/Strip";
import { GalleryActivitySamples } from "./samples";
import { ArtworkData } from "./types";
import { loadImageBitmap, pickColor, pickImage, seconds } from "./utils";

export default class GalleryData {
  public loading = {
    NextToken: undefined as string,
    Author: true,
    HasMore: true,
    PerPage: 20,
    Promise: Promise.resolve(),
  };

  public datas: ArtworkData[] = [];
  private queue: ArtworkData[] = [];
  public listeners = new Set<(d: ArtworkData) => void>();
  public sampleOffset = 0;
  public tick = {
    delay: 0, // in seconds
    running: false,
    enabled: false,
  };

  constructor() {
    this.next = this.next.bind(this);
  }

  enable(strip: ArtworkStrip) {
    this.tick.enabled = true;
    if (this.queue.length > 0) {
      strip.appendBehavior = "default";
      for (const data of this.queue) {
        this.dispatch(data);
      }
      this.queue = [];
      strip.appendBehavior = "reveal";
    }
  }

  disable() {
    this.tick.enabled = false;
  }

  private dispatch(data: ArtworkData) {
    this.datas.push(data);
    for (const listener of this.listeners) {
      listener(data);
    }
  }

  /**
   * Load extra artworks
   */
  async load(sample = false) {
    if (sample) {
      await this.loadFromSample();
    } else {
      await this.loadFromAPI();
    }
  }

  loadUserMedia() {

    const shareMedia = AppService?.state?.context?.sharedMedia;
    const pov = AppService?.state?.context?.povDatas;
    if (!shareMedia || !shareMedia.blob)
      return false;

    const data: ArtworkData = {
      id: shareMedia.artworkId,
      width: PhotoFormatDimensions[pov.format].width,
      height: PhotoFormatDimensions[pov.format].height,
      src: URL.createObjectURL(shareMedia.blob),
      pov,
    };

    this.datas.unshift(data);
    return true;

  }

  /**
   * Load artworks from samples
   */
  private async loadFromSample() {
    await this.loading.Promise;

    await seconds(Math.random() * 5);
    const Items = GalleryActivitySamples.slice(
      this.sampleOffset,
      this.sampleOffset +
      Math.min(this.loading.PerPage, GalleryActivitySamples.length)
    ).map((data, index) => {
      index = this.datas.length + index;
      return <ListArtwork200Response["Items"][number]>{
        ArtworkID: index.toString(),
        ContentType: "image/png",
        CreatedAt: Date.now(),
        Format: data.pov.format,
        ImageURL: pickImage({ ...data, color: pickColor(index), index }),
        ImageKey: "",
        Status: "published",
        UserType: data.author ? "Known" : "Unknown",
        Author: data.author,
        PostProcessing: {
          Brightness: data.pov.brightness,
          Contrast: data.pov.contrast,
          Saturation: data.pov.saturation,
          Temperature: data.pov.temperature,
          Zoom: data.pov.zoom,
        },
        PointOfView: {
          Position: data.pov.position,
          Rotation: data.pov.rotation,
        },
      };
    });
    this.loading.Promise = this.loadItems(Items);
  }

  /**
   * Load artworks from API call
   */
  private async loadFromAPI(): Promise<void> {
    if (!this.loading.HasMore) return;

    await this.loading.Promise;

    const result = await listArtwork({
      Author: this.loading.Author,
      Limit: this.loading.PerPage,
      NextToken: this.loading.NextToken,
    });

    const response = result.unwrap();
    this.loading.NextToken = response.NextToken;

    if (response.Count === 0 && this.loading.Author) {
      this.loading.Author = false;
      this.loading.NextToken = undefined;
      return this.loadFromAPI();
    } else if (!response.NextToken && !this.loading.Author) {
      this.loading.HasMore = false;
    }

    // Create a sub-promise
    // Do not wait all images loaded to resolve the parent promise
    this.loading.Promise = this.loadItems(response.Items);
  }

  private async loadItems(Items: ListArtwork200Response["Items"]) {
    const datas = await Promise.all(
      Items.map(async (Item) => {
        try {
          const url = /^\/\//.test(Item.ImageURL)
            ? window.location.protocol + Item.ImageURL
            : Item.ImageURL;
          // const image = await loadImageBitmap(url, false);
          return {
            author: Item.Author,
            id: Item.ArtworkID,
            // width: image.width,
            // height: image.height,
            // image,
            width: PhotoFormatDimensions[Item.Format].width,
            height: PhotoFormatDimensions[Item.Format].height,
            src: url,
            pov: {
              zoom: Item.PostProcessing.Zoom,
              brightness: Item.PostProcessing.Brightness,
              contrast: Item.PostProcessing.Contrast,
              saturation: Item.PostProcessing.Saturation,
              format: Item.Format,
              temperature: Item.PostProcessing.Temperature,
              position: Item.PointOfView.Position,
              rotation: Item.PointOfView.Rotation,
            },
          };
        } catch (e) {
          return null;
        }
      })
    );

    datas.filter(
      (data) => {
        return !!data && this.datas.findIndex((d) => d.id === data.id) === -1;
      }).forEach((data) => this.queue.push(data));
    if (!this.tick.running) this.next();
  }

  private async next() {
    if (!this.tick.enabled) return;
    this.tick.running = true;
    await seconds(this.tick.delay);

    if (this.queue.length > 0) {
      this.dispatch(this.queue.shift());
      this.next();
    } else {
      this.tick.running = false;
    }
  }
}
