

export const ArtworkList = [
  '050',
  '052',
  '063',
  '069',
  '080',
  '083',
  '109',
] as const


export type ArtworkId = typeof ArtworkList[number]

export const MainArtworkId : '083' = '083'

export function stringIsArtworkId( s:string ):s is ArtworkId {
  return ArtworkList.includes( s as ArtworkId )
}

/**
 * 
 * @param artworkId return true if artwork is the main painting
 * @returns 
 */
export function artworkIsMainPainting( artworkId:ArtworkId ):boolean{
  return artworkId === MainArtworkId
}