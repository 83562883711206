import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import DepthPass from "@/webgl/lib/nanogl-pbr/src/DepthPass";
import Enum from "@/webgl/lib/nanogl-pbr/src/Enum";
import { GammaModes } from "@/webgl/lib/nanogl-pbr/src/GammaModeEnum";
import Input, { Uniform } from "@/webgl/lib/nanogl-pbr/src/Input";
import DirectionalLight from "@/webgl/lib/nanogl-pbr/src/lighting/DirectionalLight";
import Light from "@/webgl/lib/nanogl-pbr/src/lighting/Light";
import LightSetup from "@/webgl/lib/nanogl-pbr/src/lighting/LightSetup";
import PunctualLight from "@/webgl/lib/nanogl-pbr/src/lighting/PunctualLight";
import SpotLight from "@/webgl/lib/nanogl-pbr/src/lighting/SpotLight";
import { StandardPass } from "@/webgl/lib/nanogl-pbr/src/StandardPass";
import Scene from "@/webgl/Scene";
import { vec3, quat } from "gl-matrix";


export default class LightsManager {

  setup: LightSetup;
  EXPO: number = 1.0;
  GAMMA: number = 2.0;
  expoUniform: Uniform;
  gammaUniform: Uniform;
  gammaMode: Enum<readonly ["GAMMA_NONE", "GAMMA_STD", "GAMMA_2_2", "GAMMA_TB"]>;
  gamma: Input;
  exposure: Input;
  dirlight: DirectionalLight;
  list: PunctualLight[] = [];

  constructor(public scene: Scene) {

    this.setup = new LightSetup();

    this.initialize();

  }

  add(light: Light) {
    this.list.push(light as PunctualLight);
    this.setup.add(light);
  }

  remove(l: Light) {
    this.setup.remove(l);
  }

  registerPass(pass: StandardPass) {
    pass.setLightSetup(this.setup);
    pass.iGamma?.proxy(this.gamma);
    pass.iExposure?.proxy(this.exposure);
    pass.gammaMode?.proxy(this.gammaMode);
  }

  initialize() {

    const setup = this.setup;

    setup.bounds.fromMinMax(
    [-5, -5, -5],
    [5, 5, 5]
    );

    setup.stdModel.shadowFilter.set('PCF4x4')
    setup.stdModel.iblShadowing.enable()
    setup.depthFormat.set("D_RGB");

    const depthPass = new DepthPass(this.scene.gl);
    depthPass.setLightSetup(setup);

    const gammaMode = this.gammaMode = new Enum('gammaMode', GammaModes);
    const gamma = this.gamma = new Input('gamma', 1, Input.ALL);
    const exposure = this.exposure = new Input('exposure', 1, Input.ALL);

    gammaMode.set('GAMMA_STD');
    const expoUniform = this.expoUniform = exposure.attachUniform('utmExpo');
    const gammaUniform = this.gammaUniform = gamma.attachUniform('uTMGamma');

    expoUniform.set(this.EXPO);
    gammaUniform.set(1 / this.GAMMA);

    const dirlight = new DirectionalLight();
    // const dirlight = this.dirlight = new PointLight();
    // dirlight.castShadows(true);
    dirlight._color[0] = 0.4 * 2;
    dirlight._color[1] = 0.5 * 2;
    dirlight._color[2] = 0.8 * 2;
    dirlight.y = 5;
    dirlight.z = 10;
    dirlight.x = 0;
    dirlight.lookAt(vec3.create());

    this.scene.root.add(dirlight);
    dirlight.invalidate();
    this.add(dirlight);

    // const plight = new SpotLight();
    // plight.castShadows(true);
    // plight._shadowmapSize = 1024;
    // plight._color[0] = 2.0 * 10.0;
    // plight._color[1] = 0.5 * 10.0;
    // plight._color[2] = 0.9 * 10.0;
    // plight.radius = 20;
    // // plight.x = -0.3;
    // plight.z = -2;
    // plight.y = 3.0;
    // plight.lookAt(vec3.create());
    // this.scene.root.add(plight);
    // this.add(plight);


    // const plight2 = new SpotLight();
    // plight2.castShadows(true);
    // plight2._shadowmapSize = 1024;
    // plight2._color[0] = 0.5 * 10.0;
    // plight2._color[1] = 0.5 * 10.0;
    // plight2._color[2] = 2.0 * 10.0;
    // plight2.radius = 20;
    // plight2.x = -0.5;
    // plight2.z = 2;
    // plight2.y = 3.0;
    // plight2.lookAt(vec3.create());
    // this.scene.root.add(plight2);
    // this.add(plight2);

    // const neon = this.neonLight = new PointLight();
    // this.scene.root.add(neon);
    // this.add(neon);

  }

}