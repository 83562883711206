import { reactive } from "@vue/runtime-core";


export const PaintingStore = reactive({
  frame: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
  // landscapeMode:true
})


// window.addEventListener( 'resize', () => {
//   MuseumStore.landscapeMode = window.innerWidth > window.innerHeight
// })