import Dispatcher from "./Dispatcher";
import BrowserPointer from "./BrowserPointer";
import XRPointer from "./XRPointer";
import { IPointer } from "./IPointer";

export default class Inputs extends Dispatcher {
  private _mode: "xr" | "browser";
  private pointer: IPointer;

  get mode() {
    return this._mode;
  }

  set mode(value) {
    if (value === this._mode) return;
    this._mode = value;

    if (this.pointer) {
      this.pointer.disable();
      this.pointer = null;
    }

    this.pointer = value === "browser" ? new BrowserPointer() : new XRPointer();
    this.pointer.setDispatcher(this);
    this.pointer.enable();
  }

  constructor() {
    super();
    this.mode = "browser";
  }
}
