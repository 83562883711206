import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import Gtm from '@/utils/Gtm'

export const PAGES = {
  LEGALS: 'legals',
  CREDITS: 'credits',
}

type PageType = keyof typeof PAGES

@Module
export class LegalPage extends VuexModule {
  public isOpen: boolean = false
  public page: string = PAGES.LEGALS

  @Action
  open(page?: PageType) {
    page && this.setPage(page)
    this._SET_OPEN(true)
    Gtm.sendVPV(page)
  }

  @Action
  close() {
    this._SET_OPEN(false)
  }

  @Action
  toggle() {
    this._SET_OPEN(!this.isOpen)
  }

  @Action
  setPage(payload: PageType) {
    this._SET_PAGE(payload);
  }

  @Mutation
  private _SET_OPEN(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @Mutation
  private _SET_PAGE(page: PageType) {
    this.page = page
  }
}

const legalPage = new LegalPage({ store, name: 'legalPage' })

export default legalPage