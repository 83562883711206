
import { defineComponent, ref, toRef, onMounted, onBeforeUnmount, nextTick } from 'vue'
import type { Ref } from 'vue'
import { gsap } from 'gsap'
import lerp from '@/utils/Lerp'


import useMousePosition from './useMousePosition'

export default defineComponent({
  props: {
    inertia: {
      type: Number,
      default: 0.15
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const root = ref<HTMLElement>(null)
    const label = ref<HTMLElement>(null)
    const inertia = toRef(props, 'inertia') as Ref<number>
    
    const { x: mouseX, y: mouseY } = useMousePosition()
    const x = ref(mouseX.value)
    const y = ref(mouseY.value)

    let setPosition = null
    let setPositionLabel = null
    function update() {
      x.value = lerp(x.value, mouseX.value, inertia.value)
      y.value = lerp(y.value, mouseY.value, inertia.value)

      setPosition({ x: x.value, y: y.value })
    }

    function onEnter(el, onComplete) {
       
      

      const $ = gsap.utils.selector(el)
      const tl = gsap.timeline({
        onComplete: () => {
          onComplete()

          // gsap.set([$('.Background'), $('.Border'), $('.Label')], {
          //   clearProps: 'all'
          // })
        }
      })

      tl
      .set($('.Label'), {
        autoAlpha: 0,
        y: 50,
      })
      .set([$('.Background'), $('.Border')], {
        scale: 0,
        transformOrigin: 'bottom',
        y: 20,
      })
      .to([$('.Background'), $('.Border')], {
        scale: 1,
        y:0,
        duration: 0.7,
        ease: 'quint.out'
      }, 'start')
      .to($('.Label'), {
        autoAlpha: 1,
        y: 0,
        duration: 0.4,
        ease: 'quint.out'
      }, 'start')

    }
    function onLeave(el, onComplete) {
      const $ = gsap.utils.selector(el)
      const tl = gsap.timeline({
        onComplete: () => {
          onComplete()

          // gsap.set([$('.Background'), $('.Border')], {
          //   clearProps: 'all'
          // })
        }
      })

      tl.to([$('.Background'), $('.Border')], {
        scale: 0,
        transformOrigin: 'top',
        duration: 0.5,
        ease: 'quint.out'
      }, 'start')
      .to([$('.Label')], {
        autoAlpha: 0,
        duration: 0.4,
        ease: 'quint.out'
      }, 'start')
    }

    onMounted(() => {
      nextTick(() => {
        setPosition = gsap.quickSetter(root.value, 'css')
        setPositionLabel = gsap.quickSetter(root.value.querySelector('.Label'), 'css')
        gsap.ticker.add(update)
      })
     
    })
    onBeforeUnmount(() => {
      gsap.ticker.remove(update)
    })

    return {
      onEnter,
      onLeave,
      root
    }
  }
})
