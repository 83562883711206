// import { useStore } from "@/store";

import XRModule from "@/webgl/entities/xr-module/XRModule";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import Program from "nanogl/program";

import GLArrayBuffer from "nanogl/arraybuffer";
import GLIndexBuffer from "nanogl/indexbuffer";

import arch_vertex from "./arch.vert";
import arch_fragment from "./arch.frag";
import { mat4, quat, vec3 } from "gl-matrix";
import { Bezier3 } from "@/webgl/lib/cinemachine/SplineHelpers";
import { clamp01 } from "@/webgl/math";
import { LocalConfig } from "nanogl-state";
import gsap from "gsap";
import decomposeMat4 from "@/webgl/math/mat4-decompose";

const M4 = mat4.create();

class Arch {

  prg: Program;

  _segcount: number = 35;

  _vbuffer: GLArrayBuffer;
  _ibuffer: GLIndexBuffer;

  _vdata: Float32Array;

  time: number = 0;
  reveal: number = 0;
  dst: number = 0;

  constructor(
    public scene: Scene
  ) {

    const gl = scene.gl;

    this.prg = new Program(
      scene.gl,
      arch_vertex(),
      arch_fragment(),
      scene.programs.getGlobalDefinitions()
    )

    this._vbuffer = new GLArrayBuffer(gl, null, gl.DYNAMIC_DRAW);
    this._ibuffer = new GLIndexBuffer(gl, gl.UNSIGNED_SHORT);

    this._vbuffer
      .attrib("aPosition", 3, gl.FLOAT)
      .attrib("aNextPosition", 3, gl.FLOAT)
      .attrib("aPreviousPosition", 3, gl.FLOAT)
      .attrib("aTexCoord", 2, gl.FLOAT);

    this._vdata = new Float32Array((this._segcount + 3) * 2 * 11);

    this.construct();

  }

  construct() {

    /*
  
    B1  ---  B2
      | / \ |
      2     3
      |     |
      0 --- 1
      
    */

    const S = 2.0;
    const V3A = vec3.create();
    const V3B = vec3.create();
    const V3C = vec3.create();

    const P0 = vec3.fromValues(-S * 0.5, -S * 0.5, 0.0);
    const P1 = vec3.fromValues(S * 0.5, -S * 0.5, 0.0);
    const P2 = vec3.fromValues(-S * 0.5, S * 0.25, 0.0);
    const P3 = vec3.fromValues(S * 0.5, S * 0.25, 0.0);

    const B1 = vec3.fromValues(-S * 0.5, S, 0.0);
    const B2 = vec3.fromValues(S * 0.5, S, 0.0);

    this.push(P0, P1, P2, 0);
    this.push(P1, P3, P0, 1);

    for (let i = 0; i < this._segcount; i++) {
      const p = (i / (this._segcount - 1));
      Bezier3(p, P3, B2, B1, P2, V3A);
      Bezier3(clamp01(p + (1 / this._segcount)), P3, B2, B1, P2, V3B);
      Bezier3(clamp01(p - (1 / this._segcount)), P3, B2, B1, P2, V3C);
      this.push(V3A, V3B, V3C, i + 2);
    }

    this.push(P0, P1, P2, this._segcount + 2);

    this._vbuffer.data(this._vdata);

  }

  push(pos: vec3, next: vec3, prev: vec3, idx: number) {

    const stride = idx * 2 * 11;
    const p = idx / ((this._segcount + 2));

    const dst = vec3.distance(pos, prev);
    this.dst += dst;

    this._vdata.set(pos, stride + 0);
    this._vdata.set(next, stride + 3);
    this._vdata.set(prev, stride + 6);
    this._vdata.set([0, this.dst], stride + 9);

    this._vdata.set(pos, stride + 11);
    this._vdata.set(next, stride + 14);
    this._vdata.set(prev, stride + 17);
    this._vdata.set([1, this.dst], stride + 20);


  }

  preRender(dt: number, reveal: number) {
    this.time += dt;
    this.reveal = reveal;
  }

  render(camera: Camera, modelView: mat4) {

    const prg = this.prg;

    prg.use();

    prg.uTimeRevealUVScale((this.time % 3.0) - 1.0, (this.reveal - 0.5) * 2.0, 1 / this.dst);
    prg.uModelViewMatrix(modelView);
    prg.uProjectionMatrix(camera.lens.getProjection());
    prg.uResolution([window.innerWidth * 0.5, window.innerHeight]);
    prg.uLineWidth(0.09);

    this._vbuffer.bind();
    this._vbuffer.attribPointer(prg);
    this._vbuffer.drawTriangleStrip();

  }


}

const V3A = vec3.create();
const V3B = vec3.create();
const QUAT = quat.create();

export default class XRLoading {

  tgtNode: Node;
  node: Node;
  txtNode: Node;
  arch: Arch;
  cfg: LocalConfig;
  reveal: number = 0;
  scale: number = 0.75;


  constructor(
    public readonly xrmodule: XRModule
  ) {

    this.node = new Node();
    this.txtNode = new Node();
    this.node.add(this.txtNode);
    this.txtNode.y = this.xrmodule.ui.textRenderer.texts.get("loading.claim").height;
    this.tgtNode = new Node();
    this.tgtNode.z = -4;
    this.tgtNode.y = 0.0;

    this.arch = new Arch(xrmodule.scene);

    this.cfg = xrmodule.scene.glstate.config();
    this.cfg
      .enableDepthTest(false)
      .enableCullface(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(xrmodule.scene.gl.SRC_ALPHA, xrmodule.scene.gl.ONE_MINUS_SRC_ALPHA);

  }

  start() {
    this.node.setMatrix(this.tgtNode._matrix);
    this.node.updateMatrix();
    this.xrmodule.scene.root.add(this.node);
    this.xrmodule.xrcamera.head.add(this.tgtNode);
  }

  stop(){
    this.xrmodule.scene.root.remove(this.node);
    this.xrmodule.xrcamera.head.remove(this.tgtNode);
  }

  show(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { scale: 1.0, reveal: 1, duration: 1.5, onUpdate: this._applyScale, ease: "power2.out", onComplete: resolve })
    });
  }

  hide(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { scale: 1.25, reveal: 0, duration: 1.5, onUpdate: this._applyScale, ease: "power2.out", onComplete: resolve })
    });
  }

  _applyScale = () => {
    this.node.setScale(this.scale);
  }

  reachTarget(flerp: number) {

    M4.set(this.tgtNode._wmatrix);
    decomposeMat4(M4, V3A, QUAT, V3B);
    

    vec3.lerp(V3A, this.node.position, V3A, flerp);
    QUAT[2] = 0;
    quat.normalize(QUAT, QUAT);
    quat.slerp(QUAT, this.node.rotation, QUAT, flerp);
    
    this.node.rotation.set(QUAT);
    this.node.position.set(V3A);
    this.node.invalidate();
    
  }

  preRender() {
    // this.tgtNode.lookAt(this.xrmodule.xrcamera.head.position);
    this.arch.preRender(this.xrmodule.scene.dt, this.reveal);
    this.reachTarget(this.xrmodule.scene.dt * 4.0)
  }

  render(camera: Camera) {

    mat4.multiply(M4, camera._view, this.node._wmatrix);
    this.xrmodule.scene.glstate.push(this.cfg);
    this.xrmodule.scene.glstate.apply();
    this.arch.render(camera, M4);
    this.xrmodule.scene.glstate.pop();

    this.xrmodule.ui.textRenderer.render(
      "loading.claim",
      this.txtNode._wmatrix,
      [1.0, 1.0, 1.0, this.reveal]
    )


  }

}