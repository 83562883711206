import MaterialPass from "nanogl-pbr/MaterialPass";
import Program from "nanogl/program";
import NanoglNode from "nanogl-node";
import Camera from "nanogl-camera";

import { mat4, mat3 } from "gl-matrix";
import ShaderVersion from "nanogl-pbr/ShaderVersion";
import ShaderPrecision from "nanogl-pbr/ShaderPrecision";
import Flag from "nanogl-pbr/Flag";


import vShader from "./matcap.vert";
import fShader from "./matcap.frag";
import Texture2D from "nanogl/texture-2d";

const MAT_ID = "matcap";

const modelViewMatrix = mat4.create();
const cameraWorlMatrixdInverse = mat4.create();
const normalMatrix = mat3.create();

export class MatCapPass extends MaterialPass {

  version     : ShaderVersion  
  precision   : ShaderPrecision
  shaderid    : Flag       

  matcapTexture: Texture2D = null;


  constructor() {

    super({
      uid: MAT_ID,
      vert: vShader(),
      frag: fShader()
    })


    const inputs = this.inputs;
    inputs.add(this.version = new ShaderVersion('100'));
    inputs.add(this.precision = new ShaderPrecision('highp'));
    inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));

  }



  prepare(prg: Program, node: NanoglNode, camera: Camera): void {

    if (prg.uWorldMatrix) {
      prg.uWorldMatrix(node._wmatrix);
    }

    if (prg.uVP) {
      prg.uVP(camera._viewProj);
    }

    if (prg.uViewMatrix) {
      prg.uViewMatrix(camera._view);
    }

    if (prg.uNormalMatrix) {
      mat4.invert(cameraWorlMatrixdInverse, camera._wmatrix);
      mat4.multiply(modelViewMatrix, cameraWorlMatrixdInverse, node._wmatrix);
      mat3.normalFromMat4(normalMatrix, modelViewMatrix);
      prg.uNormalMatrix(normalMatrix);
    }

    prg.uMatCap?.(this.matcapTexture)


  }

}