import { ITextRenderInfo } from "@/webgl/entities/UIKit/text/TextRenderer";
import UIKit from "@/webgl/entities/UIKit/UIKit";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import { vec2, vec4 } from "gl-matrix";
import gsap from "gsap";
import Camera from "nanogl-camera";


export default class LabelRect {

  public node: Node;

  private _txtKey: string;
  private _txtRenderInfo: ITextRenderInfo;
  private _padding: vec2;

  private _txtAlpha: number = 0;
  private _scaleX: number = 0;
  private _scaleY: number = 0;
  private _color: vec4 = vec4.create();

  get TextInfo(): ITextRenderInfo {
    return this._txtRenderInfo;
  }

  get scaleX(): number {
    return this._scaleX;
  }

  get scaleY(): number {
    return this._scaleY;
  }

  constructor(
    public ui: UIKit,
  ) {
    this.node = new Node();
    this._padding = vec2.create();
  }

  setPadding(x: number, y: number) {
    this._padding[0] = x;
    this._padding[1] = y;
  }

  setText(key: string) {
    this._txtKey = key;
    this._txtRenderInfo = this.ui.textRenderer.texts.get(key);
  }

  show(delay: number = 0): Promise<any> {
    gsap.killTweensOf(this);
    return new Promise((resolve)=>{
      gsap.to(this, { _scaleX: 1, duration: 0.6, delay: delay, ease: "power2.out", onComplete: resolve });
      gsap.to(this, { _scaleY: 1, duration: 0.2, delay: delay, ease: "power2.out" });
      gsap.to(this, { _txtAlpha: 1, duration: 0.4, delay: delay + 0.2, ease: "power2.out"});
    })
  }

  hide(delay: number = 0): Promise<any> {
    gsap.killTweensOf(this);
    return new Promise((resolve)=>{
    gsap.to(this, { _scaleX: 0, duration: 0.6, delay: delay, ease: "power2.out", onComplete: resolve });
    gsap.to(this, { _scaleY: 0, duration: 0.2, delay: delay + 0.4, ease: "power2.out" });
    gsap.to(this, { _txtAlpha: 0, duration: 0.4, delay: delay, ease: "power2.out" });
    })
  }

  renderRect(camera: Camera) {

    this.ui.gradientPrimitives.renderRect(
      camera,
      this.node,
      (this._txtRenderInfo.width + this._padding[0]) * this._scaleX,
      (this._txtRenderInfo.height + this._padding[1]) * this._scaleY,
      this._txtRenderInfo.height * 0.8 * 0.333,
      [0.9647058, 0.952941176, 0.89411764]
    )
  }

  renderText(color: number[]) {

    this._color.set(color);
    this._color[3] = this._txtAlpha;

    this.ui.textRenderer.render(
      this._txtKey,
      this.node._wmatrix,
      this._color
    )
  }

}