import Delay from "@/core/Delay";
import AppService from "@/store/states/AppService";
import { AppState } from "@/store/states/AppStateMachine";
import UrlParams from "@/utils/UrlParams";
import XRLoading from "@/webgl/entities/xr-loading/XRLoading";
import XRLoadingBackground from "@/webgl/entities/xr-loading/XRLoadingBackground";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import Camera from "nanogl-camera";
import Scene from "../Scene";
import { IActivity } from "./Activity";
import StateHelper from "./StateHelper";

const post_backbuffer = UrlParams.getBool("debug-xr");


export default class LoadingXRActivity implements IActivity {

  readonly priority: number = -1;

  background: XRLoadingBackground;
  loading: XRLoading;
  stateHelper: StateHelper;
  clear: boolean = true;

  get xrmodule(): XRModule {
    return this.scene.xrmodule;
  }

  constructor(public scene: Scene) {

    this.stateHelper = new StateHelper([
      { match: 'painting_loading.intro', enter: this.enterIntro },
      { match: 'painting_loading.default', enter: this.enterDefault },
      { match: 'painting_loading.outro', enter: this.enterOutro },
      { match: 'museum_loading.intro', enter: this.enterIntro },
      { match: 'museum_loading.default', enter: this.enterDefault },
      { match: 'museum_loading.outro', enter: this.enterOutro },
      { match: 'gallery_loading.intro', enter: this.enterIntro },
      { match: 'gallery_loading.fromshare', enter: this.enterIntro },
      { match: 'gallery_loading.default', enter: this.enterDefault },
      { match: 'gallery_loading.outro', enter: this.enterOutro },
    ])

  }

  onStateUpdate(state: AppState): void {
  }

  async load(): Promise<void> {
    console.log(
      "LoadingXRActivity.load()"
    );

    this.scene.loadXRModule().then(() => {
      this.loading = new XRLoading(this.xrmodule);
      this.loading.start();
      this.loading.show();
    });

    this.background = new XRLoadingBackground(this.scene);

    return Promise.resolve();

  }

  unload(): void {

  }


  start() {
    if (this.loading){
      this.loading.start();
      this.loading.show()
    }
    this.stateHelper.start()
  }

  stop(): void {
    this.stateHelper.stop()
  }


  enterIntro = () => {
    this.clear = true;
    this.background.fadeIn().then(() => {
      AppService.send('LOADING_INTRO_COMPLETE');
    });
  }


  enterDefault = () => {

  }


  enterOutro = () => {
    this.clear = false;
    Promise.all([
      this.loading.hide(),
      this.background.fadeOut()
    ]).then(() => {
      this.loading.stop();
      AppService.send('LOADING_OUTRO_COMPLETE');
    });

  }


  preFrame(): void {

  }

  render(): void {
    const gl = this.scene.gl;

    gl.bindFramebuffer(gl.FRAMEBUFFER, post_backbuffer ? null : this.scene.xrview.gllayer.framebuffer);


    gl.clearColor(0, 0, 0, 1);
    if (this.clear)
      gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);

    // RENDER
    // ========
    this.scene.glstate.apply();

    if (this.xrmodule.Ready) {

      for (const camera of this.xrmodule.xrcamera.cameras) {

        const vp = camera.lens.viewport
        gl.viewport(vp.x, vp.y, vp.width, vp.height);

        this.background.render(camera);

        if (this.loading)
          this.loading.render(camera);

        this.renderUI(camera);

      }

    }

    this.scene.glstate.apply();

  }

  renderUI(camera: Camera): void {
    if (this.xrmodule.Ready)
      this.xrmodule.render(camera);
  }

  preRender(): void {

    if (this.xrmodule.Ready)
      this.xrmodule.preRender();

    if (this.loading)
      this.loading.preRender();

    this.background.preRender();

  }

  rttPass(): void {

  }

}