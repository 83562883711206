
import BaseEffect, { EffectDependency } from 'nanogl-post/effects/base-effect'


import code from './chromashift.glsl'
import preCode from './chromashift_pre.glsl'
import Texture2D from 'nanogl/texture-2d';

const TDATA = new Uint8Array(3 * 3);
TDATA[0] = 0xFF;
TDATA[4] = 0xFF;
TDATA[8] = 0xFF;


export default class Chromashift extends BaseEffect {
  _code: string;
  _preCode: string;
  _rgbTex: any;
  amount: number;
  adjust: number = .42;


  constructor() {
    super();

    this._flags |= EffectDependency.LINEAR;

    this._code = code();
    this._preCode = preCode();

    this._rgbTex = null;

    this.amount = 0.015;

  }


  init() {
    var gl = this.post.gl;
    this._rgbTex = new Texture2D(gl, gl.RGB);
    this._rgbTex.fromData(3, 1, TDATA);
    this._rgbTex.clamp()
    this._rgbTex.setFilter(true, false, false)
  }


  release() {
    this._rgbTex.dispose()
    this._rgbTex = null;
  }

  preRender(){
    
  }

  genCode(precode, code) {
    precode.push(this._preCode)
    code.push(this._code)
  }


  setupProgram(prg) {

    if (prg.tChromaShiftTex)
      prg.tChromaShiftTex(this._rgbTex);

    prg.uAmount(this.amount);
    // prg.uChromaAdjust(this.adjust);

  }

  resize(){}
  
}