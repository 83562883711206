import { LocationStub } from "@intlify/message-compiler";
import Camera from "nanogl-camera";
import { ICameraLens } from "nanogl-camera/ICameraLens";
import { XRViewport } from "webxr";
import Scene from "../Scene";
import WebXRLens from "../xr/WebXRLens";

function lensIsXR( lens: ICameraLens ): lens is WebXRLens {
  return (lens as any).viewport !== undefined
}

export default function getViewport( camera: Camera, scene:Scene ): XRViewport {
  const lens: ICameraLens | WebXRLens = camera.lens
  if( lensIsXR(lens) ){
    return lens.viewport
  }
  return scene.viewport
  
}