import { CreatePane } from "@/dev/Tweak";
import Camera from "nanogl-camera";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Input from "nanogl-pbr/Input";
import UnlitPass from "nanogl-pbr/UnlitPass";

export default class WindTree extends Chunk {

  time: number = 0;

  constructor() {

    super(true, true);

  }

  install(pass: UnlitPass) {
    const nrm = pass.inputs.add(new Input("localNormal", 3));
    nrm.attachAttribute("aNormal", 3);
    pass.inputs.add(this);
  }

  prepare(camera: Camera) {
    // this.cameraPosition.set(camera._wposition);
  }

  update(dt){
    this.time += dt * 1.0;
  }

  setup(prg) {

    prg.uTime(this.time);

  }

  protected _genCode(slots: ChunksSlots): void {

    slots.add('pv',
    /*glsl*/`
    uniform float uTime;
    `);

    slots.add('vertex_warp',
      /*glsl*/`
      float height = aColor0.r;
      vec3 tangent = cross(vec3(0.0, 1.0, 0.0), aNormal);
      vertex.position += sin(uTime + vertex.position.z * 0.1) * tangent * height * 0.4;
      `);

    slots.add('postf',
      /*glsl*/`
      FragColor.rgb = baseColor();
      `);
  }

}