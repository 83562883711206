import Paths from "@/core/Paths";
import GLTFResource from "@/webgl/assets/GLTFResource";
import PaintingCameraController, { CTRL_MODE } from "@/webgl/activities/PaintingActivity/PaintingCameraController";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/Scene";
import UnlitPass from "nanogl-pbr/UnlitPass";
import { LocalConfig } from "nanogl-state";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import Camera from "nanogl-camera";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import { quat } from "gl-matrix";
import gsap from "gsap";
import { Uniform } from "nanogl-pbr/Input";
import { clamp01 } from "@/webgl/math";

export default class PaintingCameraTargetRenderer {

  cfg: LocalConfig;
  targetNode: GltfNode;
  aimTarget: MeshRenderer;
  res: GLTFResource;

  private _color: Uniform;
  private _glowColor: Uniform;
  private _scale: number = 1;
  private _ctrl: PaintingCameraController
  private _loadPromise: Promise<void>;
  private _active: boolean = false;
  private _valid: number = 1;
  get Active(): boolean {
    return this._active;
  }

  constructor(
    public readonly scene: Scene,
  ) {

    this.cfg = this.scene.glstate.config();
    this.cfg
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

    this.res = new GLTFResource(
      Paths.resolve("assets/webgl/gltfs/aim_target/aim_target.gltf"),
      this.scene
    );

    this.targetNode = new GltfNode();

  }

  attachController(ctrl: PaintingCameraController) {
    this._ctrl = ctrl;
    this._ctrl.onMove.on(this.onCtrlMove);
  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  private async _doLoad() {
    await this.res.load().then(this.init);
  }

  init = (gltf: Gltf) => {

    this.aimTarget = gltf.renderables[0] as MeshRenderer;
    this.aimTarget.node = this.targetNode as GltfNode;

    const glow = gltf.getElementByName(GltfTypes.MATERIAL, "Glow") as Material;
    const uwhite = gltf.getElementByName(GltfTypes.MATERIAL, "UnlitWhite") as Material;


    this._color = new Uniform("baseColor", 3);
    (uwhite.materialPass as UnlitPass).baseColorFactor.attach(this._color);
    this._glowColor = new Uniform("glowColor", 3);
    (glow.materialPass as UnlitPass).baseColorFactor.attach(this._glowColor);

    this._color.set(0.65, 0.65, 0.65);
    this._glowColor.set(0.12, 0.12, 0.12);

    glow.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

    uwhite.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

  }

  enable() {
    gsap.killTweensOf(this);
    gsap.to(this, { _scale: 1, duration: 0.4, ease: "power2.out" });
    this.scene.root.add(this.targetNode);
    this._active = true;
  }

  disable() {
    gsap.killTweensOf(this);
    gsap.to(this, {
      _scale: 0, duration: 0.4, ease: "power2.out", onComplete: () => {
        this.scene.root.remove(this.targetNode);
        this._active = false;
      }
    });
  }

  onCtrlMove = () => {
    this._scale = 1;
  }

  preRender() {

    if ((!this._ctrl.hasTarget && this.Active) || !this._ctrl.CanMove || (this._ctrl.CtrlMode === CTRL_MODE.MOUSE && this._ctrl.Moving)) {
      this.disable();
    }

    if (this._ctrl.hasTarget && !this.Active && this._ctrl.CanMove && !this._ctrl.Moving) {
      this.enable();
    }

    this._valid += this.scene.dt * 5.0 * (this._ctrl.hasGuardTarget ? -1.0 : 1.0);
    this._valid = clamp01(this._valid);

    this._color.set(0.65 + (1.0 - this._valid) * 0.3, 0.65 * this._valid, 0.65 * this._valid);
    this._glowColor.set(0.12 + (1.0 - this._valid) * 0.3, 0.12 * this._valid, 0.12 * this._valid);

    this.targetNode.setScale(this._scale);
    this.targetNode.position.set(this._ctrl.target);
    this.targetNode.rotation.set(this._ctrl.camera.rotation);
    this.targetNode.rotation[0] = 0;
    this.targetNode.rotation[2] = 0;
    quat.normalize(this.targetNode.rotation, this.targetNode.rotation);
    this.targetNode.invalidate();

  }

  render(camera: Camera) {

    if (!this.Active)
      return;

    this.aimTarget.render(
      this.scene,
      camera,
      Masks.OPAQUE,
      Passes.DEFAULT
    )


  }


}