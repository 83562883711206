import Navigation from "@/webgl/entities/xr-module/navigation/Navigation";
import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import GLArrayBuffer from "nanogl/arraybuffer";
import GLIndexBuffer from "nanogl/indexbuffer";
import { LocalConfig } from "nanogl-state";
import { GLContext } from "nanogl/types";
import { vec3 } from "gl-matrix";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import Masks from "@/webgl/gl/Masks";
import { Passes } from "@/webgl/glsl/Passes";
import { Bezier3, BezierTangent3 } from "@/webgl/lib/cinemachine/SplineHelpers";
import { clamp01 } from "@/webgl/math";
import UnlitPass from "nanogl-pbr/UnlitPass";

// SAMPLE POINT
const V3A = vec3.create();
const V3B = vec3.create();
const V3C = vec3.create();

const P0 = vec3.create();
const P1 = vec3.create();
const P2 = vec3.create();
const P3 = vec3.create();

class RayLine {

  _segcount: number = 30;

  _vbuffer: GLArrayBuffer;
  _ibuffer: GLIndexBuffer;

  _vdata: Float32Array;

  start: vec3;
  end: vec3;
  texture: Texture2D;

  constructor(gl: GLContext) {

    this.start = vec3.create();
    this.end = vec3.create();

    this._vbuffer = new GLArrayBuffer(gl, null, gl.DYNAMIC_DRAW);
    this._ibuffer = new GLIndexBuffer(gl, gl.UNSIGNED_SHORT);

    this._vbuffer
      .attrib("aPosition", 3, gl.FLOAT)
      .attrib("aNextPosition", 3, gl.FLOAT)
      .attrib("aPreviousPosition", 3, gl.FLOAT)
      .attrib("aTexCoord", 2, gl.FLOAT);

    this._vdata = new Float32Array(this._segcount * 2 * 11);

  }

  updateBezier(p0: vec3, p1: vec3, p2: vec3, p3: vec3, warning: boolean) {

    for (let i = this._segcount - 1; i >= 0; i--) {
      const stride = i * 2 * 11;

      const p = (i / (this._segcount));

      Bezier3(p, p0, p1, p2, p3, V3A);
      Bezier3(clamp01(p + (1 / this._segcount)), p0, p1, p2, p3, V3B);
      Bezier3(clamp01(p - (1 / this._segcount)), p0, p1, p2, p3, V3C);

      this._vdata.set(V3A, stride + 0);
      this._vdata.set(V3B, stride + 3);
      this._vdata.set(V3C, stride + 6);
      this._vdata.set([0, p * 0.5 + (warning ? 0.5 : 0.0)], stride + 9);

      this._vdata.set(V3A, stride + 11);
      this._vdata.set(V3B, stride + 14);
      this._vdata.set(V3C, stride + 17);
      this._vdata.set([1, p * 0.5 + (warning ? 0.5 : 0.0)], stride + 20);

    }

    this._vbuffer.data(this._vdata);

  }

  render(camera: Camera, prg: Program) {

    prg.use();
    prg.tTexture(this.texture);

    prg.uModelViewMatrix(camera._view);
    prg.uProjectionMatrix(camera.lens.getProjection());

    prg.uResolution([window.innerWidth * 0.5, window.innerHeight]);
    prg.uLineWidth(0.015);

    this._vbuffer.bind();
    this._vbuffer.attribPointer(prg);
    this._vbuffer.drawTriangleStrip();

  }

}


export default class NavigationRenderer {

  targetNode: Node;
  scene: Scene;
  cfg: LocalConfig;
  rayLine: RayLine;
  aimTarget: MeshRenderer;

  constructor(
    public readonly navigation: Navigation
  ) {

    this.scene = navigation.scene;

    this.targetNode = new Node();

    // this.targetNode.rotateX(-Math.PI / 2);
    // this.scene.root.add(this.targetNode);

    this.cfg = this.scene.glstate.config();
    this.cfg
      .enableDepthTest(true)
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

    this.rayLine = new RayLine(this.scene.gl);

  }

  init() {

    this.navigation.resources.get("ray_line").bind();
    this.navigation.resources.get("ray_line").clamp();
    this.navigation.resources.get("ray_line").setFilter(true, false, false);
    this.rayLine.texture = this.navigation.resources.get("ray_line");

    const gltf = this.navigation.resources.get("aim_target") as Gltf;

    this.aimTarget = gltf.renderables[0] as MeshRenderer;
    this.aimTarget.node = this.targetNode as GltfNode;
    const glow = gltf.getElementByName(GltfTypes.MATERIAL, "Glow") as Material;
    const uwhite = gltf.getElementByName(GltfTypes.MATERIAL, "UnlitWhite") as Material;
    (glow.materialPass as UnlitPass).baseColorFactor.attachConstant(0.65, "r");
    (uwhite.materialPass as UnlitPass).baseColorFactor.attachConstant(0.65, "r");

    glow.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

    uwhite.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(this.scene.gl.ONE, this.scene.gl.ONE);

  }

  preRender() {

    this.targetNode.position.set(this.navigation.target);
    this.targetNode.invalidate();

    const dist = (vec3.sqrDist(this.navigation.ray.pos, this.navigation.target) / 20);
    const curve = 0.3 + dist * 1.5;

    P0.set(this.navigation.ray.pos);
    vec3.scaleAndAdd(P1, this.navigation.ray.pos, this.navigation.forward, curve);
    vec3.scaleAndAdd(P2, this.navigation.target, this.navigation.normal, curve);
    P3.set(this.navigation.target);

    this.rayLine.updateBezier(P0, P1, P2, P3, !this.navigation.hasTarget);

  }

  render(camera: Camera) {

    this.scene.glstate.push(this.cfg);
    this.scene.glstate.apply();
    this.rayLine.render(camera, this.scene.programs.get("unlit-texture-line"));
    this.scene.glstate.pop();

    if (this.navigation.hasTarget) {
      this.aimTarget.render(
        this.scene,
        camera,
        Masks.OPAQUE,
        Passes.DEFAULT
      )
    }


  }

}