
import {
  defineComponent,
  PropType,
  toRef,
  computed,
  watchEffect,
  ToRef,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { useI18n } from "vue-i18n";

import type { PovDatas } from "@/store/states/AppStateMachine";
import Minimap2D from "@/webgl/entities/minimap";
import gsap from "gsap";
import { log } from "xstate/lib/actionTypes";
import { anime } from "@/utils/anime";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<PovDatas>,
    },
    animate: {
      type: Boolean,
      default: () => true,
    },
    showCoords: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const map = ref<HTMLDivElement>(null);
    const label = ref<HTMLDivElement>(null);
    const coords = ref<string>("");
    const data = toRef(props, "data") as ToRef<PovDatas>;
    const loaded = ref(false);

    const minimap2D = new Minimap2D();
    minimap2D.defaultSize.width = 221;
    minimap2D.defaultSize.height = 221;
    minimap2D.load().then(() => (loaded.value = true));

    const round = (v: number): number => Math.round(v * 1000000) / 1000000;

    const onAnimateLabel = async (e: Partial<CustomEvent>) => {
      switch (e.type) {
        case "minimap:show": {
          if (data.value?.position && !coords.value) {
            await onAnimateLabel(new CustomEvent("minimap:hide"));
          }

          if (props.showCoords) {
            await anime(label.value, {
              opacity: 1,
              duration: 0.25,
            });
          }
          break;
        }
        case "minimap:hide": {
          if (props.showCoords) {
            await anime(label.value, {
              opacity: 0,
              duration: 0.25,
            });
          }

          if (data.value?.position) {
            const [x, , y] = data.value!.position;
            const text = t("gallery.map_position", {
              x: round(x),
              y: round(y),
            });
            coords.value = text;
          } else {
            coords.value = "";
            onAnimateLabel(new CustomEvent("minimap:show"));
          }
          break;
        }
      }
    };

    const onResize = () => {
      minimap2D.resize();
      minimap2D.draw();
    };

    onMounted(() => {
      minimap2D.on("show", onAnimateLabel);
      minimap2D.on("hide", onAnimateLabel);
      window.addEventListener("resize", onResize);

      watch([loaded, map], () => {
        minimap2D.setParent(map.value);
      });

      watch([data, loaded], () => {
        if (loaded.value) {
          minimap2D.updateCursor(data.value, !props.animate);
        }
      });
    });

    onUnmounted(() => {
      minimap2D.off("show", onAnimateLabel);
      minimap2D.off("hide", onAnimateLabel);
      window.removeEventListener("resize", onResize);
    });

    return {
      map,
      label,
      coords,
    };
  },
});
