
// Probe 
var coeffs = 
[
    /* Band 0 */  2.577252289542124597,  2.592941691136326998,  3.209696772221454442 ,
    /* Band 1 */ -1.298198577017377842, -1.456323232383507360, -1.803872191982234474 ,  -0.558025407096241599, -0.562539388043099109, -0.620065068655001883, -0.020979756702741662,  0.027121035122363635,  0.076245651139082563,
    /* Band 2 */  -0.073927665618140959, -0.064684125534575865, -0.063543764636940919 ,   0.290679498392704794,  0.334517010178176977,  0.446645805624256709 ,  -0.246329448091938991, -0.299088071470092587, -0.372210169090019360 ,   0.166881345208445581,  0.167573707078008532,  0.184680466566433193 ,  -0.938665622439738612, -1.005438026301099574, -1.230708624710034949 ,
    /* Band 3 */   0.110235023838076304,  0.131769403596281337,  0.162287302812087864 ,  -0.140394767645646401, -0.142817501713415312, -0.171051665281479442 ,   0.725459380436766610,  0.761076978964077666,  0.925258586303028374 ,  -0.016222238884976748, -0.046892320301340565, -0.072543406771361049 ,  -0.007126431534713957,  0.002214301653922478, -0.006747904656689017 ,   0.335398967608921017,  0.377818276094044003,  0.469420489759835469 ,  -0.205045219167103721, -0.201511426609825073, -0.239163665222192123 ,
    /* Band 4 */   0.062866223669157950,  0.069805199907164744,  0.070820881242351794 ,  -0.217780713710489932, -0.241983484210327754, -0.305657849674034809 ,  -0.116221633720286543, -0.120461825742945686, -0.151348219902965653 ,  -0.127234172555655028, -0.151917846855769839, -0.197344143696175506 ,   0.542818053830684266,  0.588372433344621859,  0.725753981906001822 ,  -0.167046964195037256, -0.155682165641579356, -0.182783360732884537 ,  -0.001337314914609420,  0.005078934142465487,  0.018918174653928296 ,  -0.052308382514626002, -0.066710859852866192, -0.091352134739565896 ,  -0.235350542098936771, -0.270988450409299808, -0.340498836432894803 
];

module.exports = coeffs;