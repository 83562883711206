import Camera from "nanogl-camera";
import { LocalConfig } from "nanogl-state";
import Program from "nanogl/program";
import gsap from "gsap";
import { vec4 } from "gl-matrix";
import Scene from "@/webgl/Scene";

export default class Fader {

  prg: Program;
  cfg: LocalConfig;
  alpha: number = 0;
  color: vec4 = vec4.fromValues(
    0,
    0,
    0,
    0
  );

  constructor(
    public readonly scene: Scene
  ) {

    this.prg = scene.programs.get("fader");
    this.cfg = scene.glstate.config();

    const gl = scene.gl;

    this.cfg
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  }

  reset(){
    this.alpha = 0;
  }

  set(value: number){
    this.alpha = value;
  }

  fadeIn(tgt: number = 1): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: tgt, duration: 1.2, onComplete: resolve })
    });
  }

  fadeOut(): Promise<any> {
    return new Promise((resolve) => {
      gsap.to(this, { alpha: 0, duration: 1.2, onComplete: resolve })
    });
  }

  preRender() {

  }

  render(camera: Camera) {

    if (this.alpha == 0)
      return;

    this.color[3] = this.alpha;

    this.prg.use();
    this.prg.uColor(this.color);

    this.cfg.enableBlend(this.alpha !== 1);

    this.scene.glstate.push(this.cfg);
    this.scene.glstate.apply();

    this.scene.quad.bind();
    this.scene.quad.attribPointer(this.prg);

    this.scene.quad.render();

    this.scene.glstate.pop();

  }

}