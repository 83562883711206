import Scene from "@/webgl/Scene";
import Camera from "nanogl-camera";
import RainbowRing from "./RainbowRing";

export default class LensFlares {
  
  rainbowRing: RainbowRing;
  
  constructor( scene:Scene ) {
    this.rainbowRing = new RainbowRing( scene );
  }
  
  render( camera : Camera){
    this.rainbowRing.render(camera);
  }

  load(): Promise<void> {
    return this.rainbowRing.load();
  }

}