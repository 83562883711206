import PaintingXRActivity from "@/webgl/activities/PaintingXRActivity";
import GalleryBackground from "@/webgl/entities/gallery-background/GalleryBackground";
import GradientText from "@/webgl/entities/UIKit/gradient-text/GradientText";
import RaycastButton from "@/webgl/entities/UIKit/raycast-button/RaycastButton";
import XRModule from "@/webgl/entities/xr-module/XRModule";
import XRSharePhotoPreview from "@/webgl/activities/PaintingXRActivity/xr-share-photo/XRSharePhotoPreview";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import XRGalleryFloor from "@/webgl/entities/xr-gallery-floor/XRGalleryFloor";
import AppService from "@/store/states/AppService";
import { Download, DownloadBlob } from "@/utils/Files";
import LabelRect from "@/webgl/entities/UIKit/label-rect/LabelRect";

const VZ = vec3.fromValues(0.0, 0.0, 1.0);
const V3A = vec3.create();
const V3B = vec3.create();
const M4 = mat4.create();
const M3 = mat3.create();
const QUAT = quat.create();

export default class XRSharePhoto {

  node: Node;
  downloadBtn: RaycastButton;
  downloadedLabel: LabelRect;
  // paintingBtn: RaycastButton;
  // galleryBtn: RaycastButton;

  preview: XRSharePhotoPreview;
  background: GalleryBackground;
  floor: XRGalleryFloor;
  title: GradientText;

  private _downloadedMode: boolean = false;
  private _active: boolean = false;
  private _intransit: boolean = false;

  get IsActive(): boolean {
    return this._active;
  }

  get xrmodule(): XRModule {
    return this.activity.xrmodule;
  }

  get InTransition(): boolean {
    return this._intransit;
  }

  constructor(
    public readonly activity: PaintingXRActivity
  ) {

    this.downloadBtn = new RaycastButton(this.xrmodule.ui, "xr.download");
    // this.paintingBtn = new RaycastButton(this.xrmodule.ui, "painting_btn")
    // this.galleryBtn = new RaycastButton(this.xrmodule.ui, "photo_gallery_btn")

    this.downloadedLabel = new LabelRect(this.xrmodule.ui);
    this.downloadedLabel.setText("xr.image_downloaded");
    this.downloadedLabel.setPadding(0.4, 0.25);

    this.preview = new XRSharePhotoPreview(activity);

    this.background = new GalleryBackground(activity.scene);
    this.floor = new XRGalleryFloor(activity.scene);
    this.title = new GradientText(this.xrmodule.ui);
    this.title.setText("xr.expo_name");

    this.node = new Node();

    this.node.add(this.downloadBtn.node);
    this.node.add(this.downloadedLabel.node);
    // this.node.add(this.paintingBtn.node);
    // this.node.add(this.galleryBtn.node);
    this.node.add(this.preview.node);
    this.node.add(this.title.node);
    this.node.add(this.floor.node);

    this.title.node.z = -1.5;
    this.title.node.y = -this.xrmodule.ui.textRenderer.texts.get("xr.expo_name").height * 0.4;

  }

  async load(): Promise<any> {
    return Promise.all([
      this.floor.load(),
      this.background.load()
    ])
  }

  download = () => {
    this._downloadedMode = true;
    this.downloadBtn.onClick.off(this.download);
    this.downloadBtn.hide();
    this.downloadedLabel.show();
    DownloadBlob(AppService.state.context.sharedMedia.blob, "painting_photo.png");
  }

  onPaintingClick = () => {
    AppService.send("CANCEL");
  }

  onGalleryClick = () => {
    this.activity.fader.set(1.0);
    AppService.send("SHARE");
  }

  enable() {

    this._downloadedMode = false;
    this.xrmodule.xrroot.add(this.node);
    this.xrmodule.xrroot.add(this.floor.node);

    this.updatePosition();

    this.downloadBtn.node.y = -this.activity.snapshot.scale[1] * 0.8 * 2.0;
    this.downloadedLabel.node.y = -this.activity.snapshot.scale[1] * 0.8 * 2.0;

    // this.paintingBtn.node.y = -this.activity.snapshot.scale[1] * 0.8 * 2.0;
    // this.galleryBtn.node.y = -this.activity.snapshot.scale[1] * 0.8 * 2.0;
    // this.paintingBtn.node.x = -this.xrmodule.ui.textRenderer.texts.get("painting_btn").width * 0.5 - this.paintingBtn.Padding[0];
    // this.galleryBtn.node.x = this.xrmodule.ui.textRenderer.texts.get("photo_gallery_btn").width * 0.5 + this.paintingBtn.Padding[0] * 2.0;

    this.preview.updateScale(this.activity.snapshot.scale);
    this.preview.setTexture(this.activity.snapshot.Texture);

    this.xrmodule.raycaster.enable();
    this.xrmodule.raycaster.add(this.downloadBtn);
    // this.xrmodule.raycaster.add(this.paintingBtn);
    // this.xrmodule.raycaster.add(this.galleryBtn);

    this._intransit = true;

    this.downloadBtn.show(0.2);
    // this.paintingBtn.show(0.4);
    // this.galleryBtn.show(0.6);
    this.preview.show();
    this.title.show();
    this.floor.show();

    this.downloadBtn.onClick.on(this.download);
    // this.paintingBtn.onClick.on(this.onPaintingClick);
    // this.galleryBtn.onClick.on(this.onGalleryClick);

    this.background.show()
      .then(() => {
        this._intransit = false;
      });

    this._active = true;
  }

  disable() {

    this._intransit = true;

    this.downloadBtn.hide();
    this.downloadedLabel.hide();
    // this.paintingBtn.hide();
    // this.galleryBtn.hide();
    this.preview.hide();
    this.title.hide();
    this.floor.hide();

    this.downloadBtn.onClick.off(this.download);

    this.xrmodule.raycaster.remove(this.downloadBtn);
    this.xrmodule.raycaster.disable();
    
    // this.paintingBtn.onClick.off(this.onPaintingClick);
    // this.galleryBtn.onClick.off(this.onGalleryClick);

    // this.xrmodule.raycaster.enable();
    // this.xrmodule.raycaster.add(this.downloadBtn);

    this.background.hide().then(this.release)

  }

  release = () => {
    this.xrmodule.xrroot.remove(this.node);
    this.xrmodule.xrroot.remove(this.floor.node);

    this._intransit = false;
    this._active = false;
  }

  updatePosition() {

    mat4.invert(M4, this.xrmodule.xrroot._wmatrix);
    mat3.fromMat4(M3, M4);
    vec3.transformMat3(V3A, this.xrmodule.xrcamera.forward, M3);
    vec3.scale(V3A, V3A, 4.5);

    this.node.x = V3A[0];
    this.node.y = this.xrmodule.xrcamera.head.position[1] + 0.4;
    this.node.z = V3A[2];

    V3A[1] = 0;
    vec3.normalize(V3A, V3A);
    const a = Math.atan2(V3A[0], V3A[2]);
    quat.identity(QUAT)
    quat.rotateY(QUAT, QUAT, Math.PI);
    quat.rotateY(QUAT, QUAT, a);
    this.node.rotation.set(QUAT);

    this.node.updateWorldMatrix();

    mat3.fromMat4(M3, this.node._wmatrix);
    vec3.transformMat3(V3A, VZ, M3);

    vec3.scaleAndAdd(V3B, this.node._wposition as vec3, V3A, 0.07);
    this.xrmodule.raycaster.udpdatePlane(V3B, V3A);

  }

  preRender(): void {
    this.title.preRender(this.activity.scene.dt);
  }

  render(camera: Camera): void {

    if (!this._active)
      return;

    this.background.render(camera);
    this.title.render(camera);
    this.preview.render(camera);

    this.downloadBtn.render(camera);
    // this.paintingBtn.render(camera);
    // this.galleryBtn.render(camera);

    if (this._downloadedMode) {
      this.downloadedLabel.renderRect(camera);
      this.downloadedLabel.renderText([0.0270944, 0.0270944, 0.0270944, 1.0]);
    }

    this.floor.render(camera);

  }

}