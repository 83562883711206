import "./views-default.styl";

import { APP_STATE } from "@/store/modules/AppModule";
import { App, Component } from "vue";

import Home from "@/views/Home.vue";
import Loading from "@/views/Loading.vue";
import Gallery from "@/views/Gallery.vue";
import Painting from "@/views/Painting.vue";

import { AppStateType, AppStateEnum, AppState } from "@/store/states/AppStateMachine";

export function setupViews(app: App<Element>) {
  app.component(APP_STATE.INTRO, Home);
  // app.component(APP_STATE.LOADING, Loading);
  // app.component(APP_STATE.GALLERY, Gallery);
  // app.component(APP_STATE.PAINTING, Painting);
}



// export function resolveAppView( state: AppState ): Component {

//   if( state.matches( AppStateEnum.HOME        ) ) return Home
//   if( state.matches( AppStateEnum.LOADING     ) ) return Loading
//   if( state.matches( AppStateEnum.GALLERY     ) ) return Gallery
//   if( state.matches( AppStateEnum.SHARE       ) ) return Gallery
//   if( state.matches( AppStateEnum.PAINTING    ) ) return Painting
//   if( state.matches( AppStateEnum.MUSEUM      ) ) return Museum
//   if( state.matches( AppStateEnum.GALLERY_XR  ) ) return GalleryXR
//   if( state.matches( AppStateEnum.PAINTING_XR ) ) return PaintingXR
//   if( state.matches( AppStateEnum.MUSEUM_XR   ) ) return MuseumXR
  
//   console.warn(`Unhandled state: ${state.value}`);

//   return Home

// }