




import Masks from '@/webgl/gl/Masks';
import Input, { Sampler, Uniform } from 'nanogl-pbr/Input';

import { TextureResource } from '@/webgl/assets/TextureResource';

import ReflectDistPass from '@/webgl/glsl/reflect_dist';
import UnlitPass    from 'nanogl-pbr/UnlitPass'                      ;


import BaseMaterial from 'nanogl-pbr/BaseMaterial';
import { Passes } from '@/webgl/glsl/Passes';
import GLConfig from 'nanogl-state/config';

import { TextureSrcSet } from '@/webgl/assets/TextureRequest';
import TexCoord from 'nanogl-pbr/TexCoord';
import { StandardMetalness, StandardSpecular } from 'nanogl-pbr/StandardPass';
import LightmapChunk from '@/webgl/glsl/standard_lm/LightmapChunk';
import FloorReflectionChunk from '@/webgl/glsl/standard_ssr/FloorReflectionChunk';
import TextureLibrary from '@/webgl/assets/TextureLibrary';
import Scene from '@/webgl/Scene';
import Texture2D from 'nanogl/texture-2d';
import { MakeFromHex } from '@/webgl/lib/color';
import MuseumAssets from '@/assets/webgl/museum';
import MuseumScene from '.';
import { Pane } from 'tweakpane';
import { CreatePane } from '@/dev/Tweak';
import GL from '@/webgl/gl/GL';
import { ArtworkId } from '@/store/states/Artworks';
import { walkBlockDeclarations } from '@vue/compiler-core';
import { QualityLevel } from './MuseumQuality';
import Flag from 'nanogl-pbr/Flag';
import { MatCapPass } from '@/webgl/glsl/matcap';
import TexturesLoader from '@/webgl/assets/TextureLoader';




type ArtworkCanvasMaterialName = `ArtworkCanvas_${ArtworkId}`
type ArtworkHoverMaterialName = `ArtworkHover_${ArtworkId}`

type OtherMaterialName = 
  "Architecture" | 
  "Ceramic" | 
  "Floor" | 
  "ArtworkFrame" | 
  "ArtworkFrameOrnamented" | 
  "DefaultMaterial" |
  "TitlesMatcap" |
  "SkyDome" |
  "FloorPattern"

type MaterialName = ArtworkCanvasMaterialName | ArtworkHoverMaterialName | OtherMaterialName


const LM_GAMMA = 2.2
const LM_EXPO = .65


export default class MaterialFactory {
  
///////////////
//////////
////////////

  lightmapMultiplyer: Input;
  lightmapMultiplyerUniform: Uniform;
  lightmap_mul : number   = LM_EXPO
  lightmap_gamma : number = LM_GAMMA

  lightmapGamma: Input;
  lightmapGammaUniform: Uniform;

  floorReflectivity = .7
  floorAlbedoBoost = .75
  
  reflectDistPass: ReflectDistPass;
  texs: TextureLibrary;
  lightmaps: TextureLibrary;
  private _isInit : boolean = false;

  scene: Scene;

  floorAlbedoBoostUniform: Uniform;
  floorReflectivityUniform: Uniform;
  matByNames: Map<string, BaseMaterial>;
  floorColorMultiplierUniform: Uniform;
  reflChunk: FloorReflectionChunk;
  

  
  static makeBBCTextureSet( path: string, bbc = false ): TextureSrcSet {
    
    const sources: [string, string][] = [
      ['webp',  MuseumAssets.getPath(`${path}.webp`)],
      ['std' ,  MuseumAssets.getPath(path) ],
    ] 

    if( bbc ){
      sources.unshift(
        ['astc' , MuseumAssets.getPath( `${path}.astc.ktx` )],
        ['dxt' ,  MuseumAssets.getPath(`${path}.dxt.ktx` )],
        ['etc' ,  MuseumAssets.getPath(`${path}.etc.ktx` )],
        ['pvr' ,  MuseumAssets.getPath(`${path}.pvr.ktx` )],
      )
    }

    return new TextureSrcSet( sources )
  }

  
  constructor( private museum: MuseumScene  ) {
    
    if( this._isInit ) return;

    this.scene = museum.scene
    this.texs = new TextureLibrary()
    this.lightmaps = new TextureLibrary()
    this.matByNames = new Map<string, BaseMaterial>()
    
    this.reflectDistPass = new ReflectDistPass();
    this.reflectDistPass.mask = Masks.REFLECTED;
  
    this.lightmapMultiplyer = new Input( 'LightmapMultiplier'  , 1 )
    this.lightmapMultiplyerUniform = new Uniform('ulmlum', 1 )
    this.lightmapMultiplyerUniform.set( this.lightmap_mul)
    
    this.lightmapGamma = new Input( 'LightmapGamma'  , 1 )
    this.lightmapGammaUniform = new Uniform('ulmgam', 1 )
    this.lightmapGammaUniform.set( this.lightmap_gamma)

/////////////////
/////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////
    this.lightmapMultiplyer.attachConstant( this.lightmap_mul );
    this.lightmapGamma.attachConstant( this.lightmap_gamma );
//////////////

    this.floorAlbedoBoostUniform  = new Uniform("albedoMult", 3)
    this.floorReflectivityUniform = new Uniform("fru"       , 1)
    this.floorAlbedoBoostUniform.set( this.floorAlbedoBoost, this.floorAlbedoBoost, this.floorAlbedoBoost)
    this.floorReflectivityUniform.set( this.floorReflectivity)


    this.reflChunk = new FloorReflectionChunk()
    this.reflChunk.reflectionTexture = this.museum.reflect.getOutput()
    this.reflChunk.strength.                attach(this.floorReflectivityUniform)

    this.createTextures();


/////////////////
/////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////
  }


  unload(){
    this.matByNames.clear()
    this.lightmaps.unload()
    this.lightmaps.clear()
    this.texs.unload()
    this.texs.clear()

    this.lightmapMultiplyer.dispose()
    this.lightmapGamma.dispose()

  }


  setQuality(level: QualityLevel) {
    if( level.reflect ){
      this.floorAlbedoBoostUniform.set( this.floorAlbedoBoost, this.floorAlbedoBoost, this.floorAlbedoBoost)
    } else {
      this.floorAlbedoBoostUniform.set( 1, 1, 1 )

    }
  }


  getMaterial = (name: string):BaseMaterial => {
    
    if( this.matByNames.has(name) ) {
      return this.matByNames.get(name)
    }

    const reg = /(.+)_LM_(\d+)$/
    const res = reg.exec( name )
    // console.log(res);
    let basename = name
    if( res !== null ){
      // has lightmap
      basename = res[1]
    }


    const mat = this.createMaterial(basename as MaterialName)

    if( res !== null ){
      const udim = res[2]
      const lm_name = `LM.${udim}`

      const lm = this.getLightmapResource(lm_name)
      this.addLightmap( mat, lm )
    }

    this.matByNames.set( name, mat )
    return mat

  }


  getLightmapResource( name:string ){
    if( !this.lightmaps.has( name ) ){
      const lm = new TextureResource( MaterialFactory.makeBBCTextureSet(`${name}.jpg` ), this.scene )

      lm.response().then(t=>{
        t.clamp()
        t.setFilter(true, false, false)
      })
      this.lightmaps.add( name      ,  lm )
      lm.load()
    }
    return this.lightmaps.get( name )
  }
  
  createTextures() {

    
    const texs = this.texs

    this.addQuickTex( 'artwork_050' , 'art_050_L0.jpg', true )
    this.addQuickTex( 'artwork_052' , 'art_052_L0.jpg', true )
    this.addQuickTex( 'artwork_063' , 'art_063_L0.jpg', true )
    this.addQuickTex( 'artwork_069' , 'art_069_L0.jpg', true )
    this.addQuickTex( 'artwork_080' , 'art_080_L0.jpg', true )
    this.addQuickTex( 'artwork_083' , 'art_083_L0.jpg', true )
    this.addQuickTex( 'artwork_109' , 'art_109_L0.jpg', true )
    
    this.addQuickTex( 'matcap_title' , 'matcap_title.jpg', false )
    this.addQuickTex( 'skydome' , 'skybox.jpg', true )
    
    this.addStandardMaterialTextures( 'Frame_Painting' )
    this.addStandardMaterialTextures( 'Frame_Ornament' )
    this.addStandardMaterialTextures( 'Ceramic' )
    
    this.mipmapAndAniso(texs.getResource(`Frame_Ornament_normal.1001`), 0)
    this.mipmapAndAniso(texs.getResource(`Frame_Ornament_base.1001`), 0)
    this.mipmapAndAniso(texs.getResource(`Frame_Ornament_materialParams.1001`), 0)
    
    
    
    const fp = this.addQuickTex( 'floor_pattern' , 'FloorPattern.jpg', true )
    this.mipmapAndAniso(fp, 16)

    
    
  }


  addStandardMaterialTextures( name:string ){
    this.addQuickTex( `${name}_normal.1001`         , `${name}_normal.1001.jpg`         , false )
    this.addQuickTex( `${name}_base.1001`           , `${name}_base.1001.jpg`           , true )
    this.addQuickTex( `${name}_materialParams.1001` , `${name}_materialParams.1001.jpg` , true )
  }


  addQuickTex( id:string, filename:string, bbc:boolean ){
    const r = new TextureResource( MaterialFactory.makeBBCTextureSet(filename  , bbc ), this.scene ) 
    this.texs.add( id,  r )
    return r;
  }

  mipmapAndAniso( r:TextureResource, paniso = 0 ){

    const gl = this.scene.gl
    const extAniso = TextureResource.getTextureLoader(gl).extAniso
    const maxAniso = extAniso ? gl.getParameter(extAniso.MAX_TEXTURE_MAX_ANISOTROPY_EXT) : 0

    r.response().then(t=>{
      t.bind()
      if( t.internal === gl.RGB )
        gl.generateMipmap(t._target)
      t.setFilter(true, true, false )
      const aniso = Math.min( maxAniso , paniso )
      if( aniso > 0 ){
        gl.texParameterf( gl.TEXTURE_2D, extAniso.TEXTURE_MAX_ANISOTROPY_EXT, aniso );
      }
    })
  }

  createMaterial( name : MaterialName ) : BaseMaterial {
    switch(name){
      case 'Architecture' : return this.createWhiteWalls()

      case 'ArtworkFrame'           : return this.createStandardMaterial('Frame_Painting')
      case 'ArtworkFrameOrnamented' : return this.createStandardMaterial('Frame_Ornament')
      case 'Ceramic'                : return this.createStandardMaterial('Ceramic'  , false )

      case 'ArtworkCanvas_050' : return this._createSimpleTextured( "artwork_050" )
      case 'ArtworkCanvas_052' : return this._createSimpleTextured( "artwork_052" )
      case 'ArtworkCanvas_063' : return this._createSimpleTextured( "artwork_063" )
      case 'ArtworkCanvas_069' : return this._createSimpleTextured( "artwork_069" )
      case 'ArtworkCanvas_080' : return this._createSimpleTextured( "artwork_080" )
      case 'ArtworkCanvas_083' : return this._createSimpleTextured( "artwork_083" )
      case 'ArtworkCanvas_109' : return this._createSimpleTextured( "artwork_109" )


      case 'ArtworkHover_050' : return this.createHover()
      case 'ArtworkHover_052' : return this.createHover()
      case 'ArtworkHover_063' : return this.createHover()
      case 'ArtworkHover_069' : return this.createHover()
      case 'ArtworkHover_080' : return this.createHover()
      case 'ArtworkHover_083' : return this.createHover()
      case 'ArtworkHover_109' : return this.createHover()

      case 'Floor'           : return this.createWhiteFloor   ()
      case 'FloorPattern'    : return this.createFloorPattern ()
      case 'SkyDome'         : return this.createSkydome      ()
      case 'DefaultMaterial' : return this.createWhiteWalls   ()

      case 'TitlesMatcap' : return this.createMatcap( 'matcap_title' )
    }
    throw `missing material "${name}"` 
  }

  createStandardMaterial( name:string, occlu=true ) : BaseMaterial {
    const m = this.createBaseMaterial();

    const pass = new StandardMetalness()
    this.museum.lighting.setupMat( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;


    const uv0 = TexCoord.create( 'aTexCoord0' );

    const colorSampler = new Sampler( 'tColor', uv0 )
    colorSampler.set(this.texs.get(`${name}_base.1001`))
    pass.surface.baseColor.attach(colorSampler);
    
    const normalSampler = new Sampler( 'tNormals', uv0 )
    normalSampler.set(this.texs.get(`${name}_normal.1001`))
    pass.normal.attach(normalSampler);

    const paramsSampler = new Sampler( 'tParams', uv0 )
    paramsSampler.set(this.texs.get(`${name}_materialParams.1001`))
    pass.surface.metalness.attach(paramsSampler, 'r');
    pass.surface.roughness.attach(paramsSampler, 'g');

    if( occlu) pass.occlusion.attach(paramsSampler, 'b');
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;

  }


  createWhiteWalls(): BaseMaterial {
    
    const m = this.createBaseMaterial();


    const pass = this.createBaseSpecularPass();
    this.museum.lighting.setupExpoGmma( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;


    pass.surface.baseColor.attachConstant(MakeFromHex(0xffffff))
    // const uv0 = TexCoord.create( 'aTexCoord0' );
    // pass.surface.baseColor. attachSampler ( 'tAlbedo' , uv0 ).set( this.texs.get('gradient-walls') )

    pass.surface.specular.attachConstant([.04, .04, .04])
    pass.surface.glossiness.attachConstant([.4])
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;

  }

  createFloorPattern(): BaseMaterial {
    const m = this.createWhiteFloor()
    const pass = m.getPass(Passes.COLOR).pass as StandardSpecular
    pass.surface.baseColor.attachSampler( 'tpattern' , TexCoord.create() ).set( this.texs.get('floor_pattern') )
    return m
  }


  createWhiteFloor(): BaseMaterial {

    const m = this.createBaseMaterial();


    const texs = this.texs;

    const pass = this.createBaseSpecularPass();

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE

    pass.surface.baseColor.attachConstant(MakeFromHex(0xffffff))

    // const uv0 = TexCoord.create( 'aTexCoord0' );
    // pass.surface.baseColor. attachSampler ( 'tAlbedo' , uv0 ).set( texs.get('gradient-walls') )
    
    
    const spec = .00
    pass.surface.specular.attachConstant([spec, spec, spec])
    
    // reflChunk.strength.attachConstant( .9 )
    pass.inputs.add( this.reflChunk )
    
    
    pass     .surface .baseColorFactor.attach(this.floorAlbedoBoostUniform)


    // this.scene.iblMngr.setupMat( pass );
    this.museum.lighting.setupExpoGmma( pass );

    
    m.addPass( pass, Passes.DEFAULT );

    return m;

  }

  createArtwork( basecolorname:string):BaseMaterial {
    const m = this._createSimpleTextured(basecolorname)
    return m;
  }

  createMatcap( texname : string ):BaseMaterial{
    const m = this.createBaseMaterial();
    const texs = this.texs;

    const pass = new MatCapPass()

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;
    pass.matcapTexture = texs.get( texname )
    
    m.addPass( pass, Passes.DEFAULT );
    // m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;
  }

  _createSimpleTextured( basecolorname:string, glossiness= .4, tc?:TexCoord):BaseMaterial {
    const m = this.createBaseMaterial();
    const texs = this.texs;

    const pass = this.createBaseSpecularPass();
    this.museum.lighting.setupExpoGmma( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;

    
    const uv0 = tc || TexCoord.create( 'aTexCoord0' );
      
    pass.surface.baseColor. attachSampler ( 'tAlbedo' , uv0 ).set( texs.get(basecolorname ) )
    
    pass.surface.specular.attachConstant([.04, .04, .04])
    pass.surface.glossiness.attachConstant([glossiness])
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;
  }



  createHover() : BaseMaterial {

    const m = this.createBaseMaterial();
    
    const pass = new UnlitPass();
      
    pass.baseColor .attachConstant([1, 1, 1])

    pass.alpha.attachAttribute( 'aTexCoord0', 1, "y" )
    pass.alphaMode.set('BLEND')


    

    pass.glconfig
      .enableCullface(false)
      .enableDepthTest()
      .depthMask(false)
      .enableBlend(true)
      .blendFunc(GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA)

    

    m.addPass( pass, Passes.DEFAULT );
    pass.mask = Masks.BLENDED | Masks.REFLECTED_BLENDED;
    return m;
  }


  createCache( color : number[] ) : BaseMaterial {

    const m = this.createBaseMaterial();
    
    const pass = new UnlitPass();
      
    pass.baseColor .attachConstant(color)

    pass.glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)

    m.addPass( pass, Passes.DEFAULT );
    pass.mask = Masks.REFLECTED

    
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )
    return m;
  }


  createSkydome() : BaseMaterial {

    const m = this.createBaseMaterial();
    
    const pass = new UnlitPass();
      
    pass.baseColor .attachSampler( 'tex', TexCoord.create() ).set(this.texs.get('skydome'))

    pass.glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)

    m.addPass( pass, Passes.DEFAULT );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED

    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )
    return m;
  }


  addLightmap( material: BaseMaterial, lightmap: Texture2D ){
    const lmChunk = new LightmapChunk()
    const sampler = lmChunk.iLightmap.attachSampler('tLightmap' , TexCoord.create('aTexCoord1') );
    sampler.set( lightmap );
    lmChunk.iAmbientMultiplier.attachConstant(0)
    lmChunk.iLightmapMultiplier.proxy( this.lightmapMultiplyer )
    lmChunk.iLightmapGamma.proxy( this.lightmapGamma )

    const pass = material.getPass(Passes.DEFAULT).pass
    pass.inputs.add( lmChunk )

  }





  opaqueConfig( glconfig : GLConfig ) : void {
    glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)
  }





  createBaseMaterial( ) : BaseMaterial {
    return new BaseMaterial( this.scene.gl );
  }

  createBaseSpecularPass(): StandardSpecular {
    const p = new StandardSpecular()
    return p;
  }
  
}


